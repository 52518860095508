<template xmlns:v-nova-page-guide="http://www.w3.org/1999/xhtml">
  <div class="wrapper">
    <div class="d-flex flex-row">
      <div
        id="layers-toolbar"
        v-nova-page-guide:measureMenu
        class="layers-toolbar d-flex flex-row justify-content-between mr-auto"
        :class="{ 'toolbar-min': !leftSplitBarOpen }"
      >
        <!--        Меню со слоями-->
        <div class="layers-toolbar-content d-flex flex-column">
          <div class="d-flex flex-row">
            <router-link
              v-nova-page-guide:btnBackToProject
              class="col-2 add-btn py-3"
              :to="{ path: `/project/${this.$route.params.projectId}/detail` }"
            >
              <i class="fas fa-arrow-left" />
            </router-link>
            <button class="col-10 add-btn py-3" @click="addAnnotationGroup">
              <i class="fas fa-layer-group mx-2" />
              <translate>Add group</translate>
            </button>
          </div>
          <el-tree
            ref="tree"
            v-nova-page-guide:chooseLayers
            class="static-tree-group"
            :data="elData"
            :empty-text="emptyText"
            node-key="treeNodeId"
            default-expand-all
            icon-class="fas fa-angle-right"
            :expand-on-click-node="false"
            @current-change="handleNodeClick"
          >
            <span
              slot-scope="{ node, data }"
              class="custom-tree-node d-flex align-items-center"
              :class="{
                'disabled-node':
                  (data.needDimension && data.needDimension !== currentMode) ||
                  data.disabled,
              }"
            >
              <span style="width: 30px" class="text-center">
                <i
                  :class="[
                    data.visibility ? 'fa-eye' : 'fa-eye-slash visibility-false',
                    'fa mx-auto',
                  ]"
                  style="font-size: 1rem;"
                  @click="(e) => showHideLayer(e, node, data)"
                />
              </span>

              <i :class="[data.icon, 'mx-2']" style="font-size: 1.1rem" />
              <span>{{ node.label }}</span>

              <div
                v-show="!data.needDimension || data.needDimension === currentMode"
                class="custom-div-btns"
              >
                <!--                Opacity-->
                <span v-if="data.menu !== undefined && data.menu.opacity !== undefined">
                  <el-tooltip :visible-arrow="false" placement="bottom">
                    <div slot="content" class="tooltip-opacity">
                      <div class="text-center"><translate>Opacity</translate></div>
                      <input
                        v-model="data.menu.opacity"
                        class="control-btns-range1 mr-2 mt-2 pointer"
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        @input="setOpacity($event.target.value, data)"
                      />
                      <span class="text-center1">{{ data.menu.opacity }}</span>
                    </div>
                    <button
                      class="p-0"
                      style="border: 0px; background-color: transparent; cursor: pointer;"
                    >
                      <i class="fa fa-tint" />
                    </button>
                  </el-tooltip>
                </span>
              </div>

              <div
                v-show="data.needDimension && data.needDimension !== currentMode"
                class="custom-div-btns-2d-3d"
              >
                <button
                  v-if="currentMode === MODE_3D"
                  @click="
                    () => {
                      component = 'LeafletViewer'
                      currentInspectorTab = 'parameters'
                    }
                  "
                >
                  2D
                </button>
                <button
                  v-else-if="currentMode === MODE_2D"
                  v-loading="pointsLoading"
                  @click="
                    () => {
                      component = 'PotreeViewer'
                    }
                  "
                >
                  3D
                </button>
              </div>
            </span>
          </el-tree>

          <el-tree
            v-if="annotations"
            ref="groupTree"
            v-nova-page-guide:chooseGroup
            :data="annotations.data"
            :empty-text="emptyText"
            node-key="treeNodeId"
            default-expand-all
            icon-class="fas fa-angle-right"
            :expand-on-click-node="false"
            :draggable="true"
            :allow-drag="nodeCanBeDragged"
            :allow-drop="nodeCanBeDropped"
            @current-change="handleNodeClick"
          >
            <span
              slot-scope="{ node, data }"
              class="custom-tree-node d-flex align-items-center"
              :class="{
                'disabled-node': data.needDimension && data.needDimension !== currentMode,
              }"
              @dblclick="centerMeasure(node, data)"
            >
              <span style="width: 30px" class="text-center">
                <i
                  :class="[
                    data.visibility ? 'fa-eye' : 'fa-eye-slash visibility-false',
                    'fa mx-auto',
                  ]"
                  style="font-size: 1rem;"
                  @click="(e) => showHideMeasure(e, node, data)"
                />
              </span>

              <i :class="[data.icon, 'mx-2']" style="font-size: 1.1rem" />
              <span>{{ node.label }}</span>

              <div
                v-show="!data.needDimension || data.needDimension === currentMode"
                class="custom-div-btns"
              >
                <!--                Opacity-->
                <span v-if="data.menu !== undefined && data.menu.opacity !== undefined">
                  <el-tooltip :visible-arrow="false" placement="bottom">
                    <div slot="content" class="tooltip-opacity">
                      <div class="text-center"><translate>Opacity</translate></div>
                      <input
                        v-model="data.menu.opacity"
                        class="control-btns-range1 mr-2 mt-2 pointer"
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        @input="setOpacity($event.target.value, data)"
                      />
                      <span class="text-center1">{{ data.menu.opacity }}</span>
                    </div>
                    <button
                      class="p-0"
                      style="border: 0px; background-color: transparent; cursor: pointer;"
                    >
                      <i class="fa fa-tint" />
                    </button>
                  </el-tooltip>
                </span>

                <!--                Focus on-->
                <span v-if="data.menu !== undefined && data.menu.focus">
                  <el-tooltip
                    :visible-arrow="false"
                    :content="'Focus on annotation' | translate"
                    :open-delay="0"
                    placement="bottom"
                  >
                    <button
                      class="p-0 bg-warning"
                      style="border: 0px; background-color: transparent; cursor: pointer;"
                    >
                      <i class="fas fa-vector-square" />
                    </button>
                  </el-tooltip>
                </span>

                <!--                Polygon/Volume-->
                <span v-if="data.label === 'Polygon' || data.label === 'Volume'">
                  <el-tooltip
                    :visible-arrow="false"
                    :content="'Convert to polygon' | translate"
                    :open-delay="0"
                    placement="bottom"
                  >
                    <button
                      v-if="data.label === 'Volume'"
                      class="p-0"
                      style="border: 0px; background-color: transparent; cursor: pointer;"
                    >
                      <i class="fas fa-draw-polygon" />
                    </button>
                  </el-tooltip>
                </span>

                <!--                Save-->
                <!--                <span v-if=" (data.menu !== undefined) && (data.menu.save)">-->
                <!--                  <el-tooltip :visible-arrow="false" :content="'Save' | exclude-translate"-->
                <!--                              :open-delay="600"-->
                <!--                              placement="bottom">-->
                <!--                    <button class="p-0" @click="" style="border: 0px; background-color: transparent; cursor: pointer;">-->
                <!--                      <i class="fas fa-save"></i>-->
                <!--                    </button>-->
                <!--                  </el-tooltip>-->
                <!--                </span>-->

                <!--                Delete-->
                <span
                  v-if="data.menu !== undefined && data.menu.del !== undefined && canEdit"
                >
                  <button
                    class="p-0"
                    style="border: 0px; background-color: transparent; cursor: pointer;"
                    @click="showSwal('delete', node, data)"
                  >
                    <i class="fa fa-trash" />
                  </button>
                </span>
              </div>
            </span>
          </el-tree>

          <el-tree
            v-if="
              currentMode === MODE_3D && texturesIncluded && modeLight === 'SpotLight'
            "
            :data="helperSpotLight"
            node-key="treeNodeId"
            default-expand-all
            icon-class="fas fa-angle-right"
            :expand-on-click-node="false"
            @current-change="spotLightNodeClick"
          >
            <span
              slot-scope="{ node, data }"
              class="custom-tree-node d-flex align-items-center"
              :class="{
                'disabled-node': currentSpotlight !== data.uuid,
              }"
            >
              <span style="width: 30px" class="text-center">
                <i
                  :class="[
                    data.visible ? 'fa-eye' : 'fa-eye-slash visibility-false',
                    'fa mx-auto',
                  ]"
                  style="font-size: 1rem;"
                  @click="(e) => includeHelper(data)"
                />
              </span>
              <span>{{ data.name }}</span>
              <div v-if="data.children.length" class="custom-div-btns">
                <span>
                  <button
                    class="p-0"
                    style="border: 0px; background-color: transparent; cursor: pointer;"
                    @click="removeHelper(data)"
                  >
                    <i class="fa fa-trash" />
                  </button>
                </span>
              </div>
            </span>
          </el-tree>
          <div
            :v-page-guide="'Save your changes' | translate"
            class="mt-auto container-fluid"
          >
            <div class="row">
              <div v-if="false" class="col p-0">
                <button
                  v-show="annotations && annotations.changed"
                  class="action-btn cancel-btn py-3"
                >
                  <i class="fa fa-trash mx-2" />
                  <translate>Cancel</translate>
                </button>
              </div>
              <div class="col-12 p-0">
                <button
                  class="action-btn split-btn py-3"
                  @click="
                    $router.push({
                      name: 'splitscreen',
                    })
                  "
                >
                  <i class="fas fa-divide mx-2" />
                  <translate>Toggle split screen</translate>
                </button>
              </div>
              <div v-if="canSave" v-nova-page-guide:saveButton class="col-12 p-0">
                <button
                  v-show="annotations && annotations.changed"
                  class="action-btn save-btn py-3"
                  @click="saveAnnotations"
                >
                  <i class="fas fa-save mx-2" />
                  <translate>Save</translate>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="split-bar-left">
          <button
            class="split-bar-btn split-bar-btn-left"
            @click="leftSplitBarOpen = !leftSplitBarOpen"
          >
            <i
              :class="[
                leftSplitBarOpen ? 'nc-minimal-left' : 'nc-minimal-right',
                'nc-icon',
              ]"
            />
          </button>
        </div>
      </div>

      <div class="viewer-layout flex-grow-1" @keyup="onKeyUp">
        <notifications />

        <controls-toolbar
          ref="controls"
          v-model="currentMeasure"
          :mode="currentMode"
          :visible="canEdit"
          :current-measure="currentMeasure"
          :current-annotation="currentAnnotation"
          :textures-included="texturesIncluded"
          :mode-light="modeLight"
          @takeScreenshot="saveScreenshot"
          @initEventListeners="initEventListeners"
          @enableLine="enableLine"
          @enableRectangle="enableRectangle"
          @enableCircle="enableCircle"
          @enablePolygon="enablePolygon"
          @enableMarker="enableMarker"
          @enableCamera="enableCamera"
          @changeLight="changeLight"
          @includeHelper="includeHelper"
          @addSpotlight="addSpotlight(true)"
        />

        <!--        Блок с компонентом просмотра-->
        <div
          id="viewer"
          v-nova-page-guide:mainViewer
          class="viewer"
          style="height: 100%;"
        >
          <component
            :is="component"
            v-if="showViewer"
            ref="viewer"
            :dsm="dsm"
            :orthophoto="orthophoto"
            :annotations="annotations"
            :pointcloud="pointcloud"
            :current-inspector-tab="currentInspectorTab"
            @pointcloud="
              (value) => {
                pointcloud = value
              }
            "
            @initMap="setMapListeners"
            @setPotreeListener="listenToPotreeEvents"
            @importAnnotations="importAnnotations"
            @mountTexture="mountTexture"
            @clickOnMeasure="(object) => clickOnMeasure(object)"
          />
        </div>

        <div
          id="elevator-toolbar"
          class="elevator-toolbar"
          :class="{ 'bot-toolbar-min': !botSplitBarOpen }"
        >
          <div v-nova-page-guide:modeBtn2d3d class="control-btns control-btns-2d3d">
            <button
              class="p-0"
              active-class="active-control-btn"
              @click="
                () => {
                  component = 'LeafletViewer'
                  currentInspectorTab = 'parameters'
                }
              "
            >
              2D
            </button>
            <button
              v-loading="pointsLoading"
              :aria-disabled="pointsLoading"
              class="p-0"
              active-class="active-control-btn"
              @click="
                () => {
                  component = 'PotreeViewer'
                }
              "
            >
              3D
            </button>
          </div>

          <div class="control-btns control-btns-settings">
            <el-tooltip :visible-arrow="false" :open-delay="0" placement="top">
              <div slot="content">
                <div class="text-center">
                  <translate>Point</translate><br /><translate>Budget</translate>
                </div>
                <input
                  v-model="pointBudget"
                  class="control-btns-range my-2 pointer"
                  type="range"
                  min="100000"
                  max="10000000"
                  step="1000"
                  @change="changePointBudget"
                />
                <div class="text-center">
                  {{ pointBudget }}
                </div>
              </div>
              <button v-show="currentMode === MODE_3D" class="p-0">
                PB
              </button>
            </el-tooltip>

            <el-tooltip :visible-arrow="false" :open-delay="0" placement="top">
              <div slot="content">
                <div class="text-center">
                  <translate>Point</translate><br /><translate>Size</translate>
                </div>
                <input
                  v-model="pointSize"
                  class="control-btns-range my-2 pointer"
                  type="range"
                  min="0"
                  max="3"
                  step="0.01"
                  @change="changePointSize"
                />
                <div class="text-center">
                  {{ pointSize }}
                </div>
              </div>
              <button v-show="currentMode === MODE_3D" disabled1 class="p-0">
                PS
              </button>
            </el-tooltip>

            <!--Кнопка настроек видимости лейблов-->
            <el-tooltip :visible-arrow="false" :open-delay="0" placement="top">
              <div slot="content" class="text-center pointer px-2" style="width: 200px">
                <div class="row">
                  <div
                    class="m-0 p-1"
                    :class="currentMode === MODE_3D ? 'col-4' : 'col-6'"
                  >
                    <div>
                      <translate>Show distance/area measurements</translate>
                    </div>
                  </div>
                  <div
                    class="m-0 p-1"
                    :class="currentMode === MODE_3D ? 'col-4' : 'col-6'"
                  >
                    <div>
                      <translate>Show title measurements</translate>
                    </div>
                  </div>
                  <div v-if="currentMode === MODE_3D" class="col-4 m-0 p-1">
                    <div>
                      <translate>Show line measure angle labels</translate>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="m-0 p-0"
                    :class="currentMode === MODE_3D ? 'col-4' : 'col-6'"
                  >
                    <i
                      :class="[
                        showMeasureLabels ? 'fa-eye' : 'fa-eye-slash text-danger',
                        'fa mt-2',
                      ]"
                      @click="toggleMeasureLabelsVisibility(!showMeasureLabels)"
                    />
                  </div>
                  <div
                    class="m-0 p-0"
                    :class="currentMode === MODE_3D ? 'col-4' : 'col-6'"
                  >
                    <i
                      :class="[
                        showTitleLabels ? 'fa-eye' : 'fa-eye-slash text-danger',
                        'fa mt-2',
                      ]"
                      @click="toggleTitleLabelsVisibility(!showTitleLabels)"
                    />
                  </div>
                  <div v-if="currentMode === MODE_3D" class="col-4 m-0 p-0">
                    <i
                      :class="[
                        showAngleLabels ? 'fa-eye' : 'fa-eye-slash text-danger',
                        'fa mt-2',
                      ]"
                      @click="toggleAngleLabelsVisibility(!showAngleLabels)"
                    />
                  </div>
                </div>
              </div>
              <button class="p-0">
                <i class="fas fa-cog" />
              </button>
            </el-tooltip>
          </div>

          <!--          <div class="control-btns control-btns-save">-->
          <!--            <el-tooltip :visible-arrow="false" :content="'Undo' | exclude-translate"-->
          <!--                        :open-delay="600"-->
          <!--                        placement="top">-->
          <!--              <button class="p-0">-->
          <!--                <i class="fas fa-undo-alt"></i>-->
          <!--              </button>-->
          <!--            </el-tooltip>-->

          <!--            <el-tooltip :visible-arrow="false" :content="'Redo' | exclude-translate"-->
          <!--                        :open-delay="600"-->
          <!--                        placement="top">-->
          <!--              <button class="p-0">-->
          <!--                <i class="fas fa-redo-alt"></i>-->
          <!--              </button>-->
          <!--            </el-tooltip>-->
          <!--          </div>-->

          <div id="split-bar-bot">
            <button
              class="split-bar-btn split-bar-btn-bot"
              @click="botSplitBarOpen = !botSplitBarOpen"
            >
              <i
                :class="[
                  botSplitBarOpen ? 'nc-minimal-down' : 'nc-minimal-up',
                  'nc-icon mx-auto',
                ]"
              />
            </button>
          </div>
          <div v-if="currentMeasure" class="row">
            <div class="col-12 py-2">
              <translate class="px-5">
                Elevator-toolbar
              </translate>
            </div>
          </div>
          <div v-loading="loadings.elevationToolbar" class="canvas-area">
            <div class="name-y-axis text-center pb-3 text-muted">
              <translate>Height above sea level (m)</translate>
            </div>
            <chart
              ref="elevator"
              class="pl-3"
              :options="elevatorChartOptions"
              autoresize
              @globalout="removeViewerPickSphere"
              @datazoom="elevatorOnDataZoom"
            />
          </div>
          <div class="d-flex justify-content-center text-muted">
            <translate>Length (m)</translate>
          </div>
        </div>
      </div>

      <!--      Меню инспектор-->
      <div
        id="inspector-toolbar"
        :v-page-guide="'Info about the selected object' | translate"
        :class="[
          rightSplitBarOpen ? '' : 'toolbar-min',
          'inspector-toolbar d-flex flex-row justify-content-between ml-auto',
        ]"
      >
        <div id="split-bar-right">
          <button
            class="split-bar-btn split-bar-btn-right"
            @click="rightSplitBarOpen = !rightSplitBarOpen"
          >
            <i
              :class="[
                rightSplitBarOpen ? 'nc-minimal-right' : 'nc-minimal-left',
                'nc-icon ml-auto',
              ]"
            />
          </button>
        </div>
        <div
          v-nova-page-guide:inspectorToolbar
          v-nova-page-guide:photoInspectorTab
          class="inspector-toolbar-content"
        >
          <div class="mt-2">
            <el-tabs v-model="currentInspectorTab" type="border-card">
              <el-tab-pane :label="'Parameters' | translate" name="parameters">
                <div class="inspector-toolbar-header py-3 mb-2">
                  <i
                    v-if="selectedInspectorItem && selectedInspectorItem.icon"
                    class="mr-3"
                    :class="selectedInspectorItem.icon"
                  />
                  {{ selectedInspectorTypeName }}
                </div>
                <div v-if="selectedInspectorItem" v-nova-page-guide:elementGroupMenu>
                  <div
                    v-if="
                      selectedInspectorItem && selectedInspectorItem.name !== undefined
                    "
                    class="px-2 mb-3"
                  >
                    <translate>Name</translate>:
                    <div>
                      <input
                        v-model="selectedInspectorItem.name"
                        class="inspector-input"
                        type="text"
                        :readonly="!canEdit"
                        @input="debounceChangeNameLabel"
                      />
                    </div>
                  </div>

                  <div
                    v-if="
                      selectedInspectorItem &&
                        selectedInspectorItem.description !== undefined
                    "
                    class="px-2 mb-3"
                  >
                    <translate>Description</translate>:
                    <div>
                      <textarea
                        v-model="selectedInspectorItem.description"
                        class="inspector-input"
                        rows="3"
                        maxlength="75"
                        :readonly="!canEdit"
                        @keyup="textAreaSize"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      selectedInspectorItem && selectedInspectorItem.color !== undefined
                    "
                    class="px-2 mb-1 d-flex align-items-center"
                  >
                    <span> <translate>Color</translate>: </span>
                    <el-tooltip
                      :visible-arrow="false"
                      :open-delay="0"
                      popper-class="color-palette-wrapper"
                      placement="right"
                    >
                      <div slot="content" class="color-palette">
                        <div class="color-palette-header">
                          <translate>Color Palette</translate>
                        </div>

                        <div class="color-palette-body">
                          <div class="container-fluid mt-2">
                            <div class="row">
                              <div
                                v-for="(item, index) in userPalette"
                                :key="index"
                                class="col-auto p-0"
                              >
                                <drag
                                  class="drag user-palette m-1"
                                  :class="{ 'selected-color': index === selectedColor }"
                                  :transfer-data="index"
                                  :style="{ backgroundColor: item }"
                                  style="position: relative;"
                                  tag="button"
                                  :image-x-offset="12"
                                  :image-y-offset="12"
                                  @click.native="selectColor(index)"
                                >
                                  <template slot="image">
                                    <button
                                      class="user-palette m-1"
                                      :style="{ backgroundColor: item }"
                                    />
                                  </template>
                                </drag>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-auto p-0">
                                <button
                                  class="user-palette m-1 p-0 d-flex align-items-center justify-content-center"
                                  style="font-size: 1rem; color: white"
                                  :style="{
                                    backgroundColor: selectedInspectorItem.color,
                                  }"
                                  @click="addColorToPalette"
                                >
                                  <i class="fas fa-plus" />
                                </button>
                              </div>
                              <div
                                class="col-auto p-0"
                                @click="deleteColor(selectedColor)"
                              >
                                <drop
                                  class="drop user-palette m-1 p-0 d-flex align-items-center justify-content-center"
                                  :class="{ over }"
                                  style="font-size: 0.9rem; color: white; background-color: #303133"
                                  @dragover="over = true"
                                  @dragleave="over = false"
                                  @drop="deleteColor"
                                >
                                  <i class="fa fa-trash" />
                                </drop>
                              </div>
                            </div>
                          </div>
                          <color-picker
                            v-model="selectedInspectorItem.color"
                            class="mt-1"
                            :width="99"
                            :height="99"
                          />
                        </div>
                      </div>

                      <button
                        class="selected-color1 user-palette mx-3"
                        :style="{ backgroundColor: selectedInspectorItem.color }"
                      />
                    </el-tooltip>
                  </div>
                  <div v-if="currentMeasure">
                    <div class="px-2 mt-4 mb-3 inspector-block-header">
                      <translate>Measurements</translate>
                    </div>
                    <div v-if="currentMeasureHasLength" class="px-2 mb-3">
                      <translate>Length 2D</translate>:
                      <span class="ml-2 font-weight-normal">
                        {{ currentMeasure.length2D | beautifyMeasure('distance') }}
                      </span>
                    </div>
                    <div v-if="currentMeasureHasLength" class="px-2 mb-3">
                      <translate>Length 3D</translate>:
                      <span class="ml-2 font-weight-normal">
                        {{ currentMeasure.length3D | beautifyMeasure('distance') }}
                      </span>
                    </div>

                    <div v-if="currentMeasureHasArea" class="px-2 mb-3">
                      <translate>Area 2D</translate>:
                      <span class="ml-2 font-weight-normal">
                        {{ currentMeasure.area2D | beautifyMeasure('area') }}
                      </span>
                    </div>
                    <div v-if="currentMeasureHasArea" class="px-2 mb-3">
                      <translate>Area 3D</translate>:
                      <span class="ml-2 font-weight-normal">
                        {{ currentMeasure.area3D | beautifyMeasure('area') }}
                      </span>
                    </div>
                    <div v-if="currentMeasureHasVolume" class="px-2 mb-3">
                      <translate>Volume</translate>:
                      <span class="ml-2 font-weight-normal">
                        {{ currentMeasure.volume | beautifyMeasure('volume') }}
                      </span>
                      <v-icon v-if="currentMeasure._volumeLock" name="spinner" pulse />
                      <el-tooltip
                        v-if="currentMeasure._volumeNeedUpdate"
                        :content="'Calculate Volume' | translate"
                        :visible-arrow="false"
                      >
                        <i
                          class="ml-2 fas fa-sync"
                          @click="currentMeasure.calculateVolume()"
                        />
                      </el-tooltip>
                      <el-tooltip :visible-arrow="false" placement="left">
                        <template v-slot:content>
                          <span v-translate>
                            The volume is calculated by points that fall into the outlined
                            polygon,
                          </span>
                          <br />
                          <span v-translate>
                            a volumetric figure is built from them, from which the volume
                            is calculated.
                          </span>
                        </template>
                        <i class="ml-2 fas fa-question-circle" />
                      </el-tooltip>
                    </div>
                    <div
                      v-if="
                        (currentMeasure.type === 'annotation-circle' ||
                          currentMeasure.type === 'annotation-rectangle') &&
                          currentMode === MODE_3D
                      "
                      class="px-2 mb-3"
                    >
                      <div>
                        <translate>Fill</translate>
                        <span class="ml-2">
                          <input v-model="currentMeasure.fill" type="checkbox" />
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="currentMeasure.dashed !== undefined && !currentMeasure.isNew"
                      class="px-2 mb-3"
                    >
                      <div class="mb-3">
                        <div>
                          <translate>Dashed line</translate>
                          <span class="ml-2">
                            <input v-model="currentMeasure.dashed" type="checkbox" />
                          </span>
                        </div>
                      </div>
                      <div v-if="currentMeasure.dashed">
                        <div class="mb-3">
                          <translate>Gap Size</translate>:
                          <div class="p-2">
                            <el-slider
                              v-model="currentMeasure.gapSize"
                              :max="20"
                              :min="1"
                              :step="1"
                              :show-tooltip="true"
                              tooltip-class="custom-tooltip-class"
                            />
                          </div>
                        </div>

                        <div class="mb-3">
                          <translate>Dash Size</translate>:
                          <div class="p-2">
                            <el-slider
                              v-model="currentMeasure.dashSize"
                              :max="20"
                              :min="1"
                              :step="1"
                              :show-tooltip="true"
                              tooltip-class="custom-tooltip-class"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        currentMeasure.type === 'annotation-polygon' &&
                          currentMode === MODE_3D &&
                          !currentMeasure._volumeLock &&
                          currentMeasure.volume
                      "
                      key="highligthing"
                      class="px-2 mb-3"
                    >
                      <div class="mb-3">
                        <translate>Highlight points</translate>
                        <span class="ml-2">
                          <input v-model="currentMeasure.showMesh" type="checkbox" />
                        </span>
                      </div>
                      <div class="mb-3">
                        <translate>Choose density</translate>
                        <div class="d-flex p-2">
                          <el-select v-model="currentMeasure.density" value-key="density">
                            <el-option
                              v-for="item in densityItems"
                              :key="item.text"
                              :value="item"
                              :label="item.text"
                            />
                          </el-select>
                          <input
                            :value="currentMeasure.density.density"
                            class="custom-num-input px-2 text-center"
                            :disabled="currentMeasure.density.disabled"
                            type="number"
                            :min="0"
                            :step="1"
                            @input="
                              (e) => currentMeasure.chooseCustomDensity(e.target.value)
                            "
                          />
                        </div>
                      </div>
                      <div class="px-2 mb-3">
                        <translate>Mass</translate>:
                        <span class="ml-2 font-weight-normal">
                          {{
                            !currentMeasure.mass
                              ? $gettext('Choose material or input custom density')
                              : currentMeasure.mass | beautifyMeasure('mass')
                          }}
                        </span>
                      </div>
                      <div class="mb-3">
                        <translate>Up bounding box offset in meters</translate>:
                        <div class="d-flex p-2">
                          <el-button
                            icon="fas fa-minus"
                            class="custom-num-btn b-radius-left"
                            size="mini"
                            @click="currentMeasure.maxZ--"
                          />
                          <input
                            v-model.lazy="currentMeasure.maxZ"
                            class="custom-num-input px-2 text-center"
                            type="number"
                            :min="0"
                            :step="1"
                          />
                          <el-button
                            icon="fas fa-plus"
                            class="custom-num-btn b-radius-right"
                            size="mini"
                            @click="currentMeasure.maxZ++"
                          />
                        </div>
                      </div>
                      <div class="mb-3">
                        <translate>Down bounding box offset in meters</translate>:
                        <div class="d-flex p-2">
                          <el-button
                            icon="fas fa-minus"
                            class="custom-num-btn b-radius-left"
                            size="mini"
                            @click="currentMeasure.minZ--"
                          />
                          <input
                            v-model.lazy="currentMeasure.minZ"
                            class="custom-num-input px-2 text-center"
                            type="number"
                            :min="0"
                            :step="1"
                          />
                          <el-button
                            icon="fas fa-plus"
                            class="custom-num-btn b-radius-right"
                            size="mini"
                            @click="currentMeasure.minZ++"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="currentMeasure.type === 'annotation-rectangle'"
                      key="rectangle"
                      class="px-2 mb-3"
                    >
                      <div class="mb-3">
                        <translate>Square</translate>
                        <span class="ml-2">
                          <input
                            v-model="currentMeasure.isSquare"
                            type="checkbox"
                            :disabled="currentMeasure.isSquare"
                          />
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="
                        currentMeasure.type === 'annotation-circle' &&
                          currentMode === MODE_3D
                      "
                      class="px-2 mb-3"
                    >
                      <div class="mb-3">
                        <translate>Radius</translate>:
                        <div class="d-flex p-2">
                          <el-button
                            icon="fas fa-minus"
                            class="custom-num-btn b-radius-left"
                            size="mini"
                            @click="currentMeasure.radius--"
                          />
                          <input
                            v-model="currentMeasure.radius"
                            class="custom-num-input px-2 text-center"
                            type="number"
                            :min="0"
                            :step="1"
                          />
                          <el-button
                            icon="fas fa-plus"
                            class="custom-num-btn b-radius-right"
                            size="mini"
                            @click="currentMeasure.radius++"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="currentMeasure.type === 'annotation-rectangle'"
                      class="px-2 mb-3"
                    >
                      <div class="mb-3">
                        <translate>Rotation</translate>:
                        <div class="d-flex p-2">
                          <el-button
                            icon="fas fa-minus"
                            class="custom-num-btn b-radius-left"
                            size="mini"
                            @click="currentMeasure.rotation--"
                          />
                          <input
                            v-model.lazy="currentMeasure.rotation"
                            class="custom-num-input px-2 text-center"
                            type="number"
                            :min="0"
                          />
                          <el-button
                            icon="fas fa-plus"
                            class="custom-num-btn b-radius-right"
                            size="mini"
                            @click="currentMeasure.rotation++"
                          />
                        </div>
                      </div>
                      <div class="mb-3">
                        <translate>Line A Length</translate>:
                        <div class="d-flex p-2">
                          <el-button
                            icon="fas fa-minus"
                            class="custom-num-btn b-radius-left"
                            size="mini"
                            @click="currentMeasure.lineALength--"
                          />
                          <input
                            v-model.lazy="currentMeasure.lineALength"
                            class="custom-num-input px-2 text-center"
                            type="number"
                            :min="0"
                            :step="1"
                          />
                          <el-button
                            icon="fas fa-plus"
                            class="custom-num-btn b-radius-right"
                            size="mini"
                            @click="currentMeasure.lineALength++"
                          />
                        </div>
                      </div>
                      <div class="mb-3">
                        <translate>Line B Length</translate>:
                        <div class="d-flex p-2">
                          <el-button
                            icon="fas fa-minus"
                            class="custom-num-btn b-radius-left"
                            size="mini"
                            @click="currentMeasure.lineBLength--"
                          />
                          <input
                            v-model.lazy="currentMeasure.lineBLength"
                            class="custom-num-input px-2 text-center"
                            type="number"
                            :min="0"
                            :step="1"
                          />
                          <el-button
                            icon="fas fa-plus"
                            class="custom-num-btn b-radius-right"
                            size="mini"
                            @click="currentMeasure.lineBLength++"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="botSplitBarOpen && currentMode === MODE_3D"
                      class="profile-settings-panel px-2"
                    >
                      <div class="mt-4 mb-3 text-uppercase">
                        <translate>Profile settings</translate>
                      </div>
                      <div class="mb-3">
                        <el-checkbox
                          v-model="elevatorOptions.showProfile"
                          class="mx-0"
                          @change="togglePotreeProfile"
                        />
                        <translate class="pl-2">
                          Enable profile projection
                        </translate>
                      </div>
                      <div class="mb-3">
                        <translate>Width (m)</translate>:
                        <div class="p-2">
                          <el-slider
                            v-model="elevatorOptions.width"
                            :max="10"
                            :min="1"
                            :step="0.5"
                            :show-tooltip="true"
                            tooltip-class="custom-tooltip-class"
                            @change="debounceRecomputeProfile"
                          />
                        </div>
                      </div>
                      <div class="mb-3">
                        <translate>Density (p/m)</translate>:
                        <div class="p-2">
                          <el-slider
                            v-model="elevatorOptions.density"
                            :max="1000"
                            :min="100"
                            :step="1"
                            :show-tooltip="true"
                            tooltip-class="custom-tooltip-class"
                            @change="debounceRecomputeProfile"
                          />
                        </div>
                      </div>
                      <div class="mb-3 d-flex justify-content-center">
                        <button class="btn btn-link m-0" @click="resetProfileSettings">
                          <translate>Reset profile settings</translate>
                        </button>
                      </div>
                    </div>
                    <div
                      v-if="
                        currentMeasure.type === 'annotation-camera' &&
                          currentMode === MODE_3D
                      "
                      class="mt-3"
                    >
                      <div class="mb-3 d-flex justify-content-center">
                        <el-button
                          class="btn btn-link m-0"
                          @click="currentMeasure.showCamera()"
                        >
                          <translate>Show camera</translate>
                        </el-button>
                      </div>
                      <div v-if="cameraMode" class="mb-3 d-flex justify-content-center">
                        <el-button
                          class="btn btn-link m-0"
                          @click="currentMeasure.closeCamera()"
                        >
                          <translate>Close camera</translate>
                        </el-button>
                      </div>
                      <div v-if="cameraMode" class="mb-3">
                        <translate>FOV</translate>
                        <div class="d-flex p-2">
                          <el-button
                            icon="fas fa-minus"
                            class="custom-num-btn b-radius-left"
                            size="mini"
                            @click="currentMeasure.fov--"
                          />
                          <input
                            v-model.lazy="currentMeasure.fov"
                            class="custom-num-input px-2 text-center"
                            type="number"
                            :min="0"
                          />
                          <el-button
                            icon="fas fa-plus"
                            class="custom-num-btn b-radius-right"
                            size="mini"
                            @click="currentMeasure.fov++"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="px-2 mb-3">
                      <translate>Points coordinates</translate>:
                    </div>
                    <div class="px-2 mb-3">
                      <div
                        v-for="(item, index) in coordinates"
                        :key="`${index}-coordinates`"
                        class="legend-value"
                      >
                        <span class="font-weight-normal">
                          {{ item.lat }}, {{ item.lng }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div v-if="selectedInspectorItem.type === 'dsm-layer'" class="mt-3">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-auto pl-3">
                          <div
                            class="legend-gradient"
                            :style="{ background: legendGradient }"
                          />
                        </div>
                        <div class="col-auto">
                          <div
                            v-for="(item, index) in legendQuery"
                            :key="index"
                            class="legend-value"
                          >
                            {{ item.value }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                v-if="currentMode === MODE_2D"
                disabled
                :label="'Inspector (only 3D)' | translate"
                name="inspector"
              />
              <el-tab-pane
                v-if="currentMode === MODE_3D"
                :label="'Inspector' | translate"
                name="inspector"
              >
                <photo-inspector v-model="currentInspectorPoint" />
              </el-tab-pane>
            </el-tabs>
            <div class="inspector-toolbar">
              <button
                v-if="
                  currentMeasure &&
                    currentMeasure.isElevationProfile &&
                    currentInspectorTab === 'parameters'
                "
                class="inspector-toolbar-btn py-3"
                @click="toggleElevationProfile"
              >
                <translate v-if="botSplitBarOpen" key="hide">
                  Hide elevation profile
                </translate>
                <translate v-else key="show">
                  Show elevation profile
                </translate>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nova-page-guide ref="novaPageGuideRef" :guide-obj="guideObj" />
  </div>
</template>

<script>
import { ConvexBufferGeometry } from 'src/vendor/libs/three.js/ConvexGeometry'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/radar'
import 'echarts/lib/chart/map'
import 'echarts/lib/chart/treemap'
import 'echarts/lib/chart/graph'
import 'echarts/lib/chart/gauge'
import 'echarts/lib/chart/funnel'
import 'echarts/lib/chart/parallel'
import 'echarts/lib/chart/sankey'
import 'echarts/lib/chart/boxplot'
import 'echarts/lib/chart/candlestick'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/chart/lines'
import 'echarts/lib/chart/heatmap'
import 'echarts/lib/component/graphic'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/geo'
import 'echarts/lib/component/parallel'
import 'echarts/lib/component/singleAxis'
import 'echarts/lib/component/brush'
import 'echarts/lib/component/title'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/markPoint'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markArea'
import 'echarts/lib/component/timeline'
import 'echarts/lib/component/toolbox'

import {
  Button,
  Checkbox,
  Notification,
  Option,
  Select,
  Slider,
  TabPane,
  Tabs,
  Tooltip,
  Tree,
} from 'element-ui'
import ControlsToolbar from '@nova/Layout/ControlsToolbar'
import Utils from '@nova/Classes/Utils'

import { MODE_2D, MODE_3D, SELECTED_COLOR, STANDART_DENSITY_ITEMS } from '@nova/constants'
import html2canvas from 'html2canvas'
import downloadjs from 'downloadjs'
import { fromLatLon } from 'utm'

import ColorPicker from 'vue-color-picker-wheel'
import { Drag, Drop } from 'vue-drag-drop'

import swal from 'sweetalert2'
import { Measure } from '@nova/Classes/Common/Measures/Measure'
import { Annotation } from '@nova/Classes/Common/Annotations/Annotation'
import { Annotations } from '@nova/Classes/Common/Annotations/Annotations'
import { Line } from '@nova/Classes/Common/Measures/Line'
import * as Potree from '@letsnova/potree/build/potree/potree'
import NovaPageGuide from '@nova/NovaPageGuide/NovaPageGuide'
import PhotoInspector from '@nova/Layout/ProjectsLayout/PhotoInspector'
import { debounce } from 'lodash'
import * as THREE from 'three'
import { loadPointcloud } from '@utils/loadPointcloud'
import LeafletViewer from '@nova/2D/LeafletViewer.vue'
import PotreeViewer from '@nova/3D/PotreeViewer.vue'
import viewerMixin from '@nova/Mixins/viewerMixin'
import mapMixin from '@nova/Mixins/mapMixin'
import { Marker } from '@nova/Classes/Common/Measures/Marker'
import Camera from '@nova/Classes/Common/Measures/Camera'

let self = null

export default {
  components: {
    NovaPageGuide,
    PhotoInspector,
    [Tooltip.name]: Tooltip,
    [Tree.name]: Tree,
    [Checkbox.name]: Checkbox,
    [Slider.name]: Slider,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Select.name]: Select,
    [Option.name]: Option,
    ColorPicker,
    Drag,
    Drop,
    chart: ECharts,
    ControlsToolbar,
    LeafletViewer,
    PotreeViewer,
  },
  filters: {
    beautifyMeasure(value, type) {
      if (value) {
        switch (type) {
          case 'distance':
            return value < 1000
              ? value.toFixed(2) + ' ' + self.$gettext('m')
              : (value / 1000).toFixed(2) + ' ' + self.$gettext('km')
          case 'area':
            return value < 1000 * 1000
              ? value.toFixed(1) + ' ' + self.$gettext('m²')
              : (value / (1000 * 1000)).toFixed(2) + self.$gettext('km²')
          case 'volume':
            return value < 1000 * 1000 * 1000
              ? value.toFixed(1) + ' ' + self.$gettext('m³')
              : (value / (1000 * 1000 * 1000)).toFixed(2) + ' ' + self.$gettext('km³')
          case 'mass':
            return value < 1000
              ? value.toFixed(1) + ' ' + self.$gettext('kg')
              : (value / 1000).toFixed(2) + ' ' + self.$gettext('ton')
        }
      } else {
        return '-'
      }
    },
  },
  mixins: [viewerMixin, mapMixin],
  props: {
    project: {
      type: Number,
      default() {
        return parseInt(this.$route.params.projectId)
      },
    },
    task: {
      type: String,
      default() {
        return this.$route.params.taskId
      },
    },
  },
  data() {
    return {
      MODE_2D,
      MODE_3D,
      guideObj: [
        {
          name: 'measureMenu',
          mode: 2,
          position: '',
          desc: this.$gettext(
            'On the left there is a menu with measurements, where you can combine all measurements into a group, add and delete them, and also adjust transparency.'
          ),
        },
        {
          name: 'chooseLayers',
          mode: 3,
          position: 'relative',
          desc: this.$gettext('Here you can select the desired layer.'),
        },
        {
          name: 'inspectorToolbar',
          mode: 2,
          position: '',
          desc: this.$gettext(
            "On the right is the information menu with measurement characteristics, where you can change the color of the lines, add a name and description to them, make the line dotted, and also change the size of the gap and stroke. On the Parameters tab, all measurement parameters (length, width, area and volume) will be displayed. There is also an Inspector tab, let's get back to it in 3D mode."
          ),
        },
        {
          name: 'controlsBtn',
          mode: 2,
          position: 'absolute',
          desc: this.$gettext(
            'This is a toolbar with which you can draw a line, a polygon, put a marker on the map, and add a rectangle or circle. It is also available in 3D.'
          ),
        },
        {
          name: 'controlBtnView',
          mode: 2,
          position: 'absolute',
          desc: this.$gettext(
            'Viewing tools. In 2D mode, you can center the map and take a screenshot. More tools will be available in 3D.'
          ),
        },
        {
          name: 'controlBtnView3d',
          mode: 3,
          position: 'relative',
          desc: this.$gettext(
            'Viewing tools. In 3D mode, you can look at the object from different sides, as well as take a screenshot and center the map.'
          ),
        },
        {
          name: 'photoInspectorTab',
          mode: 3,
          position: 'relative',
          desc: this.$gettext(
            'In 3D mode, the Inspector tab is available to us, go to it. Here we can see the original photos taken at the facility. By clicking on the place on the map that you would like to find in the original photos, then in the right panel we will see the coordinates and all the photos in which the given point is present. Close the instructions and try it yourself.'
          ),
        },
        // drawLine: {
        //   position: 'relative',
        //   desc: 'drawLine'
        // },
        // modeBtn2d3d: {
        //   position: 'absolute',
        //   desc: 'modeBtn2d3d'
        // },
        // btnBackToProject: this.$gettext('Button to return to the list of projects'),
        //
        // chooseGroup: this.$gettext('Here you can select a group for working with elements'),
        // elementGroupMenu: this.$gettext('Menu for working with the characteristics of the current element/group/layer'),
        // mainViewer: this.$gettext('This is awesome main viewer!'),
        // // changeModeTo3D: this.$gettext('Here you can change view to 3D mode or 2D'),
        // saveButton: this.$gettext('Remember to save your changes'),
      ],
      pointsLoading: false,
      legendGradient: null,
      legendQuery: [
        { value: 498, r: 0, g: 0, b: 4 },
        { value: 520, r: 31, g: 16, b: 75 },
        { value: 543, r: 88, g: 21, b: 126 },
        { value: 566, r: 144, g: 42, b: 129 },
        { value: 589, r: 202, g: 62, b: 114 },
        { value: 612, r: 245, g: 105, b: 92 },
        { value: 635, r: 254, g: 171, b: 117 },
        { value: 664, r: 252, g: 253, b: 191 },
      ],
      // Выбранная аннотация, для корректного добавления новых замеров в нужную аннотацию
      currentAnnotation: null,
      currentMeasure: null,
      leftSplitBarOpen: true,
      rightSplitBarOpen: true,
      botSplitBarOpen: false,
      pointBudget: 1000 * 1000,
      pointSize: 1,
      // Параметры отображения лейблов длины/площади и лейблов названия замеров
      showAngleLabels: false,
      over: false,
      selectedColor: 0,
      userPalette: ['#17f589', '#6bf51e', '#f5f51a', '#f55419', '#f5114c', '#ed18f5'],
      // Выбранный пункт в меню/для показа в инспекторе
      selectedInspectorItem: null,
      // Обозначение зоны UTM, нужно для пересчёта координат
      utmZoneNum: null,
      utmZoneLetter: null,
      // Справочник аннотаций, по дефолту ставим пустым массивом
      annotations: null,
      // отображать карту
      showViewer: false,

      emptyText: this.$gettext('No data'),
      loadings: {
        elevationToolbar: false,
      },
      // Текущая вкладка инспектора
      currentInspectorTab: 'parameters',
      // Роль пользователя в проекте
      userRole: null,
      // Стандартные значения плотности
      densityItems: STANDART_DENSITY_ITEMS.call(this),
      component: 'LeafletViewer',
      currentSpotlight: null,
    }
  },
  computed: {
    assetsPath() {
      return this.$root.apiPath(`/api/projects/${this.project}/tasks/${this.task}/assets`)
    },
    currentMode() {
      switch (this.component) {
        case 'PotreeViewer':
          return 3
        case 'LeafletViewer':
          return 2
        default:
          return null
      }
    },
    selectedInspectorTypeName() {
      // Вычисление текущего типа
      if (this.selectedInspectorItem && this.selectedInspectorItem.type) {
        switch (this.selectedInspectorItem.type) {
          case 'annotation':
            return this.$gettext('Group')
          case 'annotation-marker':
            return this.$gettext('Marker')
          case 'annotation-camera':
            return this.$gettext('Camera')
          case 'annotation-rectangle':
            return this.$gettext('Rectangle')
          case 'annotation-circle':
            return this.$gettext('Circle')
          case 'annotation-line':
            return this.$gettext('Line')
          case 'annotation-polygon':
            return this.$gettext('Polygon')
          case 'annotation-volume':
            return this.$gettext('Volume')
          case 'annotation-inspection':
            return this.$gettext('Inspection')
          case 'orthophoto-layer':
            return this.$gettext('Orthomosaic')
          case 'dsm-layer':
            return this.$gettext('DSM')
          case 'pointcloud':
            return this.$gettext('Point cloud')
          case '3d-mesh':
            return this.$gettext('3D Textured mesh')
        }
      }

      return this.$gettext('nVision')
    },
    /**
     * Может ли пользователь сохранять замеры
     * @return {boolean}
     */
    canSave() {
      return (
        this.userRole === 'owner' ||
        this.userRole === 'project_admin' ||
        this.userRole === 'internal' ||
        this.userRole === 'external'
      )
    },
    /**
     * Может ли пользователь редактировать замеры
     * @return {boolean}
     */
    canEdit() {
      return (
        this.userRole === 'owner' ||
        this.userRole === 'project_admin' ||
        this.userRole === 'internal' ||
        this.userRole === 'external'
      )
    },
    currentMeasureHasLength() {
      return !['annotation-marker', 'annotation-camera'].includes(
        this.currentMeasure.type
      )
    },
    currentMeasureHasArea() {
      return !['annotation-marker', 'annotation-camera', 'annotation-line'].includes(
        this.currentMeasure.type
      )
    },
    currentMeasureHasVolume() {
      return this.currentMeasure.type === 'annotation-polygon'
    },
    showTitleLabels() {
      return this.$store.state.viewer.showTitleLabels
    },
    showMeasureLabels() {
      return this.$store.state.viewer.showMeasureLabels
    },
    coordinates() {
      return this.currentMeasure.points
    },
  },
  watch: {
    currentMeasure(newValue, oldValue) {
      if (newValue) {
        newValue.setLayerColor(SELECTED_COLOR)
        newValue.setLayerOpacity(1.0)
        this.currentAnnotation = newValue.$annotation
        this.selectedInspectorItem = newValue
        // делаем новый замер доступным для редактирования в 3D
        newValue.changeSpheresVisibility(true)
      }
      if (oldValue) {
        oldValue.setLayerColor(oldValue.color)
        oldValue.setLayerOpacity(oldValue.opacity)
        // а старый недоступным
        oldValue.changeSpheresVisibility(false)
        if (oldValue.$layer && oldValue.$layer.pm) {
          oldValue.$layer.pm.disable()
          oldValue.$layer.pm.disableLayerDrag()
        }
      }
    },
    selectedInspectorItem(newValue, oldValue) {
      // Если выбран слой аннотации, устанавливаем цвет выделения на все замеры
      if (newValue) {
        if (newValue instanceof Annotation) {
          newValue.setLayerColor(SELECTED_COLOR)
          newValue.setLayerOpacity(1.0)
        }

        // Выбираем текущий элемент в меню el-tree(groupTree)
        const currentTreeNodeUUID = this.$refs.groupTree.getCurrentKey()
        if (newValue.treeNodeId && currentTreeNodeUUID !== newValue.treeNodeId) {
          this.changeCurrentElNode(newValue.treeNodeId)
        }
      }

      // Если старый выбранный элемент был аннотацией, то возвращаем цвета на место, кроме выбранного замера
      if (oldValue && oldValue instanceof Annotation) {
        oldValue.measures.map((m) => {
          if (m !== newValue) {
            m.setLayerColor(m.color)
            m.setLayerOpacity(m.opacity)
          }
        })
      }
    },
  },
  async mounted() {
    await this.checkUserRole()
    // используется в beautifyMeasure
    self = this
    this.setToolbar()
    await this.loadTaskInfo()
    this.generateTreeItems()
    this.showViewer = true
    // Проверяем наличие слоя 3d-mesh
    this.check3dMesh(this.axios, this.project, this.task)

    let gradient = 'linear-gradient(to bottom,'
    const legend = this.legendQuery.reverse()
    for (const i in legend) {
      gradient += 'rgb(' + legend[i].r + ',' + legend[i].g + ',' + legend[i].b + '),'
    }
    gradient = gradient.substring(0, gradient.length - 1) + ')'

    this.legendGradient = gradient
    // Подписка на события графика профайла
    this.listenToEchartsEvents()
  },
  methods: {
    // Подписка на события Leaflet
    setMapListeners() {
      if (!this.currentAnnotation) {
        this.addAnnotationGroup()
      }
      this.listenDrawEvents()
      this.listenZoomEvent(this.annotations)
    },
    setToolbar() {
      // Resize layers - toolbar
      document.getElementById('split-bar-left').onmousedown = (e) => {
        e.preventDefault()
        document.onmousemove = (e) => {
          e.preventDefault()
          let x =
            e.pageX -
            document.getElementById('layers-toolbar').getBoundingClientRect().left +
            5
          if (x < 200) {
            x = 200
          }
          document.getElementById('layers-toolbar').style.width = x + 'px'
        }
      }
      // Resize inspector - toolbar
      document.getElementById('split-bar-right').onmousedown = (e) => {
        e.preventDefault()
        document.onmousemove = (e) => {
          e.preventDefault()
          let x =
            document.getElementById('inspector-toolbar').getBoundingClientRect().right +
            5 -
            e.pageX
          if (x < 200) {
            x = 200
          }
          document.getElementById('inspector-toolbar').style.width = x + 'px'
        }
      }
      // Resize elevator - toolbar
      document.getElementById('split-bar-bot').onmousedown = (e) => {
        e.preventDefault()
        document.onmousemove = (e) => {
          e.preventDefault()
          let x =
            document.getElementById('elevator-toolbar').getBoundingClientRect().bottom +
            5 -
            e.pageY
          if (x < 200) {
            x = 200
          }
          document.getElementById('elevator-toolbar').style.height = x + 'px'
          // При изменении размеров тулбара профайла - пересчитывать ширину и высоту графика
          if (this.$refs.elevator.chart) {
            this.elevatorOptions.widthGraph = this.$refs.elevator.chart._coordSysMgr._coordinateSystems[0]._rect.width
            this.elevatorOptions.heightGraph = this.$refs.elevator.chart._coordSysMgr._coordinateSystems[0]._rect.height
          }
        }
      }
      document.onmouseup = (e) => {
        document.onmousemove = null
      }
    },
    showSwal(type, node, data) {
      if (type === 'delete') {
        swal({
          title: this.$gettext('Are you sure?'),
          text: this.$gettext('You will not be able to revert this!'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'action-btn save-btn py-3 w-50',
          cancelButtonClass: 'action-btn cancel-btn py-3 w-50',
          confirmButtonText: this.$gettext('Yes, delete it!'),
          cancelButtonText: this.$gettext('Cancel'),
          buttonsStyling: false,
        }).then(() => {
          this.removeMeasure(node, data)
        })
      }
    },
    toggleElevationProfile() {
      this.botSplitBarOpen = !this.botSplitBarOpen

      if (this.botSplitBarOpen && !this.currentMeasure.elevationProfile.length) {
        // Берем из замера настройки расчета профайла
        this.elevatorOptions.width = this.currentMeasure.elevationProfileOptions.width
        this.elevatorOptions.density = this.currentMeasure.elevationProfileOptions.density
        this.currentMeasure.calculateElevationProfile()
      }

      this.elevatorChartOptions.series[0].data = this.currentMeasure.elevationProfile
    },
    changeCurrentElNode(uuid) {
      /*
        Костыльная функция выделения текущего элемента в el-tree
         */
      const node = this.$refs.groupTree.getNode(uuid)
      if (node) {
        this.$refs.groupTree.setCurrentKey(uuid)
      } else {
        this.$nextTick(() => {
          this.changeCurrentElNode(uuid)
        })
      }
    },
    textAreaSize(e) {
      if (e.target.scrollTop > 0) {
        e.target.style.height = e.target.scrollHeight + 'px'
      }
    },
    showSuccessNotification(message) {
      Notification({
        title: this.$gettext('Saved'),
        message: message,
        type: 'success',
        position: 'top-right',
        showClose: false,
      })
    },
    showDangerNotification(message) {
      Notification({
        title: this.$gettext('Not saved'),
        message: message,
        type: 'error',
        position: 'top-right',
        showClose: false,
      })
    },
    async saveAnnotations() {
      const status = await this.annotations.save()
      if (status === 200) {
        this.showSuccessNotification(this.$gettext('Changes saved successfully'))
      } else {
        this.showDangerNotification(this.$gettext('Changes not saved'))
      }
    },
    addAnnotationGroup() {
      const annotation = new Annotation(this, { $annotations: this.annotations })
      this.annotations.add(annotation, true)
      this.currentAnnotation = annotation
      this.currentMeasure = null
      this.selectedInspectorItem = annotation
      this.generateTreeItems()
      this.changeCurrentElNode(annotation.treeNodeId)
      return annotation
    },
    async loadTaskInfo() {
      try {
        const response = await this.axios.get(
          `projects/${this.project}/tasks/${this.task}/`
        )
        this.annotations = new Annotations(this, response.data.annotations || [])

        if (!this.currentAnnotation && this.annotations.data.length > 0) {
          this.currentAnnotation = this.annotations.data[this.annotations.data.length - 1]
        }
        if (this.currentAnnotation && !this.selectedInspectorItem) {
          this.$nextTick((vm) => {
            this.selectedInspectorItem = this.currentAnnotation
          })
        }
      } catch (e) {
        console.error(
          `Не удалось загрузить информацию о задаче ${this.task} проекта ${this.project}`,
          e
        )
      }
      // Грузим инфо о слоях DSM
      this.dsm = null
      try {
        const response = await this.axios.get(
          `projects/${this.project}/tasks/${this.task}/dsm/tiles.json`
        )
        const dsm = response ? response.data : null
        // Переопределяем пути для слоёв
        if (dsm.tiles) {
          dsm.tiles = response.data.tiles.map((tile) => this.$root.apiPath(tile))
        }
        // FIXME надо переиграть
        dsm.name = undefined
        if (dsm.bounds) {
          const result = fromLatLon(dsm.bounds[1], dsm.bounds[0])
          this.utmZoneNum = result.zoneNum
          this.utmZoneLetter = result.zoneLetter
        }
        dsm.type = 'dsm-layer'
        this.dsm = dsm
      } catch (e) {
        console.error(
          `Не удалось загрузить информацию DSM. Задача ${this.task} проекта ${
            this.project
          }`,
          e
        )
        // проставим значения по умолчанию, чтобы корректно работали замеры без 2Д истории
        this.utmZoneNum = 32
        this.utmZoneLetter = 'T'
      }
      // Грузим инфо о слоях orthomosaic
      this.orthophoto = null
      try {
        const response = await this.axios.get(
          `projects/${this.project}/tasks/${this.task}/orthophoto/tiles.json`
        )
        const orthophoto = response.data
        // Переопределяем пути для слоёв
        if (orthophoto.tiles) {
          orthophoto.tiles = response.data.tiles.map((tile) => this.$root.apiPath(tile))
        }
        // FIXME надо переиграть
        orthophoto.name = undefined
        if (orthophoto.bounds) {
          const result = fromLatLon(orthophoto.bounds[1], orthophoto.bounds[0])
          this.utmZoneNum = result.zoneNum
          this.utmZoneLetter = result.zoneLetter
        }
        orthophoto.type = 'orthophoto-layer'
        this.orthophoto = orthophoto
      } catch (e) {
        console.error(
          `Не удалось загрузить информацию orthophoto. Задача ${this.task} проекта ${
            this.project
          }`,
          e
        )
      }
      // Грузим pointcloud
      try {
        // Формируем ебучий путь к воркерам Potree
        Potree.scriptPath = this.$root.apiPath(
          '/webodm/static/app/js/vendor/potree/build'
        )
        // Прокидываем авторизацию, нужно будет для того, чтобы скачивались ноды EPT
        Potree.XHRFactory.config.withCredentials = true
        Potree.XHRFactory.config.customHeaders = this.$root.headers

        // Формируем путь к воркерам Potree
        Potree.scriptPath = this.$root.apiPath(
          '/webodm/static/app/js/vendor/potree/build'
        )
        console.warn('Облако точек еще не загружено, загружаем...')
        // Путь к информации о PC
        const path = `${this.assetsPath}/entwine_pointcloud/ept.json`
        // выдернули из Potree.loadPointCloud, чтобы пробросить авторизацию
        this.pointcloud = await loadPointcloud.call(this, path)
      } catch (e) {
        console.error(
          `Не удалось загрузить информацию pointcloud. Задача ${this.task} проекта ${
            this.project
          }`,
          e
        )
      }
    },
    generateTreeItems() {
      const result = []
      // Подключение слоя DSM
      // Если слоя нет, выключаем его
      const dsmItem = {
        label: this.$gettext('DSM'),
        type: 'dsm-layer',
        icon: 'fas fa-cubes',
        visibility: this.dsm !== null && this.orthophoto === null,
        disabled: this.dsm === null,
        needDimension: MODE_2D,
        menu: {
          opacity: 1,
        },
        setVisibility: (newValue) => {
          this.setLayerVisibility(this.dsm.$layer, newValue)
        },
        $ref: this.dsm,
      }
      if (this.dsm) {
        this.dsm.$menu = dsmItem
      }
      result.push(dsmItem)

      // Подключение слоя ортофотокарты
      // Если слоя нет, выключаем его
      const orthophotoItem = {
        label: this.$gettext('Orthomosaic'),
        type: 'orthophoto-layer',
        icon: 'fas fa-map',
        visibility: this.orthophoto !== null,
        disabled: this.orthophoto === null,
        needDimension: MODE_2D,
        menu: {
          opacity: 1,
        },
        setVisibility: (newValue) => {
          this.setLayerVisibility(this.orthophoto.$layer, newValue)
        },
        $ref: this.orthophoto,
      }
      if (this.orthophoto) {
        this.orthophoto.$menu = orthophotoItem
      }
      result.push(orthophotoItem)

      // Подключение облака точек
      result.push({
        label: this.$gettext('Point cloud'),
        type: 'pointcloud',
        icon: 'fas fa-chess-board',
        visibility: true,
        get disabled() {
          return self.pointcloud === null
        },
        setVisibility: (newValue) => {
          this.pointcloud.visible = newValue
        },
        needDimension: MODE_3D,
      })

      const tmp = [
        {
          label: this.$gettext('3D Textured mesh'),
          type: '3d-mesh',
          icon: 'fas fa-dice-d20',
          visibility: false,
          get disabled() {
            return self.mesh3d
          },
          setVisibility: (newValue) => {
            this.toggleTextureModel(newValue)
          },
          needDimension: MODE_3D,
          menu: {
            opacity: 1,
          },
          $ref: null,
        },
      ]

      tmp.forEach((e) => result.push(e))

      this.elData = result
    },
    handleNodeClick(data) {
      // Удаляем у текущего замера профайл, если кликнули по другому замеру
      if (
        data !== this.currentMeasure &&
        this.currentMeasure &&
        this.currentMeasure.elevationProfile.length
      ) {
        // Отмена всех запросов на просчет профайла
        // Убираем профайл со сцены
        if (this.viewer) {
          this.viewer.scene.removeProfile(this.currentMeasure.profile)
          this.viewer.profileWindowController.requests.map((request) => request.cancel())
        }
        this.currentMeasure.elevationProfile = []
        this.currentMeasure.elevationProfileCoords = []
        this.currentMeasure.elevationData = {}
        this.currentMeasure.profile = {}
      }
      // если кликнули по аннотации, то делаем ее активной
      this.selectedInspectorItem = data.$ref || null

      // Закрываем панель профайла если выбрали группу а не замер
      if (data instanceof Annotation) {
        this.currentAnnotation = data
        this.botSplitBarOpen = false
      }

      if (data instanceof Measure) {
        this.currentMeasure = data

        // закрываем панель профиля высоты, если для текущего замера он не просчитан
        if (!this.currentMeasure.elevationProfile.length) {
          this.botSplitBarOpen = false
        }

        // и записываем данные о текущем elevation profile
        this.elevatorChartOptions.series[0].data = this.currentMeasure.elevationProfile
      } else {
        this.currentMeasure = null
      }
    },
    // Проверка на то что перетаскивается не группа
    nodeCanBeDragged(node) {
      if (node.level !== 1) {
        return true
      }
    },
    // Проверка, куда перетаскивается замер, нельзя перетаскивать в уровень групп и в дургие замеры
    nodeCanBeDropped(node, whereToDrop, type) {
      if (
        (whereToDrop.level === 2 && type !== 'inner') ||
        (whereToDrop.level === 1 && type === 'inner')
      ) {
        return true
      }
    },
    removeMeasure(node, data) {
      if (data instanceof Measure) {
        data.remove()
      } else if (data instanceof Annotation) {
        data.remove()
      } else {
        console.warn('Поведение для удаления замера не реализовано!', data)
      }
    },
    setOpacity(opacity, menuItem) {
      if (menuItem instanceof Annotation || menuItem instanceof Measure) {
        menuItem.opacity = parseFloat(opacity)
      } else {
        try {
          // у разных типов слоев по-разному проставляется прозрачность
          if (menuItem.type === '3d-mesh') {
            menuItem.$ref.children[0].material.opacity = opacity
          } else {
            const layer = menuItem.$ref.$layer
            if (layer) {
              try {
                layer.setStyle({ opacity: opacity })
              } catch (e) {
                layer.setOpacity(opacity)
              }
            } else {
              console.error(
                'Не удалось изменить прозрачность - отсутствует экземпляр слоя в объекте меню'
              )
            }
          }
        } catch (e) {
          console.error('Ошибка изменения прозрачности слоя', e)
        }
      }
    },
    remove(node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex((d) => d === data)
      children.splice(index, 1)
    },
    showHideMeasure(e, node, data) {
      e.stopPropagation()
      if (data.needDimension === undefined || data.needDimension === this.currentMode) {
        const newValue = !data.visibility
        // пердыдущая штука, когда принимали параметр из функции - заставляла нас всегда возвращать boolean для простановки, хотя знаем, что это протсто !visible
        data.visibility = newValue
      }
    },
    centerMeasure(node, measure) {
      if (measure instanceof Measure) {
        measure.centerCamera()
      }
    },
    showHideLayer(e, node, data) {
      e.stopPropagation()
      if (data.setVisibility !== undefined) {
        if (data.needDimension === undefined || data.needDimension === this.currentMode) {
          const newValue = !data.visibility
          data.setVisibility(newValue)
          // пердыдущая штука, когда принимали параметр из функции - заставляла нас всегда возвращать boolean для простановки, хотя знаем, что это протсто !visible
          data.visibility = newValue
        }
      }
    },
    changePointBudget() {
      this.viewer.setPointBudget(this.pointBudget)
    },
    changePointSize() {
      this.viewer.scene.pointclouds[0].material.size = parseFloat(this.pointSize)
    },
    addColorToPalette() {
      this.userPalette.push(this.selectedInspectorItem.color)
      this.selectedColor = this.userPalette.length - 1
    },
    selectColor(index) {
      this.selectedColor = index
      this.selectedInspectorItem.color = this.userPalette[this.selectedColor]
    },
    deleteColor(index) {
      this.over = false
      this.userPalette.splice(index, 1)
      if (this.selectedColor === index) {
        this.selectedColor = 0
      }
    },
    async saveScreenshot() {
      const canvas = await html2canvas(this.$refs.viewer.$el)
      downloadjs(canvas.toDataURL('image/png'), 'screenShot.png', 'image/png')
    },
    async getPointsInPolygon(
      measure,
      minZ = this.pointcloud.boundingBox.min.z,
      maxZ = this.pointcloud.boundingBox.max.z
    ) {
      try {
        // проставляем bounding box для замера, причем максимально по высоте
        measure.createBoxFromMeasure(
          this.pointcloud.boundingBox.min.z,
          this.pointcloud.boundingBox.max.z
        )
        // на основе этого получаем ноды где лежат точки
        const nodes = Utils.getIntersectNodes(
          this.pointcloud.pcoGeometry.root,
          measure.box
        )
        let minPoint
        // тут соберем все точки, которые попадут в рамки нашего замера
        const promises = nodes.map(async (node) => {
          const points = []

          const array = await Utils.getPointsInNode(node)

          const boundingBox = node.boundingBox.min
          // Минимальная точка, которая вычисляется для избежания "дергания" текстуры
          minPoint = new THREE.Vector3(
            array[0] + boundingBox.x,
            array[1] + boundingBox.y,
            array[2] + boundingBox.z
          )

          const measureTurfPoints = [[]]

          measure.$potreeMeasure.points.map((point) =>
            measureTurfPoints[0].push([point.position.x, point.position.y])
          )
          measureTurfPoints[0].push(measureTurfPoints[0][0])

          for (let i = 0; i < array.length; i += 3) {
            const x = array[i] + boundingBox.x
            if (x < minPoint.x) {
              minPoint.x = x
            }
            const y = array[i + 1] + boundingBox.y
            if (y < minPoint.y) {
              minPoint.y = y
            }
            const z = array[i + 2] + boundingBox.z
            if (z < minPoint.z) {
              minPoint.z = z
            }

            const point = new THREE.Vector3(x, y, z)

            if (
              z > maxZ ||
              z < minZ ||
              !Utils.pointInPolygon([measureTurfPoints], [x, y])
            ) {
              continue
            }

            points.push(point)
          }
          return points
        })

        // сюда будем класть точки
        let list = []
        // соберем все точки из всех промисов
        await Promise.all(promises).then((r) => {
          list = r.flat()
        })

        const geometry = new ConvexBufferGeometry(list)
        measure.highlightPoints(list, minPoint)
        measure.drawFrameBox(minPoint, maxZ - measure.maxZ)
        return geometry
      } catch (e) {
        console.error('Не удалось взять точки внутри полигона', e)
        return false
      }
    },
    // Пересчет профайла с задержкой
    debounceRecomputeProfile: debounce(() => {
      // Сохранение в замер текущих настроек расчета профайла
      this.currentMeasure.elevationProfileOptions.width = this.elevatorOptions.width
      this.currentMeasure.elevationProfileOptions.density = this.elevatorOptions.density
      // Отмена всех запросов на просчет профайла
      this.viewer.profileWindowController.requests.map((request) => request.cancel())
      this.currentMeasure.calculateElevationProfile()
      this.elevatorChartOptions.series[0].data = this.currentMeasure.elevationProfile
    }, 500),
    // Сброс настроек расчета профайла и запуск пересчета
    resetProfileSettings() {
      this.elevatorOptions.width = 1
      this.elevatorOptions.density = 100
      this.debounceRecomputeProfile()
    },
    // Включение отображения профайла на поинтклауде
    togglePotreeProfile() {
      if (this.currentMeasure.profile && this.elevatorOptions.showProfile) {
        this.viewer.scene.addProfile(this.currentMeasure.profile)
      } else {
        this.viewer.scene.removeProfile(this.currentMeasure.profile)
      }
    },
    // Обработка нажатия клавиш
    onKeyUp(e) {
      switch (e.code) {
        case 'KeyL':
          this.$refs.controls.drawLine()
          break
        case 'KeyA':
          this.$refs.controls.drawPolygon()
          break
        // case 'KeyV':
        //   this.$refs.controls.drawVolume()
        //   break
        case 'KeyE':
          this.$refs.controls.drawEdit()
          break
        case 'KeyD':
          this.$refs.controls.drawDrag()
          break
        case 'KeyM':
          this.$refs.controls.drawMarker()
          break
        case 'Escape':
          this.$refs.controls.disableTool()
          break
      }
    },
    // Функции котовые проходят по всем замерам и выставляют нужные параметры отображения лейблов
    toggleMeasureLabelsVisibility(value) {
      this.$store.commit('viewer/showMeasureLabels', value)
      if (this.currentMode === MODE_2D) {
        for (const measure of this.annotations.allMeasures) {
          // Выдача leaflet-measure-path параметра отображения лейблов расстояния/площади замера и обновление лейблов замера
          if (!measure.$layer._measurementOptions) {
            measure.$layer._measurementOptions = {}
          }
          measure.$layer._measurementOptions.showMeasures = value
          measure.$layer.updateMeasurements()
          const layers = measure.$layer._measurementLayer._layers
          const layersKeys = Object.keys(layers).filter(
            (key) =>
              layers[key]._title === 'Segment length' ||
              layers[key]._title === 'Total length' ||
              layers[key]._title === 'Total area'
          )

          if (this.showMeasureLabels) {
            layersKeys.map((key) => this.$store.commit('viewer/addLayer', layers[key]))
          } else {
            layersKeys.map((key) => layers[key].remove())
          }
        }
      } else {
        for (const measure of this.annotations.allMeasures) {
          if (measure.$potreeMeasure) {
            if (measure.type === 'annotation-polygon') {
              measure.$potreeMeasure.showArea = value
            }
            measure.$potreeMeasure.showDistances = value
          }
        }
      }
    },
    /**
     * Функция, которая переключает видимость лейблов названий замеров
     */
    toggleTitleLabelsVisibility(value) {
      this.$store.commit('viewer/showTitleLabels', value)
      if (this.currentMode === MODE_2D) {
        for (const measure of this.annotations.allMeasures) {
          // Выдача leaflet-measure-path параметра отображения лейбла имени замера и обновление лейблов замера
          measure.$layer._measurementOptions.showName = value
          measure.$layer.updateMeasurements()
          const layers = measure.$layer._measurementLayer._layers
          const layersKeys = Object.keys(layers).filter(
            (key) =>
              layers[key]._title === 'Line name' ||
              layers[key]._title === 'Polygon name' ||
              layers[key]._title === 'Marker name'
          )

          if (this.showTitleLabels) {
            layersKeys.map((key) => this.$store.commit('viewer/addLayer', layers[key]))
          } else {
            layersKeys.map((key) => layers[key].remove())
          }
        }
      } else {
        for (const measure of this.annotations.allMeasures) {
          if (measure.$potreeMeasure) {
            measure.$potreeMeasure.nameLabel.visible = value
          }
        }
      }
    },
    toggleAngleLabelsVisibility(value) {
      this.showAngleLabels = value
      if (this.currentMode === MODE_2D) {
      } else {
        for (const measure of this.annotations.allMeasures) {
          if (measure.$potreeMeasure && measure instanceof Line) {
            measure.$potreeMeasure.showAngles = value
          }
        }
      }
    },
    /**
     * Функция которая дергается при датазуме на графике профайла
     * Зумит второй слайдер при зуме первого и наоборот, если включен зумлок
     * @param e - получаем при ивенте датазума, содержит начальную и конечную точки, а так же айди слайдера
     */
    elevatorOnDataZoom(e) {
      if (this.elevatorOptions.dataZoomLock) {
        if (e.dataZoomId === '2') {
          this.$refs.elevator.chart.dispatchAction({
            type: 'dataZoom',
            dataZoomIndex: 3,
            start: (e.start * this.elevatorOptions.scaleX) / this.elevatorOptions.scaleY,
            end: (e.end * this.elevatorOptions.scaleX) / this.elevatorOptions.scaleY,
          })
        }
        if (e.dataZoomId === '3') {
          this.$refs.elevator.chart.dispatchAction({
            type: 'dataZoom',
            dataZoomIndex: 2,
            start: (e.start * this.elevatorOptions.scaleY) / this.elevatorOptions.scaleX,
            end: (e.end * this.elevatorOptions.scaleY) / this.elevatorOptions.scaleX,
          })
        }
      }
    },
    /**
     * Переключение режима датазума графика профайла
     * Когда включен - зум по осям графика становится 1 к 1 метру
     * При включении меняет иконку и смотрит на плотность осей графика
     * Сравнивает плотности и подгоняет одну из осей по плотности ко второй чтобы достичь соотношения 1 к 1 метру
     */
    toggleElevatorZoomLock() {
      this.elevatorOptions.dataZoomLock = !this.elevatorOptions.dataZoomLock
      this.elevatorChartOptions.toolbox.feature.myZoomLockTool.iconStyle.color = this
        .elevatorOptions.dataZoomLock
        ? 'red'
        : null
      if (this.elevatorOptions.dataZoomLock) {
        if (this.elevatorOptions.scaleX > this.elevatorOptions.scaleY) {
          this.$refs.elevator.chart.dispatchAction({
            type: 'dataZoom',
            dataZoomIndex: 3,
            start: 0,
            end: 100,
          })
          this.$refs.elevator.chart.dispatchAction({
            type: 'dataZoom',
            dataZoomIndex: 2,
            start: 0,
            end: (100 * this.elevatorOptions.scaleY) / this.elevatorOptions.scaleX,
          })
        } else {
          this.$refs.elevator.chart.dispatchAction({
            type: 'dataZoom',
            dataZoomIndex: 3,
            start: 0,
            end: (100 * this.elevatorOptions.scaleX) / this.elevatorOptions.scaleY,
          })
          this.$refs.elevator.chart.dispatchAction({
            type: 'dataZoom',
            dataZoomIndex: 2,
            start: 0,
            end: 100,
          })
        }
        // Блокировка зума колесиком при зумлоке
        if (this.$refs.elevator.chart) {
          this.$refs.elevator.chart._model.option.dataZoom[0].zoomLock = true
          this.$refs.elevator.chart._model.option.dataZoom[1].zoomLock = true
        }
      } else {
        // Разблокировка зума колесиком
        if (this.$refs.elevator.chart) {
          this.$refs.elevator.chart._model.option.dataZoom[0].zoomLock = false
          this.$refs.elevator.chart._model.option.dataZoom[1].zoomLock = false
        }
      }
    },
    /**
     * Функция которая считает минимальное значение по оси X графика профайла
     * Необходима для того чтобы это значение можно было нормально вытащить из компонента eCharts
     * @param value - значения точек по координате X
     * @returns {null} - возвращает минимальное
     */
    getMinXAxis(value) {
      this.elevatorOptions.minX = Math.floor(value.min)
      this.elevatorOptions.scaleX =
        (this.elevatorOptions.maxX - this.elevatorOptions.minX) /
        this.elevatorOptions.widthGraph
      return this.elevatorOptions.minX
    },
    /**
     * Функция которая считает максимальное значение по оси X графика профайла
     * Необходима для того чтобы это значение можно было нормально вытащить из компонента eCharts
     * Так же считает плотность оси X
     * @param value - значения точек по координате X
     * @returns {null} - возвращает максимальное
     */
    getMaxXAxis(value) {
      this.elevatorOptions.maxX = Math.ceil(value.max)
      if (this.$refs.elevator.chart) {
        this.elevatorOptions.widthGraph = this.$refs.elevator.chart._coordSysMgr._coordinateSystems[0]._rect.width
        this.elevatorOptions.heightGraph = this.$refs.elevator.chart._coordSysMgr._coordinateSystems[0]._rect.height
      }
      this.elevatorOptions.scaleX =
        (this.elevatorOptions.maxX - this.elevatorOptions.minX) /
        this.elevatorOptions.widthGraph
      return this.elevatorOptions.maxX
    },
    /**
     * Функция которая считает минимальное значение по оси Y графика профайла
     * Необходима для того чтобы это значение можно было нормально вытащить из компонента eCharts
     * Так же считает плотность оси Y
     * @param value - значения точек по координате Y
     * @returns {null} - возвращает минимальное
     */
    getMinYAxis(value) {
      this.elevatorOptions.minY = Math.floor(value.min)
      if (this.$refs.elevator.chart) {
        this.elevatorOptions.widthGraph = this.$refs.elevator.chart._coordSysMgr._coordinateSystems[0]._rect.width
        this.elevatorOptions.heightGraph = this.$refs.elevator.chart._coordSysMgr._coordinateSystems[0]._rect.height
      }
      this.elevatorOptions.scaleY =
        (this.elevatorOptions.maxY - this.elevatorOptions.minY) /
        this.elevatorOptions.heightGraph
      return this.elevatorOptions.minY
    },
    /**
     * Функция которая считает максимальное значение по оси Y графика профайла
     * Необходима для того чтобы это значение можно было нормально вытащить из компонента eCharts
     * Так же считает плотность оси Y
     * @param value - значения точек по координате Y
     * @returns {null} - возвращает максимальное
     */
    getMaxYAxis(value) {
      this.elevatorOptions.maxY = Math.ceil(value.max)
      if (this.$refs.elevator.chart) {
        this.elevatorOptions.widthGraph = this.$refs.elevator.chart._coordSysMgr._coordinateSystems[0]._rect.width
        this.elevatorOptions.heightGraph = this.$refs.elevator.chart._coordSysMgr._coordinateSystems[0]._rect.height
      }
      this.elevatorOptions.scaleY =
        (this.elevatorOptions.maxY - this.elevatorOptions.minY) /
        this.elevatorOptions.heightGraph
      return this.elevatorOptions.maxY
    },
    /**
     * Функция, которая меняет текст лейбла названия замера
     */
    debounceChangeNameLabel: debounce(() => {
      if (this.currentMode === MODE_3D && this.selectedInspectorItem instanceof Measure) {
        this.currentMeasure.$potreeMeasure.nameLabel.setText(
          this.selectedInspectorItem.name
        )
      } else {
        // меняем название замера на лету
        if (
          this.currentMeasure.$layer._measurementLayer ||
          !this.currentMeasure.$layer._map
        ) {
          this.currentMeasure.$layer._measurementOptions.measureName = this.selectedInspectorItem.name
          this.currentMeasure.$layer.updateMeasurements()
          this.toggleTitleLabelsVisibility(this.showTitleLabels)
          this.toggleMeasureLabelsVisibility(this.showMeasureLabels)
        }
      }
    }, 500),
    /**
     * Проверка роли пользователя в проекте
     */
    async checkUserRole() {
      try {
        const response = await this.axios.get(`/projects/${this.project}/`)
        this.userRole = response.data.roles ? response.data.roles[0].short_name : 'owner'
      } catch (e) {
        console.error('Не получилось проверить права пользователя', e)
      }
    },
    importAnnotations() {
      if (this.currentMode === MODE_2D) {
        this.importMapAnnotations(this.annotations)
      } else {
        this.importViewerAnnotations(this.annotations)
      }
    },
    initEventListeners() {
      let measure
      // Продолжаем добавлять маркеры
      if (this.currentMeasure instanceof Marker) {
        measure = new Marker(this, {
          isNew: true,
          points: [],
          $annotation: this.currentAnnotation || this.addAnnotationGroup(),
        })
      } else if (this.currentMeasure instanceof Camera) {
        measure = new Camera(this, {
          isNew: true,
          points: [],
          $annotation: this.currentAnnotation || this.addAnnotationGroup(),
        })
      }
      this.currentAnnotation.add(measure)
      this.currentMeasure = measure
    },
    spotLightNodeClick(data) {
      this.currentSpotlight = data.uuid
      let index = this.helperSpotLight.findIndex((item) => item.uuid === data.uuid)
      if (index === -1) {
        index = this.helperSpotLight.findIndex(
          (item) => item.children[0].uuid === data.uuid
        )
      }
      if (index !== -1) {
        this.light = this.spotLights[index]
        this.addGUI(this.light.position, this.light.target.position)
      }
    },
  },
}
</script>
<style lang="sass">
.echarts
  width: 100%
  height: 100%

.n-modal
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.5)
  z-index: 900
  visibility: hidden


.pointer
  cursor: pointer

.tree-anchor
  width: calc(100% - 24px)

.custom-div
  position: relative
  width: calc(100% - 24px) !important
  color: white
/*width: 350px !important*/

.custom-div-content
  display: inline-block
  width: 0px

.custom-div-btns-2d-3d
  position: absolute
  right: 0
  display: flex
  align-items: center
  height: 26px

.custom-div-btns-2d-3d button
  cursor: pointer
  border: none
  border-radius: 4px
  padding: 0 4px
  background-color: rgba(116, 116, 116, 0.9)
  color: white
  width: 100%
  height: 22px
  /*font-weight: bold*/
  outline: none

.custom-div-btns
  visibility: hidden
  position: absolute
  right: 0
  /*background-color: #474747*/
  background-color: #2b2b2b
  border-radius: 4px 0 0 4px
  display: inline-block
  height: 26px

.custom-div-btns button
  margin: 0 8px
  color: white
  outline: none

.tooltip-opacity
  width: 165px

.toolbar-min
  width: 10px !important

.bot-toolbar-min
  height: 10px !important


.viewer-layout
  position: relative
  min-width: 510px
  //width: 100%
  overflow: hidden
/*width: auto*/

.controls
  position: absolute
  width: 100%

.viewer
  position: absolute
  width: 100%

.el-tree
  background-color: #2b2b2b !important
  color: #d6d6d6 !important
  font-weight: bold
  padding: 10px 0
  max-height: calc(100% - 56px - 56px - 124px)
  overflow-y: auto !important

.el-tree::-webkit-scrollbar
  width: 5px
  background-color: transparent

.el-tree::-webkit-scrollbar-thumb
  background-color: #d0d0d0
  border-radius: 10px

.static-tree-group
  background-color: #343434 !important

.is-current
  background-color: #3f3f3f

.disabled-node
  color: rgba(116, 116, 116, 0.9) !important
.disabled-node i
  color: rgba(116, 116, 116, 0.9) !important

.el-checkbox
  margin: 0 4px

.el-tree-node__content:hover
  background-color: #1d1d1d !important

.el-tree-node:focus > .el-tree-node__content
  background-color: #3f3f3f !important

.el-tooltip__popper
  background-color: #1d1d1d !important
  color: #d6d6d6 !important

.inspector-toolbar
  width: 310px

.inspector-toolbar-header
  font-size: 16px
  color: white
  background-color: #2b2b2b
  cursor: default
  font-weight: 600
  text-align: center

.inspector-toolbar-btn
  position: absolute
  bottom: 0
  /*left: 0*/
  /*right: 0*/
  width: 100%
  font-size: 16px
  color: white
  border: none
  background-color: #1d1d1d
  cursor: pointer
  font-weight: 600
  text-align: center

.inspector-toolbar-btn:hover
  background-color: rgba(116, 116, 116, 0.9) !important
.inspector-toolbar-btn:focus
  outline: none

.layers-toolbar
  /*height: 100vh*/
  /*height: calc(100vh - 63px)*/
  height: 100vh
  width: 310px
  min-width: 10px
  /*overflow: hidden*/
  /*overflow-y: auto*/
  /*overflow-x: auto*/
  /*background-color: #f4f3ef*/
  background-color: #2b2b2b
/*display: flex*/

.layers-toolbar-content
  width: calc(100% - 10px)
  overflow: hidden
  overflow-y: auto
  overflow-x: auto

.inspector-toolbar-content
  position: relative
  color: #d6d6d6
  font-weight: bold
  background-color: #2b2b2b
  width: calc(100% - 10px)
  overflow: hidden
  overflow-y: auto
  /*overflow-x: auto*/

.inspector-block-header
  font-size: 16px
  cursor: default
  font-weight: 600
  text-align: left

.elevator-toolbar
  position: absolute
  bottom: 0
  /*height: 100vh*/
  height: 310px
  max-height: calc(100% - 292px)
  z-index: 1000
  width: 100%
  min-width: 10px
  /*overflow: hidden*/
  /*overflow-y: auto*/
  /*overflow-x: auto*/
  /*background-color: #f4f3ef*/
  background-color: #2b2b2b

/*display: flex*/

.elevator-toolbar-content
  /*display: flex*/
  /*flex-direction: column*/
  color: #d6d6d6
  font-weight: bold
  /*width: 100%*/
  height: 100%
  /*height: calc(100% - 10px)*/
  overflow: hidden
  /*overflow-y: auto*/
  /*overflow-x: auto*/

.elevator-chart
  flex-grow: 1
  max-height: 100%

#split-bar-left
  background-color: #1e1e1d
  height: 100%
  width: 10px
  cursor: col-resize
  position: relative

#split-bar-right
  background-color: #1e1e1d
  height: 100%
  width: 10px
  cursor: col-resize
  position: relative

#split-bar-bot
  background-color: #1e1e1d
  height: 10px
  width: 100%
  cursor: row-resize
  position: relative


.split-bar-btn i
  color: white
  font-size: 22px

.split-bar-btn
  position: absolute
  z-index: 1000
  cursor: pointer
  padding: 0
  display: -ms-flexbox
  display: flex
  -ms-flex-align: center
  align-items: center
  background-color: #1e1e1d
  border: none
/*outline: none*/

.split-bar-btn-left
  top: calc(50% - 20px)
  width: 25px
  height: 40px
  border-bottom-right-radius: 20px
  border-top-right-radius: 20px

.split-bar-btn-right
  top: calc(50% - 20px)
  left: -15px
  width: 25px
  height: 40px
  border-bottom-left-radius: 20px
  border-top-left-radius: 20px

.split-bar-btn-bot
  left: calc(50% - 20px)
  top: -15px
  width: 40px
  height: 25px
  border-top-right-radius: 20px
  border-top-left-radius: 20px
  padding-top: 2px


.active-control-btn
  border-radius: 4px
  background-color: rgba(116, 116, 116, 0.9) !important

.control-btns
  /*display: flex*/
  /*-webkit-box-orient: vertical*/
  /*flex-direction: column*/
  /*align-items: center*/

  position: absolute
  z-index: 500
  /*overflow: hidden*/
  /*padding: 0 5px*/
  background-color: rgba(44, 44, 44, .9)
  border-radius: 4px

.swal2-modal
  background-color: #2b2b2b !important
  color: #d6d6d6 !important
  box-shadow: none !important
.swal2-content, .swal2-title
  color: #d6d6d6 !important

.visibility-false
  color: #ef8157

.control-btns i
  font-size: 20px
  display: block
  margin: auto

.control-btns button
  font-size: 19px
  color: white
  border: none
  background-color: transparent
  cursor: pointer
  /*font-weight: bold*/
  font-weight: 600
  width: 32px !important
  height: 32px !important

.control-btns button:hover
  background-color: #1d1d1d
  border-radius: 4px

.control-btns button:focus
  outline: none
  border-radius: 4px

.add-btn
  width: 100%
  font-size: 16px
  color: white
  border: none
  background-color: #1d1d1d
  cursor: pointer
  font-weight: 600
  text-align: center
.add-btn:hover
  background-color: rgba(116, 116, 116, 0.9) !important
.add-btn:focus
    outline: none

.action-btn
  width: 100%
  height: 100%
  font-size: 16px
  color: white
  border: none
  cursor: pointer
  font-weight: 600
  text-align: center
.action-btn:focus
    outline: none

.save-btn
  background-image: linear-gradient(120deg, #16a085 0%, #107461 100%)
.save-btn:hover
  background-image: linear-gradient(120deg, #107461 0%, #16a085 100%)
.split-btn
  background-image: linear-gradient(120deg, #ef8157 0%, #b06446 100%)
.split-btn:hover
  background-image: linear-gradient(120deg, #b06446 0%, #ef8157 100%)

.cancel-btn
  background-image: linear-gradient(120deg, #d60739 0%, #c10840 100%)
.cancel-btn:hover
  background-image: linear-gradient(120deg, #c10840 0%, #ff0844 100%)


.control-btns button
  position: relative

.modal-btns
  /*overflow: hidden*/
  position: absolute
  top: 0
  left: 36px
  height: 32px
  text-align: left
  border-radius: 4px
  display: flex
  /*display: block !important*/


.modal-btns button
  display: inline-block !important
  background-color: rgba(44, 44, 44, 0.9)
  width: max-content !important
  height: 32px
  font-size: 12px
  border-radius: 4px

.modal-btns-right
  left: auto
  right: 36px

.control-btns-2d3d
  -webkit-box-orient: horizontal
  flex-direction: row
  top: -50px
  left: 68px

.control-btns-settings
  -webkit-box-orient: horizontal
  flex-direction: row
  top: -50px
  left: 166px
/*width: 90px*/

.control-btns-save
  -webkit-box-orient: horizontal
  flex-direction: row
  top: -50px
  right: 68px

.control-btns-tools
  top: 18px
  left: 18px
/*margin-left: 15px*/

.control-btns-view
  top: 18px
  right: 18px

.control-btns-tools button, .control-btns-view button
  display: block

.control-btns-range
  -webkit-appearance: slider-vertical
  /*height: 100px*/
  width: 60px
//.control-btns-tooltip-settings
  -webkit-appearance: slider-vertical

/*.tree-open ul*/
/*min-height: 24px !important*/
/*max-height: 224px !important*/


/*el-tree*/
.el-tree
  overflow: hidden
.el-tree-node__expand-icon
  font-size: 1.3rem !important

.custom-tree-node
  width: 100%
.custom-tree-node:hover .custom-div-btns
  visibility: visible


.tooltip
  display: block !important
  z-index: 10000


.tooltip .tooltip-inner
  background: black
  color: white
  border-radius: 16px
  padding: 5px 10px 4px


.tooltip .tooltip-arrow
  width: 0
  height: 0
  border-style: solid
  position: absolute
  margin: 5px
  border-color: black
  z-index: 1


.tooltip[x-placement^="top"]
  margin-bottom: 5px


.tooltip[x-placement^="top"] .tooltip-arrow
  border-width: 5px 5px 0 5px
  border-left-color: transparent !important
  border-right-color: transparent !important
  border-bottom-color: transparent !important
  bottom: -5px
  left: calc(50% - 5px)
  margin-top: 0
  margin-bottom: 0


.tooltip[x-placement^="bottom"]
  margin-top: 5px


.tooltip[x-placement^="bottom"] .tooltip-arrow
  border-width: 0 5px 5px 5px
  border-left-color: transparent !important
  border-right-color: transparent !important
  border-top-color: transparent !important
  top: -5px
  left: calc(50% - 5px)
  margin-top: 0
  margin-bottom: 0


.tooltip[x-placement^="right"]
  margin-left: 5px


.tooltip[x-placement^="right"] .tooltip-arrow
  border-width: 5px 5px 5px 0
  border-left-color: transparent !important
  border-top-color: transparent !important
  border-bottom-color: transparent !important
  left: -5px
  top: calc(50% - 5px)
  margin-left: 0
  margin-right: 0


.tooltip[x-placement^="left"]
  margin-right: 5px


.tooltip[x-placement^="left"] .tooltip-arrow
  border-width: 5px 0 5px 5px
  border-top-color: transparent !important
  border-right-color: transparent !important
  border-bottom-color: transparent !important
  right: -5px
  top: calc(50% - 5px)
  margin-left: 0
  margin-right: 0


.tooltip.popover .popover-inner
  background: #f9f9f9
  color: black
  padding: 24px
  border-radius: 5px
  box-shadow: 0 5px 30px rgba(black, .1)


.tooltip.popover .popover-arrow
  border-color: #f9f9f9


.tooltip[aria-hidden='true']
  visibility: hidden
  opacity: 0
  transition: opacity .15s, visibility .15s


.tooltip[aria-hidden='false']
  visibility: visible
  opacity: 1
  transition: opacity .15s

button:hover
  cursor: pointer
button:focus
  outline: none

.selected-color
  border: 3px solid 303133 !important
  /*background-color: #05AE0E*/

.user-palette
  /*background-color: #05AE0E*/
  border: none
  width: 25px
  height: 25px
  border-radius: 50%

/*.drag*/
/*  background-color: rgba(1, 1, 1, 0)*/

.drop.over
  /*border-color: #ffffff !important*/
  background: #6d6d6d !important


.color-palette-wrapper
  padding: 0
.color-palette
  text-align: center
.color-palette-header
  color: #d6d6d6
  padding: 10px 0
  background-color: #1d1d1d
  cursor: default
  font-weight: bold
  font-size: .7rem
  text-transform: uppercase
.color-palette-body
  margin: 10px !important
  width: 99px


.legend-gradient
  border-radius: 4px
  display: inline-block
  height: 100%
  width: 15px

.legend-value
  padding-top: 4px
  padding-bottom: 4px


.inspector-input
  width: 100%
  padding: 5px 10px
  margin-top: 5px
  border: none
  outline: none
  background-color: #343434
  border-radius: 4px
  color: white
  border-bottom: 2px solid d6d6d6
  transition: border-bottom .6s
.inspector-input:focus
  border-bottom: 2px solid darkred
.el-slider__runway
  margin: 0 !important
  background-color: #2c2f30 !important
.el-slider__bar
  background-color: #1e1e1d !important
.el-slider__button
  border: none !important
  background-color: #c30b36 !important
.custom-slider-container
  width: 100%
.custom-slider-input
  -webkit-appearance: none
  width: 100%
  height: 6px
  border-radius: 3px
  background: #2c2f30
  outline: none
  -webkit-transition: .2s
  transition: opacity .2s
.custom-slider-input::-webkit-slider-thumb
  -webkit-appearance: none
  appearance: none
  width: 16px
  height: 16px
  border-radius: 50%
  background-color: #c30835
  cursor: grab
.custom-slider-input::-moz-range-thumb
  width: 16px
  height: 16px
  border-radius: 50%
  background-color: #c30835
  cursor: grab
.custom-slider-input::-webkit-slider-thumb:hover
  -webkit-transform: scale(1.2)
  -moz-transform: scale(1.2)
  -ms-transform: scale(1.2)
  -o-transform: scale(1.2)
  transform: scale(1.2)
.custom-slider-input::-moz-range-thumb
  transform: scale(1.2)
.custom-num-input
  background: #343434
  color: #ffffff
  border: none
  width: 100%
/* Chrome, Safari, Edge, Opera */
.custom-num-input::-webkit-outer-spin-button,
.custom-num-input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
.custom-num-input[type=number]
  -moz-appearance: textfield
.custom-num-btn
  background: #343434 !important
  border: none !important
  color: #ffffff !important
.el-button.custom-num-btn:focus
  background-color: #343434 !important
  color: #ffffff !important
.el-button.custom-num-btn:hover
  background-color: #3f3f3f !important
  color: #ffffff !important
.el-button.custom-num-btn:active
  background-color: #3f3f3f !important
  color: #51cbce !important
.custom-num-btn.b-radius-right
  border-radius: 0 5px 5px 0 !important
.custom-num-btn.b-radius-left
  border-radius: 5px 0 0 5px !important
.inspector-toolbar-content
  max-height: 100vh
  overflow: scroll
  -ms-overflow-style: none
  scrollbar-width: none

.inspector-toolbar-content::-webkit-scrollbar
  display: none
</style>

<style lang="scss">
.canvas-area {
  width: 100%;
  height: 70%;
  display: flex;
}
@media only screen and (max-width: 1200px) {
  .canvas-area {
    height: 50%;
  }
}
@media only screen and (max-width: 1700px) {
  .canvas-area {
    height: 70%;
  }
}
.name-y-axis {
  position: absolute;
  transform: rotate(180deg);
  -moz-transform: rotate(180deg); /* Для Firefox */
  -ms-transform: rotate(180deg); /* Для IE */
  -webkit-transform: rotate(180deg); /* Для Safari, Chrome, iOS */
  -o-transform: rotate(180deg); /* Для Opera */
  writing-mode: tb-rl;
}
#gui {
  position: absolute;
  left: 45%;
}
</style>
