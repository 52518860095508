<template>
  <div>
    <nova-header />
    <div class="container">
      <div class="context-section row justify-content-end mt-3">
        <div class="secion-action d-flex justify-content-end col-12 col-md-8">
          <div class="action-btns row">
            <div class="pl-2">
              <el-select
                v-if="organizations && organizations.length > 1"
                v-model="currentOrganization"
                @change="loadTasks"
              >
                <el-option
                  v-for="(organization, key) in organizations"
                  :key="key"
                  :value="key"
                  :label="organization.name"
                />
              </el-select>
            </div>
            <div class="pl-2">
              <el-button
                v-if="currentUser.owner"
                round
                :type="blocked ? 'success' : 'danger'"
                style="font-size: 10px; font-weight: 700"
                @click="blockUser(!blocked)"
              >
                {{ blockingTextBtn }}
              </el-button>
              <el-button
                v-if="currentUser.owner"
                round
                style="font-size: 10px; font-weight: 700"
                @click="showSwal('password')"
              >
                <translate>Change password</translate>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-if="loadingUser" v-loading="loadingUser" style="height: 200px" />
        <user-profile-card v-if="user" ref="profile" v-model="user" />
        <!--      person projects table-->
        <div v-if="loadingTable" v-loading="loadingTable" style="height: 200px" />
        <div v-if="tasks" class="tables mt-5">
          <el-table
            v-if="showTable"
            ref="table"
            :data="tasks"
            style="width: 100%; font-weight: 700"
            row-class-name="custom-el-table-row"
          >
            <el-table-column :label="'Project' | translate" prop="name" />
            <el-table-column :label="'Access rights' | translate" prop="localizedRole" />
            <el-table-column :label="'Block status' | translate" align="center">
              <template slot-scope="scope">
                {{ scope.row.blocked }}
                <el-checkbox v-model="scope.row.blocked" :disabled="true" />
              </template>
            </el-table-column>
            <el-table-column :label="'Actions' | translate" prop="actions" align="right">
              <template slot-scope="scope">
                <el-dropdown
                  trigger="click"
                  :hide-on-click="false"
                  @command="(c) => handleTaskActions(c, scope.row)"
                >
                  <el-button
                    size="mini"
                    type="info"
                    icon="icon nova-dots icon-rotate-90"
                    circle
                  />
                  <el-dropdown-menu v-if="scope.row.id" slot="dropdown">
                    <el-dropdown-item v-if="scope.row.blocked" command="unblock">
                      <translate key="unblock">
                        To unblock
                      </translate>
                    </el-dropdown-item>

                    <el-dropdown-item v-else command="block">
                      <translate key="block">
                        To block
                      </translate>
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-if="scope.row.role !== $gettext('None')"
                      command="remove"
                    >
                      <translate key="remove">
                        Remove from the project
                      </translate>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <notifications />
    </div>
    <!--      end person projects table-->
  </div>
</template>

<script>
import {
  Input,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Select,
  Option,
  Checkbox,
  Notification,
} from 'element-ui'
import NovaHeader from '@layouts/HeaderLayout/NovaHeader'
import UserProfileCard from '@layouts/UsersLayout/UserProfileCard'
import swal from 'sweetalert2'

export default {
  components: {
    NovaHeader,
    [Input.name]: Input,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    UserProfileCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      search: '',
      // Профиль пользователя для передачи в компонент карточки
      user: {
        // опции редактирования фио и емэйла
        editable: {
          fullName: false,
          email: false,
        },
        // Профиль пользователя
        profile: {
          email: null,
          last_name: null,
          first_name: null,
          patronomic: null,
        },
        // Текущая выбранная компания
        company: {
          name: null,
          country: null,
          city: null,
          // Айди пользователя в компании
          userId: null,
        },
        // количество доступных проектов в компании
        tasks: null,
      },
      // Проекты для отображения в таблице
      tasks: [],
      // Организации в которых есть пользователь
      organizations: null,
      // Текущая выбранная организация
      currentOrganization: 0,
      // Текущий пользователь который просматривает профиль
      currentUser: {
        id: null,
        // Является ли владельцем организации
        // Соответственно может ли блокировать пользователя
        owner: false,
      },
      // Параметр, заблокирован ли пользователь
      blocked: false,
      // Была ли нажата клавиша блокировки, для обрабатывания случайных дабл кликов по кнопке
      blockInProgress: false,
      blocking: {
        blockText: this.$gettext('Unlock user'),
        unlockText: this.$gettext('Block user'),
      },
      showTable: true,
      loadingUser: false,
      loadingTable: false,
    }
  },
  computed: {
    // параметр для вывода нужного текста в кнопку блокировки/разблокировки пользователя
    blockingTextBtn() {
      return this.blocked ? this.blocking.blockText : this.blocking.unlockText
    },
  },
  async mounted() {
    // Получаем текущего пользователя который смотрит профиль
    this.currentUser.id = await this.$auth.user().id
    // Получаем пользователя чей профиль
    this.loadUser(+this.$route.params.userId)
  },
  methods: {
    /**
     * Функция которая загружает пользователя
     * @param userId айди пользователя
     * @returns {Promise<void>}
     */
    async loadUser(userId) {
      this.loadingUser = true
      try {
        const response = await this.axios.get(`/user/${userId}`)
        this.user.profile = response.data
        await this.loadCompanies(this.user.profile.id)
      } catch (e) {
        console.error('Не удалось загрузить пользователя', e)
      }
      this.loadingUser = false
    },
    /**
     * Функция которая загружает компании в которых состоят и тот кто смотрит профайл и тот чей профайл
     * @param userId айди пользователя чей профайл
     * @returns {Promise<void>}
     */
    async loadCompanies(userId) {
      // TODO надо бы переделать запрос на бэке с фильтром по ид
      const response = await this.axios.get('/organizations/')
      this.organizations = response.data.results.filter((company) => {
        for (const user of company.users) {
          if (+user.id === +userId) {
            return true
          }
        }
      })
      this.user.company = this.organizations[this.currentOrganization]
      this.loadTasks()
    },
    /**
     * Функция которая загружает проекты доступные в компании пользователю
     * @returns {Promise<void>}
     */
    async loadTasks() {
      this.loadingTable = true
      try {
        this.tasks = null
        const projects = []
        this.currentUser.owner = false
        // Грузим проекты в текущей организации доступные пользователю который смотрит профайл
        let response = await this.axios.get(
          `/organizations/${this.organizations[this.currentOrganization].id}/projects/`
        )
        let nextProjectsPage = response.data.next
        projects.push(...response.data.results)
        while (nextProjectsPage) {
          const response = await this.axios.get(nextProjectsPage)
          nextProjectsPage = response.data.next
          projects.push(...response.data.results)
        }
        // Проверяем права пользователя который смотрит профайл в текущей организации
        response = await this.axios.get(
          `/organizations/${this.organizations[this.currentOrganization].id}/profiles/`
        )
        for (const profile of response.data.results) {
          if (profile.user.id === this.currentUser.id && profile.roles) {
            this.currentUser.owner =
              profile.roles.findIndex((role) => role.short_name === 'owner') !== -1
          }
          if (profile.user.id === this.user.profile.id) {
            // Так же записываем айди юзера чей профайл в организации
            this.user.company.userId = profile.id
          }
        }
        // грузим задачи проектов
        let tasks = await Promise.all(
          projects.map(async (project) => {
            const response = await this.axios.get(`/projects/${project.id}/tasks/`)
            return response.data
          })
        )
        tasks = tasks.flat()
        this.user.tasks = tasks.length
        // грузим профайлы проектов
        let profiles = await Promise.all(
          projects.map(async (project) => {
            const response = await this.axios.get(
              `/organizations/${
                this.organizations[this.currentOrganization].id
              }/projects/${project.id}/profiles/`
            )
            const blocked = await this.checkUserBlock(project.id)
            return {
              results: response.data.results,
              project: project.id,
              actions: response.data.actions,
              blocked,
            }
          })
        )
        profiles = profiles.flat()
        // TODO: Раздаем всем таскам возможность редактирования для отображения нужных кнопок в таблице, на основании прав пользователя который смотрит профайл
        this.blocked = tasks.length > 0
        for (const task of tasks) {
          const taskProfiles = profiles.find(
            (profile) => profile.project === task.project
          )
          for (const profile of taskProfiles.results) {
            if (profile.id === this.user.company.userId) {
              task.role = profile.roles[0].short_name
              task.localizedRole = profile.roles[0].localized_name
            }
          }
          if (!task.role) {
            task.localizedRole = this.$gettext('None')
          }
          // Проверка блокировки пользователя в тасках
          if (!this.currentUser.owner) {
            task.rightsEditable = !!(taskProfiles.actions && taskProfiles.actions.POST)
            this.blocked = this.blocked && taskProfiles.blocked
            task.blocked = taskProfiles.blocked
          } else {
            task.rightsEditable = true
            task.blocked = taskProfiles.blocked
            this.blocked = this.blocked && task.blocked
          }
        }
        this.tasks = tasks
        // TODO: сделать нормальное проставление чекбоксов, реактивность в el-table не работает
        // не понял что не работает, чекбоксы заполняются корректно
        // setTimeout(this.terribleRerenderTable, 500)
      } catch (e) {
        console.error('Не удалось загрузить список проектов', e)
      }
      this.loadingTable = false
    },
    /**
     * Функция смены пароля
     */
    async changePassword() {
      try {
        const response = await this.axios.post(
          `/organizations/${
            this.organizations[this.currentOrganization].id
          }/users/forgot-password/`,
          {
            email: this.user.profile.email,
          }
        )
        if (response.status === 200) {
          this.showSuccessNotification(this.$gettext('Password recovery requested'))
          this.toggleChangePasswordModal(false)
        } else {
          this.showDangerNotification(
            this.$gettext('Password recovery request ends with errors')
          )
        }
      } catch (e) {
        console.error('Не удалось запросить смену пароля', e)
      }
    },
    /**
     * Функция Блокировки пользователя
     * @param value Boolean означающий блокировать или разблокировать
     * @returns {Promise<void>}
     */
    async blockUser(value) {
      if (value) {
        await this.block()
        this.blockingTextBtn = this.blocking.blockText
      } else {
        await this.unblock()
        this.blockingTextBtn = this.blocking.unlockText
      }
    },
    /**
     * Блокировка пользователя
     */
    async block(project) {
      // Помечаем что началась блокировка, для обрабатываний случайных нажатий на кнопку блокировки
      this.blockInProgress = true
      try {
        // Получаем все проекты пользователя в организации
        let response = await this.axios.get(
          `/organizations/${this.organizations[this.currentOrganization].id}/projects/`
        )
        let projects = []
        if (project) {
          projects.push(project)
        } else {
          projects = response.data.results.map((project) => {
            return project.id
          })
        }
        // ЗАпрос на блокировку пользователя во всех проектах
        projects.map(async (project) => {
          response = await this.axios.post(
            `/organizations/${
              this.organizations[this.currentOrganization].id
            }/user-project-blocks/`,
            {
              projects: [project],
              blocked_users: [this.user.company.userId],
              comment: 'block ' + this.user.profile.username,
            }
          )
          if (response.status === 201) {
            this.showSuccessNotification(this.$gettext('User blocked'))
            this.loadTasks()
            this.blocked = true
          } else {
            this.showDangerNotification(this.$gettext('User not blocked'))
          }
        })
      } catch (e) {
        console.error('Не получилось заблокировать пользователя', e)
      }
      this.blockInProgress = false
    },
    /**
     * Разблокировка пользователя
     */
    async unblock(project) {
      try {
        // Получаем список блокировок
        let response = await this.axios.get(
          `/organizations/${
            this.organizations[this.currentOrganization].id
          }/user-project-blocks/`
        )
        // Ищем среди блокировок текущего пользователя
        response.data.results.map((block) => {
          block.blocked_users.map(async (user) => {
            if (user === this.user.company.userId) {
              if (!project) {
                // Для каждой блокировки снимаем ее
                response = await this.axios.delete(
                  `/organizations/${
                    this.organizations[this.currentOrganization].id
                  }/user-project-blocks/${block.id}/`
                )
                if (response.status === 204) {
                  this.showSuccessNotification(this.$gettext('User unblocked'))
                  this.loadTasks()
                  this.blocked = false
                } else {
                  this.showDangerNotification(this.$gettext('User not unblocked'))
                }
              } else {
                block.projects.map(async (blockedProject) => {
                  if (blockedProject === project) {
                    response = await this.axios.delete(
                      `/organizations/${
                        this.organizations[this.currentOrganization].id
                      }/user-project-blocks/${block.id}/`
                    )
                    if (response.status === 204) {
                      this.showSuccessNotification(this.$gettext('User unblocked'))
                      this.loadTasks()
                      this.blocked = false
                    } else {
                      this.showDangerNotification(this.$gettext('User not unblocked'))
                    }
                  }
                })
              }
            }
          })
        })
      } catch (e) {
        console.error('Не получилось разблокировать пользователя', e)
      }
    },
    /**
     * Проверка, заблокирован ли пользователь
     * @returns {Promise<boolean>}
     */
    async checkUserBlock(projectId) {
      const response = await this.axios.get(
        `/organizations/${
          this.organizations[this.currentOrganization].id
        }/user-project-blocks/`
      )
      let blocked = false
      response.data.results.map((block) => {
        block.blocked_users.map((user) => {
          block.projects.map((project) => {
            if (user === this.user.company.userId && project === projectId) {
              blocked = true
            }
          })
        })
      })
      return blocked
    },
    /**
     * Удаление из проекта
     * @param project айди проекта
     */
    async removeFromProject(project) {
      try {
        const response = await this.axios.patch(
          `/organizations/${
            this.organizations[this.currentOrganization].id
          }/projects/${project}/profiles/${this.user.company.userId}/`,
          {
            roles: [],
          }
        )
        if (response.status === 200) {
          this.showSuccessNotification(
            this.user.profile.username + ' ' + this.$gettext('removed from project')
          )
          this.loadTasks()
        } else {
          this.showDangerNotification(
            this.user.profile.username + ' ' + this.$gettext('not removed from project')
          )
        }
      } catch (e) {
        console.warn('Не получилось забрать права на проект', e)
      }
    },
    /**
     * Обработка событий дропдауна в таблице проектов
     * @param action действие
     * @param task таск
     */
    async handleTaskActions(action, task) {
      switch (action) {
        case 'remove':
          await this.removeFromProject(task.project)
          break
        case 'block':
          await this.block(task.project)
          break
        case 'unblock':
          await this.unblock(task.project)
          break
      }
    },
    /**
     * Высвечивание оповещения об успехе
     * @param message Сообщение
     */
    showSuccessNotification(message) {
      Notification({
        title: this.$gettext('Saved'),
        message: message,
        type: 'success',
        position: 'top-right',
        showClose: false,
      })
    },
    /**
     * Высвечивание оповещения о неудаче
     * @param message Сообщение
     */
    showDangerNotification(message) {
      Notification({
        title: this.$gettext('Not saved'),
        message: message,
        type: 'error',
        position: 'top-right',
        showClose: false,
      })
    },
    /**
     * Предупреждение при запуске смены пароля
     * @param type - тип оповещения
     */
    showSwal(type) {
      if (type === 'password') {
        const self = this
        swal({
          title: this.$gettext('Are you sure?'),
          text: this.$gettext('Leave a password request?'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'action-btn save-btn py-3 w-50',
          cancelButtonClass: 'action-btn cancel-btn py-3 w-50',
          confirmButtonText: this.$gettext('Yes, change password!'),
          cancelButtonText: this.$gettext('Cancel'),
          buttonsStyling: false,
        }).then(() => {
          self.changePassword()
        })
      }
    },
    /**
     * Ререндер таблицы посредством перекликивания v-if на ней
     */
    // TODO: без этого не обновляется таблица, но вообще надо избавиться от этого убожества
    terribleRerenderTable() {
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
    },
  },
}
</script>

<style scoped>
.el-button--info {
  background: #747474 0 0 no-repeat padding-box;
  border-color: #747474;
}
.el-input__inner {
  background-color: #0c2646;
}
.action-btns {
  margin: 0;
}
.action-btns .el-button.is-circle {
  background: #464646;
  color: #ffffff;
  border: none;
  font-size: 14px;
}
/*!* main content *!*/
.font-size-10 {
  font-weight: 700;
  font-size: 10px;
}
.count-person {
  font-weight: 700;
}
.card-bottom {
  background: #808080;
  border-radius: 0 0 5px 5px;
}
.card__person-status i {
  font-size: 16px;
}
</style>
<style>
.custom-el-table-row {
  color: white;
}
.custom-el-table-row:hover > td {
  background-color: #737373 !important;
}
.el-button.is-circle {
  border-radius: 50%;
  background: transparent;
  color: #ffffff;
  font-size: 25px;
  border: none;
  padding: 0;
}
.for-guide {
  background-color: rgb(244, 243, 239);
}
</style>
