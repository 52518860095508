<template>
  <div class="wrapper">
    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks">
      <user-menu v-if="useAuth" />
    </side-bar>

    <div class="main-panel">
      <!--      <top-navbar></top-navbar>-->

      <dashboard-content @click.native="toggleSidebar" />

      <!--      <content-footer></content-footer>-->
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
// import TopNavbar from './TopNavbar.vue'
// import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
import NPMPackage from 'package'

export default {
  components: {
    // TopNavbar,
    // ContentFooter,
    DashboardContent,
    UserMenu,
  },
  data() {
    return {
      useAuth: NPMPackage.novaConfig ? NPMPackage.novaConfig.useAuth : false,
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
  },
}
</script>
