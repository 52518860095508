/**
 * Помощник GUI для управления цветом
 */
export default class ColorGUIHelper {
  constructor(object, prop) {
    this.object = object
    this.prop = prop
  }

  get value() {
    return `#${this.object[this.prop].getHexString()}`
  }

  set value(hexString) {
    this.object[this.prop].set(hexString)
  }
}
