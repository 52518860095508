<script>
import 'jstree'
import * as THREE from 'three'
import * as Potree from '@letsnova/potree/build/potree/potree'
import MTTLoader from 'src/vendor/libs/other/MTLLoader'
import 'src/vendor/libs/other/OBJLoader'
import { loadPointcloud } from 'src/util/loadPointcloud'

THREE.MTLLoader = MTTLoader

// инициализация potree
window.Potree = Potree
export default {
  props: {
    task1: {
      type: Object,
      default: () => {
        return {}
      },
    },
    task2: {
      type: Object,
      default: () => {
        return {}
      },
    },
    vertical: {
      type: Boolean,
      default: true,
    },
    pointBudget: {
      type: Number,
      default: 1000000,
    },
  },
  data: function() {
    return {
      sceneL: {},
      sceneR: {},
      viewer1: {},
      viewer2: {},
      topPanel: {},
      parent: {},
      rangeValue: 0.5,
      thumbsize: 42,
      separatorWidth: 4,
      slider: {},
      range: {},
    }
  },
  watch: {
    /**
     * При смене таска, маунтим новый поинтклауд и синхрониззируем камеру относительно второго вьюера
     * @param newVal
     */
    task1(newVal) {
      this.mountPointCloud(newVal, this.viewer1)
      this.synchronizeCameras(this.viewer1)
    },
    /**
     * При смене таска, маунтим новый поинтклауд и синхрониззируем камеру относительно второго вьюера
     * @param newVal
     */
    task2(newVal) {
      this.mountPointCloud(newVal, this.viewer2)
      this.synchronizeCameras(this.viewer2)
    },
    vertical(newVal) {
      if (newVal) {
        this.initSliderVertical()
      } else {
        this.initSliderHorizontal()
      }
    },
    /**
     * Меняем количество точек отображаемых в PC
     * @param newVal
     */
    pointBudget(newVal) {
      this.viewer1.setPointBudget(this.pointBudget)
      this.viewer2.setPointBudget(this.pointBudget)
    },
  },
  mounted() {
    // Инициализируеем сплитскрин
    this.parent = document.querySelector('.splitview')
    this.topPanel = this.parent.querySelector('.top')

    // Создаем вьюеры и линкуем сцены
    this.viewer1 = new window.Potree.Viewer(
      document.getElementById('potree_render_area_left'),
      { project: this.project, task: this.task }
    )
    this.viewer1.setEDLEnabled(true)
    this.viewer1.setFOV(60)
    this.viewer1.setPointBudget(1 * 1000 * 1000)
    this.viewer1.loadSettingsFromURL()
    this.viewer1.setDescription('')

    this.viewer2 = new window.Potree.Viewer(
      document.getElementById('potree_render_area_right'),
      { project: this.project, task: this.task }
    )
    this.viewer2.setEDLEnabled(true)
    this.viewer2.setFOV(60)
    this.viewer2.setPointBudget(1 * 1000 * 1000)
    this.viewer2.loadSettingsFromURL()
    this.viewer2.setDescription('')

    this.sceneL = this.viewer1.scene.scene
    this.sceneR = this.viewer2.scene.scene

    // Линкуем вьюеры друг к другу
    this.viewer1.$otherViewer = this.viewer2
    this.viewer2.$otherViewer = this.viewer1

    // Инициализируем подписки на ивенты
    if (this.vertical) {
      this.initSliderVertical()
    } else {
      this.initSliderHorizontal()
    }
    this.initWindowResizeListener()
    this.$nextTick(() => {
      // Линкуем управление между вьюерами
      this.initDoubleControls()
    })
  },
  methods: {
    /**
     * Метод синхронизирующий камеру одного вьюера относительно второго
     * @param viewer - вьюер
     */
    synchronizeCameras(viewer) {
      const camera = viewer.scene.view
      const prevCam = viewer.$otherViewer.scene.view
      for (const key in prevCam) {
        if (prevCam[key] instanceof THREE.Vector3) {
          camera[key] = new THREE.Vector3()
          camera[key].copy(prevCam[key])
        } else if (typeof prevCam[key] === 'number') {
          camera[key] = prevCam[key]
        } else return
      }
    },
    // TODO: Ниже неиспользуемый метод - возможно стоит перенести загрузку поинтклауда в этот компонент
    /**
     * Загружает и монтирует поинтклауд в выбранный вьюер
     * @param task - id таска
     * @param viewer - вьюер
     */
    loadAndMountPointCloud(task, viewer) {
      console.warn('Облако точек еще не загружено, загружаем...')
      const path = `${this.assetsPath(this.task)}/entwine_pointcloud/ept.json`
      loadPointcloud.call(this, path).then((res) => {
        this.mountPointCloud(res, viewer)
      })
    },
    /**
     * Монтирование поинтклауда в выбранный вьюер
     * @param pointcloud - поинтклауд
     * @param viewer - вьюер
     */
    mountPointCloud(pointcloud, viewer) {
      const scene = viewer.scene
      if (scene.pointclouds.length) {
        scene.scenePointCloud.remove(pointcloud)
        scene.pointclouds = []
      }
      scene.addPointCloud(pointcloud)

      const material = pointcloud.material
      material.size = 1

      material.pointSizeType = Potree.PointSizeType.FIXED
      // material.pointColorType = Potree.PointColorType.RGB
      material.shape = Potree.PointShape.CIRCLE
      if (!viewer.$otherViewer.scene.pointclouds.length) {
        viewer.fitToScreen()
      }
    },
    /**
     * Путь к ассетам
     * @param task - id таска
     * @returns {string}
     */
    assetsPath(task) {
      return this.$root.apiPath(
        `/api/projects/${this.$route.params.projectId}/tasks/${task}/assets`
      )
    },
    /**
     * Синхронизация управления вьюерами
     */
    initDoubleControls() {
      Object.entries(this.viewer1.orbitControls._listeners).map((listener) => {
        // Отключаем даблклик, потому что некорректно работает, зумит два вьюера в разные позиции
        if (listener[0] === 'dblclick') {
          listener[1].map((item, index) => {
            const existListener = this.viewer2.orbitControls._listeners[listener[0]][
              index
            ]
            this.viewer2.orbitControls.removeEventListener(listener[0], existListener)
            this.viewer1.orbitControls.removeEventListener(listener[0], item)
          })
        } else {
          listener[1].map((item, index) => {
            const existListener = this.viewer2.orbitControls._listeners[listener[0]][
              index
            ]
            this.viewer2.orbitControls.addEventListener(listener[0], item)
            this.viewer1.orbitControls.addEventListener(listener[0], existListener)
          })
        }
      })
    },
    /**
     * Инициализация слайдера разделяюшего панели
     */
    initSliderVertical() {
      this.$nextTick(() => {
        this.slider = document.querySelector('.handle')
        this.range = document.querySelector('.handle-range-vertical')
        this.rangeValue = 0.5
        const delta =
          (this.parent.getBoundingClientRect().right -
            this.parent.getBoundingClientRect().left) *
            this.rangeValue -
          this.separatorWidth / 2
        this.slider.style.left = delta + 'px'
        this.slider.style.top = 0
        this.topPanel.style.width = delta + 'px'
        this.range.style.minWidth = this.parent.clientWidth + 'px'
        this.topPanel.style['min-height'] = '100%'
        this.topPanel.style['min-width'] = '0'
      })
    },
    /**
     * Инициализация слайдера разделяюшего панели
     */
    initSliderHorizontal() {
      this.$nextTick(() => {
        this.slider = document.querySelector('.handle-horizontal')
        this.range = document.querySelector('.handle-range-horizontal')
        this.rangeValue = 0.5
        const delta =
          (this.parent.getBoundingClientRect().bottom -
            this.parent.getBoundingClientRect().top) *
            this.rangeValue -
          this.separatorWidth / 2
        this.slider.style.left = 0
        this.slider.style.top = delta + 'px'
        this.topPanel.style.height = delta + 'px'
        this.range.style.minWidth = this.parent.clientHeight + 'px'
        this.topPanel.style['min-width'] = '100%'
        this.topPanel.style['min-height'] = '0'
      })
    },
    /**
     * Передвижение слайдера
     * @param value - от 0 до 1 положение слайдера на экране
     */
    moveSlider(value) {
      this.rangeValue = value
      const box = this.parent.getBoundingClientRect()
      const offset = (0.5 - value) * this.thumbsize - this.separatorWidth / 2

      if (this.vertical) {
        const width = box.right - box.left
        const delta = width * value + offset

        // Move the handle.
        this.slider.style.left = delta + 'px'

        // Adjust the top panel width.
        this.topPanel.style.width = delta + 'px'
      } else {
        const height = box.bottom - box.top
        const delta = height * value + offset

        // Move the handle.
        this.slider.style.top = delta + 'px'

        // Adjust the top panel width.
        this.topPanel.style.height = delta + 'px'
      }
    },
    /**
     * Блокировка управления
     */
    blockControls() {
      this.clicked = true
      this.viewer1.controls.enabled = false
      this.viewer2.controls.enabled = false
    },
    /**
     * Разблокировка управления
     */
    unblockControls() {
      this.clicked = false
      this.viewer1.controls.enabled = true
      this.viewer2.controls.enabled = true
    },
    /**
     * Инициализация листенера ресайза окна
     */
    initWindowResizeListener() {
      function onWindowResize() {
        this.moveSlider(this.rangeValue)
      }
      window.addEventListener('resize', onWindowResize.bind(this))
    },
  },
}
</script>

<template>
  <div style="height: 100%">
    <div class="splitview">
      <div class="panel bottom">
        <div class="content">
          <div style="height:100%; width: 100%; position: relative;">
            <div id="potree_render_area_right" style="height: 100%" />
          </div>
        </div>
      </div>

      <div class="panel top">
        <div class="content">
          <div style="height:100%; width: 100%; position: relative;">
            <div id="potree_render_area_left" style="height: 100%" />
          </div>
        </div>
      </div>
      <div :class="vertical ? 'handle' : 'handle-horizontal'" />
      <input
        :value="rangeValue"
        class="px-0"
        :class="vertical ? 'handle-range-vertical' : 'handle-range-horizontal'"
        type="range"
        min="0"
        max="1"
        step="any"
        @input="(e) => moveSlider(e.target.value)"
        @mousedown="blockControls"
        @mouseup="unblockControls"
        @touchstart="blockControls"
        @touchend="unblockControls"
      />
    </div>
  </div>
</template>

<style>
/* https://webdesign.tutsplus.com/ru/tutorials/how-to-create-a-split-screen-slider-with-javascript--cms-28844 */
/* Panels. */
.splitview {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.panel {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.panel .content {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  color: #fff;
}

.panel .description {
  width: 25%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.panel img {
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.15);
  width: 35%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom {
  background-color: rgb(44, 44, 44);
  z-index: 1;
}

.bottom .description {
  right: 5%;
}

.top {
  background-color: rgb(77, 69, 173);
  z-index: 2;
  width: 50vw;
}

.top .description {
  left: 5%;
}

/* Стиль слайдера взял из используемой для 2д библиотеки лиафлета */
/* https://github.com/digidem/leaflet-side-by-side */
/* Handle. */
.handle {
  height: 100%;
  position: absolute;
  display: block;
  background-color: #fff;
  width: 4px;
  top: 0;
  left: 50%;
  z-index: 3;
  pointer-events: none;
}

.handle-horizontal {
  height: 4px;
  position: absolute;
  display: block;
  background-color: #fff;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 3;
  pointer-events: none;
}

.handle-range-vertical {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 999;
  -webkit-appearance: none;
  display: inline-block !important;
  vertical-align: middle;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.25);
  min-width: 100px;
  cursor: pointer;
  pointer-events: none;
  z-index: 999;
}
.handle-range-vertical::-ms-fill-upper {
  background: transparent;
}
.handle-range-vertical::-ms-fill-lower {
  background: rgba(255, 255, 255, 0.25);
}
/* Browser thingies */

.handle-range-vertical::-moz-range-track {
  opacity: 0;
}
.handle-range-vertical::-ms-track {
  opacity: 0;
}
.handle-range-vertical::-ms-tooltip {
  display: none;
}
/* For whatever reason, these need to be defined
   * on their own so dont group them */

.handle-range-vertical::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(~public/static/icons/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.handle-range-vertical::-ms-thumb {
  margin: 0;
  padding: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(~public/static/icons/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.handle-range-vertical::-moz-range-thumb {
  padding: 0;
  right: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(~public/static/icons/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.handle-range-vertical:disabled::-moz-range-thumb {
  cursor: default;
}
.handle-range-vertical:disabled::-ms-thumb {
  cursor: default;
}
.handle-range-vertical:disabled::-webkit-slider-thumb {
  cursor: default;
}
.handle-range-vertical:disabled {
  cursor: default;
}
.handle-range-vertical:focus {
  outline: none !important;
}
.handle-range-vertical::-moz-focus-outer {
  border: 0;
}

.handle-range-horizontal {
  position: absolute;
  top: 50%;
  left: calc(50% - 50vh);
  right: 50%;
  z-index: 999;
  -webkit-appearance: none;
  display: inline-block !important;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.25);
  min-width: 100px;
  cursor: pointer;
  pointer-events: none;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.handle-range-horizontal::-ms-fill-upper {
  background: transparent;
}
.handle-range-horizontal::-ms-fill-lower {
  background: rgba(255, 255, 255, 0.25);
}
/* Browser thingies */

.handle-range-horizontal::-moz-range-track {
  opacity: 0;
}
.handle-range-horizontal::-ms-track {
  opacity: 0;
}
.handle-range-horizontal::-ms-tooltip {
  display: none;
}
/* For whatever reason, these need to be defined
   * on their own so dont group them */

.handle-range-horizontal::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ns-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(~public/static/icons/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.handle-range-horizontal::-ms-thumb {
  margin: 0;
  padding: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ns-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(~public/static/icons/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.handle-range-horizontal::-moz-range-thumb {
  padding: 0;
  right: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ns-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(~public/static/icons/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.handle-range-horizontal:disabled::-moz-range-thumb {
  cursor: default;
}
.handle-range-horizontal:disabled::-ms-thumb {
  cursor: default;
}
.handle-range-horizontal:disabled::-webkit-slider-thumb {
  cursor: default;
}
.handle-range-horizontal:disabled {
  cursor: default;
}
.handle-range-horizontal:focus {
  outline: none !important;
}
.handle-range-horizontal::-moz-focus-outer {
  border: 0;
}

/* Skewed. */
.skewed .handle {
  top: 50%;
  transform: rotate(30deg) translateY(-50%);
  height: 200%;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}

.skewed .top {
  transform: skew(-30deg);
  margin-left: -1000px;
  width: calc(50vw + 1000px);
}

.skewed .top .content {
  transform: skew(30deg);
  margin-left: 1000px;
}

/* Responsive. */
@media (max-width: 900px) {
  body {
    font-size: 75%;
  }
}
</style>
