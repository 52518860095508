<template>
  <div :class="vertical ? 'row' : ''">
    <div :class="vertical ? verticalNavClasses : ''">
      <div
        class="nav-tabs-navigation"
        :class="{'verical-navs': vertical}"
      >
        <div class="nav-tabs-wrapper">
          <slot name="nav" />
        </div>
      </div>
    </div>
    <div :class="vertical ? verticalTabContentClasses : ''">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsLayout',
  props: {
    vertical: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    verticalNavClasses: [String, Object],
    // eslint-disable-next-line vue/require-default-prop
    verticalTabContentClasses: [String, Object],
  }
}
</script>
