<script>
import { fromLatLon } from 'utm'
import { Tooltip, Tree } from 'element-ui'
import SplitscreenLeafletViewer from '../2D/SplitscreenLeafletViewer'
import _ from 'lodash'
import SplitscreenPotreeViewer from '../3D/SplitscreenPotreeViewer'
import { MODE_2D, MODE_3D } from '../constants'
import * as Potree from '@letsnova/potree/build/potree/potree'
import { loadPointcloud } from 'src/util/loadPointcloud'

export default {
  components: {
    SplitscreenPotreeViewer,
    [Tree.name]: Tree,
    [Tooltip.name]: Tooltip,
    SplitscreenLeafletViewer,
  },
  data() {
    return {
      project: {},
      tasks: [],
      treeItems: [],
      layer1: {},
      layer2: {},
      task1: {},
      task2: {},
      currentMode: MODE_2D,
      mode2d: MODE_2D,
      mode3d: MODE_3D,
      vertical: true,
      pointBudget: 1000000,
    }
  },
  async mounted() {
    // Грузим проект и таски
    await this.loadProject()
    this.project.tasks.map(async (task) => {
      const item = await this.loadTaskInfo(task)
      this.tasks.push(item)
      this.generateTreeItem(item)
    })
  },
  methods: {
    assetsPath(task) {
      return this.$root.apiPath(
        `/api/projects/${this.$route.params.projectId}/tasks/${task}/assets`
      )
    },
    /**
     * Загрузка проекта
     * @returns {Promise<any>} - проект
     */
    async loadProject() {
      try {
        const response = await this.axios.get(
          `/projects/${this.$route.params.projectId}/`
        )
        if (response.data) {
          this.project = response.data
        }
        return response.data
      } catch (e) {
        console.error(
          `Не удалось загрузить информацию о проекте ${this.$route.params.projectId}`,
          e
        )
      }
    },
    /**
     * Загрузка таска и парсинг слоев
     * @param id - таск
     * @returns {Promise<void>}
     */
    async loadTaskInfo(id) {
      try {
        const object = {}
        const response = await this.axios.get(
          `projects/${this.$route.params.projectId}/tasks/${id}/`
        )
        object.taskInfo = response.data
        // Грузим инфо о слоях DSM и orthomosaic
        try {
          object.dsm = null
          const response = await this.$http.get(
            `projects/${this.$route.params.projectId}/tasks/${id}/dsm/tiles.json`
          )
          object.dsm = response.data
          // Переопределяем пути для слоёв
          if (object.dsm.tiles) {
            object.dsm.tiles = object.dsm.tiles.map((tile) => this.$root.apiPath(tile))
          }
          // FIXME надо переиграть
          object.dsm.name = undefined
          if (object.dsm.bounds) {
            const result = fromLatLon(object.dsm.bounds[1], object.dsm.bounds[0])
            object.utmZoneNum = result.zoneNum
            object.utmZoneLetter = result.zoneLetter
          }
          object.dsm.type = 'dsm-layer'
          object.dsm.$task = object
        } catch (e) {
          console.error(
            `Не удалось загрузить информацию DSM. Задача ${this.task} проекта ${
              this.project
            }`,
            e
          )
          object.dsm = null
          // проставим значения по умолчанию, чтобы корректно работали замеры без 2Д истории
          object.utmZoneNum = 32
          object.utmZoneLetter = 'T'
        }
        try {
          object.orthophoto = null
          const response = await this.$http.get(
            `projects/${this.$route.params.projectId}/tasks/${id}/orthophoto/tiles.json`
          )
          object.orthophoto = response.data
          // Переопределяем пути для слоёв
          if (object.orthophoto.tiles) {
            object.orthophoto.tiles = object.orthophoto.tiles.map((tile) =>
              this.$root.apiPath(tile)
            )
          }
          // FIXME надо переиграть
          object.orthophoto.name = undefined
          if (object.orthophoto.bounds) {
            const result = fromLatLon(
              object.orthophoto.bounds[1],
              object.orthophoto.bounds[0]
            )
            object.utmZoneNum = result.zoneNum
            object.utmZoneLetter = result.zoneLetter
          }
          object.orthophoto.type = 'orthophoto-layer'
          object.orthophoto.$task = object
        } catch (e) {
          console.error(
            `Не удалось загрузить информацию orthophoto. Задача ${this.task} проекта ${
              this.project
            }`,
            e
          )
          object.orthophoto = null
        }
        try {
          // Прокидываем авторизацию, нужно будет для того, чтобы скачивались ноды EPT
          Potree.XHRFactory.config.withCredentials = true
          Potree.XHRFactory.config.customHeaders = this.$root.headers

          // Формируем путь к воркерам Potree
          Potree.scriptPath = this.$root.apiPath(
            '/webodm/static/app/js/vendor/potree/build'
          )

          object.pointcloud = {}
          const pointcloud = await loadPointcloud.call(
            this,
            `${this.assetsPath(id)}/entwine_pointcloud/ept.json`
          )
          object.pointcloud = pointcloud
          object.pointcloud.$task = object
        } catch (e) {
          console.error(
            `Не удалось загрузить информацию pointcloud. Задача ${this.task} проекта ${
              this.project
            }`,
            e
          )
          object.pointcloud = null
        }
        return object
      } catch (e) {
        console.error(
          `Не удалось загрузить информацию о задаче ${this.task} проекта ${this.project}`,
          e
        )
      }
    },
    /**
     * Генерация из таска элемента дерева
     * @param task
     */
    generateTreeItem(task) {
      const item = {
        label: task.taskInfo.name || this.$gettext('Task without name'),
        type: 'task',
        icon: 'fas fa-cubes',
        visibility: task.dsm !== null && task.orthophoto === null,
        $ref: task,
        children: [],
      }
      if (task.orthophoto !== null) {
        item.children.push({
          label: this.$gettext('Orthomosaic'),
          type: 'orthophoto-layer',
          icon: 'fas fa-map',
          $ref: task.orthophoto,
          needDimension: MODE_2D,
        })
      }
      if (task.dsm !== null) {
        item.children.push({
          label: this.$gettext('DSM'),
          type: 'dsm-layer',
          icon: 'fas fa-cubes',
          $ref: task.dsm,
          needDimension: MODE_2D,
        })
      }
      if (task.pointcloud !== null) {
        item.children.push({
          label: this.$gettext('Point cloud'),
          type: 'pointcloud',
          icon: 'fas fa-chess-board',
          get disabled() {
            return _.isEmpty(this.$ref.pointcloud)
          },
          $ref: task,
          needDimension: MODE_3D,
        })
      }
      this.treeItems.push(item)
    },
    /**
     * Выбор элемента для показа в левой половине экрана
     * @param item
     */
    chooseLeft(item) {
      if (this.currentMode === this.mode2d) {
        this.layer1 = _.cloneDeep(item.$ref)
      }
      if (this.currentMode === this.mode3d) {
        this.task1 = _.cloneDeep(item.$ref.pointcloud)
      }
    },
    /**
     * Выбор элемента для показа в правой половине экрана
     * @param item
     */
    chooseRight(item) {
      if (this.currentMode === this.mode2d) {
        this.layer2 = _.cloneDeep(item.$ref)
      }
      if (this.currentMode === this.mode3d) {
        this.task2 = _.cloneDeep(item.$ref.pointcloud)
      }
    },
    changeMode() {
      this.currentMode = this.currentMode === this.mode2d ? this.mode3d : this.mode2d
    },
  },
}
</script>

<template xmlns:v-nova-page-guide="http://www.w3.org/1999/xhtml">
  <div class="wrapper">
    <div class="d-flex flex-row">
      <div
        id="layers-toolbar"
        v-nova-page-guide:measureMenu
        class="layers-toolbar d-flex flex-row justify-content-between mr-auto"
      >
        <!--Меню со тасками-->
        <div class="layers-toolbar-content d-flex flex-column">
          <div class="d-flex flex-row">
            <button
              v-nova-page-guide:btnBackToProject
              class="col-2 add-btn py-3"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-left" />
            </button>
            <span class="col-10 add-btn py-3">
              {{
                `${$gettext('Project')}: ${project.name ||
                  $gettext('Project with no name')}`
              }}
            </span>
          </div>
          <el-tree
            v-if="tasks"
            ref="groupTree"
            :data="treeItems"
            node-key="treeNodeId"
            default-expand-all
            icon-class="fas fa-angle-right"
            :expand-on-click-node="false"
          >
            <span
              slot-scope="{ node, data }"
              class="custom-tree-node d-flex align-items-center"
              :class="{
                'disabled-node':
                  (data.needDimension && data.needDimension !== currentMode) ||
                  data.disabled,
              }"
            >
              <i :class="[data.icon, 'mx-2']" style="font-size: 1.1rem" />
              <span>{{ node.label }}</span>
              <div
                v-show="
                  (!data.needDimension || data.needDimension === currentMode) &&
                    !data.children &&
                    !data.disabled
                "
                class="custom-div-btns"
              >
                <button
                  class="p-0"
                  style="border: 0px; background-color: transparent; cursor: pointer;"
                  @click="chooseLeft(data)"
                >
                  <i
                    class="fas"
                    :class="vertical ? 'fa-chevron-left' : 'fa-chevron-up'"
                  />
                </button>
                <button
                  class="p-0"
                  style="border: 0px; background-color: transparent; cursor: pointer;"
                  @click="chooseRight(data)"
                >
                  <i
                    class="fas"
                    :class="vertical ? 'fa-chevron-right' : 'fa-chevron-down'"
                  />
                </button>
              </div>
            </span>
          </el-tree>
        </div>
        <div id="split-bar-left" />
      </div>

      <div class="viewer-layout flex-grow-1">
        <!--        Блок с компонентом просмотра-->
        <div id="viewer" class="viewer" style="height: 100%;">
          <SplitscreenLeafletViewer
            v-if="currentMode === mode2d"
            ref="viewer"
            :layer1="layer1"
            :layer2="layer2"
            :vertical="vertical"
          />
          <SplitscreenPotreeViewer
            v-else
            :task1="task1"
            :task2="task2"
            :vertical="vertical"
            :point-budget="pointBudget"
          />
          <div class="control-btns control-btns-2d3d">
            <button
              class="p-0"
              :class="{ 'active-control-btn': currentMode === mode2d }"
              @click="currentMode = mode2d"
            >
              2D
            </button>
            <button
              class="p-0"
              :class="{ 'active-control-btn': currentMode === mode3d }"
              @click="currentMode = mode3d"
            >
              3D
            </button>
          </div>
          <div class="control-btns control-btns-settings">
            <el-tooltip :visible-arrow="false" :open-delay="0" placement="top">
              <div slot="content">
                <div class="text-center">
                  <translate>Point</translate><br /><translate>Budget</translate>
                </div>
                <div v-if="currentMode === mode3d" class="p-0">
                  <input
                    v-model.number="pointBudget"
                    class="control-btns-range my-2 pointer"
                    type="range"
                    min="100000"
                    max="10000000"
                    step="1000"
                  />
                  <div class="text-center">
                    {{ pointBudget }}
                  </div>
                </div>
              </div>
              <button v-show="currentMode === mode3d" class="p-0">
                PB
              </button>
            </el-tooltip>
            <el-tooltip :visible-arrow="false" :open-delay="0" placement="top">
              <div slot="content">
                <div class="text-center">
                  <translate>Splitter orientation</translate>
                </div>
              </div>
              <button class="p-0" @click="vertical = !vertical">
                <i
                  class="fas"
                  :class="vertical ? 'fa-arrows-alt-h' : 'fa-arrows-alt-v'"
                />
              </button>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.custom-div-btns
  visibility: hidden
  position: absolute
  right: 0
  /*background-color: #474747*/
  background-color: #2b2b2b
  border-radius: 4px 0 0 4px
  display: inline-block
  height: 26px

.custom-div-btns button
  margin: 0 8px
  color: white
  outline: none

.viewer-layout
  position: relative
  min-width: 458px
  overflow: hidden

.viewer
  position: absolute
  width: 100%

.el-tree
  background-color: #2b2b2b !important
  color: #d6d6d6 !important
  font-weight: bold
  padding: 10px 0
  max-height: calc(100% - 56px - 56px - 124px)
  overflow-y: auto !important

.el-tree::-webkit-scrollbar
  width: 5px
  background-color: transparent

.el-tree::-webkit-scrollbar-thumb
  background-color: #d0d0d0
  border-radius: 10px

.el-checkbox
  margin: 0 4px

.el-tree-node__content:hover
  background-color: #1d1d1d !important

.el-tree-node:focus > .el-tree-node__content
  background-color: #3f3f3f !important

.el-tooltip__popper
  background-color: #1d1d1d !important
  color: #d6d6d6 !important

/*el-tree*/
.el-tree
  overflow: hidden
.el-tree-node__expand-icon
  font-size: 1.3rem !important

.custom-tree-node
  width: 100%
.custom-tree-node:hover .custom-div-btns
  visibility: visible
.disabled-node
  color: rgba(116, 116, 116, 0.9) !important
.disabled-node i
  color: rgba(116, 116, 116, 0.9) !important

button:hover
  cursor: pointer
button:focus
  outline: none
.action-btn
  width: 100%
  height: 100%
  font-size: 16px
  color: white
  border: none
  cursor: pointer
  font-weight: 600
  text-align: center
.action-btn:focus
  outline: none
.split-btn
  background-image: linear-gradient(120deg, #ef8157 0%, #b06446 100%)
.split-btn:hover
  background-image: linear-gradient(120deg, #b06446 0%, #ef8157 100%)
.active-control-btn
  border-radius: 4px
  background-color: rgba(116, 116, 116, 0.9) !important

.control-btns
  /*display: flex*/
  /*-webkit-box-orient: vertical*/
  /*flex-direction: column*/
  /*align-items: center*/

  position: absolute
  z-index: 500
  /*overflow: hidden*/
  /*padding: 0 5px*/
  background-color: rgba(44, 44, 44, .9)
  border-radius: 4px
.control-btns i
  font-size: 20px
  display: block
  margin: auto

.control-btns button
  font-size: 19px
  color: white
  border: none
  background-color: transparent
  cursor: pointer
  /*font-weight: bold*/
  font-weight: 600
  width: 32px !important
  height: 32px !important

.control-btns button:hover
  background-color: #1d1d1d
  border-radius: 4px

.control-btns button:focus
  outline: none
  border-radius: 4px
.control-btns button
  position: relative
.control-btns-2d3d
  -webkit-box-orient: horizontal
  flex-direction: row
  bottom: 30px
  top: unset
  left: 20px
.control-btns-settings
  -webkit-box-orient: horizontal
  flex-direction: row
  bottom: 30px
  top: unset
  left: 104px
</style>
