import { render, staticRenderFns } from "./ProjectDetail.vue?vue&type=template&id=205ae0e8&scoped=true&"
import script from "./ProjectDetail.vue?vue&type=script&lang=js&"
export * from "./ProjectDetail.vue?vue&type=script&lang=js&"
import style0 from "./ProjectDetail.vue?vue&type=style&index=0&id=205ae0e8&scoped=true&lang=css&"
import style1 from "vue2-dropzone/dist/vue2Dropzone.min.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205ae0e8",
  null
  
)

export default component.exports