<template>
  <div>
    <div
      class="header"
      :v-page-guide="'Current menu item' | translate"
    >
      <translate>Settings</translate>
    </div>
    <div class="container">
      <div class="row">
        <div
          :v-page-guide="'Language selection menu' | translate"
          class="col-3 settings-wrapper"
        >
          <div
            class="settings-header"
            data-v-step="1"
          >
            <translate>Select language</translate>
          </div>
          <el-select
            v-model="language"
            class="select-primary select-lang"
            collapse-tags
          >
            <el-option
              v-for="(languageItem, key) in $language.available"
              :key="key"
              class="select-primary"
              :value="key"
              :label="languageItem"
            />
          </el-select>
        </div>
        <!--        <div class="col-3 settings-wrapper">-->
        <!--          <div class="settings-header">-->
        <!--            <translate>Enable/disable guide</translate>-->
        <!--          </div>-->
        <!--          <el-switch v-model="switchGuide" />-->
        <!--        </div>-->
      </div>
      <v-page-guide v-model="showGuide" />
    </div>
  </div>
</template>

<script>
import { Switch, Select, Option } from 'element-ui'
import VPageGuide from 'vue-page-guide/src/components/VPageGuide'

export default {
  components: {
    VPageGuide,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      language: this.$language.current,
      switchGuide: true,
      showGuide: false,
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (newValue) {
        localStorage.language = newValue
        this.$language.current = newValue
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>
  .container {
    max-width: 1000px;
  }

  .row {
    display: block;
    margin: 0 auto;
  }

  .header {
    margin-bottom: 15px;
    padding: 10px;
    text-align: center;
    background-color: #1d1d1d;
    color: #d6d6d6;
    font-weight: bold;
    font-size: 1.8rem;
  }

  .settings-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px auto;
    padding: 20px 25px;
    max-width: 90%;
    background-color: #808080;
    border-radius: 4px;
  }

  .settings-header {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }

  .select-lang {
    width: 15%;
  }
</style>
