/**
 * @file Определяет  класс Camera
 */
import { SVGIcon } from 'leaflet-svgicon'
import {
  DEFAULT_FILL_OPACITY,
  MODE_3D,
  DISTANCE_FOR_CAMERA_CENTERING,
} from '@nova/constants'
import { Measure } from './Measure'
import { Box3, Color, Object3D, Vector3, Sphere } from 'three'
import { isEmpty } from 'lodash'

/**
 * Класс камера который обозначает замер с одной точкой, в 2Д подставляется svg картинка, в 3Д просто одна точка (сфера)
 */
export default class Camera extends Measure {
  constructor(vm, data = null) {
    if (data === null) {
      data = {}
    }
    super(vm, data)
    this.type = 'annotation-camera'
    this._fov = data.fov || null
  }

  /**
   * @inheritDoc
   */
  createPotreeMeasure() {
    super.createPotreeMeasure()
    this.$potreeMeasure.showDistances = false
    this.$potreeMeasure.maxMarkers = 1
    if (!this.fov) {
      this._fov = this.vm.viewer.fovBak ? this.vm.viewer.fovBak : this.vm.viewer.fov
    }
  }

  /**
   * У камеры не убираем сферы в 3Д, иначе он совсем пропадет
   */
  // метод делает 3Д часть замера недоступным для редактирования или наоборот
  changeSpheresVisibility(visibility) {
    console.log('не убираем сферы у камеры')
  }

  /**
   * @inheritDoc
   */
  centerCamera() {
    if (this.vm.currentMode === MODE_3D) {
      const points = this.$potreeMeasure.points.map((p) => p.position)

      const box = new Box3()
      box.setFromPoints(points)
      const node3d = new Object3D()
      node3d.boundingBox = box
      node3d.boundingSphere = box.getBoundingSphere(new Sphere(this.points3D[0]))
      node3d.boundingSphere.radius = DISTANCE_FOR_CAMERA_CENTERING
      this.vm.viewer.zoomTo(node3d, 1, 300)
      setTimeout(this.vm.scaleNameLabels, 300)
    } else if (this.vm.currentMode) {
      console.warn('Центрирование карты по замеру не реализовано в режиме 2D')
    }
  }

  /**
   * Метод переносящий текущую камеру в точку выбранной камеры и применяющий ее сохраненный FOV
   */
  showCamera() {
    // Работает только в 3D
    if (this.vm.currentMode === MODE_3D) {
      // Бэкапим стандартный FOV
      if (!this.vm.viewer.fovBak) {
        this.vm.viewer.fovBak = this.fov
      }
      // Бэкапим все параметры текущего положения камеры, чтобы потом без проблем вернуть его
      const camera = this.$potreeMeasure.$scene.view
      if (
        !this.$potreeMeasure.$scene._previousView ||
        isEmpty(this.$potreeMeasure.$scene._previousView)
      ) {
        const prevCam = (this.$potreeMeasure.$scene._previousView = {})
        for (const key in camera) {
          if (camera[key] instanceof Vector3) {
            prevCam[key] = new Vector3()
            prevCam[key].copy(camera[key])
          } else if (typeof camera[key] === 'number') {
            prevCam[key] = camera[key]
          }
        }
      }
      camera.position.copy(this.$potreeMeasure.points[0].position)
      camera.radius = 0
      this.vm.cameraMode = true
      this.fov = this._fov
    } else if (this.vm.currentMode) {
      console.warn('Открытие камеры не реализовано в режиме 2D')
    }
  }

  /**
   * Метод, возвращающий текущую камеру в забэкапленное положение и возвращающий стандартный FOV
   */
  closeCamera() {
    if (
      this.vm.currentMode === MODE_3D &&
      Object.keys(this.$potreeMeasure.$scene._previousView).length
    ) {
      // Восстанавливаем из бэкапа параметры камеры
      const camera = this.$potreeMeasure.$scene.view
      const prevCam = this.$potreeMeasure.$scene._previousView
      for (const key in prevCam) {
        if (prevCam[key] instanceof Vector3) {
          camera[key] = new Vector3()
          camera[key].copy(prevCam[key])
        } else if (typeof prevCam[key] === 'number') {
          camera[key] = prevCam[key]
        } else return
      }
      this.$potreeMeasure.$scene._previousView = {}
      this.vm.cameraMode = false
      this.vm.viewer.setFOV(this.vm.viewer.fovBak)
    } else if (this.vm.currentMode) {
      console.warn('Закрытие камеры не реализовано в режиме 2D')
    }
  }

  /**
   * @inheritDoc
   */
  setLayerColor(color) {
    // проставляем выбранные цвета, как цвета по умолчанию
    // this.$layer.options = this.$layer.options || {opacity: 0.4}
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        // Обновляем цвет маркера
        const icon = this.$layer.getIcon()
        const options = icon.options
        delete options.html
        options.color = color
        options.circleColor = color
        options.fillColor = color
        options.circleFillColor = this.color
        this.$layer.setIcon(new SVGIcon(options))
      }
      if (this.$potreeMeasure) {
        const _color = new Color(color)
        this.$potreeMeasure.color = _color
        this.$potreeMeasure.spheres.map((sphere) => {
          sphere.material.color = _color
        })
      }
    } else {
      console.warn(
        'Нельзя изменить цвет замера, отсутстует объект $layer или $potreeMeasure',
        this
      )
    }
  }

  /**
   * @inheritDoc
   */
  setLayerOpacity(opacity) {
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        this.$layer.options.opacity = opacity
        // Вычисляем прозрачность заливки
        const fillOpacity = parseFloat(opacity) * DEFAULT_FILL_OPACITY
        this.$layer.options.fillOpacity = fillOpacity.toFixed(3)

        const icon = this.$layer.getIcon()
        const options = icon.options
        delete options.html
        options.fillOpacity = fillOpacity
        options.opacity = opacity
        options.circleFillOpacity = opacity
        options.circleOpacity = opacity
        this.$layer.setIcon(new SVGIcon(options))
        // debugger
      }
      if (this.$potreeMeasure) {
        console.error(
          'Нельзя изменить прозрачность замера, функционал не реализован в режиме 3D',
          this
        )
        // this.$potreeMeasure.color = new THREE.Color(color)
      }
    } else {
      console.warn(
        'Нельзя изменить прозрачность замера, отсутстует объект $layer или $potreeMeasure',
        this
      )
    }
  }

  /**
   * У камеры нет свойств для просчета
   * @return {undefined}
   */
  updateCalculations() {
    console.log('У камеры нет свойств по длине')
  }

  /**
   * У камеры нельзя посчитать Elevation Profile
   * @return {undefined}
   */
  calculateElevationProfile() {
    console.error('Этот замер не может быть профилирован')
  }

  /**
   * @inheritDoc
   */
  get color() {
    return super.color
  }

  /**
   * @inheritDoc
   */
  set color(value) {
    if (value !== this.color) {
      this._color = value
      const icon = this.$layer.getIcon()
      const options = icon.options
      delete options.html
      options.circleFillColor = value
      this.$layer.setIcon(new SVGIcon(options))
    }
  }

  /**
   * @inheritDoc
   */
  get isElevationProfile() {
    return false
  }

  /**
   * Установить FOV для объекта камеры, так же устанавливает FOV для текущей камеры показанной на экране
   * @param value
   */
  set fov(value) {
    if (!this.isNew && value !== null) {
      const viewer = this.vm.viewer
      viewer.setFOV(value)
      this._fov = value
    }
  }

  /**
   * Получить FOV объекта камеры
   * @return {*|null}
   */
  get fov() {
    return this._fov
  }

  /**
   * @inheritDoc
   */
  get defaultLabel() {
    return this.vm.$gettext('Camera')
  }

  /**
   * @inheritDoc
   */
  get centerPoint() {
    return this.points[0]
  }

  /**
   * @inheritDoc
   */
  get exportData() {
    const data = super.exportData
    data.fov = this.fov
    return data
  }
}
