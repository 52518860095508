/**
 * @file Определяет вспомогательный класс Utils
 */

import {
  Vector2,
  Vector3,
  Mesh,
  MeshNormalMaterial,
  BoxBufferGeometry,
  Raycaster,
} from 'three'
import * as turf from '@turf/turf'
import { LatLng } from 'leaflet/dist/leaflet-src.esm'
import { RADIUS } from '@nova/constants'
/**
 * Всопомгательный статичный класс Utils для работы с точками в 2D и 3D
 */
export default class Utils {
  /*
    Функция модифицирует точки, устанавливая параметр inPointCloud в котором хранится флаг,
    находится ли точка внутри pointcloud
  */
  static pointsInPointCloud(pointcloud, points) {
    return points.map((point) => {
      point.inPointCloud = pointcloud.pcoGeometry.root.boundingBox.containsPoint(point)
      return point
    })
  }

  static pointInPolygon(polyArray, point) {
    const turfPoint = turf.point(point)
    const poly = turf.multiPolygon(polyArray)
    return turf.booleanPointInPolygon(turfPoint, poly)
  }

  // функция для получения всех точек в ноде
  static async getPointsInNode(node) {
    const points = []

    await this.waitForLoading(node)
    node.geometry.attributes.position.array.map((point) => points.push(point))

    return points
  }

  // получаем ноды, которые пересекаются с объектом Box3
  static getIntersectNodes(rootNode, boundingBox) {
    const nodes = Object.values(rootNode.children).filter((node) => {
      return node.boundingBox.intersectsBox(boundingBox)
    })
    nodes
      .filter((n) => n)
      .map((n) => {
        this.getContainsNodes(n, boundingBox).map((n) => {
          if (n.boundingBox.intersectsBox(boundingBox)) {
            nodes.push(n)
          }
          // this.boxFromNode(n)
        })
      })
    // nodes.map(node => this.boxFromNode(node))
    return nodes
  }

  // Функция притягивания точек сверху вниз
  static fixPointsZ(pointcloud, points, vm) {
    vm.pointsLoading = true

    const requestedPoints = this.pointsInPointCloud(pointcloud, points)
    const promises = requestedPoints
      .filter((p) => p.inPointCloud)
      .map(async (point) => {
        const highestNode = this.getHighestNode(this.getDeepestNodes(pointcloud, point))
        await this.waitForLoading(highestNode)

        // Получаем 3Д вектор для искомой точки
        const point3DVector = new Vector3(
          point.x,
          point.y,
          highestNode.ept.root.boundingBox.min.z
        )
        // Вычитаем смещение ноды
        point3DVector.sub(highestNode.boundingBox.min)

        // Перебираем все точки в ноде и вычисляем дистанцию от искомой точки до всех точек в ноде
        const vectors2 = highestNode.geometry.attributes.position.array
          .reduce(
            (chunks, el, i) =>
              (i % 3 ? chunks[chunks.length - 1].push(el) : chunks.push([el])) && chunks,
            []
          )
          .map((p) => {
            const point = new Vector2(...p)
            point.distance = point.distanceTo(point3DVector)
            point.raw = p
            return point
          })

        // Получаем самую близкую точку
        const closestNodePoint = vectors2.sort(Utils.dynamicSort('distance'))[0]

        // Формируем самую близкую точку в 3Д
        const closestNode3DPoint = new Vector3(...closestNodePoint.raw)

        // Прибавляем смещение ноды
        closestNode3DPoint.add(highestNode.boundingBox.min)

        // Применяем новую высоту к искомой точке
        point.rawPoint.alt = closestNode3DPoint.z
        console.log('Посчитана высота у точки', point.rawPoint, point.rawPoint.alt)
      })

    // Применяем настройки для точек, которые за пределами облака точек
    // Ждём пока посчитаются все точки
    return Promise.all(promises).then(() => {
      // pointcloud.loading = false
      // Получаем первую корректную точку, это нужно чтобы точки идущие до этой точки получили верные координаты
      let correctPoint = requestedPoints.find((p) => p.inPointCloud && p.rawPoint.alt)

      if (correctPoint) {
        requestedPoints.map((point) => {
          if (point.rawPoint.alt === undefined) {
            point.rawPoint.alt = correctPoint.rawPoint.alt
          } else if (point.inPointCloud) {
            correctPoint = point
          }
        })
      } else {
        console.warn('Не удалось рассчитать корректные точки')
      }
      vm.pointsLoading = false
    })
  }

  // Получить самые глубокие ноды(где наиболее детализированные точки)
  static getDeepestNodes(pointcloud, point) {
    const nodes = this.getContainsNodes(pointcloud.pcoGeometry.root, point)
    const maxLevel = Math.max(...nodes.map((n) => n.level))
    const closestNodes = nodes.filter((n) => n.level === maxLevel)
    return closestNodes
  }

  // Получить самую ноду, которая стоит выше всех
  static getHighestNode(nodes) {
    let highestNode = null
    let highestZ = null
    nodes.map((node) => {
      if (highestNode === null) {
        highestNode = node
      }
      if (highestZ === null) {
        highestZ = node.boundingBox.max.z
      }
      if (highestZ < node.boundingBox.max.z) {
        highestNode = node
        highestZ = node.boundingBox.max.z
      }
    })
    return highestNode
  }

  // Ожидаем загрузку ноды
  static waitForLoading(node) {
    return new Promise((resolve, reject) => {
      // Если уже загружено, завершаем Promise
      if (node.loaded) {
        console.warn('Нода уже загружена', node)
        resolve(node)
      } else {
        if (!node.loading) {
          node.load()
          console.warn('Загружаем ноду', node)
        }
        const intervalID = setInterval(() => {
          if (node.loaded) {
            console.warn('Нода загрузилась', node)
            clearInterval(intervalID)
            resolve(node)
          } else {
            if (!node.loading) {
              node.load()
              console.warn(
                'Нода должна была попасть в очередь загрузки, но этого не произошло. Пинаем загрузку опять',
                node
              )
            }
            console.warn('Нода загружается', node)
          }
        }, 500)
      }
    })
  }

  // Получить ноды, которые пересекаются по 2D координатам с точкой
  static getContainsNodes(node, point) {
    const result = Object.values(node.children).filter((node) => {
      return node.boundingBox.containsPoint(point)
    })
    result
      .filter((n) => n)
      .map((n) => {
        this.getContainsNodes(n, point).map((n) => {
          result.push(n)
        })
      })
    return result
  }

  /*
  Вспомогательная функция сортировки по ключу
   */
  static dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    return function(a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }

  /*
  Вспомогательная функция сортировки по нескольким ключам
  */
  static dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments
    return function(obj1, obj2) {
      var i = 0
      var result = 0
      var numberOfProperties = props.length
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while (result === 0 && i < numberOfProperties) {
        result = this.dynamicSort(props[i])(obj1, obj2)
        i++
      }
      return result
    }
  }

  // Функция отладки, создаёт box с координатами ноды
  static boxFromNode(node) {
    const size = node.boundingBox.size()
    const geometry = new BoxBufferGeometry(size.x, size.y, size.z)
    const material = new MeshNormalMaterial()
    const mesh = new Mesh(geometry, material)
    const center = node.boundingBox.center()
    mesh.position.x = center.x
    mesh.position.y = center.y
    mesh.position.z = center.z
    window.viewer.scene.scene.add(mesh)
    return mesh
  }

  /**
   * Изменяет позицию точки на offsetX и offsetY
   * @param point {LatLng} - точка, которую необходимо подвинуть
   * @param offsetX {Number} - сдвиг по горизонтали
   * @param offsetY {Number} - сдвиг по вертекали
   * @return point - итоговые координаты точки
   */
  static offsetLatLngByMeters(point, offsetX, offsetY) {
    const lat = point.lat
    const lon = point.lng

    const R = 6371000

    // Coordinate offsets in radians
    const dLat = offsetY / R
    const dLon = offsetX / (R * Math.cos((Math.PI * lat) / 180))

    // OffsetPosition, decimal degrees
    const latO = lat + (dLat * 180) / Math.PI
    const lonO = lon + (dLon * 180) / Math.PI
    const newPoint = new LatLng(latO, lonO, point.alt)
    return newPoint
  }

  /**
   * Функция находит точку на поинтклауде под мышкой
   * @param mouse - координаты мышки на экране
   * @param camera - акивная камера
   * @param viewer - вьюер
   * @param pointclouds - поинтклауды
   * @param params - доп параметры
   * @return {null|{distance: number, location: null, pointcloud: null, point: null}} - точка в utm
   */
  static getMousePointCloudIntersection(mouse, camera, viewer, pointclouds, params = {}) {
    const renderer = viewer.renderer

    const nmouse = {
      x: (mouse.x / renderer.domElement.clientWidth) * 2 - 1,
      y: -(mouse.y / renderer.domElement.clientHeight) * 2 + 1,
    }

    const pickParams = {}

    if (params.pickClipped) {
      pickParams.pickClipped = params.pickClipped
    }

    pickParams.x = mouse.x
    pickParams.y = renderer.domElement.clientHeight - mouse.y

    const raycaster = new Raycaster()
    raycaster.setFromCamera(nmouse, camera)
    const ray = raycaster.ray

    let selectedPointcloud = null
    let closestDistance = Infinity
    let closestIntersection = null
    let closestPoint = null

    for (const pointcloud of pointclouds) {
      const point = pointcloud.pick(viewer, camera, ray, pickParams)

      if (!point) {
        continue
      }

      const distance = camera.position.distanceTo(point.position)

      if (distance < closestDistance) {
        closestDistance = distance
        selectedPointcloud = pointcloud
        closestIntersection = point.position
        closestPoint = point
      }
    }

    if (selectedPointcloud) {
      return {
        location: closestIntersection,
        distance: closestDistance,
        pointcloud: selectedPointcloud,
        point: closestPoint,
      }
    } else {
      return null
    }
  }

  /**
   * Считает расстояние между двумя latlng координатами
   * @param latlng1
   * @param latlng2
   * @return {number}
   */
  static distanceTo(latlng1, latlng2) {
    const rad = Math.PI / 180
    const dlat = latlng2.lat - latlng1.lat
    const dlon = latlng2.lng - latlng1.lng
    const x = Math.cos((Math.PI * (latlng2.lat + latlng1.lat)) / 360)
    const c = rad * Math.sqrt(dlat * dlat + dlon * dlon * x * x)
    return RADIUS * c
  }

  /**
   * Форматтер для лейблов длины, обрубающий числа после запятой для величин более 10 метров
   * @param dist - длина
   * @return {string} - отформатированная длина
   */
  static labelFormatter() {
    return (dist) => {
      const meter = this.$gettext('m')
      return dist > 10 ? dist.toFixed(0) + ' ' + meter : dist.toFixed(1) + ' ' + meter
    }
  }
}
