/**
 * @file Определяет  класс Rectangle3D
 */
import { Measure } from '@letsnova/potree/src/utils/Measure'
import * as THREE from 'three'

/**
 * Класс Rectangle3D для отоборажения прямоугольника в 3D режиме
 */
export default class Rectangle3D extends Measure {
  /**
   * @param centerPoint {Vector3} - центральная точка вокруг которой строится прямоугольник
   * @param lineALength {Number} - длина линии (верхний левый угол, нижний левый)
   * @param lineBLength {Number} - длина линии (Верхний левый угол, верхний правый)
   */
  constructor(centerPoint, lineALength, lineBLength) {
    super()
    this._points = []

    this.lineALength = lineALength
    this.lineBLength = lineBLength
    this.centerPoint = centerPoint

    this._points.push(
      new THREE.Vector3(
        centerPoint.x - lineBLength / 2,
        centerPoint.y + lineALength / 2,
        centerPoint.z
      )
    )
    this._points.push(
      new THREE.Vector3(
        centerPoint.x + lineBLength / 2,
        centerPoint.y + lineALength / 2,
        centerPoint.z
      )
    )
    this._points.push(
      new THREE.Vector3(
        centerPoint.x + lineBLength / 2,
        centerPoint.y - lineALength / 2,
        centerPoint.z
      )
    )
    this._points.push(
      new THREE.Vector3(
        centerPoint.x - lineBLength / 2,
        centerPoint.y - lineALength / 2,
        centerPoint.z
      )
    )

    this._points.forEach((point) => this.addMarker(point))
  }

  /**
   * @inheritDoc
   */
  createMesh() {
    if (this.mesh) {
      this.remove(this.mesh)
    }
    this.geometry = new THREE.PlaneGeometry(this.lineBLength, this.lineALength)
    this.material = this.createMeshMaterial(0.4)

    this.mesh = new THREE.Mesh(this.geometry, this.material)
    this.mesh.position.set(this.centerPoint.x, this.centerPoint.y, this.centerPoint.z)

    this.add(this.mesh)
    if (this.points.length !== 5) {
      this.addMarker(this.centerPoint, true)
    }
  }

  /**
   * Возвращает материал для меша замера
   * @param opacity - прозрачность материала
   * @return {MeshBasicMaterial}
   */
  createMeshMaterial(opacity = 1) {
    return new THREE.MeshBasicMaterial({
      color: this.color,
      depthWrite: true,
      depthTest: true,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: opacity,
    })
  }

  /**
   * Создание простого квадрата с стандартными размерами в 3D
   * @param centerPoint - центр квадрата
   */
  createSimple3D(centerPoint) {
    // Размеры сторон
    this.lineALength = 10
    this.lineBLength = 10
    // Удаляем маркер, так как он является центром квадрата и от него нужны только координаты центра
    this.removeMarker(0)
    this._points = []

    this._points.push(
      new THREE.Vector3(
        centerPoint.x - this.lineBLength / 2,
        centerPoint.y + this.lineALength / 2,
        centerPoint.z
      )
    )
    this._points.push(
      new THREE.Vector3(
        centerPoint.x + this.lineBLength / 2,
        centerPoint.y + this.lineALength / 2,
        centerPoint.z
      )
    )
    this._points.push(
      new THREE.Vector3(
        centerPoint.x + this.lineBLength / 2,
        centerPoint.y - this.lineALength / 2,
        centerPoint.z
      )
    )
    this._points.push(
      new THREE.Vector3(
        centerPoint.x - this.lineBLength / 2,
        centerPoint.y - this.lineALength / 2,
        centerPoint.z
      )
    )

    this._points.forEach((point) => this.addMarker(point))
    // Установка _centerPoint
    this.$measure.setCenterPoint(centerPoint)
    this.centerPoint = centerPoint
    this.createMesh()
  }
}
