export default {
  state: {
    viewer: null,
  },
  mutations: {
    setViewer(state, { project, task, document }) {
      state.viewer = new window.Potree.Viewer(document, { project, task })
    },
  },
}
