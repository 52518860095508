import * as L from 'leaflet'

export default {
  state: {
    map: null,
  },
  mutations: {
    setMap(state, { container, parameters }) {
      state.map = L.map(container, parameters)
      // Показываем весь мир
      state.map.fitWorld()
    },
    addTileLayer(state, { url, parameters }) {
      L.tileLayer(url, parameters).addTo(state.map)
    },
    setPathOptions(state, value) {
      state.map.pm.setPathOptions(value)
    },
    fitBounds(state, value) {
      state.map.fitBounds(value)
    },
    addLayer(state, value) {
      state.map.addLayer(value)
    },
    setLang(state, value) {
      state.map.pm.setLang(value)
      state.map.attributionControl.setPrefix('')
    },
    removeLayer(state, value) {
      state.map.removeLayer(value)
    },
  },
}
