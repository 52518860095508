<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div
    class=""
  >
    <div class="inspector-toolbar-header py-3 mb-2 row align-items-center justify-content-center">
      <i class="icon nova-img mr-3" />{{ photos ? photos[currentImageIndex].name : text }}
    </div>
    <div
      v-if="photos"
      class="image-wrapper m-auto"
    >
      <canvas
        ref="currentImagePoint"
        class="image"
        style="position: absolute; height: 100%"
      />
      <AsyncImagePhotoInspector
        ref="currentImage"
        :value="currentPhotoSrc"
        class="image"
      />
      <div class="middle">
        <el-button
          class="zoom-btn"
          icon="icon nova-zoom"
          circle
          @click="togglePhotoModal(true)"
        />
      </div>
    </div>
    <div class="coordinates mb-5 pl-1">
      <div class="coordinates-title my-2">
        <translate>Сoordinates</translate>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              x: {{ value ? value.x.toFixed(3) : '' }}
            </div>
            <div class="col-12">
              y: {{ value ? value.y.toFixed(3) : '' }}
            </div>
            <div class="col-12">
              z: {{ value ? value.z.toFixed(3) : '' }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              u: {{ photos ? photos[currentImageIndex].uv[0].toFixed(3) : '' }}
            </div>
            <div class="col-12">
              v: {{ photos ? photos[currentImageIndex].uv[1].toFixed(3) : '' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="photos">
      <div class="photos-title mb-2 pl-1">
        <translate>Photos</translate>
      </div>
      <div
        v-if="photos"
        class="photos-content d-flex flex-wrap"
      >
        <div
          v-for="(img, index) of photos"
          :key="index"
          class="col-6 p-1"
          @click="selectImg(index)"
        >
          <AsyncImagePhotoInspector
            class="small-photo"
            :class="[currentImageIndex === index ? 'selected-img' : '']"
            :value="getAssetSrc(img)"
          />
        </div>
      </div>
    </div>
    <modal
      :show.sync="modal"
      modal-classes="m-size"
      header-classes="grey p-1 border-0"
      body-classes="grey p-0"
      footer-classes="grey d-flex justify-content-between px-1 border-0"
    >
      <div
        v-show="!imgManager"
      >
        <canvas
          ref="currentImagePointHD"
          style="position: absolute;"
        />
        <AsyncImagePhotoInspector
          ref="currentImageHD"
          :value="currentPhotoSrc"
          class="rounded-0"
        />
      </div>
      <div
        v-if="imgManager"
        class="d-flex justify-content-center"
      >
        <div
          class="press-click-mouse position-absolute rounded mt-4 p-3"
        >
          <translate>Please click on the photo</translate>
        </div>
        <el-image
          :src="bigPictureSrc"
          class="rounded-0"
          alt="Avatar"
          :preview-src-list="[bigPictureSrc]"
        />
      </div>
      <template
        v-slot:footer
      >
        <div class="col-4">
          <span class="text-center">{{ currentPhoto ? currentPhoto.name : '' }}</span>
        </div>
        <div class="col-4">
          <el-checkbox
            v-model="imgManager"
          >
            <translate>Enable Image Manager</translate>
          </el-checkbox>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { Image, Button, Badge, Checkbox, CheckboxButton } from 'element-ui'
import Modal from 'src/components/UIComponents/Modal'
import AsyncImagePhotoInspector from 'src/components/Nova/Layout/ProjectsLayout/AsyncImagePhotoInspector.vue'

export default {
  name: 'PhotoInspector',
  components: {
    AsyncImagePhotoInspector,
    [Image.name]: Image,
    [Button.name]: Button,
    [Badge.name]: Badge,
    [Checkbox.name]: Checkbox,
    [CheckboxButton.name]: CheckboxButton,
    Modal,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: Object,
  },
  data() {
    return {
      // текс
      text: this.$gettext('Please click on the map'),
      // активация менеджера фото
      imgManager: false,
      // Индекс текущего фото
      currentImageIndex: 0,
      // Массив фото
      photos: null,
      // Текущий проект
      project: this.$route.params.projectId,
      // Текущий таск
      task: this.$route.params.taskId,
      // Грузятся ли сейчас новые фото
      photosLoading: false,
      // Показывать ли модалку
      modal: false,
      bigPictureSrc: '',
    }
  },
  computed: {
    /**
     * Путь к ассетам
     * @return {String} - путь к ассетам текущего таска
     */
    assetsPath() {
      return this.$root.apiPath(`/api/projects/${this.project}/tasks/${this.task}/assets`)
    },
    /**
     * Путь к текущему выбранному фото
     * @return {String} - путь к текущему выбранному фото
     */
    currentPhotoSrc() {
      if (this.photos) {
        return this.assetsPath + '/point_finder/photo/' + this.photos[this.currentImageIndex].name
      } else {
        return null
      }
    },
    /**
     * Возвращает Текущее выбранное фото объектом
     * Костыль чтобы в вотчере отслеживать выбранное фото
     * @return {Object} - текущее выбранное фото
     */
    currentPhoto() {
      return this.photos ? this.photos[this.currentImageIndex] : null
    }
  },
  watch: {
    // Оба вотча направлены на то чтобы обновлять нарисованную точку при смене фото или точки
    value: function(newValue) {
      if (newValue === null) {
        this.photos = null
      } else {
        this.loadImages(newValue)
          .then(this.drawPointOnCurrentImage)
      }
    },
    currentPhoto: function() {
      if (!this.photosLoading && this.photos) {
        this.drawPointOnCurrentImage()
      }
    },
    modal: function(value) {
      if (!value) {
        this.imgManager = false
      }
    },
    imgManager: function(value) {
      if (value) {
        this.getImage(this.currentPhotoSrc)
          .then((res) => {
            this.bigPictureSrc = res
          })
      }
    }
  },
  methods: {
    /**
     * Выбор необходимого фото
     * @param id - идентификатор конкретногвоо фото
     */
    selectImg(id) {
      this.currentImageIndex = id
    },
    /**
     * Загрузка фото
     * @param point {Object} - точка которую надо найти
     * @return {Promise<void>}
     */
    async loadImages(point) {
      try {
        this.photos = null
        this.photosLoading = true
        const response = await this.axios.post(`/projects/${this.project}/tasks/${this.task}/get-photo-by-point/`, {
          point: [point.x, point.y, point.z]
        })
        this.currentImageIndex = 0
        this.photos = response.data.result
      } catch (e) {
        if (e.response && e.response.status === 500) {
          console.log(e.response.data)
        } else {
          console.error('Не могу получить фото с бэка', e)
        }
      }
    },
    /**
     * Получение пути к ассетам
     * @param img {Object} - объект картинки
     * @return {string} - строка с путем к ассету
     */
    getAssetSrc(img) {
      const type = img.thumbnail_name ? 'thumbnails' : 'photo'
      const asset = type === 'thumbnails' ? img.thumbnail_name : img.name
      return this.assetsPath + '/point_finder/' + type + '/' + asset
    },
    /**
     * Рисование точки на картинке
     * @param canvas {Element} - канвас на котором рисуем
     * @param image {Element}- картинка
     */
    drawPointOnCurrentImage(canvas = this.$refs.currentImagePoint, image = this.$refs.currentImage.$el) {
      const context = canvas.getContext('2d')
      const uv = this.photos[this.currentImageIndex].uv
      image.onload = function() {
        // Разрешение фото
        const trueHeight = this.naturalHeight
        const trueWidth = this.naturalWidth
        // Ширина и высота элемента на экране
        const height = this.height
        const width = this.width
        // В зависимости от разрешения и реального отображения фото, высчитываем координаты точки на фото
        const x = uv[0] / trueWidth * width
        const y = uv[1] / trueHeight * height
        // Подгоняем канвас под размеры фото
        context.canvas.width = width
        context.canvas.height = height
        // Очищаем канвас
        context.clearRect(0, 0, width, height)
        // Рисуем кружок
        context.beginPath()
        context.arc(x, y, 5, 0, 2 * Math.PI, false)
        context.lineWidth = 1
        context.strokeStyle = '#c30b36'
        context.stroke()
      }
      if (canvas === this.$refs.currentImagePointHD && image === this.$refs.currentImageHD.$el) {
        // Разрешение фото
        const trueHeight = image.naturalHeight
        const trueWidth = image.naturalWidth
        // Ширина и высота элемента на экране
        const height = image.height
        const width = image.width
        // В зависимости от разрешения и реального отображения фото, высчитываем координаты точки на фото
        const x = uv[0] / trueWidth * width
        const y = uv[1] / trueHeight * height
        // Подгоняем канвас под размеры фото
        context.canvas.width = width
        context.canvas.height = height
        // Очищаем канвас
        context.clearRect(0, 0, width, height)
        // Рисуем кружок
        context.beginPath()
        context.arc(x, y, 10, 0, 2 * Math.PI, false)
        context.lineWidth = 4
        context.strokeStyle = '#c30b36'
        context.stroke()
      }
      this.photosLoading = false
    },
    /**
     * Переключение отображения модалки с крупным фото
     * @param value {Boolean} - отображать или нет
     */
    togglePhotoModal(value) {
      this.modal = value
      if (value) {
        this.$nextTick(() => {
          this.drawPointOnCurrentImage(this.$refs.currentImagePointHD, this.$refs.currentImageHD.$el)
        })
      }
    },
    /**
     * Асинхронная загрузка картинки с прокидыванием хедера авторизации
     * https://medium.com/javascript-in-plain-english/loading-images-with-authorization-8aab33663ba6
     * @param url - ссылка на картинку
     * @return {Promise<unknown>|null}
     */
    getImage(url) {
      if (!url) {
        return null
      }
      return Promise.resolve(this.axios.get(url, { ...this.$root.headers, responseType: 'blob' })
        .then((res) => {
          const reader = new FileReader()
          return new Promise((resolve) => {
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(res.data)
          })
        }).then((result) => {
          return result
        }))
    },
  },
}
</script>

<style scoped>
  .press-click-mouse {
    z-index: 100;
    background-color: #464646;
    color: #ffffff;
    bottom: 10px;
  }
  .image-wrapper {
    max-width: 280px;
    position: relative;
  }
  .zoom-btn {
    color: #ffffff;
  }
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  .image-wrapper:hover .image {
    opacity: 0.3;
  }

  .image-wrapper:hover .middle {
    opacity: 1;
  }
  .photos-content {
    max-height: 500px;
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 5px;
    background-color: #464646;
  }
  ::-webkit-scrollbar-thumb {
    height: 20px;
    border-radius: 5px;
    margin-top: 5px;
    background-color: #6e6e6e;
  }
  ::-webkit-scrollbar-corner {
    background-color: #999;
  }
  ::-webkit-resizer {
    background-color: #666;
  }
  .small-photo:hover {
    cursor: pointer;
    outline: 3px solid;
  }
  .small-photo:active > img {
    cursor: pointer;
    outline: 2px solid;
  }
  .selected-img {
    outline: 2px solid;
  }
</style>
<style>
  .grey {
    background-color: #464646 !important;
  }
  .m-size {
    max-width: 1000px !important;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 0 10px 0 0 !important;
  }

  .el-tabs--border-card > .el-tabs__header {
    border-bottom: none !important;
  }
  .el-tabs__content {
    background-color: #2b2b2b;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #2b2b2b !important;
    color: #ffffff !important;
  }
  .el-tabs--border-card > .el-tabs__header {
    background-color: #1d1d1d !important;
  }
  .el-tabs--border-card {
    border: none !important;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border: none !important;
    border-right: none !important;
    border-left: none !important;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    border: none !important;
  }
  .el-tabs__nav-scroll {
    display: flex !important;
    justify-content: center !important;
  }
  .el-tabs__nav.is-top {
    width: 100% !important;
  }
  .el-tabs__item.is-top {
    width: 50% !important;
    text-align: center !important;
  }
  .el-tabs--border-card {
    box-shadow: none !important;
  }
  .el-image-viewer__btn.el-image-viewer__close {
    color: #ffffff;
  }
</style>
