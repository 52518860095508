export const DEFAULT_LINE_WEIGHT_LEVEL = 3
export const MODE_2D = 2
export const MODE_3D = 3
export const DEFAULT_RECTANGLE_LINE_LENGTH = 0
export const SELECTED_COLOR = '#f58700'
export const DEFAULT_FILL_OPACITY = 0.4
export const ZOOM_LINE_WEIGHT_LEVEL = {
  12: Math.ceil(DEFAULT_LINE_WEIGHT_LEVEL * 0.3),
  13: Math.ceil(DEFAULT_LINE_WEIGHT_LEVEL * 0.3),
  14: Math.ceil(DEFAULT_LINE_WEIGHT_LEVEL * 0.3),
  15: Math.ceil(DEFAULT_LINE_WEIGHT_LEVEL * 0.3),
  16: Math.ceil(DEFAULT_LINE_WEIGHT_LEVEL * 0.6),
  17: Math.ceil(DEFAULT_LINE_WEIGHT_LEVEL * 0.6),
  18: Math.ceil(DEFAULT_LINE_WEIGHT_LEVEL * 0.6),
  19: Math.ceil(DEFAULT_LINE_WEIGHT_LEVEL * 0.6),
  20: DEFAULT_LINE_WEIGHT_LEVEL,
  21: DEFAULT_LINE_WEIGHT_LEVEL,
}
export const MEASURE_ICON = {
  'annotation-marker': 'fas fa-map-marker-alt',
  'annotation-line': 'fas fa-minus',
  'annotation-polygon': 'fas fa-draw-polygon',
  'annotation-volume': 'fas fa-ring',
  'annotation-inspection': 'fas fa-crosshairs',
  'annotation-rectangle': 'fas fa-square',
  'annotation-circle': 'fas fa-circle',
  'annotation-camera': 'fas fa-video',
}

export const DASH_COEFFICIENT = 3

export const DEFAULT_CIRCLE_RADIUS = 10
export const RADIUS = 6371000
// Расстояние до камеры при центрировании по двойному клику
export const DISTANCE_FOR_CAMERA_CENTERING = 100.0
// Предзагруженные значения плотности
export const STANDART_DENSITY_ITEMS = function() {
  return [
    {
      text: this.$gettext('Уголь битуминозный (курной) кусковой'),
      density: 830,
      disabled: true,
    },
    {
      text: this.$gettext('Уголь древесный'),
      density: 200,
      disabled: true,
    },
    {
      text: this.$gettext('Угольная пыль'),
      density: 750,
      disabled: true,
    },
    {
      text: this.$gettext('Щебень мелкий'),
      density: 1600,
      disabled: true,
    },
    {
      text: this.$gettext('Пшеница'),
      density: 770,
      disabled: true,
    },
    {
      text: this.$gettext('Песок сухой рыхлый'),
      density: 1440,
      disabled: true,
    },
    {
      text: this.$gettext('Песок сухой уплотненный'),
      density: 1680,
      disabled: true,
    },
    {
      text: this.$gettext('Известняк порошок'),
      density: 1400,
      disabled: true,
    },
    {
      text: this.$gettext('Гравий сухой'),
      density: 1600,
      disabled: true,
    },
    {
      text: this.$gettext('Свой выбор'),
      density: 0,
      disabled: false,
    },
  ]
}
// delta для зума на карте в режиме 3D
export const ZOOM_DELTA = 3
