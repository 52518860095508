<template>
  <div>
    <notifications />
    <div class="users mt-5">
      <div class="container">
        <!--          users table open-->
        <div class="tables">
          <el-table
            v-if="showTable"
            :data="users"
            row-class-name="custom-el-table-row"
            style="width: 100%"
          >
            <el-table-column :label="'User' | translate" prop="username" />
            <el-table-column :label="'Access rights' | translate" prop="role" />
            <el-table-column
              :label="'Block status' | translate"
              align="center"
              :formatter="isActiveRowFormatter"
            >
              <template v-slot="scope">
                <span :class="{ 'text-danger': scope.row.blocked }">
                  <i
                    class="icon icon-lg"
                    :class="[scope.row.blocked ? 'nova-c-remove' : 'nova-c-check']"
                  />
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="'Actions' | translate" prop="actions" align="right">
              <template slot-scope="scope">
                <div class="row justify-content-end">
                  <div class="col-5 col-lg-2 p-0">
                    <router-link
                      :to="
                        checkMyProfile(scope.row.id)
                          ? { name: 'MyProfile' }
                          : { name: 'User', params: { userId: scope.row.id } }
                      "
                    >
                      <el-button
                        size="mini"
                        type="info"
                        icon="icon nova-btn-play"
                        circle
                      />
                    </router-link>
                  </div>
                  <div class="col-5 col-lg-2 p-0 d-flex align-items-center">
                    <el-dropdown
                      v-if="isAdmin"
                      ref="dropdown"
                      class="m-0 d-flex align-items-center"
                      trigger="click"
                      :hide-on-click="false"
                      @command="(c) => handleTableCommands(c, scope)"
                    >
                      <el-button
                        size="mini"
                        type="info"
                        icon="icon nova-dots icon-rotate-90"
                        circle
                      />
                      <el-dropdown-menu v-if="scope.row.id !== myProfile" slot="dropdown">
                        <el-dropdown-item v-if="scope.row.blocked" command="unblock">
                          <translate key="unblock">
                            To unblock
                          </translate>
                        </el-dropdown-item>

                        <el-dropdown-item v-else command="block">
                          <translate key="block">
                            To block
                          </translate>
                        </el-dropdown-item>

                        <el-dropdown-item command="password">
                          <translate>Change password</translate>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                      <el-dropdown-menu v-else slot="dropdown">
                        <translate>My profile</translate>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--          users table close-->
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Table,
  TableColumn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Notification,
} from 'element-ui'

export default {
  name: 'UserPageList',
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  props: {
    users: {
      type: Array,
      default: function() {
        return []
      },
    },
    isAdmin: {
      type: Boolean,
      default: function() {
        return false
      },
    },
    companyId: {
      type: Number,
      default: function() {
        return null
      },
    },
  },
  data() {
    return {
      myProfile: null,
      showTable: true,
    }
  },
  async mounted() {
    // Проверка на текущего пользователя
    this.myProfile = await this.$auth.user().id
  },
  methods: {
    handleEdit(index, row) {
      console.log(index, row)
    },
    handleDelete(index, row) {
      console.log(index, row)
    },
    /**
     * Проверка профиля текущего пользователя
     * @param id id пользователя
     */
    checkMyProfile(id) {
      // Проверка на текущего пользователя
      return id === this.myProfile
    },
    /**
     * Функция форматтер для колонки состояния активированности пользователя
     * @param row - строка
     * @param column - колонка
     * @param cellValue - значение ячейки
     * @param index - номер
     * @returns {*|string} - Возвращает отформатированную строку
     */
    isActiveRowFormatter(row, column, cellValue, index) {
      if (cellValue) {
        return this.$gettext('Blocked')
      } else {
        return this.$gettext('Active')
      }
    },
    /**
     * Обработка кликов в дропдауне в таблице
     * @param action - действие
     * @param user - пользователь
     */
    async handleTableCommands(action, user) {
      let result
      switch (action) {
        case 'block':
          result = await this.$store.dispatch('users/block', {
            userId: user.row.companyId,
            companyId: this.companyId,
            username: user.row.username,
          })
          if (result) {
            this.showNotification(true, this.$gettext('User blocked'))
          } else {
            this.showNotification(false, this.$gettext('User not blocked'))
          }
          this.$set(user.row, 'blocked', result)
          this.terribleRerenderTable()
          break
        case 'unblock':
          result = await this.$store.dispatch('users/block', {
            userId: user.row.companyId,
            companyId: this.companyId,
          })
          if (result) {
            this.showNotification(true, this.$gettext('User unblocked'))
          } else {
            this.showNotification(false, this.$gettext('User not unblocked'))
          }
          this.$set(user.row, 'blocked', result)
          this.terribleRerenderTable()
          break
        case 'password':
          result = await this.$store.dispatch('users/changePassword', {
            companyId: user.row.companyId,
            email: user.row.email,
          })
          if (result) {
            this.showNotification(true, this.$gettext('Password recovery requested'))
          } else {
            this.showNotification(
              false,
              this.$gettext('Password recovery request ends with errors')
            )
          }
          this.terribleRerenderTable()
          break
      }
    },
    /**
     * Высвечивание оповещения
     * @param message Сообщение
     */
    showNotification(success, message) {
      Notification({
        title: success ? this.$gettext('Saved') : this.$gettext('Not saved'),
        message: message,
        type: success ? 'success' : 'error',
        position: 'top-right',
        showClose: false,
      })
    },
    /**
     * Ререндер таблицы посредством перекликивания v-if на ней
     */
    // TODO: без этого не обновляется таблица, но вообще надо избавиться от этого убожества
    terribleRerenderTable() {
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
    },
  },
}
</script>

<style scoped>
/* users list table*/
.el-table {
  color: #ffffff;
}
.el-dropdown {
  margin: 10px;
}
.tables {
  width: 100%;
}
.custom-el-table-row {
  color: #ffffff;
}
.custom-el-table-row:hover > tr {
  background-color: #737373 !important;
}
.el-button--info:hover,
.el-button--info:focus {
  background: transparent !important;
  color: #51cbce !important;
}
.el-button:hover,
.el-button:focus {
  /*color: #51cbce !important;*/
  background: transparent !important;
}
.for-guide {
  background-color: rgb(244, 243, 239);
}
</style>
