<template>
  <div
    class="input-group"
  >
    <input
      v-model="text"
      type="text"
      class="form-control nova-search search-input"
      :placeholder="'Search' | translate"
    >
    <span
      class="input-group-text nova-search search-icon"
    >
      <i class="icon nova-zoom" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  data() {
    return {
      text: '',
    }
  },
}
</script>

<style scoped>
  /* custom search input */
  .nova-search {
    background-color: #747474;
    border: none;
  }
  .search-input {
    border-radius: .25em 0 0 .25em;
  }
  .search-input:focus {
    border: none;
    color: #ffffff;
  }
  .search-icon {
    border-radius: 0 .25em .25em 0;
    color: #ffffff;
  }
  ::placeholder {
    color: #ffffff;
  }
</style>
