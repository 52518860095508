import * as Potree from '@letsnova/potree/build/potree/potree'

// выдернули из Potree.loadPointCloud, чтобы пробросить авторизацию
export function loadPointcloud(path) {
  return this.axios.get(path).then((response) => {
    path = path.substr(0, path.lastIndexOf('ept.json'))
    const geometry = new Potree.PointCloudEptGeometry(path, response.data)
    geometry.root = new Potree.PointCloudEptGeometryNode(geometry)
    // замыкание для loadHierarchy, чтобы пробросить axios с заголовками авторизации
    const axios = this.axios
    // переопределяем функцию, чтобы там была наша авторизация
    geometry.root.loadHierarchy = async function() {
      const nodes = {}
      nodes[this.filename()] = this
      this.hasChildren = false

      const eptHierarchyFile = `${this.ept.url}ept-hierarchy/${this.filename()}.json`

      const response = await axios(eptHierarchyFile)
      const hier = await response.data
      // Since we want to traverse top-down, and 10 comes
      // lexicographically before 9 (for example), do a deep sort.
      var keys = Object.keys(hier).sort((a, b) => {
        const [da, xa, ya, za] = a.split('-').map((n) => parseInt(n, 10))
        const [db, xb, yb, zb] = b.split('-').map((n) => parseInt(n, 10))
        if (da < db) return -1
        if (da > db) return 1
        if (xa < xb) return -1
        if (xa > xb) return 1
        if (ya < yb) return -1
        if (ya > yb) return 1
        if (za < zb) return -1
        if (za > zb) return 1
        return 0
      })

      keys.forEach((v) => {
        const [d, x, y, z] = v.split('-').map((n) => parseInt(n, 10))
        const a = x & 1
        const b = y & 1
        const c = z & 1
        const parentName = d - 1 + '-' + (x >> 1) + '-' + (y >> 1) + '-' + (z >> 1)

        const parentNode = nodes[parentName]
        if (!parentNode) return
        parentNode.hasChildren = true

        const key = parentNode.key.step(a, b, c)

        const node = new Potree.PointCloudEptGeometryNode(
          this.ept,
          key.b,
          key.d,
          key.x,
          key.y,
          key.z
        )

        node.level = d
        node.numPoints = hier[v]
        parentNode.addChild(node)
        nodes[key.name()] = node
      })
    }

    geometry.root.load()

    return new Potree.PointCloudOctree(geometry)
  })
}
