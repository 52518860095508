<template>
  <div class="login-page">
    <app-navbar />
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="validateBeforeSubmit">
                <card type="login">
                  <h3
                    slot="header"
                    class="header"
                  >
                    <translate translate-context="title">
                      Reset password
                    </translate>
                  </h3>
                  <!--<h3  class="header text-center" v-translate>Log in</h3>-->
                  <small
                    v-for="(error, index) in errorsMessages.nonFieldErrors"
                    :key="index"
                    class="text-danger"
                  >
                    {{ error }}
                  </small>
                  <div v-if="validKey === true">
                    <div class="form-group">
                      <label
                        v-translate
                        for="password1"
                      >Password</label>
                      <input
                        id="password1"
                        ref="password"
                        v-model="form.password1"
                        v-validate="'required'"
                        name="password"
                        minlength="3"
                        autocomplete="new-password"
                        data-vv-validate-on="none"
                        required
                        type="password"
                        :placeholder="'Enter password' | translate"
                        :class="{'input': true, 'form-control-danger': errors.any() }"
                        class="form-control input-no-border"
                      >
                      <small
                        v-if="errors.has('password')"
                        class="text-danger"
                      >
                        {{ errors.first('password') }}
                      </small>
                    </div>
                    <div class="form-group">
                      <label
                        v-translate
                        for="password2"
                      >Password again</label>
                      <input
                        id="password2"
                        v-model="form.password2"
                        v-validate="'required|confirmed:password'"
                        name="confirmPassword"
                        minlength="3"
                        autocomplete="new-password"
                        data-vv-validate-on="none"
                        required
                        type="password"
                        :placeholder="'Enter password again' | translate"
                        :class="{'input': true, 'form-control-danger': errors.any() }"
                        class="form-control input-no-border"
                      >
                      <small
                        v-if="errors.has('confirmPassword')"
                        class="text-danger"
                      >
                        {{ errors.first('confirmPassword') }}
                      </small>
                    </div>
                    <input
                      name="username"
                      type="hidden"
                      :value="form.username"
                      autocomplete="username"
                    >
                  </div>
                  <div slot="footer">
                    <p-button
                      v-if="validKey === true"
                      v-loading="loading"
                      type="warning"
                      round
                      block
                      class="mb-3"
                      native-type="submit"
                    >
                      <translate>Reset password</translate>
                    </p-button>
                    <div
                      v-if="validKey === false"
                      class="forgot text-center"
                    >
                      <router-link
                        v-translate
                        to="/forgot-password"
                      >
                        Restore password
                      </router-link>
                    </div>
                  </div>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer />
        <div class="full-page-background" />
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Checkbox, Button } from 'src/components/UIComponents'
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter'
export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button
  },
  data() {
    return {
      message: null,
      success: false,
      loading: false,
      validKey: null,
      errorsMessages: {
        nonFieldErrors: []
      },
      form: {
        username: '',
        password1: '',
        password2: ''
      }
    }
  },
  created() {
    this.$validator.localize('ru')
    this.$http.get(`/user/0/reset-password/${this.$route.params.uid}-${this.$route.params.key}/`)
      .then(response => {
        if (response.data.status === 'OK') {
          this.validKey = true
          this.form.username = response.data.username
        } else {
          this.validKey = false
        }
      }, error => {
        if (error.response.status === 400) {
          this.validKey = false
          this.errorsMessages.nonFieldErrors = [error.response.data.message]
        }
      })
  },
  beforeDestroy() {
    this.closeMenu()
  },
  methods: {
    validateBeforeSubmit(e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit(e)
          return true
        }
      })
    },
    cleanErrors() {
      this.errorsMessages.nonFieldErrors = []
    },
    submit(e) {
      e.preventDefault()
      this.errorsMessages = {
        nonFieldErrors: []
      }
      this.loading = true
      const self = this
      this.$http.post(`/user/0/reset-password/${this.$route.params.uid}-${this.$route.params.key}/`, {
        password: this.form.password1
      }).then(() => {
        this.loading = false
        this.cleanErrors()
        this.$auth.login({
          data: {
            username: this.form.username,
            password: this.form.password1
          },
          error: function(response) {
            self.loading = false
            if (undefined === response.response && response.message.toString() === 'Network Error') {
              self.errorsMessages.nonFieldErrors = [self.$gettext('Network error. Check internet connection')]
            } else {
              self.errorsMessages.nonFieldErrors = response.response.data.nonFieldErrors || []
              self.errorsMessages.username = response.response.data.username || []
              self.errorsMessages.password = response.response.data.password || []
            }
          },
          rememberMe: true,
          redirect: '/',
          fetchUser: true
        })
        // this.$router.push('/')
      }, error => {
        this.loading = false
        if (undefined === error.response && error.message.toString() === 'Network Error') {
          this.errorsMessages.nonFieldErrors = [this.$gettext('Network error. Check internet connection')]
        } else
        if (error.response.status === 400) {
          if (error.response.data.status === 'error') {
            this.validKey = false
            this.errorsMessages.nonFieldErrors = [error.response.data.message] || []
          } else {
            this.errorsMessages.nonFieldErrors = error.response.data.nonFieldErrors || []
          }
        } else {
          this.cleanErrors()
          this.errorsMessages.nonFieldErrors = [this.$gettext('Unknown error')]
        }
      })
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    }
  }
}
</script>
<style scoped>
  .login-page {
    background: transparent url('/assets/images/shutterstock_631636766.png') 0 0 no-repeat padding-box;
  }

  h3.header {
    margin-bottom: 0;
  }

</style>
