<template>
  <div>
    <div
      class="users"
      :class="{ 'target-element' : fromGrid }"
    >
      <div class="container mt-5">
        <div
          class="row"
        >
          <!--          users cards open-->
          <div
            v-for="user in users"
            :key="user.id"
            v-nova-page-guide:cardUserGrid
            class="card-project col-12 col-sm-6 col-md-6 col-lg-3 mb-3"
          >
            <user-card
              :value="user"
              :is-admin="isAdmin"
              :company-id="companyId"
            />
          </div>
          <!--users cards close-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserCard from './UserCard'

export default {
  name: 'UserPageGrid',
  components: {
    UserCard,
  },
  props: {
    users: {
      type: Array,
      default: function() {
        return []
      }
    },
    isAdmin: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    companyId: {
      type: Number,
      default: function() {
        return null
      }
    },
  },
  data() {
    return {
      fromGrid: false
    }
  },
  methods: {
    test() {
      console.log('rrun')
    }
  }
}
</script>

<style scoped>
  .el-button-group .el-button--info {
    font-size: 16px;
    padding: 9px;
  }
  .el-dropdown {
    margin: auto 10px;
  }
  .el-button--info:hover, .el-button--info:focus {
    background: transparent !important;
    color: #51cbce !important;
  }
  .el-button:hover, .el-button:focus {
    /*color: #51cbce !important;*/
    background: transparent !important;
  }
  /* for guide */
  .for-guide {
    background-color: rgb(244, 243, 239);
  }
</style>
