/**
 * @file Определяет  класс Marker
 */
import { SVGIcon } from 'leaflet-svgicon'
import {
  DEFAULT_FILL_OPACITY,
  MODE_3D,
  DISTANCE_FOR_CAMERA_CENTERING,
} from '@nova/constants'
import { Measure } from './Measure'
import { Box3, Object3D, Color, Vector3, Sphere } from 'three'

/**
 * Класс маркера который обозначает замер с одной точкой, в 2Д подставляется svg картинка, в 3Д просто одна точка (сфера)
 */
class Marker extends Measure {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Object} объект в котором зранятся свойства для создания объекта
   */
  constructor(vm, data = null) {
    super(vm, data)
    this.type = 'annotation-marker'
  }

  /**
   * @inheritDoc
   */
  centerCamera() {
    if (this.vm.currentMode === MODE_3D) {
      const points = this.$potreeMeasure.points.map((p) => p.position)

      const box = new Box3()
      box.setFromPoints(points)
      const node3d = new Object3D()
      node3d.boundingBox = box
      node3d.boundingSphere = box.getBoundingSphere(new Sphere(points[0]))
      node3d.boundingSphere.radius = DISTANCE_FOR_CAMERA_CENTERING
      this.vm.viewer.zoomTo(node3d, 1, 300)
      setTimeout(this.vm.scaleNameLabels, 300)
    } else if (this.vm.currentMode) {
      console.warn('Центрирование карты по замеру не реализовано в режиме 2D')
    }
  }

  /**
   * @inheritDoc
   */
  createPotreeMeasure() {
    super.createPotreeMeasure()
    this.$potreeMeasure.showDistances = false
    this.$potreeMeasure.maxMarkers = 1
  }

  /**
   * У маркера не убираем сферы в 3Д, иначе он совсем пропадет
   */
  // метод делает 3Д часть замера недоступным для редактирования или наоборот
  changeSpheresVisibility(visibility) {
    console.log('не убираем сферы у маркера')
  }

  /**
   * @inheritDoc
   */
  setLayerColor(color) {
    // проставляем выбранные цвета, как цвета по умолчанию
    // this.$layer.options = this.$layer.options || {opacity: 0.4}
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        // Обновляем цвет маркера
        const icon = this.$layer.getIcon()
        const options = icon.options
        delete options.html
        options.color = color
        options.circleColor = color
        options.fillColor = color
        options.circleFillColor = this.color
        this.$layer.setIcon(new SVGIcon(options))
      }
      if (this.$potreeMeasure) {
        const _color = new Color(color)
        this.$potreeMeasure.color = _color
        this.$potreeMeasure.spheres.map((sphere) => {
          sphere.material.color = _color
        })
      }
    } else {
      console.warn(
        'Нельзя изменить цвет замера, отсутстует объект $layer или $potreeMeasure',
        this
      )
    }
  }

  /**
   * @inheritDoc
   */
  setLayerOpacity(opacity) {
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        this.$layer.options.opacity = opacity
        // Вычисляем прозрачность заливки
        const fillOpacity = parseFloat(opacity) * DEFAULT_FILL_OPACITY
        this.$layer.options.fillOpacity = fillOpacity.toFixed(3)

        const icon = this.$layer.getIcon()
        const options = icon.options
        delete options.html
        options.fillOpacity = fillOpacity
        options.opacity = opacity
        options.circleFillOpacity = opacity
        options.circleOpacity = opacity
        this.$layer.setIcon(new SVGIcon(options))
        // debugger
      }
      if (this.$potreeMeasure) {
        console.error(
          'Нельзя изменить прозрачность замера, функционал не реализован в режиме 3D',
          this
        )
        // this.$potreeMeasure.color = new THREE.Color(color)
      }
    } else {
      console.warn(
        'Нельзя изменить прозрачность замера, отсутстует объект $layer или $potreeMeasure',
        this
      )
    }
  }

  /**
   * У маркера нет свойств для просчета
   * @return {undefined}
   */
  updateCalculations() {
    console.log('У маркера нет свойств по длине')
  }

  /**
   * У маркера нельзя посчитать Elevation Profile
   * @return {undefined}
   */
  calculateElevationProfile() {
    console.error('Этот замер не может быть профилирован')
  }

  /**
   * @inheritDoc
   */
  placeLabelsOnWorkingLayer() {
    console.warn('У маркера нет лейблов длины/площади')
  }

  /**
   * @inheritDoc
   */
  get color() {
    return super.color
  }

  /**
   * @inheritDoc
   */
  set color(value) {
    if (value !== this.color) {
      this._color = value
      const icon = this.$layer.getIcon()
      const options = icon.options
      delete options.html
      options.circleFillColor = value
      this.$layer.setIcon(new SVGIcon(options))
    }
  }

  /**
   * @inheritDoc
   */
  get isElevationProfile() {
    return false
  }

  /**
   * @inheritDoc
   */
  get defaultLabel() {
    return this.vm.$gettext('Marker')
  }
}

export { Marker }
