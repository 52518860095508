<template>
  <div>
    <nova-header
      @notificationReaded="updateMarkAsReadButton($event)"
    />
    <div class="container">
      <div class="row">
        <!--          table open-->
        <div class="tables mt-2">
          <el-table
            :data="notifications"
            row-class-name="custom-el-table-row"
            style="width: 100%"
          >
            <el-table-column
              :label="'Who made' | translate"
              prop="actor"
            >
              <template slot-scope="scope">
                <div class="row">
                  <div class="col-12 col-sm-4">
                    <gravatar
                      :email="scope.row.data.notification_data.email"
                      alt="user avatar"
                      :size="30"
                      class="rounded-circle border"
                    />
                  </div>
                  <div class="whois d-flex align-items-center col-12 col-sm-8 ">
                    {{ scope.row.actor }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="'Сhanges' | translate"
            >
              <template slot-scope="scope">
                <div class="change">
                  <div class="change__chenged">
                    <translate>New changes in</translate>
                    <strong> {{ scope.row.data.notification_data.task_name }}</strong>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              :label="'How long' | translate"
              prop="data_type"
            >
              <template slot-scope="scope">
                {{ timeFromChanges(scope.row.timestamp) }}
              </template>
            </el-table-column>
            <el-table-column
              :label="'Actions' | translate"
              prop="actions"
              align="right"
            >
              <template slot-scope="scope">
                <router-link
                  :to="{name: 'task-viewer', params: {projectId: scope.row.data.notification_data.project_id, taskId: scope.row.data.notification_data.task_id}}"
                >
                  <el-button
                    icon="icon nova-btn-play"
                    circle
                  />
                </router-link>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Mark as read' | translate"
                  placement="left"
                  :visible-arrow="false"
                >
                  <el-button
                    v-if="scope.row.unread"
                    style="color: #959fa7"
                    icon="icon nova-c-check"
                    circle
                    @click="markAsRead(scope.row)"
                  />
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!--          table close-->
    </div>
  </div>
</template>
<script>
import NovaHeader from './NovaHeader'
import { Avatar, Button, Table, TableColumn, Tooltip } from 'element-ui'
import moment from 'moment'
import Gravatar from 'vue-gravatar'

export default {
  components: {
    [Avatar.name]: Avatar,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
    NovaHeader,
    Gravatar
  },
  data() {
    return {
      notifications: null,
    }
  },
  async mounted() {
    this.loadNotifications()
  },
  methods: {
    async loadNotifications() {
      try {
        let response = await this.axios.get('/notification/all-count/')
        response = await this.axios.get('/notification/all-list/?max=99')
        // response = await this.axios.get(`/notification/all-list/?max=${response.data.all_count}`)
        this.notifications = await response.data.all_list
      } catch (e) {
        console.error('Не удалось загрузить список уведомлений', e)
      }
    },
    timeFromChanges(changedAt) {
      return moment(changedAt).fromNow()
    },
    async markAsRead(notification) {
      try {
        await this.axios.post(`/notification/${notification.id}/mark-as-read/`)
        notification.unread = false
      } catch (e) {
        console.error('Не удалось отметить как прочитанное', e)
      }
    },
    updateMarkAsReadButton(id) {
      const item = this.notifications[this.notifications.findIndex(item => item.id === id)]
      if (item) {
        item.unread = false
      } else {
        console.log('Это уведомление не на экране')
      }
    }
  }
}
</script>

<style scoped>
  .el-table {
    color: #FFFFFF;
  }
  .tables {
    width: 100%;
  }
  .custom-el-table-row {
    color: white;
  }
  .custom-el-table-row:hover > td {
    background-color: #737373 !important;
  }
</style>
