<template>
  <div
    class="head-notify mb-3"
    :v-if="notifications"
  >
    <el-dropdown
      trigger="click"
      :hide-on-click="false"
    >
      <el-badge
        :value="notificationsCount"
        :max="9"
        class="item"
        :hidden="newNotifications()"
      >
        <el-button
          style="font-size: 16px; padding: 0 5px 0 0;"
          :class="{'rotate-bell': notificationsCount}"
          type="info"
          icon="icon nova-alarm"
          circle
          @click="loadNotifications()"
        />
      </el-badge>
      <el-dropdown-menu slot="dropdown">
        <div class="bell-notify mx-4">
          <div
            class="bell-notify__title border-bottom pb-2"
          >
            <translate>You have </translate>
            <span style="color: red"> {{ notificationsCount }} </span>
            <translate> new events</translate>
          </div>
          <div class="bell-notify__list">
            <div
              v-for="(notification, index) in notifications"
              :key="notification.id"
              class="notify-list__item d-flex py-1 border-bottom"
            >
              <div class="notify-list__avatar col-2 px-0 py-1">
                <gravatar
                  :email="notification.data.notification_data.email"
                  alt="user avatar"
                  :size="30"
                  class="rounded-circle border"
                />
              </div>
              <div class="notify-list__change col-5 px-0">
                <div class="change__whois">
                  {{ notification.actor }}
                </div>
                <div class="change__changed">
                  <translate>'New changes in - '</translate>
                  <router-link
                    :to="{name: 'task-viewer', params: {projectId: notification.data.notification_data.project_id, taskId: notification.data.notification_data.task_id}}"
                  >
                    <span class="clip btn-to-project"><u>{{ notification.data.notification_data.task_name }}</u></span>
                  </router-link>
                </div>
              </div>
              <div class="notify-list__how-long col-3 px-0 my-auto mx-0 text-right">
                {{ timeFromChanges(notification.timestamp) }}
              </div>
              <div class="col-2 px-0 text-right">
                <el-button
                  circle
                  icon="icon nova-c-remove"
                  style="color: #464646; font-size: 1em"
                  @click="markAsRead(notification, index)"
                />
              </div>
            </div>
            <div class="notify-list__more text-center">
              <router-link
                :to="{name:'NotificationsList'}"
              >
                <el-button type="text">
                  <translate>Show all</translate>
                </el-button>
              </router-link>
            </div>
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { Avatar, Button, Dropdown, DropdownItem, DropdownMenu, Badge } from 'element-ui'
import moment from 'moment'
import Gravatar from 'vue-gravatar'

export default {
  name: 'Notifications',
  components: {
    [Avatar.name]: Avatar,
    [Button.name]: Button,
    [Badge.name]: Badge,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    Gravatar,
  },
  data() {
    return {
      notifications: null,
      notificationsCount: null
    }
  },
  async mounted() {
    await this.loadNotifications()
  },
  methods: {
    newNotifications() {
      return this.notificationsCount === 0
    },
    timeFromChanges(changedAt) {
      return moment(changedAt).fromNow()
    },
    async loadNotifications() {
      try {
        const response = await this.axios.get('/notification/unread-list/?max=5')
        this.notifications = await response.data.unread_list
        this.notificationsCount = await response.data.unread_count
      } catch (e) {
        console.error('Не удалось загрузить список уведомлений', e)
      }
    },
    async markAsRead(notification, index) {
      try {
        await this.axios.post(`/notification/${notification.id}/mark-as-read/`)
        this.$parent.$emit('notificationReaded', notification.id)
        this.notifications.splice(index, 1)
        await this.loadNotifications()
      } catch (e) {
        console.error('Не удалось отметить уведомление как прочитанное', e)
      }
    }
  }
}
</script>

<style scoped>
  .head-notify {
    margin: 0 22px 22px 0;
    height: 13px;
    width: 13px;
  }
  /* dropdown notify */
  .bell-notify {
    width: 300px;
  }
  .bell-notify__title {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    color: #747474;
    position: relative;
  }
  .change__whois {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    color: #000000;
  }
  .change__changed {
    font-size: 10px;
    font-weight: 100;
    letter-spacing: 0;
    color: #000000;
  }
  .btn-to-project {
    color: #000000;
  }
  .notify-list__how-long {
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0;
    color: #747474;
  }
  .notify-list__more button {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0;
    color: #D32628;
  }
  .rotate-bell {
    -ms-transform: rotate(20deg); /* IE 9 */
    -webkit-transform: rotate(20deg); /* Safari 3-8 */
    transform: rotate(20deg);
  }
</style>
