<template>
  <div>
    <nova-header />
    <div class="d-flex">
      <div class="col-12 p-5">
        <vue-dropzone
          id="dropzone"
          ref="myVueDropzone"
          :options="dropzoneOptions"
          use-custom-slot
          duplicate-check
          @vdropzone-success="(file, response) => success(file, response)"
          @vdropzone-duplicate-file="(file) => duplicateFile(file)"
          @vdropzone-removed-file="(file, error, xhr) => removeFile(file, error, xhr)"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              <translate>Drag and drop to upload content!</translate>
            </h3>
            <div class="subtitle">
              <translate>...or click to select a file from your computer</translate>
            </div>
          </div>
        </vue-dropzone>
      </div>
    </div>
  </div>
</template>

<script>
import NovaHeader from './HeaderLayout/NovaHeader'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { Button, Notification } from 'element-ui'

export default {
  name: 'UploadLayout',
  components: {
    NovaHeader,
    vueDropzone: vue2Dropzone,
    [Button.name]: Button,
  },
  data() {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailMethod: 'contain',
        headers: {},
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
      },
      selectedFiles: [],
      loadingUpload: false,
    }
  },
  methods: {
    /**
     * Метод вызывает уведомление при успешной загрузке файлов
     * @param file - свойства объекта файла
     */
    success(file) {
      const message = `${file.name} ${this.$gettext('successfully added!')}`
      this.showNotification(message)
    },
    /**
     * Метод вызывает уведомление при выявлении дубликата загружаемого файла
     * @param file - свойства объекта файла
     */
    duplicateFile(file) {
      const message = `${file.name} ${this.$gettext('has already been added!')}`
      const title = this.$gettext('Not saved')
      this.showNotification(message, 'error', title)
    },
    /**
     * Метод вызывает уведомление при удалении файла с жропзоны
     * @param file - свойства объекта файла
     */
    removeFile(file) {
      const message = `${file.name} ${this.$gettext('removed!')}`
      const title = this.$gettext('Removed')
      this.showNotification(message, title)
    },
    /**
     * Метод уведомлений
     * @param message - сообщение в уведомлении
     * @param title - заголовок уведомления
     * @param type - тип уведомления
     */
    showNotification(message, title, type) {
      Notification({
        title: title || this.$gettext('Saved'),
        message: message,
        type: type || 'success',
        position: 'top-right',
        showClose: false
      })
    },
  }
}
</script>

<style lang="scss">
  .vue-dropzone.dropzone.dz-clickable {
    background-color: #212220;
    border-color: rgba(255, 255, 255, 0.5);;
  }
</style>
