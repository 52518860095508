<template>
  <navbar navbar-menu-classes="navbar-right">
    <template slot="navbar-menu">
      <!--<router-link to="/admin" class="nav-item" tag="li">-->
      <!--<a class="nav-link">-->
      <!--<i class="nc-icon nc-layout-11"></i> Dashboard-->
      <!--</a>-->
      <!--</router-link>-->
      <router-link
        to="/signup"
        class="nav-item"
        tag="li"
      >
        <a
          v-translate
          class="nav-link"
        >Register</a>
      </router-link>
      <router-link
        to="/login"
        class="nav-item"
        tag="li"
      >
        <a
          v-translate
          class="nav-link"
        >Login</a>
      </router-link>
      <router-link
        to="/forgot-password"
        class="nav-item"
        tag="li"
      >
        <a
          v-translate
          class="nav-link"
        >Forgot password?</a>
      </router-link>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from 'src/components/UIComponents'
export default {
  name: 'MainNavbar',
  components: {
    Navbar
  }
}
</script>

<style scoped>

</style>
