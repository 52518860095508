/**
 * @file Определяет  класс Circle
 */
import { DEFAULT_CIRCLE_RADIUS, SELECTED_COLOR } from '@nova/constants'
import { Measure } from '@letsnova/potree/src/utils/Measure'
import Circle3D from '@nova/Classes/3D/Circle3D'
import { MultiPointMeasure } from './MultiPointMeasure'
import * as THREE from 'three'
import * as L from 'leaflet'

/**
 * Класс Circle для создания замеров типа круг
 */
export default class Circle extends MultiPointMeasure {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Object} объект в котором зранятся свойства для создания объекта
   */
  constructor(vm, data = null) {
    if (data === null) {
      data = {}
    }
    super(vm, data)
    this.type = 'annotation-circle'
    this._radius = data.radius || DEFAULT_CIRCLE_RADIUS
  }

  /**
   * @inheritDoc
   */
  createPotreeMeasure() {
    this.$potreeMeasure = new Circle3D(
      this.centerPoint3D,
      this.radius,
      this.dashed,
      this.dashSize,
      this.gapSize
    )

    this.setUpPotreeMeasure()
    this.$potreeMeasure.createMesh()
    this.$potreeMeasure.mesh.visible = this.fill

    if (this.checkPotreeMeasureIsCorrect(Measure)) {
      this.vm.viewer.scene.addMeasurement(this.$potreeMeasure)
    } else {
      console.error('Недопустимый тип замера', this.$potreeMeasure)
    }
  }

  /**
   * @inheritDoc
   */
  updateCalculations() {
    if (this.points.length > 0) {
      if (this.$potreeMeasure) {
        this.area3D = this.$potreeMeasure.getArea()
        this.length3D = this.$potreeMeasure.getTotalDistance()
        this._volumeNeedUpdate = true
      } else {
        this.length2D = 2 * Math.PI * this.radius
        // пишем длину и площадь FIXME тут просто костылим
        this.area2D = Math.PI * this.radius * this.radius
        this.area3D = null
        this.length3D = null
      }
    } else {
      console.warn('У замера нет точек для подсчётов', this)
    }
  }

  /**
   * @inheritDoc
   */
  changeSpheresVisibility(visibility) {
    if (this.$potreeMeasure) {
      // Проверяем что замер уже нарисован, если нет, то сфера, которую рисуем как центральную точку - отображаем
      if (!this.isNew) {
        this.$potreeMeasure.spheres.map((sphere) => {
          sphere.visible = visibility
          const meshColor = this.$potreeMeasure.mesh.material.color
          sphere.color = new THREE.Color(
            1 - meshColor.r,
            1 - meshColor.g,
            1 - meshColor.b
          )
        })
      }
    }
    console.warn('убираем сферы у круга всегда')
  }

  updatePosition(points) {
    if (!this.isNew && this.$potreeMeasure) {
      this.$potreeMeasure.centerPoint = points[0].position
      this.setCenterPoint(points[0].position)
      this.$potreeMeasure.createMesh()
    }
  }

  /**
   * @inheritDoc
   */
  setLayerColor(color) {
    super.setLayerColor(color)
    if (this.$potreeMeasure) {
      const _color = new THREE.Color(color)
      this.$potreeMeasure.mesh.material.color = _color
      this.$potreeMeasure.circleLine.material.color = _color
    }
  }

  /**
   * @inheritDoc
   */
  placeLabelsOnWorkingLayer(layer, formatter, visible, map, drawMode) {
    layer.on('pm:centerplaced', () => {
      if (drawMode && visible) {
        if (!layer._measurementOptions) {
          layer._measurementOptions = L.extend(
            {
              measureName: null,
              defaultMeasureName: null,
              showOnHover: false,
              minPixelDistance: 30,
              showDistances: true,
              showArea: true,
              lang: {
                totalLength: 'Total length',
                totalArea: 'Total area',
                segmentLength: 'Segment length',
                lineName: 'Line name',
                polygonName: 'Polygon name',
                markername: 'Marker name',
              },
            },
            {}
          )
          layer._measurementLayer = L.layerGroup().addTo(map, true)
          layer._measurementOptions.showMeasures = visible
        }
      }
    })
    map.on('mousemove', (mouse) => {})
  }

  // updatePotreeMeasure() {
  //   console.warn('Не обновляем замер круга')
  // }

  /**
   * @inheritDoc
   */
  get defaultLabel() {
    return this.vm.$gettext('Circle')
  }

  /**
   * Возвращает радиус замера
   * @return {Number}
   */
  get radius() {
    return parseFloat(this._radius).toFixed(2)
  }

  /**
   * Уставливает новое значение разиуса
   * @param value {Number} - новый радиус замера
   */
  set radius(value) {
    this._radius = parseFloat(value)
    // удаляем замер
    this.$potreeMeasure.$scene.removeMeasurement(this.$potreeMeasure)
    // создаем новый
    this.createPotreeMeasure()
    // без этого не показывается лейбл названия
    this.updateCalculations()
    this.vm.recomputeNameLabelPosition(this.$potreeMeasure)
    this.vm.scaleNameLabel(this.$potreeMeasure.nameLabel)
    // Скрываем сферу центра при перерисовке
    this.changeSpheresVisibility(true)
    this.setLayerColor(SELECTED_COLOR)
  }

  /**
   * @inheritDoc
   */
  get centerPoint() {
    return this.points[0]
  }

  /**
   * Геттер возвращает значение заливки для замера
   * @return {Boolean}
   */
  get fill() {
    return this._fill
  }

  /**
   * Сеттер устанавливает значение заливки для замера
   * @param value {Boolean} - значения заливки
   */
  set fill(value) {
    this._fill = value
    this.$potreeMeasure.mesh.visible = value
  }

  /**
   * @inheritDoc
   */
  get exportData() {
    const data = super.exportData
    data.radius = this.radius
    return data
  }
}
