/**
 * @file Определяет  класс DashMeasure
 */
import { Measure } from './Measure'
import { DASH_COEFFICIENT } from '../../../constants'

/**
 * Класс DashMeasure который расширяет класс Measure и добавляет функционал для создания пунктирных линий в замере
 */
export default class DashMeasure extends Measure {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Object} объект в котором зранятся свойства для создания объекта
   */
  constructor(vm, data = null) {
    super(vm, data)

    this._dashSize = data.dashSize || 5
    this._gapSize = data.gapSize || 5
    this._dashed = data.dashed || false
  }

  /**
   * Функция обновляет стиль пунктирной линии с текущими параметрами объекта
   * @return {undefined}
   */
  updateDashStyle() {
    if (this.$potreeMeasure) {
      this.$potreeMeasure.dashed = this._dashed
      this.$potreeMeasure.dashSize = this.potreeDashSize
      this.$potreeMeasure.gapSize = this.potreeGapSize
      if (this.$potreeMeasure.$measure.type === 'annotation-circle') {
        this.$potreeMeasure.createMesh()
      }
    }
    if (this.$layer) {
      if (this._dashed) {
        this.$layer.setStyle({
          dashArray: `${this.dashSize} ${this.gapSize}`,
        })
      } else {
        this.$layer.setStyle({
          dashArray: null,
        })
      }
    }
  }

  /**
   * Возвращает является ли текущее состояние замера пунктирным
   * @return {boolean}
   */
  get dashed() {
    return this._dashed
  }

  /**
   * Устанавливает является ли замер пунктирным или нет
   * @param value {boolean} - значение для простановки "пунктирности"
   */
  set dashed(value) {
    this._dashed = value
    this.updateDashStyle()
  }

  /**
   * Возвращает размер штриха пунктира для 3D режима, тк 2Д и 3Д не считается, как 1 к 1
   * @return {Number}
   */
  get potreeDashSize() {
    return this._dashSize / DASH_COEFFICIENT
  }

  /**
   * Возвращает размер штриха пунктира
   * @return {Number}
   */
  get dashSize() {
    return this._dashSize
  }

  /**
   * Устанавливает размер штриха пунктира
   * @param value {Number} - размер черточек пунктира
   */
  set dashSize(value) {
    this._dashSize = parseInt(value)
    this.updateDashStyle()
  }

  /**
   * Возвращает размер зазора пунктирной линии
   * @return {Number}
   */
  get gapSize() {
    return this._gapSize
  }

  /**
   * Возвращает размер зазора для 3D режима
   * @return {Number}
   */
  get potreeGapSize() {
    return this._gapSize / DASH_COEFFICIENT
  }

  /**
   * Устанавливает размер зазора для пунктира
   * @param value {Number}
   */
  set gapSize(value) {
    this._gapSize = parseInt(value)
    this.updateDashStyle()
  }

  /**
   * @inheritDoc
   */
  get exportData() {
    const data = super.exportData
    data.dashed = this._dashed
    data.gapSize = this._gapSize
    data.dashSIze = this._dashSize

    return data
  }
}
