import axios from 'axios'
import VueAxios from 'vue-axios'

export default {
  install(Vue) {
    // axios.defaults.baseURL = process.env.VUE_APP_API_BASE || "http://127.0.0.1:8000/api";
    // const API_BASE = process.env.NODE_ENV === 'development' ? '/' : window.VUE_APP_API_BASE || process.env.VUE_APP_API_BASE || '/'
    if (process.env.VUE_APP_API_BASE) {
      window.VUE_APP_API_BASE = window.VUE_APP_API_BASE || process.env.VUE_APP_API_BASE
    }
    const API_BASE = window.VUE_APP_API_BASE || '/api/'
    const instance = axios.create({
      baseURL: API_BASE,
      timeout: 30000,
    })
    // интерцептор реквеста с указанием текущей локализации
    const langInterceptor = (config) => {
      // параметр текущей локализации
      const getLang = () => Vue.config.language
      config.headers.common['Accept-Language'] = getLang()
      return config
    }
    // использование интерцептора
    instance.interceptors.request.use(langInterceptor)
    window.AXIOS_INSTANCE = instance
    window.VUE_CLASS = Vue
    Vue.use(VueAxios, instance)
    Vue.axios.defaults.baseURL = API_BASE
  }
}
