<template>
  <div style="height: calc(100% - 10px); width: 100%;">
    <div id="leaflet_map" style="height: 100%; width: 100%; position: relative" />
    <!-- TODO не увидел присваивания длины -->
    <!--<div>Длина текущей линии: {{ lineLength }}</div> -->
    <!--    <div>Площадь текущего полигона: {{polygonArea}}</div>-->
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import * as L from 'leaflet'
// import 'leaflet.pm/dist/leaflet.pm.css';
// import 'leaflet.pm';
import '@letsnova/leaflet-measure-path'
import 'src/util/leafletAuthorizationPlugin'
// import 'leaflet-measure-path/leaflet-measure-path.css'
import 'src/vendor/leaflet-pm/src/js/L.PM'
import 'src/vendor/leaflet-pm/src/css/controls.css'
import 'src/vendor/leaflet-pm/src/css/layers.css'
import { DEFAULT_FILL_OPACITY, DEFAULT_LINE_WEIGHT_LEVEL } from '@nova/constants'
import Utils from '@nova/Classes/Utils'

export default {
  name: 'LeafletViewer',
  props: {
    project: {
      type: Number,
      default() {
        return parseInt(this.$route.params.projectId)
      },
    },
    task: {
      type: String,
      default() {
        return this.$route.params.taskId
      },
    },
    dsm: {
      type: Object,
      default: () => {},
    },
    orthophoto: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      measures: [],
      // lineLength: null
      // polygonArea: null,
      // accessToken:
      //   'pk.eyJ1IjoiYWZhbmVvciIsImEiOiJjangzN3V0czAwN3o1M3lydzlxMGIyNWdkIn0.__BUTmfls9DLgcusu-3jXw',
      //  imageryLayers: [],
      container: 'leaflet_map',
    }
  },
  computed: {
    showTitleLabels() {
      return this.$store.state.viewer.showTitleLabels
    },
    showMeasureLabels() {
      return this.$store.state.viewer.showMeasureLabels
    },
    map() {
      return this.$root.storeMap.state.map
    },
  },
  mounted() {
    // инициализация карты
    this.initMap()
  },
  methods: {
    async initMap() {
      this.$root.storeMap.mutations.setMap(this.$root.storeMap.state, {
        container: this.container,
        parameters: {
          scrollWheelZoom: true,
          positionControl: true,
          zoomControl: false,
          editable: true,
        },
      })
      // Показываем весь мир
      this.map.fitWorld()
      // подгрузка слоя карты
      L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
          maxZoom: 18,
          attribution:
            'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        }
      ).addTo(this.map)
      // тайлы для наложения дорог и названий дорог
      L.tileLayer(
        'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.{ext}',
        {
          attribution:
            'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          subdomains: 'abcd',
          maxZoom: 18,
          ext: 'png',
        }
      ).addTo(this.map)

      window.map = this.map
      // добавление кнопок зума
      // let zoomControl = L.control.zoom({
      //   position:'bottomleft'
      // }).addTo(this.map);
      this.map.pm.setPathOptions(this.getDefaultMeasureStyle())
      // добавление рисовалки
      // this.map.pm.addControls({
      //   position: 'topleft',
      //   drawCircle: false,
      //   drawMarker: true,
      //   drawRectangle: false,
      //   cutPolygon: false,
      //   removalMode: false,
      // });
      // подгрузка фото
      const layers = await this.$store.dispatch('viewer/loadExtraLayers', {
        project: +this.$route.params.projectId,
        task: this.$route.params.taskId,
        headers: this.$root.headers,
        dsm: this.dsm,
        orthophoto: this.orthophoto,
        map: this.map,
      })
      if (this.$store.state.viewer.mapBounds) {
        this.map.fitBounds(this.$store.state.viewer.mapBounds)
      }
      for (const ind in layers) {
        this.showLayer(layers[ind])
      }
      // проверяем, что если мы еще не импортировали замеры, то импортируем, два слоя - mapBox и ортофото
      if (Object.keys(this.map._layers).length === 3) {
        this.$emit('importAnnotations')
      }
      this.$emit('initMap')
      // простановка текущего языка
      this.map.pm.setLang(this.$language.curren)
      this.map.attributionControl.setPrefix('')
    },
    showLayer(layer) {
      if (layer.$measure) {
        layer.showMeasurements({
          measureName: layer.$measure.name,
          formatDistance: Utils.labelFormatter.call(this),
          showName: this.showTitleLabels,
          showMeasures: this.showMeasureLabels,
        })
      }
    },
    getDefaultMeasureStyle(color = undefined) {
      if (!color) {
        color = '#4281f5'
      }
      return {
        color: color,
        fillColor: color,
        fillOpacity: DEFAULT_FILL_OPACITY,
        weight: DEFAULT_LINE_WEIGHT_LEVEL,
      }
    },
  },
}
</script>

<style>
.leaflet-measure-path-measurement {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: -1px 0 0 #000000, -1px -1px 0 #000000, 0 -1px 0 #000000, 1px -1px 0 #000000,
    1px 0 0 #000000, 1px 1px 0 #000000, 0 1px 0 #000000, -1px 1px 0 #000000;
  white-space: nowrap;
  transform-origin: 0;
  pointer-events: none;
}

.leaflet-measure-path-measurement > div {
  background: rgba(0, 0, 0, 0.3);
  border: solid 3px black;
  border-radius: 5px;
  padding: 0 5px 0 5px;
  position: relative;
  margin-top: -25%;
  left: -50%;
}
.leaflet-marker-icon.marker-icon {
  background-color: #ef8157 !important;
  border: 0px solid #000000;
}
</style>
