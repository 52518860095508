/**
 * @file Определяет  класс Line
 */
import { MultiPointMeasure } from './MultiPointMeasure'
import { Vector3 } from 'three'
import { Profile } from '@letsnova/potree/build/potree/potree'
import Utils from '../../Utils'
import * as L from 'leaflet'

/**
 * Класс Line для создания линий
 */
class Line extends MultiPointMeasure {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Object} объект в котором зранятся свойства для создания объекта
   */
  constructor(vm, data = null) {
    super(vm, data)
    this.type = 'annotation-line'
    // Параметр в котором хранится potree profile
    this.profile = null
  }

  /**
   * @inheritDoc
   */
  createPotreeMeasure() {
    super.createPotreeMeasure()
    this.$potreeMeasure.closed = false
    this.$potreeMeasure.showAngles = this.vm.showAngleLabels
  }

  /**
   * @inheritDoc
   */
  updateCalculations() {
    if (this.points.length > 0) {
      if (this.$layer) {
        this.length2D = this.getPathLength(this.$layer)
      }
      if (this.$potreeMeasure) {
        this.length3D = this.$potreeMeasure.getTotalDistance()
        // Удаляем профайл и закрываем панель профайла при обновлении замера
        if (this.elevationProfile.length) {
          // Отмена всех запросов на просчет профайла
          window.viewer.profileWindowController.requests.map((request) =>
            request.cancel()
          )
          this.elevationProfile = []
          this.elevationProfileCoords = []
          if (this.profile) {
            // Удаляем со сцены отображение профайла
            window.viewer.scene.removeProfile(this.profile)
            this.profile = {}
          }
          this.vm.botSplitBarOpen = false
        }
      } else {
        this.area3D = null
        this.length3D = null
      }
    } else {
      console.warn('У замера нет точек для подсчётов', this)
    }
  }

  /**
   * Функция просчитывает Elevation Profile для замера
   * @return {undefined}
   */
  calculateElevationProfile() {
    // Проверка, есть ли у замера точки в потри
    if (this.$potreeMeasure) {
      const self = this
      // Трем старый профайл
      this.elevationProfile = []
      this.elevationProfileCoords = []
      if (this.profile) {
        // Удаляем со сцены отображение профайла при пересчете
        window.viewer.scene.removeProfile(this.profile)
        this.profile = {}
      }
      const profile = new Profile()
      // объект с параметрами профайла
      const profileOptions = this.vm.elevatorOptions
      // Ширина профайла
      profile.width = profileOptions.width
      this.$potreeMeasure.points.map((point) =>
        profile.addMarker(
          new Vector3(point.position.x, point.position.y, point.position.z)
        )
      )
      // FIXME не могу обращаться к this.vm.pointcloud, не начинает считать
      const pco = window.viewer.scene.pointclouds[0]
      // pwc нужен для того чтобы выхватить число treshold которое ограничивает пересчет профайла
      const pwc = window.viewer.profileWindowController
      // numPoints считает сколько точек для профайла мы выгрузили и помогает ограничить постоянную загрузку профайла
      pwc.numPoints = 0
      // threshold ограничивает максимальное количество точек в профайле
      // FIXME: число подобрано вручную
      pwc.threshold = profileOptions.density * 30

      // В итоге готовую сущность potree профайла грузим в параметры замера, чтобы можно было его отобразить на 3д карте
      this.profile = profile
      // Убираем сферы-точки чтобы его нельзя было перемещать (профайл это отдельный тип замера в potree)
      this.profile.children
        .filter((object) => object.type === 'Mesh')
        .map((object) => {
          object.visible = false
        })
      // Добавляем на сцену
      if (this.vm.elevatorOptions.showProfile) {
        if (self.vm.viewer) {
          self.vm.viewer.scene.addProfile(profile)
        }
      }

      // показываем, что туллбар грузится
      this.vm.loadings.elevationToolbar = true
      // request для того чтобы остановить пересчет профайла
      const request = pco.getPointsInProfile(profile, null, {
        onProgress: (event) => {
          // получили первые данные, делать вид, что грузимся больше не надо
          this.vm.loadings.elevationToolbar = false
          this.elevationData = event
          this.elevationData.points.segments.map((segment) => {
            for (let i = 0; i < segment.points.numPoints; i++) {
              // Проверяем точки на совпадение с уже имеющимися
              let x = true
              this.elevationProfile.map((point) => {
                if (
                  segment.points.data.mileage[i] === point[0] &&
                  segment.points.data.position[i * 3 + 2] === point[1]
                ) {
                  x = false
                }
              })
              if (x) {
                // Массив точек графика профайла
                this.elevationProfile.push([
                  segment.points.data.mileage[i],
                  segment.points.data.position[i * 3 + 2],
                ])
                // дополнительный массив с точками у которых три координаты, для дальнейшего отображения положения мышки на профайле в поинтклауде
                this.elevationProfileCoords.push([
                  segment.points.data.position[i * 3],
                  segment.points.data.position[i * 3 + 1],
                  segment.points.data.position[i * 3 + 2],
                ])
              }
            }
          })
          // Подсчет сколько точек навыгружали через getPointsInProfile
          for (const segment of event.points.segments) {
            pwc.numPoints += segment.points.numPoints
          }
          // Сверка количества точек с treshold и отправка запроса на остановку прогрузки elevationProfile
          if (pwc.numPoints > pwc.threshold) {
            request.finishLevelThenCancel()
            this.finished = true
            console.warn('Просчет профайла был завершен')
          }
        },
        onFinish: () => {},
        onCancel: () => {
          console.warn('Просчет профайла был остановлен')
          // Удаление реквеста из списка реквестов при отмене
          const index = pwc.requests.indexOf(request)
          if (index >= 0) {
            pwc.requests.splice(index, 1)
          }
        },
      })
      // Отправка запроса в общий пул запросов на просчет профайла в profileWindowController
      pwc.requests.push(request)
    } else {
      console.error('У замера отсутствуют точки в 3д')
    }
  }

  /**
   * @inheritDoc
   */
  placeLabelsOnWorkingLayer(layer, formatter, visible, map, drawMode) {
    layer.on('pm:vertexadded', (vertex) => {
      if (layer._latlngs.length === 1) {
        layer.showMeasurements({
          formatDistance: formatter,
        })
        if (!layer._measurementOptions) {
          layer._measurementOptions = {}
        }
        layer._measurementOptions.showMeasures = visible
      } else {
        layer.updateMeasurements()
      }
      map.on('mousemove', (mouse) => {
        if (drawMode) {
          if (visible && layer._latlngs.length > 0) {
            if (layer._tempMeasure) {
              layer._tempMeasure.remove()
            }
            const ll1 = layer._latlngs[layer._latlngs.length - 1]
            const ll2 = mouse.latlng
            const dist = Utils.distanceTo(ll1, ll2)
            const p1 = map.latLngToLayerPoint(ll1)
            const p2 = map.latLngToLayerPoint(ll2)
            layer._tempMeasure = L.marker
              .measurement(
                map.layerPointToLatLng([(p1.x + p2.x) / 2, (p1.y + p2.y) / 2]),
                formatter(dist),
                'Segment length',
                0,
                {}
              )
              .addTo(layer._measurementLayer, true)
          }
        }
      })
    })
  }

  /**
   * @inheritDoc
   */
  get isElevationProfile() {
    return true
  }

  /**
   * @inheritDoc
   */
  get defaultLabel() {
    return this.vm.$gettext('Line')
  }
}

export { Line }
