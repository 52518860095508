<template>
  <div class="container project-wrapper-container">
    <div
      class="row project-wrapper"
      :v-page-guide="'Select a task within the project' | translate"
    >
      <div class="col-12 p-0">
        <div
          v-if="projectInfo"
          class="project-wrapper-header"
        >
          {{ projectInfo.name }}
          <div
            v-if="projectInfo.created_at"
            class="project-wrapper-date"
          >
            {{ projectInfo.created_at | moment("dddd, MMMM Do YYYY, h:mm") }}
          </div>
        </div>
      </div>
      <div
        v-if="projectInfo"
        class="col-12 project-wrapper-description"
      >
        {{ projectInfo.description }}
      </div>

      <div class="col-12 p-0">
        <div class="container-fluid">
          <div class="row justify-content-center mb-2">
            <div
              v-for="task in tasks"
              :key="task.id"
              class="col-12 col-md-6 col-lg-3"
            >
              <transition
                name="fade"
                mode="out-in"
              >
                <router-link :to="{name: 'task-viewer', params: {projectId: task.project, taskId: task.id}}">
                  <div class="project-box-header">
                    {{ task.name }}
                    <div class="project-box-date">
                      {{ task.created_at | moment("dddd, MMMM Do YYYY, h:mm") }}
                    </div>
                  </div>
                </router-link>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :v-page-guide="'Click anywhere to hide tooltips' | translate"
      class="close-guide"
    />
    <v-page-guide v-model="showGuide" />
  </div>
</template>

<script>
import VPageGuide from 'vue-page-guide/src/components/VPageGuide'

export default {
  name: 'ProjectDetails',
  components: {
    VPageGuide
  },
  props: {
    projectId: {
      type: Number,
      default() {
        return parseInt(this.$route.params.projectId)
      }
    }
  },
  data() {
    return {
      projectInfo: null,
      tasks: null,
      showGuide: false,
    }
  },
  mounted() {
    this.loadProjectInfo()
    this.loadTasks()
  },
  methods: {
    async loadProjectInfo() {
      try {
        const response = await this.$http.get(`projects/${this.projectId}/`)
        this.projectInfo = response.data

        // this.projectInfo.description = 'Какое то описание проекта Какое то описание проекта Какое то описание проекта'
      } catch (e) {
        console.error(`Не удалось загрузить информацию о проекте ${this.projectId}`, e)
      }
    },
    async loadTasks() {
      try {
        const response = await this.$http.get(`projects/${this.projectId}/tasks/`)
        this.tasks = response.data
        this.showGuide = false
      } catch (e) {
        console.error(`Не удалось загрузить задачи проекта ${this.projectId}`, e)
      }
    }
  }
}
</script>

<style scoped>

  .project-wrapper-container{
    padding-top: 63px;
  }
  .project-wrapper{
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .project-wrapper-header {
    padding: 10px;
    text-align: center;
    background-color: rgb(29, 29, 29);
    color: #d6d6d6;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .project-wrapper-date {
    font-weight: normal;
    font-size: 1rem;
  }

  .project-wrapper-description{
    padding-top: 30px;
    padding-bottom: 15px;
    font-size: 1rem;
  }


  a {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    background-image: linear-gradient(45deg, #8baaaa 0%, #ae8b9c 100%);
    border-radius: 4px;
    margin: 15px 0;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  }

  a:hover {
    text-decoration: none;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .project-box-header {
    padding: 10px;
    text-align: center;
    background-color: rgba(29, 29, 29, 0.5);
    color: #d6d6d6;
    font-weight: bold;
    font-size: 1rem;
  }

  .project-box-date {
    font-weight: normal;
    font-size: .8rem;
  }


  .fade-enter-active, .fade-leave-active {
    transition: all .5s;
  }

  .fade-enter, .fade-leave-to {
    transform: scale(0.7);
    opacity: 0;
  }

</style>
