<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <div class="container">
          <div class="copyright">
            &copy; 2017-{{ new Date() | moment('YYYY') }},
            <span>
              <translate>Coded with</translate>
              <i class="fa fa-heart heart">&nbsp;</i> by
            </span>
            <a href="https://aetalon.tech" target="_blank">Aetalon Tech</a>.
          </div>
        </div>
        <!--<nav class="footer-nav">-->
        <!--<ul>-->
        <!--<li>-->
        <!--<a href="https://www.creative-tim.com" target="_blank">Creative Tim</a>-->
        <!--</li>-->
        <!--<li>-->
        <!--<a href="http://blog.creative-tim.com/" target="_blank">Blog</a>-->
        <!--</li>-->
        <!--<li>-->
        <!--<a href="https://www.creative-tim.com/license" target="_blank">Licenses</a>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</nav>-->
        <!--<div class="credits ml-auto">-->
        <!--<div class="copyright">-->
        <!--&copy; Coded with-->
        <!--<i class="fa fa-heart heart"></i> by-->
        <!--<a href="https://github.com/cristijora" target="_blank">Cristi Jora</a>.-->
        <!--Designed by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" target="_blank">Creative Tim</a>.-->
        <!--</div>-->
        <!--</div>-->
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'MainFooter',
}
</script>
<style></style>
