<template>
  <div class="row">
    <!--          projects table open-->
    <div class="col-12 pt-md-3">
      <el-table
        :data="tasks"
        row-class-name="custom-el-table-row"
        style="width: 100%"
      >
        <el-table-column
          :label="'Project' | translate"
          prop="name"
        />
        <el-table-column
          :label="'Create date' | translate"
        >
          <template slot-scope="scope">
            {{ scope.row.created_at| moment("DD.MM.YYYY, hh:mm") }}
          </template>
        </el-table-column>
        <el-table-column
          :label="'Data type' | translate"
          prop="data_type"
        >
          <template slot-scope="scope">
            <el-tooltip
              :class="checkDataType(scope.row, 'orthophoto.mbtiles')"
              effect="dark"
              :content="'Orthophoto' | translate"
              placement="top"
              :popper-class="checkDataType(scope.row, 'orthophoto.mbtiles') ? 'absent-data-type' : ''"
              :visible-arrow="false"
            >
              <i
                class="icon nova-map icon-xl p-1"
              />
            </el-tooltip>
            <el-tooltip
              :class="checkDataType(scope.row, 'dsm.tif')"
              effect="dark"
              :content="'DSM' | translate"
              placement="top"
              :popper-class="checkDataType(scope.row, 'dsm.tif') ? 'absent-data-type' : ''"
              :visible-arrow="false"
            >
              <i
                class="icon nova-blend icon-xl p-1"
              />
            </el-tooltip>
            <el-tooltip
              :class="checkDataType(scope.row, 'georeferenced_model.laz')"
              effect="dark"
              :content="'Point cloud' | translate"
              placement="top"
              :popper-class="checkDataType(scope.row, 'georeferenced_model.laz') ? 'absent-data-type' : ''"
              :visible-arrow="false"
            >
              <i
                class="icon nova-apps icon-xl p-1"
              />
            </el-tooltip>
            <el-tooltip
              :class="checkDataType(scope.row, 'textured_model.zip')"
              effect="dark"
              :content="'Mesh' | translate"
              placement="top"
              :popper-class="checkDataType(scope.row, 'textured_model.zip') ? 'absent-data-type' : ''"
              :visible-arrow="false"
            >
              <i
                class="icon nova-globe icon-xl p-1"
              />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="'Actions' | translate"
          prop="actions"
          align="right"
        >
          <template slot-scope="scope">
            <div class="row justify-content-end">
              <div class="col-5 col-lg-2 p-0">
                <!--                        :to="{name: 'task-viewer', params: {projectId: scope.row.project, taskId: scope.row.id}}"-->
                <router-link
                  :to="{name: 'project-detail', params: {projectId: scope.row.id}}"
                >
                  <el-button
                    icon="icon nova-btn-play"
                    circle
                  />
                </router-link>
              </div>
              <div class="col-5 col-lg-2 p-0 d-flex align-items-center">
                <el-dropdown
                  class="m-0 d-flex align-items-center"
                  trigger="click"
                  :hide-on-click="false"
                >
                  <el-button
                    size="mini"
                    type="info"
                    icon="icon nova-dots icon-rotate-90"
                    circle
                  />
                  <el-dropdown-menu slot="dropdown">
                    <router-link
                      :to="{name: 'task-editor', params: {projectId: scope.row.project, taskId: scope.row.id}}"
                    >
                      <el-dropdown-item>
                        <translate>Edit</translate>
                      </el-dropdown-item>
                    </router-link>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--          projects table close-->
  </div>
</template>

<script>
import { Input, Button, ButtonGroup, Table, TableColumn, Dropdown, DropdownItem, DropdownMenu, Tooltip } from 'element-ui'

export default {
  components: {
    [Input.name]: Input,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tooltip.name]: Tooltip,
  },
  props: {
    currentProjectTasks: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {}
  },
  computed: {
    tasks: {
      get() {
        return this.currentProjectTasks
      }
    },
  },
  methods: {
    checkDataType(row, type) {
      const dataTypeProject = row.available_assets.includes(type)
      return !dataTypeProject ? 'no-layer' : ''
    },
    handleEdit(index, row) {
      console.log(index, row)
    },
    handleDelete(index, row) {
      console.log(index, row)
    },
  },
}
</script>

<style scoped>
  .el-dropdown-menu.el-popper > a {
    text-decoration: none;
    color: #000000;
  }
  .el-button:hover,
  .el-button:focus {
    color: #51cbce !important;
    background: transparent !important;
  }
  .el-table {
    color: #FFFFFF;
  }
  .el-dropdown {
    margin-left: 16px;
  }
  .tables {
    width: 100%;
  }
  .icon-xl {
    font-size: 1.4em;
  }
  .no-layer {
    opacity: 0.3;
    cursor: not-allowed;
  }
</style>
<style>
  .absent-data-type {
    text-decoration: line-through !important;
  }
  .custom-el-table-row {
    color: white;
  }
  .custom-el-table-row:hover > td {
    background-color: #737373 !important;
  }
</style>
