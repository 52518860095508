<template>
  <div
    v-nova-page-guide:sideBar
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="logo">
      <a class="simple-text logo-mini" aria-label="sidebar mini logo" href="/">
        <div class="logo-img">
          <img :src="logo" alt="" class="h-100" />
        </div>
      </a>
      <a class="simple-text logo-normal" href="/">
        {{ title }}
      </a>
    </div>
    <div ref="sidebarScrollArea" class="sidebar-wrapper">
      <slot />
      <ul v-nova-page-guide:leftPanelNav class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in filteredSidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, subIndex) in link.children"
              :key="subLink.name + subIndex"
              :link="subLink"
            />
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import 'perfect-scrollbar/dist/css/perfect-scrollbar.css'
export default {
  props: {
    title: {
      type: String,
      default: 'nVision',
      description: 'Sidebar title',
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        const acceptedValues = ['white', 'brown', 'black']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar background color (white|brown|black)',
    },
    activeColor: {
      type: String,
      default: 'danger',
      validator: (value) => {
        const acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar active text color (primary|info|success|warning|danger)',
    },
    logo: {
      type: String,
      default: '/static/img/logo-black.png',
      // default: '/img/icons/nova-logo-white.svg',
      // default: 'static/img/logo.png',
      description: 'Sidebar Logo',
    },
    sidebarLinks: {
      type: Function,
      default: () => {},
      description:
        'Sidebar links. Can be also provided as children components (sidebar-item)',
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  computed: {
    /**
     * Фильтруем ссылки для не суперюзеров
     * @returns {T[]|*[]}
     */
    filteredSidebarLinks() {
      if (!this.$auth.user().is_superuser) {
        const linksWithoutSuperuser = this.sidebarLinks.apply(this).filter((item) => {
          return !Object.prototype.hasOwnProperty.call(item, 'superuser')
        })
        return linksWithoutSuperuser
      } else {
        return this.sidebarLinks.apply(this)
      }
    },
  },
  mounted() {
    this.initScrollBarAsync()
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
  methods: {
    async initScrollBarAsync() {
      const isWindows = navigator.platform.startsWith('Win')
      if (!isWindows) {
        return
      }
      const PerfectScroll = await import('perfect-scrollbar')
      PerfectScroll.initialize(this.$refs.sidebarScrollArea)
    },
  },
}
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
.logo {
  height: 90px;
}
.sidebar .logo,
.off-canvas-sidebar .logo {
  padding: 20px 0.7rem !important;
}
.sidebar .logo .simple-text .logo-img {
  border-radius: 0 !important;
  background: bottom !important;
}
</style>
