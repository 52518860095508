<template>
  <div
    class="form-group"
    :class="[
      {'input-group': hasIcon},
      {'has-danger': error},
      {'input-group-focus': focused},
      {'has-label': label || $slots.label},
      {'has-success': hasSuccess}]"
  >
    <slot name="label">
      <label
        v-if="label"
        :class="labelClasses"
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >*</span>
      </label>
    </slot>


    <div
      v-if="addonLeftIcon || $slots.addonLeft"
      class="input-group-prepend"
    >
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon" />
        </slot>
      </span>
    </div>
    <slot>
      <input
        :value="value"
        v-bind="$attrs"
        class="form-control"
        :required="required"
        :class="[{'is-valid': hasSuccess}, inputClasses, {'is-invalid': error}]"
        aria-describedby="addon-right addon-left"
        v-on="listeners"
      >
    </slot>
    <div
      v-if="addonRightIcon || $slots.addonRight"
      class="input-group-append"
    >
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon" />
        </slot>
      </span>
    </div>
    <slot name="infoBlock" />
    <slot name="helpBlock">
      <div
        v-if="error"
        class="invalid-feedback error-text"
        :class="{'mt-2': hasIcon}"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'FgInput',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    // eslint-disable-next-line vue/require-default-prop
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    // eslint-disable-next-line vue/require-default-prop
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    // eslint-disable-next-line vue/require-default-prop
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    // eslint-disable-next-line vue/require-default-prop
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    // eslint-disable-next-line vue/require-default-prop
    addonRightIcon: {
      type: String,
      description: 'Addon right icon'
    },
    // eslint-disable-next-line vue/require-default-prop
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon'
    }
  },
  data() {
    return {
      touched: false,
      focused: false,
      hadError: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      }
    },
    hasSuccess() {
      return this.hadError && this.touched && !this.error
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined
    }
  },
  created() {
    this.$watch('error', (newVal) => {
      if (newVal) {
        this.hadError = true
      }
    }, { immediate: true })
  },
  methods: {
    updateValue(evt) {
      const value = evt.target.value
      if (!this.touched && value) {
        this.touched = true
      }
      this.$emit('input', value)
    },
    onFocus(value) {
      this.focused = true
      this.$emit('focus', value)
    },
    onBlur(value) {
      this.focused = false
      this.$emit('blur', value)
    }
  }
}
</script>
<style scoped>
.error-text {
  display: block;
}
</style>
