/**
 * @file Определяет  класс Measure
 */
import uuidv4 from 'uuid/v4'
import { SVGIcon } from 'leaflet-svgicon'
import * as utm from 'utm'
import {
  DEFAULT_FILL_OPACITY,
  MEASURE_ICON,
  MODE_3D,
  DISTANCE_FOR_CAMERA_CENTERING,
} from 'src/components/Nova/constants'
import Utils from '@nova/Classes/Utils'
import * as THREE from 'three'
import * as Potree from '@letsnova/potree/build/potree/potree'
import { LatLng } from 'leaflet/dist/leaflet-src.esm'
import Circle3D from '@nova/Classes/3D/Circle3D'
import Rectangle3D from '@nova/Classes/3D/Rectangle3D'

/**
 * Класс Measure - основной класс в котором хранится вся информация о замере, как 2D, так и 3D
 */
class Measure {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Object} объект в котором зранятся свойства для создания объекта
   */
  constructor(vm, data = null) {
    if (data === null) {
      data = {}
    }
    this.name = data.name || null
    this.description = data.description || null
    this._color = data.color || null
    this.type = data.type || null
    this.points = data.points || []
    this.points = this.points.map((point) => new LatLng(point.lat, point.lng, point.alt))
    this._visibility = true
    this.vm = vm
    this.$layer = data.$layer || null
    this.$potreeMeasure = data.$potreeMeasure || null
    this._uuid = uuidv4()
    this._opacity = data.opacity || 1.0

    this.isNew = data.isNew || false

    this._volume = data.volume || null

    this._volumeLock = false
    this._volumeNeedUpdate = false

    this._potreeMesh = null

    this.profile = data.profile || null

    if (data.$annotation) {
      this.$annotation = data.$annotation
    } else {
      console.error('В замере не указана ссылка на Annotation', this, data)
    }

    if (this.$layer) {
      this.$layer.$measure = this
    }

    this.length2D = data.length2D || null
    this.length3D = data.length3D || null
    this.area2D = data.area2D || null
    this.area3D = data.area3D || null
    this.volume = data.volume || null
    this.elevationProfile = data.elevationProfile || []
    // Массив с точками с тремя координатами, для отображения в potreeViewer текущего положения указателя мыши на графике профайла
    this.elevationProfileCoords = data.elevationProfileCoords || []
    // Настройки расчета профайла
    this.elevationProfileOptions = data.elevationProfileOptions || {
      width: 1,
      density: 100,
    }
  }

  /**
   * Функция для определения высот у точек замера
   * @return {Promise<void>}
   */
  async fixAltitudes() {
    if (this.vm.pointcloud) {
      await Utils.fixPointsZ(this.vm.pointcloud, this.pointsWithoutAltitude2D, this.vm)
      this.updateCalculations()
    } else {
      console.warn(
        'Не удалось пофиксить высоты, так как отсутствует pointcloud для расчетов',
        this
      )
    }
  }

  /**
   * Функция для создания 3D замера текущего замера
   * @return {undefined}
   */
  createPotreeMeasure() {
    this.$potreeMeasure = new Potree.Measure()
    this.setUpPotreeMeasure()

    if (this.checkPotreeMeasureIsCorrect(Potree.Measure)) {
      this.vm.viewer.scene.addMeasurement(this.$potreeMeasure)
    } else {
      console.error('Недопустимый тип замера', this.$potreeMeasure)
    }

    // Добавляем точки
    this.points3D.map((p) => this.$potreeMeasure.addMarker(p))
  }

  /**
   * Функция проверяет является ли this.$potreeMeasure типом файла переданного в качестве аргумента
   * @param objectType {Object}
   * @return {boolean}
   */
  checkPotreeMeasureIsCorrect(objectType) {
    return this.$potreeMeasure instanceof objectType
  }

  /**
   * Функция устанавливает нужные параметры в 3D замере
   * @return {undefined}
   */
  setUpPotreeMeasure() {
    // Указываем разные параметры, чтобы не валилось в ошибку
    this.$potreeMeasure.lengthUnit = this.vm.viewer.lengthUnit
    this.$potreeMeasure.lengthUnitDisplay = this.vm.viewer.lengthUnitDisplay

    this.$potreeMeasure.$measure = this
    this.$potreeMeasure.$scene = this.vm.viewer.scene

    this.$potreeMeasure.name = this.label
    this.$potreeMeasure.color = new THREE.Color(this.color || '#ff0000')

    this.$potreeMeasure.visible = this.visibility

    // Указываем параметр отображения лейбла длины
    if (!this.vm.showMeasureLabels) {
      this.$potreeMeasure.showDistances = false
    }

    // Добавляем лейбл названия
    this.$potreeMeasure.nameLabel = new Potree.TextSprite(this.$potreeMeasure.name)
    this.$potreeMeasure.nameLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 0.8 })
    this.$potreeMeasure.nameLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 0.3 })
    this.$potreeMeasure.nameLabel.setTextColor({ r: 180, g: 220, b: 180, a: 1.0 })
    this.$potreeMeasure.nameLabel.material.depthTest = false
    this.$potreeMeasure.nameLabel.material.opacity = 1
    this.$potreeMeasure.nameLabel.visible = this.isNew ? false : this.vm.showTitleLabels
    this.$potreeMeasure.add(this.$potreeMeasure.nameLabel)

    this.$potreeMeasure.addEventListener('marker_added', this.vm.onMarkerAdded)
    this.$potreeMeasure.addEventListener('marker_moved', this.vm.onMarkerMoved)
    this.$potreeMeasure.addEventListener('marker_dropped', this.vm.onMarkerMoved)
    this.$potreeMeasure.addEventListener('marker_removed', this.vm.onMarkerRemoved)
  }

  /**
   * Функция центрирует камеру на замере
   * @return {undefined}
   */
  centerCamera() {
    if (this.vm.currentMode === MODE_3D) {
      const points = this.$potreeMeasure.points.map((p) => p.position)

      const box = new THREE.Box3()
      box.setFromPoints(points)
      const node3d = new THREE.Object3D()
      node3d.boundingBox = box
      if (points.length === 1) {
        node3d.boundingSphere = box.getBoundingSphere(new THREE.Sphere(this.points3D[0]))
        node3d.boundingSphere.radius = DISTANCE_FOR_CAMERA_CENTERING
      }
      this.vm.viewer.zoomTo(node3d, 1, 300)
      setTimeout(this.vm.scaleNameLabels, 300)
    } else if (this.vm.currentMode) {
      console.warn('Центрирование карты по замеру не реализовано в режиме 2D')
    }
  }

  /**
   * Функция делает 3Д часть замера недоступным для редактирования или наоборот
   * @param visibility {boolean}
   * @return {undefined}
   */
  changeSpheresVisibility(visibility) {
    if (this.$potreeMeasure && this.type !== 'annotation-marker') {
      // Поменял скрытие сфер маркеров в 3D
      this.$potreeMeasure.spheres.map((sphere) => {
        sphere.visible = visibility
      })
    } else {
      console.warn('У данного замера нет информации о potree', this)
    }
  }

  /**
   * Абстрактная функция для просчета Elevation Profile у замера
   */
  calculateElevationProfile() {
    console.error('Этот замер не может быть профилирован')
  }

  /**
   * Функция для удаления текущего замера из всех режимов и самого замера
   * @return {undefined}
   */
  remove() {
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        this.$layer.remove()
      }
      if (this.$potreeMeasure) {
        if (this.$potreeMeasure instanceof Potree.Profile) {
          this.$potreeMeasure.$scene.removeProfile(this.$potreeMeasure)
        } else if (
          this.$potreeMeasure instanceof Potree.Measure ||
          this.$potreeMeasure instanceof Circle3D ||
          this.$potreeMeasure instanceof Rectangle3D
        ) {
          this.$potreeMeasure.$scene.removeMeasurement(this.$potreeMeasure)
          // Проверка на наличие профайла и подчистка его данных
          if (this.profile) {
            this.$potreeMeasure.$scene.removeProfile(this.profile)
          }
          // Закрытие панели профайла и очистка графика
          this.vm.botSplitBarOpen = false
          this.vm.elevatorChartOptions.series[0].data.length = 0
        } else {
          console.error('Попытка удалить недопустимый тип', this.$potreeMeasure)
        }
        // Данный код необходим для корректной работы тулбара.
        // Если замер новый, сбрасываем у замера Potree ссылку на Measure
        if (this.isNew) {
          delete this.$potreeMeasure.$measure
        }
      }
    } else {
      console.warn('Функционал удаления замера не реализован', this)
    }
    // обнуляем currentMeasure
    if (this.vm.currentMeasure === this) {
      this.vm.currentMeasure = null
    }
    if (this.vm.selectedInspectorItem === this) {
      this.vm.selectedInspectorItem = null
    }
    const measureIndex = this.$annotation.measures.indexOf(this)
    // Удаляем элемент из замеров аннотации
    if (measureIndex !== -1) {
      this.$annotation.measures.splice(measureIndex, 1)
    }
  }

  /**
   * Функция для проставновки цвета замера
   * @param color {String} - цвет замера
   */
  setLayerColor(color) {
    // проставляем выбранные цвета, как цвета по умолчанию
    // this.$layer.options = this.$layer.options || {opacity: 0.4}
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        if (this.type === 'annotation-marker') {
          // Обновляем цвет маркера
          const icon = this.$layer.getIcon()
          const options = icon.options
          delete options.html
          options.color = color
          options.circleColor = color
          options.fillColor = color
          options.circleFillColor = this.color
          this.$layer.setIcon(new SVGIcon(options))
        } else {
          this.$layer.options.fillColor = color
          this.$layer.options.color = color
          // и меняем цвет текущего слоя
          this.$layer.setStyle(this.$layer.options)
        }
      }
      if (this.$potreeMeasure) {
        const _color = new THREE.Color(color)
        this.$potreeMeasure.color = _color
        this.$potreeMeasure.spheres.map((sphere) => {
          sphere.material.color = _color
        })
      }
    } else {
      console.warn(
        'Нельзя изменить цвет замера, отсутстует объект $layer или $potreeMeasure',
        this
      )
    }
  }

  /**
   * Функция проставляем прозрачность заемру
   * @param opacity {Number} - прозрачность заемра от 0 до 1
   */
  setLayerOpacity(opacity) {
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        this.$layer.options.opacity = opacity
        // Вычисляем прозрачность заливки
        const fillOpacity = parseFloat(opacity) * DEFAULT_FILL_OPACITY
        this.$layer.options.fillOpacity = fillOpacity.toFixed(3)

        if (this.type === 'annotation-marker') {
          const icon = this.$layer.getIcon()
          const options = icon.options
          delete options.html
          options.fillOpacity = fillOpacity
          options.opacity = opacity
          options.circleFillOpacity = opacity
          options.circleOpacity = opacity
          this.$layer.setIcon(new SVGIcon(options))
        } else {
          // применяем новый стиль
          this.$layer.setStyle(this.$layer.options)
        }
        // debugger
      }
      if (this.$potreeMeasure) {
        console.error(
          'Нельзя изменить прозрачность замера, функционал не реализован в режиме 3D',
          this
        )
        // this.$potreeMeasure.color = new THREE.Color(color)
      }
    } else {
      console.warn(
        'Нельзя изменить прозрачность замера, отсутстует объект $layer или $potreeMeasure',
        this
      )
    }
  }

  /**
   * Подтягивание длины замера
   * @param layer - замер в лиафлете
   * @return {*} - длина
   */
  getPathLength(layer) {
    // return points.reduce((acc, point) => {
    //   if (typeof acc === 'object' && acc.last !== null) {
    //     acc.distance += distanceFn(point, acc.last, 0.01)
    //   }
    //   acc.last = point
    //   return acc
    // }, { last: null, distance: 0 }).distance
    return layer._measurementLayer.options.totalDist
  }

  /**
   * Подтягивание значения площади
   * @param layer - замер в лиафлете
   * @return {*} - площадь
   */
  getArea(layer) {
    return layer._measurementLayer.options.area
  }

  /**
   * Абстракный метод для просчета всех параметров замера
   */
  updateCalculations() {
    throw new Error('You have to implement updateCalculations')
  }

  /**
   * Функция возврашающая куб в 3D по текущим точкам замера и определнной высоты
   * @param minZ {Number} - минимальная высота
   * @param maxZ {Number} - максимальная высота
   * @return {Box3}
   */
  createBoxFromMeasure(minZ = null, maxZ = null) {
    const box = new THREE.Box3()
    box.setFromPoints(this.points3D)

    // если проставили максимальную или минимальную высоту бокса, то проставим его

    box.max.z = maxZ || box.max.z
    box.min.z = minZ || box.min.z

    this.box = box

    return box
  }

  createMesh() {
    console.warn('Данный метод находится в стадии разработки')

    const geometry = new THREE.BoxBufferGeometry(100, 100, 100)
    // geometry.addAttribute('position', new THREE.BufferAttribute(new Float32Array(this.getCoordinatesArrayOnPointCloud), 3))
    // geometry.computeBoundingBox()
    // let center = geometry.boundingBox.getCenter()
    const center = this.viewer.scene.pointclouds[0].boundingBox.getCenter()

    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 }) //, transparent: true, opacity: 1

    this.mesh = new THREE.Mesh(geometry, material)

    this.mesh.position.x = center.x
    this.mesh.position.y = center.y
    this.mesh.position.z = center.z

    this.vm.viewer.scene.scene.add(this.mesh)
  }

  updatePosition() {
    console.warn('У класса не задан метод отвечающий за обновление положения в 3d')
  }

  /**
   * Подписывается на необходимые ивенты лиафлета и располагает леблы замера при рисовании
   * @param layer - workinglayer
   * @param formatter - форматтер длины
   * @param visible - флаг переключения отображаются сейчас замеры или нет
   * @param map - leaflet map
   * @param drawMode - флаг переключения режима рисования
   */
  placeLabelsOnWorkingLayer(layer, formatter, visible, map, drawMode) {
    console.warn(
      'Необходимо определить методы отвечающие за вывод лейблов длин при рисовании'
    )
  }

  /**
   * Возвращает данные по объекту для экспорта замера
   * @return {Object}
   */
  get exportData() {
    return {
      type: this.type,
      name: this.name,
      description: this.description,
      color: this._color,
      points: this.points,
      volume: this.volume,
      length2D: this.length2D,
      length3D: this.length3D,
      area2D: this.area2D,
      area3D: this.area3D,
      opacity: this._opacity,
      elevationProfileOptions: this.elevationProfileOptions,
    }
  }

  get $ref() {
    return this
  }

  /**
   * Возврашает текущие параметры в меню
   * @return {Object}
   */
  get menu() {
    return {
      save: true,
      del: true,
      opacity: this._opacity,
    }
  }

  /**
   * Возвращает виден ли замер
   * @return {boolean}
   */
  get visibility() {
    return this._visibility
  }

  /**
   * Устанавливает значение видимости для замера
   * @param value {boolean}
   */
  set visibility(value) {
    if (value) {
      this.vm.showMeasure(this)
    } else {
      this.vm.hideMeasure(this)
    }
    this._visibility = value
  }

  /**
   * Возвращает иконку объекта
   * @return {String}
   */
  get icon() {
    return MEASURE_ICON[this.type]
  }

  /**
   * Возвращает лейбл для замера
   * @return {String}
   */
  get label() {
    return this.name ? this.name : this.defaultLabel
  }

  /**
   * Абстрактное свойство
   */
  get defaultLabel() {
    throw new Error('You have to implement defaultLabel')
  }

  /**
   * Возвращает уникальный номер аннотации
   * @return {uuidv4}
   */
  get treeNodeId() {
    return this._uuid
  }

  /**
   * Возвращает цвет замера
   * @return {String}
   */
  get color() {
    return this._color || '#17f589'
  }

  /**
   * Устанавливает цвет замера
   * @param value {String}
   */
  set color(value) {
    if (value !== this.color) {
      this._color = value
      if (this.vm.currentMeasure !== this) {
        this.setLayerColor(this._color)
      } else if (this.type === 'annotation-marker' && this.$layer) {
        const icon = this.$layer.getIcon()
        const options = icon.options
        delete options.html
        options.circleFillColor = value
        this.$layer.setIcon(new SVGIcon(options))
      }
    }
  }

  /**
   * Возвращает текущую прозрачность замера
   * @return {number}
   */
  get opacity() {
    return this._opacity
  }

  /**
   * Устанавливает значение прозрачности замера
   * @param value {Number}
   */
  set opacity(value) {
    if (value !== this._opacity) {
      this._opacity = value
      if (this.vm.currentMeasure !== this) {
        this.setLayerOpacity(this._opacity)
      }
    }
  }

  /**
   * Возвращает нужно ли для данного замера считать Elevation Profile
   * @return {boolean}
   */
  get isElevationProfile() {
    return this.type === 'annotation-line'
  }

  /**
   * Возвращает точки в 3D для текущего замера
   * @return {Vector3[]}
   */
  get points3D() {
    return this.points.map((point) => {
      const point3D = utm.fromLatLon(point.lat, point.lng)
      return new THREE.Vector3(point3D.easting, point3D.northing, point.alt)
    })
  }

  /**
   * Возвращает точки без высот для текущего замера
   * @return {Array.<LatLng>}
   */
  get pointsWithoutAltitude() {
    return this.points.filter((point) => {
      return point.alt === undefined
    })
  }

  /**
   * Возвращает точки без высот в виде вектора Vector2
   * @return {Vector2[]}
   */
  get pointsWithoutAltitude2D() {
    return this.pointsWithoutAltitude.map((point) => {
      const point3D = utm.fromLatLon(point.lat, point.lng)
      const vector = new THREE.Vector2(point3D.easting, point3D.northing)
      vector.rawPoint = point
      return vector
    })
  }

  /**
   * Функция для проставления точек текущих замеров из данных в 3D замере
   * @param points3D {Array.<Object>} - массив объектов у которых в свойстве position хранится Vector3
   * @return {undefined}
   */
  set points3D(points3D) {
    // Фильтрация задуюлированных точек
    let lastPoint = null
    const points = points3D
      .map((p) => p.position || p)
      .filter((p) => !(p.x === 0 && p.y === 0 && p.z === 0))
      .filter((p) => {
        if (
          lastPoint === null ||
          p.x !== lastPoint.x ||
          p.y !== lastPoint.y ||
          p.z !== lastPoint.z
        ) {
          lastPoint = p
          return true
        }
      })
    this.points = points.map((p) => {
      const result = utm.toLatLon(
        p.x,
        p.y,
        this.vm.utmZoneNum,
        this.vm.utmZoneLetter,
        undefined,
        false
      )
      return new LatLng(result.latitude, result.longitude, p.z)
    })
    this.updateCalculations()
  }
}

export { Measure }
