export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  /**
   * Блокировка пользователя
   * @param userId id профайла пользователя в компании
   * @param companyId id компании
   * @param username имя пользователя
   * @return Boolean заблокирован или нет
   */
  async block({ state }, { userId, companyId, username }) {
    try {
      // Получаем все проекты пользователя в организации
      let response = await window.AXIOS_INSTANCE.get(
        `/organizations/${companyId}/projects/`
      )
      const projects = response.data.results.map((project) => {
        return project.id
      })
      // ЗАпрос на блокировку пользователя во всех проектах
      response = await window.AXIOS_INSTANCE.post(
        `/organizations/${companyId}/user-project-blocks/`,
        {
          projects: projects,
          blocked_users: [userId],
          comment: 'block ' + username,
        }
      )
      if (response.status === 201) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.error('Не получилось заблокировать пользователя', e)
      return false
    }
  },
  /**
   * Разблокировка пользователя
   * @param userId id профайла пользователя в компании
   * @param companyId id компании
   * @return Boolean заблокирован или нет
   */
  async unblockUser({ state }, { userId, companyId }) {
    let result = false
    try {
      // Получаем список блокировок
      let response = await window.AXIOS_INSTANCE.get(
        `/organizations/${companyId}/user-project-blocks/`
      )
      // Ищем среди блокировок текущего пользователя
      response.data.results.forEach((block) => {
        block.blocked_users.forEach(async (user) => {
          if (user === userId) {
            // Для каждой блокировки снимаем ее
            response = await window.AXIOS_INSTANCE.delete(
              `/organizations/${companyId}/user-project-blocks/${block.id}/`
            )
            if (response.status === 204) {
              result = false
            } else {
              result = true
            }
          }
        })
      })
    } catch (e) {
      console.error('Не получилось разблокировать пользователя', e)
      result = false
    }
    return result
  },
  /**
   * Отправка запроса на смену пароля
   * @param companyId - id компании
   * @param email - емэйл пользователя
   */
  async changePassword({ state }, { companyId, email }) {
    try {
      const response = await window.AXIOS_INSTANCE.post(
        `/organizations/${companyId}/users/forgot-password/`,
        {
          email: email,
        }
      )
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.error('Не удалось запросить смену пароля', e)
      return false
    }
  },
}
