/**
 * @file Определяет  класс Polygon
 */
import { MultiPointMeasure } from './MultiPointMeasure'
import Utils from '../../Utils'
import * as L from 'leaflet'
import * as THREE from 'three'
import { STANDART_DENSITY_ITEMS } from '../../../constants'
import Delaunator from 'delaunator'

class Polygon extends MultiPointMeasure {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Object} объект в котором зранятся свойства для создания объекта
   */
  constructor(vm, data = null) {
    if (data === null) {
      data = {}
    }
    super(vm, data)
    this.type = 'annotation-polygon'
    // Флаг отвечающий за отображение меша подсвечивающего точки в полигоне
    this._showMesh = false
    // Оффсеты bounding box для просчета точек в полигоне
    this._minZ = data.minZ || 10
    this._maxZ = data.maxZ || 10
    // Плотность и масса
    this._density = data.density || {}
    this._mass = data.mass || null
  }

  /**
   * @inheritDoc
   */
  createPotreeMeasure() {
    super.createPotreeMeasure()
    if (this.vm.showMeasureLabels) {
      this.$potreeMeasure.showArea = true
    }
  }

  /**
   * @inheritDoc
   */
  updateCalculations() {
    if (this.points.length > 0) {
      if (this.$layer) {
        this.length2D = this.getPathLength(this.$layer)
        this.area2D = this.getArea(this.$layer)
      }
      if (this.$potreeMeasure) {
        this.area3D = this.$potreeMeasure.getArea()
        this.length3D = this.$potreeMeasure.getTotalDistance()
        this._volumeNeedUpdate = true
        // При изменении полигона удаляем меш
        if (this.mesh) {
          this.vm.viewer.scene.scene.remove(this.mesh)
        }
        // При изменении полигона удаляем фрейм
        if (this.frame) {
          this.vm.viewer.scene.scene.remove(this.frame)
          this.drawFrameBox()
        }
      } else {
        this.area3D = null
        this.length3D = null
      }
    } else {
      console.warn('У замера нет точек для подсчётов', this)
    }
  }

  /**
   * Просчитывает объем текущего замера и сохранят в свойство volume
   * @return {Promise<void>}
   */
  async calculateVolume() {
    if (!this._volumeLock) {
      this._volumeNeedUpdate = false
      this._volumeLock = true
      const zCenter = this.zCenter()
      // получаем геометрию для текущего замера
      const geometry = await this.vm.getPointsInPolygon(
        this,
        zCenter - this.minZ,
        zCenter + this.maxZ
      )
      if (!geometry) {
        this._volumeNeedUpdate = true
        this._volumeLock = false
        return
      }

      const position = geometry.attributes.position
      const faces = position.count / 3
      let sum = 0

      const p1 = new THREE.Vector3()
      const p2 = new THREE.Vector3()
      const p3 = new THREE.Vector3()

      for (let i = 0; i < faces; i++) {
        p1.fromBufferAttribute(position, i * 3 + 0)
        p2.fromBufferAttribute(position, i * 3 + 1)
        p3.fromBufferAttribute(position, i * 3 + 2)
        sum += Polygon.signedVolumeOfTriangle(p1, p2, p3)
      }
      this.volume = sum
      this._volumeLock = false
      if (this.density.density) {
        this._mass = this.volume * this.density.density
      }
    }
  }

  /**
   * Функция рассчитывает площадь триугольника по 3-м точкам
   * @param p1 {Vector3}
   * @param p2 {Vector3}
   * @param p3 {Vector3}
   * @return {number}
   */
  static signedVolumeOfTriangle(p1, p2, p3) {
    const volume = p1.dot(p2.cross(p3)) / 6.0
    return typeof volume === 'number' ? volume : 0
  }

  /**
   * @inheritDoc
   */
  calculateElevationProfile() {
    console.error('Этот замер не может быть профилирован')
  }

  /**
   * @inheritDoc
   */
  placeLabelsOnWorkingLayer(layer, formatter, visible, map, drawMode) {
    layer.on('pm:vertexadded', (vertex) => {
      if (layer._latlngs.length === 1) {
        layer.showMeasurements({
          formatDistance: formatter,
        })
        if (!layer._measurementOptions) {
          layer._measurementOptions = {}
        }
        layer._measurementOptions.showMeasures = visible
      } else {
        layer.updateMeasurements()
      }
      map.on('mousemove', (mouse) => {
        if (drawMode) {
          if (visible && layer._latlngs.length > 0) {
            if (layer._tempMeasure) {
              layer._tempMeasure.remove()
            }
            const ll1 = layer._latlngs[layer._latlngs.length - 1]
            const ll2 = mouse.latlng
            const dist = Utils.distanceTo(ll1, ll2)
            const p1 = map.latLngToLayerPoint(ll1)
            const p2 = map.latLngToLayerPoint(ll2)
            layer._tempMeasure = L.marker
              .measurement(
                map.layerPointToLatLng([(p1.x + p2.x) / 2, (p1.y + p2.y) / 2]),
                formatter(dist),
                'Segment length',
                0,
                {}
              )
              .addTo(layer._measurementLayer, true)
          }
        }
      })
    })
  }

  /**
   * Рисование бокса в котором берется объем
   * @param minPoint - точка от которой отсчитывать координаты для бокса
   * @param zCenter - центр по оси Z
   */
  drawFrameBox(
    minPoint = this.$potreeMeasure.points[0].position.clone(),
    zCenter = this.zCenter()
  ) {
    // Получаем координаты минимума и максимума по высоте
    const minZ = zCenter - this.minZ
    const maxZ = zCenter + this.maxZ
    // Если есть фрейм - удаляем его
    if (this.frame) {
      this.vm.viewer.scene.scene.remove(this.frame)
    }
    // Создаем вершины, для каждой точки в полигоне рисуем квадрат
    // Квадрат рисуем по линиям - на каждую линию две вершины
    const vertices = []
    for (let i = 1; i <= this.points.length; i++) {
      let point = this.$potreeMeasure.points[i - 1].position.clone()
      // Берем координаты точки полигона по x и y
      // Все координаты берем относительно точки отсчета чтобы фрейм не лагал
      point.x -= minPoint.x
      point.y -= minPoint.y
      // Подставляем минимальный Z
      point.z = minZ - minPoint.z
      vertices.push(point.clone())
      // Вторая точка фрейма - прямо над первой в тех же x и y, но в максимальном Z
      point.z = maxZ - minPoint.z
      vertices.push(point.clone())
      // Копируем точку еще раз для второй линии
      vertices.push(point.clone())
      point = this.$potreeMeasure.points[i % this.points.length].position.clone()
      // Берем координаты по x и y следующей точки полигона
      point.x -= minPoint.x
      point.y -= minPoint.y
      // Подставляем максимальный Z
      point.z = maxZ - minPoint.z
      vertices.push(point.clone())
      // Копируем еще раз для третьей линии
      vertices.push(point.clone())
      // Третья линия - сверху вниз в координатах x и y следующей точки полигона
      point.z = minZ - minPoint.z
      vertices.push(point.clone())
      // Копируем еще раз для четвертой линии
      vertices.push(point.clone())
      // Конец четвертой линии в первой точке нашего квадрата
      vertices.push(vertices[vertices.length - 7].clone())
    }
    // Делаем геометрию для фрейма
    const boxFrameGeometry = new THREE.BufferGeometry().setFromPoints(vertices)
    this.frame = new THREE.LineSegments(
      boxFrameGeometry,
      new THREE.LineBasicMaterial({
        color: 'red',
        linewidth: 10,
      })
    )
    // Ставим фрейм по координатам точки отсчета
    this.frame.position.set(minPoint.x, minPoint.y, minPoint.z)
    // Отображаем если включено отображение меша
    this.frame.visible = this.showMesh
    this.vm.viewer.scene.scene.add(this.frame)
  }

  zCenter() {
    // Находим центр полигона чтобы было от чего отсчитывать оффсеты по высоте
    const centroid = new THREE.Vector3()
    for (let i = 0; i < this.points.length; i++) {
      const point = this.$potreeMeasure.points[i]
      centroid.add(point.position)
    }
    centroid.divideScalar(this.points.length)
    return centroid.z
  }

  /**
   * Подсветка точек внутри полигона
   * @param list - массив точек
   * @param minPoint - минимальная точка от которой считаем все координаты
   */
  highlightPoints(list, minPoint) {
    // Считаем точки относительно минимальной
    const highlightPoints = list.map((item) => {
      return {
        x: item.x - minPoint.x,
        y: item.y - minPoint.y,
        z: item.z - minPoint.z,
      }
    })
    // Строим геометрию
    const geom = new THREE.BufferGeometry().setFromPoints(highlightPoints)
    // Триангулируем по x и y
    const indexDelaunay = Delaunator.from(
      highlightPoints.map((v) => {
        return [v.x, v.y]
      })
    )

    const meshIndex = [] // delaunay index => three.js index
    for (let i = 0; i < indexDelaunay.triangles.length; i++) {
      meshIndex.push(indexDelaunay.triangles[i])
    }

    geom.setIndex(meshIndex) // add three.js index to the existing geometry
    geom.computeVertexNormals()
    const material = new THREE.MeshBasicMaterial({
      color: 'red',
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.4,
    })
    const mesh = new THREE.Mesh(geom, material)
    mesh.position.set(minPoint.x, minPoint.y, minPoint.z)
    mesh.visible = this.showMesh
    this.vm.viewer.scene.scene.add(mesh)
    this.mesh = mesh
  }

  /**
   * @inheritDoc
   */
  remove() {
    super.remove()
    if (this.frame) {
      this.vm.viewer.scene.scene.remove(this.frame)
    }
    if (this.mesh) {
      this.vm.viewer.scene.scene.remove(this.mesh)
    }
  }

  /**
   * @inheritDoc
   */
  get isElevationProfile() {
    return false
  }

  /**
   * @inheritDoc
   */
  get defaultLabel() {
    return this.vm.$gettext('Polygon')
  }

  /**
   * Получение флага показа меша
   * @return {boolean}
   */
  get showMesh() {
    return this._showMesh
  }

  /**
   * При присвоении флага показа меша - переключаем отображение
   * @param value
   */
  set showMesh(value) {
    this._showMesh = value
    if (this.mesh) {
      this.mesh.visible = value
    }
    if (this.frame) {
      this.frame.visible = value
    }
  }

  /**
   * Получение оффсета bounding box снизу
   * @return {number}
   */
  get minZ() {
    return parseInt(this._minZ)
  }

  /**
   * Присвоение оффсета bounding box снизу
   * @param value
   */
  set minZ(value) {
    if (-value >= this.maxZ) {
      return
    }
    this._minZ = value
    this._volumeNeedUpdate = true
    // При изменении полигона удаляем меш
    if (this.mesh) {
      this.vm.viewer.scene.scene.remove(this.mesh)
    }
    // При изменении оффсета перерисовываем фрейм
    if (this.frame) {
      this.drawFrameBox()
    }
  }

  /**
   * Получение оффсета bounding box сверху
   * @return {number}
   */
  get maxZ() {
    return parseInt(this._maxZ)
  }

  /**
   * Присвоение оффсета bounding box сверху
   * @param value
   */
  set maxZ(value) {
    if (-value >= this.minZ) {
      return
    }
    this._maxZ = value
    this._volumeNeedUpdate = true
    // При изменении полигона удаляем меш
    if (this.mesh) {
      this.vm.viewer.scene.scene.remove(this.mesh)
    }
    // При изменении оффсета перерисовываем фрейм
    if (this.frame) {
      this.drawFrameBox()
    }
  }

  /**
   * Получение текущей плотности
   * @returns {*|{}}
   */
  get density() {
    return this._density
  }

  /**
   * Установка плотности
   * @param value
   */
  set density(value) {
    this._density = value
    if (this.volume) {
      this._mass = this.volume * value.density
    }
  }

  /**
   * Получение текущей массы
   * @returns {null}
   */
  get mass() {
    return this._mass
  }

  /**
   * Выбор кастомного значения плотности
   * @param value
   */
  chooseCustomDensity(value) {
    if (!this.density.density) {
      this._density = STANDART_DENSITY_ITEMS.call(this.vm).find(
        (item) => item.density === 0
      )
    }
    this._density.density = value
    if (this.volume) {
      this._mass = this.volume * value
    }
  }

  /**
   * @inheritDoc
   */
  get exportData() {
    const data = super.exportData
    data.minZ = this.minZ
    data.maxZ = this.maxZ
    data.density = this.density
    data.mass = this.mass
    return data
  }

  /**
   * @inheritDoc
   */
  set visibility(value) {
    super.visibility = value
    if (this.frame) {
      this.frame.visible = value
    }
    if (this.mesh) {
      this.mesh.visible = value
    }
  }

  /**
   * @inheritDoc
   */
  get visibility() {
    return super.visibility
  }
}

export { Polygon }
