<template>
  <div>
    <div
      class="head-section d-flex justify-content-between flex-wrap"
    >
      <div class="col-3 mr-auto">
        <el-button
          v-if="$auth.user().is_superuser"
          circle
          icon="el-icon-set-up"
          @click="$router.push({ path: '/administrative' })"
        />
      </div>

      <!--      <el-breadcrumb separator="/">-->
      <!--        <el-breadcrumb-item :to="{ path: '/users' }">-->
      <!--          <translate>Users</translate>-->
      <!--        </el-breadcrumb-item>-->
      <!--      </el-breadcrumb>-->
      <div class="col-2 d-flex justify-content-end mb-2 pr-0">
        <el-button-group>
          <el-tooltip
            v-for="(languageItem, key) in $language.available"
            :key="key"
            :visible-arrow="false"
            :content="languageItem | translate"
            :open-delay="300"
            placement="top"
          >
            <el-button
              :class="{'activeLangBtn': language === key}"
              type="info"
              size="mini"
              @click="language = key"
            >
              {{ key.toUpperCase() }}
            </el-button>
          </el-tooltip>
        </el-button-group>
        <!--          <el-select-->
        <!--            v-model="language"-->
        <!--            class="select-primary select-lang"-->
        <!--            collapse-tags-->
        <!--          >-->
        <!--            <el-option-->
        <!--              v-for="(languageItem, key) in $language.available"-->
        <!--              :key="key"-->
        <!--              class="select-primary"-->
        <!--              :value="key"-->
        <!--              :label="languageItem"-->
        <!--            />-->
        <!--          </el-select>-->
      </div>
      <div
        class="col-1 d-flex justify-content-end"
        style="align-items: flex-end"
      >
        <notifications />
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from './Notifications'
import { Button, ButtonGroup, Select, Option, Tooltip } from 'element-ui'

export default {
  components: {
    Notifications,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      notifications: null,
      language: this.$language.current,
    }
  },
  watch: {
    language(newValue) {
      if (newValue) {
        localStorage.language = newValue
        this.$language.current = newValue
      }
    }
  },
}
</script>

<style scoped>
  .activeLangBtn {
    background-color: #297f7f !important;
    border-color: #297f7f !important;
    font-weight: 700;
  }
  /*crumbs and notify*/
  .head-section {
    position: relative;
    height: 90px;
    padding: 0 15px 10px;
    align-items: flex-end;
    text-transform: uppercase;
    margin: 0 auto;
  }
  .head-section:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.5);
  }
  .el-button--info {
    background: #747474 0 0 no-repeat padding-box;
    border-color: #747474;
  }
  .el-button-group .el-button--info:first-child {
    margin-right: 5px;
    border-right-color: #747474;
  }
  .el-button-group .el-button--info:last-child {
    border-left-color: #747474;
  }
  .el-button--success {
    background: #177F7F;
    border-color: #177F7F
  }
  .select-lang {
    width: 100%;
  }
</style>
