import * as THREE from 'three'
import * as Potree from '@letsnova/potree/build/potree/potree'
import { SELECTED_COLOR } from '@nova/constants'
import Utils from '@nova/Classes/Utils'
import { Measure } from '@letsnova/potree/src/utils/Measure'
import { Line } from '@nova/Classes/Common/Measures/Line'
import Rectangle from '@nova/Classes/Common/Measures/Rectangle'
import Circle from '@nova/Classes/Common/Measures/Circle'
import { Polygon } from '@nova/Classes/Common/Measures/Polygon'
import { Marker } from '@nova/Classes/Common/Measures/Marker'
import Camera from '@nova/Classes/Common/Measures/Camera'
import { debounce } from 'lodash'
import ColorGUIHelper from '@utils/ColorGUIHelper'
import DegRadHelper from '@utils/DegRadHelper'
import { GUI } from '@libs/dat.gui/dat.gui.module'

export default {
  data() {
    return {
      modelReference: null,
      // Инстанс 3D-объекта
      texturedMesh: null,
      elData: null,
      // Режим камеры
      cameraMode: false,
      // Текущая точка в инспеторе точек
      currentInspectorPoint: null,
      // Инстанс облака точек
      pointcloud: null,
      // Опции отображения профайла
      elevatorOptions: {
        width: 1,
        showProfile: false,
        density: 100,
        currentTooltipIndex: null,
        // опция блокировки зума 1 к 1 метру
        dataZoomLock: false,
        // ширина и высота баундинг бокса графика на экране в пикселях
        widthGraph: null,
        heightGraph: null,
        // плотность осей графика в метрах на пиксель
        scaleX: null,
        scaleY: null,
        // минимальные и максимальные значения осей
        minX: null,
        maxX: null,
        minY: null,
        maxY: null,
      },
      // График профайла
      elevatorChartOptions: {
        title: {},
        animation: false,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1,
            },
          },
          backgroundColor: 'rgba(30, 30, 29, 0.8)',
        },
        toolbox: {
          show: true,
          right: 5,
          bottom: 15,
          feature: {
            myZoomLockTool: {
              show: true,
              title: this.$gettext('Zoom Lock'),
              icon:
                'path://M203.2 75.2C217.6 94.4 225.6 116.8 225.6 142.4C225.6 204.8 176 256 113.6 256S0 204.8 0 142.4C0 80 51.2 28.8 113.6 28.8C139.2 28.8 163.2 36.8 180.8 51.2L228.8 3.2C232 0 236.8 -1.6 240 -1.6S248 0 251.2 3.2C257.6 9.6000000000001 257.6 19.2 251.2 25.6000000000001L203.2 75.2zM113.6 62.4C68.8 62.4 32 97.6 32 142.4S68.8 224 113.6 224S195.2 187.2 195.2 142.4S158.4 62.4 113.6 62.4z',
              onclick: this.toggleElevatorZoomLock,
              iconStyle: {
                color: null,
              },
            },
          },
        },
        axisPointer: {
          label: {
            backgroundColor: '#1d1d1d',
            shadowBlur: 0,
          },
        },
        grid: {
          left: '10px',
          right: '50px',
          top: '10px',
          bottom: '50px',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          axisLine: { show: false },
          // функции записи минимального и максимального значения по оси X
          min: this.getMinXAxis,
          max: this.getMaxXAxis,
          splitLine: {
            lineStyle: {
              color: '#d6d6d6',
              width: 2,
              type: 'dotted',
              opacity: 0.2,
            },
            show: false,
          },
          axisLabel: {
            formatter: '{value}',
            color: '#d6d6d6',
            fontWeight: 'bold',
          },
          axisTick: {
            show: false,
          },
        },

        yAxis: {
          type: 'value',
          axisLine: { show: false },
          // функции записи минимального и максимального значения по оси Y
          min: this.getMinYAxis,
          max: this.getMaxYAxis,
          splitLine: {
            lineStyle: {
              color: '#d6d6d6',
              width: 2,
              type: 'dotted',
              opacity: 0.2,
            },
            show: false,
          },
          axisLabel: {
            formatter: '{value}',
            color: '#d6d6d6',
            fontWeight: 'bold',
          },
          axisTick: {
            show: false,
          },
          scale: true,
        },

        dataZoom: [
          {
            id: 0,
            type: 'inside',
            xAxisIndex: [0],
            filterMode: 'empty',
          },
          {
            id: 1,
            type: 'inside',
            yAxisIndex: [0],
            filterMode: 'empty',
          },
          {
            id: 2,
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'empty',
            height: 8,
            bottom: 26,
            borderColor: 'transparent',

            fillerColor: '#1e1e1d',
            handleIcon:
              'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z',
            handleSize: 16,
            handleStyle: {
              color: '#c30b36',
            },
            showDetail: false,
          },
          {
            id: 3,
            type: 'slider',
            yAxisIndex: [0],
            filterMode: 'empty',
            width: 8,
            right: 16,
            borderColor: 'transparent',

            fillerColor: '#1e1e1d',
            handleIcon:
              'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z',
            handleSize: 16,
            handleStyle: {
              color: '#c30b36',
            },
            showDetail: false,
          },
        ],
        series: [
          {
            data: [],
            type: 'scatter',
          },
        ],
      },
      gui: null,
      light: null,
      helper: null,
      helperSpotLight: [],
      texturesIncluded: false,
      modeLight: '',
      spotLights: [],
    }
  },
  watch: {
    /**
     * Вотчер на текущую вкладку инспетора
     * @param newValue
     */
    currentInspectorTab(newValue) {
      if (newValue === 'inspector') {
        // Слушаем клик по потри
        this.viewer.renderArea.addEventListener('click', this.clickOnPotree, false)
      } else {
        // Отписываемся от потри
        this.viewer.renderArea.removeEventListener('click', this.clickOnPotree, false)
        // Сбрасываем текущую точку
        this.currentInspectorPoint = null
      }
    },
  },
  computed: {
    viewer() {
      return this.$root.storeViewer.state.viewer
    },
  },
  beforeDestroy() {
    if (this.gui) {
      this.gui.destroy()
      this.gui = null
    }
  },
  methods: {
    mountTexture() {
      if (this.modelReference) {
        this.viewer.scene.scene.add(this.modelReference)
        this.helper = null
        this.helperSpotLight.splice(0)
        this.light = null
        this.spotLights.splice(0)
        if (this.modelReference.visible) {
          this.changeLight(this.modeLight)
        }
      }
    },
    toggleTextureModel(visible) {
      if (visible) {
        // Need to load model for the first time?
        if (!this.modelReference) {
          const manager = new THREE.LoadingManager()
          manager.onStart = function(url, itemsLoaded, itemsTotal) {
            console.info(
              'Started loading file: ' +
                url +
                '.\nLoaded ' +
                itemsLoaded +
                ' of ' +
                itemsTotal +
                ' files.'
            )
          }
          manager.onLoad = function() {
            console.info('Loading complete!')
          }
          manager.onError = function(url) {
            console.error('There was an error loading ' + url)
          }

          const mtlLoader = new THREE.MTLLoader(manager)
          mtlLoader.setCrossOrigin(true)
          mtlLoader.setPath(this.texturedModelDirectoryPath())
          mtlLoader.load(this.mtlFilename(), (materials) => {
            materials.preload()

            const objLoader = new THREE.OBJLoader(manager)
            objLoader.setMaterials(materials)
            objLoader.load(this.objFilePath(), (object) => {
              this.texturesIncluded = true
              const bboxWorld = this.viewer.scene.pointclouds[0].getBoundingBoxWorld()
              const pcCenter = new THREE.Vector3()
              bboxWorld.getCenter(pcCenter)

              object.position.set(pcCenter.x, pcCenter.y, pcCenter.z)

              // Bring the model close to center
              if (object.children.length > 0) {
                const geom = object.children[0].geometry

                // Compute center
                geom.computeBoundingBox()

                const center = new THREE.Vector3()
                geom.boundingBox.getCenter(center)

                object.translateX(-center.x)
                object.translateY(-center.y)
                object.translateZ(-center.z)
              }
              // добавляем в сцену свет, иначе не будет видно материалы на 3D модели
              this.changeLight('AmbientLight')

              window.viewer.scene.scene.add(object)
              // Делаем свойство transparent true чтобы работала прозрачность
              object.children[0].material.transparent = true
              this.modelReference = object
              // отправляем в el-tree
              this.setTexturedObject(object)
            })
          })
        } else {
          // Already initialized
          this.modelReference.visible = true
          this.texturesIncluded = true
          this.changeLight(this.modeLight)
        }
      } else {
        this.modelReference.visible = false
        this.texturesIncluded = false
        if (this.gui) {
          this.gui.destroy()
          this.gui = null
        }
        if (this.helper || this.helperSpotLight.length) {
          this.includeHelper(null, false)
        }
      }
    },
    texturedModelDirectoryPath() {
      return this.assetsPath + '/odm_texturing/'
    },
    objFilePath() {
      const file = 'odm_textured_model_geo.obj'
      return this.texturedModelDirectoryPath() + file
    },
    mtlFilename() {
      // For some reason, loading odm_textured_model_geo.mtl does not load textures properly
      return 'odm_textured_model_geo.mtl'
    },
    setTexturedObject(object) {
      this.texturedMesh = object
      this.elData[
        this.elData.findIndex((item) => item.type === '3d-mesh')
      ].$ref = this.texturedMesh
    },
    // Проверяем наличие слоя 3d-mesh
    async check3dMesh(axios, project, task) {
      try {
        await axios.head(
          `projects/${project}/tasks/${task}/assets/odm_texturing/odm_textured_model_geo.obj`
        )
      } catch (e) {
        console.error(
          `Не удалось загрузить информацию 3d-mesh. Задача ${task} проекта ${project}`,
          e
        )
      }
    },
    // Метод, определяющий размер сферы, отмечающей положение указателя мыши над профайлом
    computeSizeOfViewPickSphere() {
      const camera = this.viewer.scene.getActiveCamera()
      const domElement = this.viewer.renderer.domElement
      const distance = this.viewer.profileWindow.viewerPickSphere.position.distanceTo(
        camera.position
      )
      const pr = Potree.Utils.projectedRadius(
        1,
        camera,
        distance,
        domElement.clientWidth,
        domElement.clientHeight
      )
      const scale = 10 / pr
      this.viewer.profileWindow.viewerPickSphere.scale.set(scale, scale, scale)
    },
    /**
     * Функция, которая меняет Scale всем лейблам названий
     */
    scaleNameLabels() {
      this.annotations.data.map((annotation) => {
        annotation.measures.map((measure) => {
          if (measure.$potreeMeasure) {
            this.scaleNameLabel(measure.$potreeMeasure.nameLabel)
          }
        })
      })
    },
    /**
     * Функция, которая меняет скейл у одного лейбла названия
     * Основана на расчете расстояния между камерой и позицией лейбла
     * @param label Принимает непосредственно potreeMeasure.nameLabel
     */
    scaleNameLabel(label) {
      const domElement = this.viewer.renderer.domElement
      const camera = this.viewer.scene.getActiveCamera()
      const distance = label.position.distanceTo(camera.position)
      const pr = Potree.Utils.projectedRadius(
        1,
        camera,
        distance,
        domElement.clientWidth,
        domElement.clientHeight
      )
      const scale = 70 / pr
      label.scale.set(scale, scale, scale)
    },
    // Подписка на ивенты потри
    listenToPotreeEvents() {
      // Скалирование лейблов имен замеров при загрузке
      setTimeout(this.scaleNameLabels, 1000)
      // Перемещение по сцене перетаскиванием
      this.viewer.orbitControls.addEventListener('drag', this.scaleNameLabels, false)
      // Зум
      this.viewer.orbitControls.addEventListener(
        'mousewheel',
        this.scaleNameLabels,
        false
      )
      // Окончание рисования замера
      this.viewer.addEventListener('cancel_measure', (e) => {
        if (e && e.measure && e.measure.$measure && e.measure.points.length) {
          this.recomputeNameLabelPosition(e.measure)
          this.scaleNameLabel(e.measure.nameLabel)
        }
      })
    },
    /**
     * Функция, которая пересчитывает позицию лейбла названия замера
     * @param potreeMeasure Принимает объект potreeMeasure
     */
    recomputeNameLabelPosition(potreeMeasure) {
      if (potreeMeasure.points.length) {
        const pos = potreeMeasure.points[0].position.clone()
        potreeMeasure.nameLabel.position.set(pos.x, pos.y, pos.z + 10)
      }
    },
    importViewerAnnotations(annotations) {
      if (annotations) {
        // Очищаем замеры на сцене
        this.viewer.scene.removeAllMeasurements()

        annotations.data.map((annotation) => {
          annotation.measures.map((measure) => {
            measure.createPotreeMeasure()
            // обновляем данные замера для 2Д и 3Д
            measure.updateCalculations()
            // делаем замеры недоступными для редактирования по умолчанию
            measure.changeSpheresVisibility(false)
            // Указываем параметры отображения лейблов
            if (!this.showMeasureLabels) {
              this.changeLabelVisibility(measure, false)
            }
            // Выставляем лейблу названия замера позицию
            if (measure.$potreeMeasure.nameLabel) {
              this.recomputeNameLabelPosition(measure.$potreeMeasure)
            }
            // крутим, если надо
            if (measure.rotation !== 0 && measure.type === 'annotation-rectangle') {
              measure.rotation = measure._rotation
            }
          })
        })

        if (
          this.selectedInspectorItem &&
          this.selectedInspectorItem.type !== 'annotation'
        ) {
          this.currentMeasure.setLayerColor(SELECTED_COLOR)
          // Включаем сферы у текущего замера
          this.currentMeasure.changeSpheresVisibility(true)
        } else if (this.selectedInspectorItem) {
          this.currentAnnotation.setLayerColor(SELECTED_COLOR)
        }
      } else {
        console.warn('Нет аннотаций для импорта', annotations)
      }
    },
    // Функция изменения параметров отображения лейблов
    changeLabelVisibility(measure, visibility) {
      if (measure.type === 'annotation-polygon') {
        measure.$potreeMeasure.showArea = visibility
      }
      measure.$potreeMeasure.showDistances = visibility
    },
    // Подписка на ивент движения мыши над графиком профайла
    listenToEchartsEvents() {
      // Присвоение тултипу графика нашей функции форматтера
      this.elevatorChartOptions.tooltip.formatter = this.tooltipFormatter
      this.$refs.elevator.chart.getZr().on('mousemove', () => {
        // Нахождение необходимой точки из текущего положения тултипа и постановка сферы в нее
        if (this.viewer && this.elevatorOptions.currentTooltipIndex) {
          this.viewer.profileWindow.viewerPickSphere.position.set(
            ...this.currentMeasure.elevationProfileCoords[
              this.elevatorOptions.currentTooltipIndex
            ]
          )
          if (
            !this.viewer.scene.scene.children.includes(
              this.viewer.profileWindow.viewerPickSphere
            )
          ) {
            this.computeSizeOfViewPickSphere()
            this.viewer.scene.scene.add(this.viewer.profileWindow.viewerPickSphere)
          }
        }
      })
    },
    // Форматтер тултипа графика профайла, необходим вынесенный чтобы записать текущий индекс данных из тултипа
    // Костыль для работы функции mouseover-а, асана - https://app.asana.com/0/1144218666186369/1161282094481560/f
    /**
     * Форматтер для тултипа ечартс https://www.echartsjs.com/en/option.html#tooltip.formatter
     * @param params принимает параметры текущего тултипа ечартс
     * @returns {string} отдает отформатированные параметры ечартс
     */
    tooltipFormatter(params) {
      this.elevatorOptions.currentTooltipIndex = params[0].dataIndex
      return (
        this.$gettext('Distance:') +
        ' ' +
        params[0].axisValueLabel +
        '<br/>' +
        this.$gettext('Height:') +
        ' ' +
        params[0].value[1].toFixed(3)
      )
    },
    // Убираем сферу отмечающую положение текущей точки на графике если увели мышь с графика
    removeViewerPickSphere() {
      if (this.viewer) {
        if (
          this.viewer.scene.scene.children.includes(
            this.viewer.profileWindow.viewerPickSphere
          )
        ) {
          this.viewer.scene.scene.remove(this.viewer.profileWindow.viewerPickSphere)
        }
      }
      // Обнуление текущего индекса тултипа
      this.elevatorOptions.currentTooltipIndex = null
    },
    /**
     * Клик по потри
     * @param e - ивент клика
     * @return {Promise<void>}
     */
    async clickOnPotree(e) {
      // Получаем точку под курсором
      const I = Utils.getMousePointCloudIntersection(
        {
          x: e.layerX,
          y: e.layerY,
        },
        this.viewer.scene.getActiveCamera(),
        this.viewer,
        this.viewer.scene.pointclouds
      )
      // Отдаем в инспетор
      if (I) {
        this.currentInspectorPoint = I.point.position
      }
    },
    showMeasure(measure) {
      this.$nextTick(() => {
        measure.$potreeMeasure.visible = true
      })
    },
    hideMeasure(measure) {
      this.$nextTick(() => {
        measure.$potreeMeasure.visible = false
      })
    },
    addNewMeasure(measure) {
      this.addAnnotationGroup()
      // Проверка на то, есть ли текущий выбранный замер и есть ли у него профайл, если есть, при создании нового замера и переключении на него, удаляем профайл у текущего
      if (this.currentMeasure && this.currentMeasure.elevationProfile.length) {
        // Отмена всех запросов на просчет профайла
        this.viewer.profileWindowController.requests.map((request) => request.cancel())
        this.currentMeasure.elevationProfile = []
        this.currentMeasure.elevationProfileCoords = []
        this.currentMeasure.elevationData = {}
        // Удаляем отображение текущего профайла со сцены
        this.viewer.scene.removeProfile(this.currentMeasure.profile)
        this.currentMeasure.profile = {}
      }
      measure.$annotation = this.currentAnnotation
      measure.isNew = true

      measure.createPotreeMeasure()

      this.activateNewMeasure(measure.$potreeMeasure)

      measure.$annotation.add(measure)
      // Обновляем выбранный замер на добавленный
      if (measure instanceof Rectangle) {
        this.currentMeasure = measure
      }
      this.viewer.addEventListener('cancel_measure', (e) => {
        if (e && e.measure && e.measure.$measure && e.measure.points.length) {
          // e.measure.$measure.updatePotreeMeasure()
          e.measure.nameLabel.visible = this.showTitleLabels
        }
      })
      return measure
    },
    activateNewMeasure(measure) {
      if (measure instanceof Potree.Measure) {
        /*
          Данная функция скопипизжена из файла potree/src/utils/MeasuringTool.js
          Функция startInsertionPotreeMeasure
          */
        const domElement = this.viewer.renderer.domElement

        const cancel = {
          removeLastMarker: measure.maxMarkers > 3,
          callback: null,
        }
        // Фикс ошибки при которой рисование замера завершается, а вершина еше не нарисована
        // onAdd - коллбэк, который срабатывает после создания вершины
        // inProgress - флаг того что создание вершины в процессе
        // при условии, когда нажали правую кнопку, но вершина еще в процессе создания -
        // вешаем обычный коллбэк завершения рисования на onAdd
        // add: без debounce точки не рисуются
        let onAdd = () => {}
        let inProgress = false
        const insertionCallback = debounce((e) => {
          if (e.button === THREE.MOUSE.LEFT) {
            inProgress = true
            measure.addMarker(measure.points[measure.points.length - 1].position.clone())
            onAdd()
            inProgress = false
            if (measure.points.length >= measure.maxMarkers) {
              cancel.callback()
            }

            this.viewer.inputHandler.startDragging(
              measure.spheres[measure.spheres.length - 1]
            )
          } else if (e.button === THREE.MOUSE.RIGHT) {
            if (inProgress) onAdd = cancel.callback
            else cancel.callback()
          }
        }, 1)

        cancel.callback = (e) => {
          if (cancel.removeLastMarker) {
            measure.removeMarker(measure.points.length - 1)
          }
          domElement.removeEventListener('mouseup', insertionCallback, true)
          this.viewer.removeEventListener('cancel_insertions', cancel.callback)
          this.viewer.dispatchEvent({
            type: 'cancel_measure',
            measure,
          })
        }

        if (measure.maxMarkers > 1) {
          this.viewer.addEventListener('cancel_insertions', cancel.callback)
          domElement.addEventListener('mouseup', insertionCallback, true)
        }

        measure.addMarker(new THREE.Vector3(0, 0, 0))
        this.viewer.inputHandler.startDragging(
          measure.spheres[measure.spheres.length - 1]
        )
        return measure
      } else if (measure instanceof Measure) {
        // Рисование круга и прямоугольника
        const domElement = this.viewer.renderer.domElement

        const cancel = {
          callback: null,
        }
        const insertionCallback = (e) => {
          if (e.button === THREE.MOUSE.LEFT) {
            // Как только нарисовали одну точку, запускаем создание простой фигуры и закрываем инструмент
            measure.createSimple3D(measure.points[0].position.clone())
            cancel.callback()
          } else if (e.button === THREE.MOUSE.RIGHT) {
            measure.removeMarker(0)
            cancel.callback()
          }
        }

        cancel.callback = (e) => {
          domElement.removeEventListener('mouseup', insertionCallback, true)
          this.viewer.removeEventListener('cancel_insertions', cancel.callback)
          this.viewer.dispatchEvent({
            type: 'cancel_measure',
            measure,
          })
        }

        if (measure.maxMarkers > 1) {
          this.viewer.addEventListener('cancel_insertions', cancel.callback)
          domElement.addEventListener('mouseup', insertionCallback, true)
        }

        // Удаляем все точки из замера, так как они создаются автоматически, а нам нужна одна единственная для рисования простых фигур
        const length = measure.points.length
        for (let i = 0; i < length; i++) {
          measure.removeMarker(0)
        }
        measure.addMarker(new THREE.Vector3(0, 0, 0))
        this.viewer.inputHandler.startDragging(measure.spheres[0])

        return measure
      } else {
        console.error('Добавлен недостимый тип potreeMeasure', measure)
      }
    },
    clickOnMeasure(object) {
      // если сейчас рисуем, то ничего не делаем
      if (this.$refs.controls.currentTool) {
        return
      }
      // FIXME: Иначе глючит добавление большого количества маркеров
      if (this.$refs.controls.selectedBtn !== 'marker') {
        const el = object.potreeContainer

        const measures = this.annotations.allMeasures
        const potreeMeasuresChildren = measures
          .map((measure) => measure.$potreeMeasure.children)
          .flat()

        const camera = this.viewer.scene.getActiveCamera()
        const raycaster = new THREE.Raycaster()
        const mouse = new THREE.Vector2()

        mouse.x = (object.event.layerX / el.offsetWidth) * 2 - 1
        mouse.y = -(object.event.layerY / el.offsetHeight) * 2 + 1

        raycaster.setFromCamera(mouse, camera)

        const intersects = raycaster.intersectObjects(potreeMeasuresChildren)

        if (intersects.length) {
          const measure = measures.find(
            (measure) => measure.$potreeMeasure === intersects[0].object.parent
          )
          this.handleNodeClick(measure)
        }
      }
    },
    enableLine() {
      this.addNewMeasure(new Line(this))
    },
    enableRectangle() {
      this.addNewMeasure(new Rectangle(this))
    },
    enableCircle() {
      this.addNewMeasure(new Circle(this))
    },
    enablePolygon() {
      this.addNewMeasure(new Polygon(this))
    },
    enableMarker() {
      const measure = this.addNewMeasure(new Marker(this))
      const onMarkerDropped = ({ measurement }) => {
        if (measurement.$measure && measurement.$measure.isNew) {
          measurement.$measure.isNew = false
          measurement.removeEventListener('marker_dropped', onMarkerDropped)
          this.$nextTick(() => {
            // следующие две строки - чтобы появился лейбл названия при создании
            measurement.nameLabel.visible = this.showTitleLabels
            this.scaleNameLabel(measurement.nameLabel)
            if (this.currentTool) {
              this.currentTool.enable()
            }
          })
        }
      }
      measure.$potreeMeasure.addEventListener('marker_dropped', onMarkerDropped)
      this.currentMeasure = measure
    },
    enableCamera() {
      const measure = this.addNewMeasure(new Camera(this))
      const onMarkerDropped = ({ measurement }) => {
        if (measurement.$measure && measurement.$measure.isNew) {
          measurement.$measure.isNew = false
          measurement.removeEventListener('marker_dropped', onMarkerDropped)
          this.$nextTick(() => {
            // следующие две строки - чтобы появился лейбл названия при создании
            measurement.nameLabel.visible = this.showTitleLabels
            this.scaleNameLabel(measurement.nameLabel)
            if (this.currentTool) {
              this.currentTool.enable()
            }
          })
        }
      }
      measure.$potreeMeasure.addEventListener('marker_dropped', onMarkerDropped)
      this.currentMeasure = measure
    },
    createPotreeMeasure(measure) {
      let potreeMeasure
      switch (measure.type) {
        // Если замер объема, создаём новый профиль
        case 'annotation-volume':
          potreeMeasure = new Potree.Profile()
          break
        // Иначе создаём новый замер
        default:
          potreeMeasure = new Potree.Measure()
      }

      // Указываем параметр отображения лейбла длины
      if (!this.showMeasureLabels) {
        potreeMeasure.showDistances = false
      }
      // Указываем разные параметры, чтобы не валилось в ошибку
      potreeMeasure.lengthUnit = this.viewer.lengthUnit
      potreeMeasure.lengthUnitDisplay = this.viewer.lengthUnitDisplay

      measure.$potreeMeasure = potreeMeasure
      potreeMeasure.$measure = measure
      potreeMeasure.$scene = this.viewer.scene

      potreeMeasure.name = measure.label
      potreeMeasure.color = new THREE.Color(measure.color || '#ff0000')

      potreeMeasure.visible = measure.visibility

      // Применяем опции
      switch (measure.type) {
        case 'annotation-marker':
          potreeMeasure.showDistances = false
          potreeMeasure.maxMarkers = 1
          break
        case 'annotation-line':
          potreeMeasure.closed = false
          break
        case 'annotation-polygon':
          // Указываем параметр отображения лейбла площади
          if (this.showMeasureLabels) {
            potreeMeasure.showArea = true
          }
          break
        case 'annotation-volume':
          potreeMeasure.showArea = true
          potreeMeasure.showAngles = true
          potreeMeasure.calculateVolume = true
          break
      }

      // Добавляем точки
      measure.points3D.map((p) => potreeMeasure.addMarker(p))

      // Если замер объема, добавляем замер в профайлер
      if (potreeMeasure instanceof Potree.Profile) {
        this.viewer.scene.addProfile(potreeMeasure)
        // Иначе добавляем замер в инструмент замеров
      } else if (potreeMeasure instanceof Potree.Measure) {
        this.viewer.scene.addMeasurement(potreeMeasure)
      } else {
        console.error('Недопустимый тип замера', potreeMeasure)
      }

      potreeMeasure.addEventListener('marker_added', this.onMarkerAdded)
      potreeMeasure.addEventListener('marker_moved', this.onMarkerMoved)
      potreeMeasure.addEventListener('marker_dropped', this.onMarkerMoved)
      potreeMeasure.addEventListener('marker_removed', this.onMarkerRemoved)

      // Добавляем лейбл названия
      potreeMeasure.nameLabel = new Potree.TextSprite(potreeMeasure.name)
      potreeMeasure.nameLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 0.8 })
      potreeMeasure.nameLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 0.3 })
      potreeMeasure.nameLabel.setTextColor({ r: 180, g: 220, b: 180, a: 1.0 })
      potreeMeasure.nameLabel.material.depthTest = false
      potreeMeasure.nameLabel.material.opacity = 1
      potreeMeasure.nameLabel.visible = this.showTitleLabels
      potreeMeasure.add(potreeMeasure.nameLabel)

      return potreeMeasure
    },
    // Обработчик событий меток
    onMarkerAdded(e) {
      // marker_added
      if (e.measurement.$measure) {
        e.measurement.$measure.points3D = e.measurement.points
      }
      // console.warn(`onMarkerAdded`, e)
    },
    onMarkerMoved(e) {
      // marker_moved
      // Для Measure
      if (e.measure && e.measure.$measure) {
        e.measure.$measure.points3D = e.measure.points
        // Пересчитываем позицию лейбла названия замера
        this.recomputeNameLabelPosition(e.measure)
        e.measure.$measure.updatePosition(e.measure.points)
      } else if (e.profile && e.profile.$measure) {
        e.profile.$measure.points3D = e.profile.points
      }
      // console.warn(`onMarkerMoved`, e)
    },
    onMarkerRemoved(e) {
      // marker_removed
      if (e.measurement.$measure) {
        e.measurement.$measure.points3D = e.measurement.points
      }
      // console.warn(`onMarkerRemoved`, e)
    },
    /**
     * Переключание между источниками света
     * @param {String} value ('AmbientLight' - стандартный свет, 'DirectionalLight' - солнечный свет,
     * SpotLight - прожекторы)
     */
    changeLight(value) {
      let position = { x: 0, y: 10, z: 0 } // точка источника света
      let targetPosition = { x: 0, y: 10, z: 0 } // точка куда направлен свет
      if (value === 'AmbientLight') {
        this.modeLight = 'AmbientLight'
        let lightExist = false
        for (const child of window.viewer.scene.scene.children) {
          if (child.type === 'DirectionalLight' || child.type === 'SpotLight') {
            child.visible = false
          } else if (child.type === 'AmbientLight') {
            child.visible = true
            lightExist = true
            this.light = child
          }
        }
        if (!lightExist) {
          this.light = new THREE.AmbientLight(0xffffff, 1)
          window.viewer.scene.scene.add(this.light)
        }
        if (this.helper || this.helperSpotLight.length) {
          this.includeHelper(null, false)
        }
      } else if (value === 'DirectionalLight') {
        this.modeLight = 'DirectionalLight'
        let lightExist = false
        for (const child of window.viewer.scene.scene.children) {
          if (child.type === 'AmbientLight' || child.type === 'SpotLight') {
            child.visible = false
          } else if (child.type === 'DirectionalLight') {
            child.visible = true
            lightExist = true
            this.light = child
          } else if (child.type === 'Group') {
            position = child.position
          }
        }
        targetPosition = {
          x: position.x,
          y: position.y,
          z: position.z - 500,
        }
        if (!lightExist) {
          this.light = new THREE.DirectionalLight(0xffffff, 1)
          this.light.position.set(position.x, position.y, position.z)
          this.light.target.position.set(
            targetPosition.x,
            targetPosition.y,
            targetPosition.z
          )
          window.viewer.scene.scene.add(this.light)
          window.viewer.scene.scene.add(this.light.target)
        }
        if (this.helperSpotLight.length) {
          this.includeHelper(null, false, 'SpotLight')
        }
        if (!this.helper) {
          this.helper = new THREE.DirectionalLightHelper(this.light)
          window.viewer.scene.scene.add(this.helper)
        } else {
          this.includeHelper(null, true, 'DirectionalLight')
        }
        this.updateLight()
      } else if (value === 'SpotLight') {
        this.modeLight = 'SpotLight'
        ;({ position, targetPosition } = this.addSpotlight())
      }
      this.addGUI(position, targetPosition)
    },
    addGUI(position, targetPosition) {
      if (this.gui) {
        this.gui.destroy()
      }
      // помощник для управления свойствами света
      this.gui = new GUI()
      this.gui
        .addColor(new ColorGUIHelper(this.light, 'color'), 'value')
        .name(this.$gettext('color'))
      this.gui.add(this.light, this.$gettext('intensity'), 0, 2, 0.01)
      if (this.modeLight === 'SpotLight') {
        this.gui
          .add(new DegRadHelper(this.light, 'angle'), 'value', 0, 90)
          .name(this.$gettext('angle'))
          .onChange(this.updateLight)
        this.gui.add(this.light, this.$gettext('penumbra'), 0, 1, 0.01)
      }
      if (this.light.target) {
        // добавляем скрываемые блоки с управлением координатами расположения света
        this.makeXYZGUI(
          this.gui,
          this.light.position,
          'position',
          position,
          this.updateLight
        )
        this.makeXYZGUI(
          this.gui,
          this.light.target.position,
          'target',
          targetPosition,
          this.updateLight
        )
      }
      this.gui.domElement.id = 'gui' // для задания расположения помощника (на ViewerLayout)
    },
    makeXYZGUI(gui, vector3, name, position, onChangeFn) {
      const folder = gui.addFolder(name)
      folder.add(vector3, 'x', position.x - 1000, position.x + 1000).onChange(onChangeFn)
      folder.add(vector3, 'y', position.y - 1000, position.y + 1000).onChange(onChangeFn)
      folder.add(vector3, 'z', position.z - 1000, position.z + 1000).onChange(onChangeFn)
      folder.open()
    },
    updateLight() {
      this.light.target.updateMatrixWorld()
      for (const helper of this.helperSpotLight) {
        helper.update()
      }
      if (this.helper) {
        this.helper.update()
      }
    },
    includeHelper(data, value, type) {
      if (data) {
        data.visible = !data.visible
        const index = this.helperSpotLight.findIndex((item) => item.uuid === data.uuid)
        if (index !== -1) {
          this.spotLights[index].visible = !this.spotLights[index].visible
        }
      } else {
        let typeLight = null
        if (this.modeLight === 'AmbientLight') {
          value = false
        } else {
          typeLight = type || this.modeLight
        }
        if (!typeLight || typeLight === 'SpotLight') {
          for (const helper of this.helperSpotLight) {
            helper.visible = value !== undefined ? value : !helper.visible
          }
        }
        if (this.helper && (!typeLight || typeLight === 'DirectionalLight')) {
          this.helper.visible = value !== undefined ? value : !this.helper.visible
        }
      }
    },
    removeHelper(data, type) {
      if (data) {
        const index = this.helperSpotLight.findIndex((item) => item.uuid === data.uuid)
        if (index !== -1) {
          window.viewer.scene.scene.remove(data)
          window.viewer.scene.scene.remove(this.spotLights[index])
          this.helperSpotLight.splice(index, 1)
        }
      } else {
        if (!type || type === 'SpotLight') {
          for (const helper of this.helperSpotLight) {
            window.viewer.scene.scene.remove(helper)
          }
        }
        if (!type || type === 'DirectionalLight') {
          window.viewer.scene.scene.remove(this.helper)
        }
      }
    },
    addSpotlight(addition) {
      let position = { x: 0, y: 10, z: 0 } // точка источника света
      let targetPosition = { x: 0, y: 10, z: 0 } // точка куда направлен свет
      let lightExist = false
      for (const child of window.viewer.scene.scene.children) {
        if (child.type === 'AmbientLight' || child.type === 'DirectionalLight') {
          child.visible = false
        } else if (child.type === 'SpotLight') {
          child.visible = true
          lightExist = true
          this.light = child
        } else if (child.type === 'Group') {
          position = child.position
        }
      }
      targetPosition = {
        x: position.x,
        y: position.y,
        z: position.z - 500,
      }
      if (!lightExist || addition) {
        this.light = new THREE.SpotLight(0xffffff, 1)
        this.light.position.set(position.x, position.y, position.z)
        this.light.target.position.set(
          targetPosition.x,
          targetPosition.y,
          targetPosition.z
        )
        window.viewer.scene.scene.add(this.light)
        window.viewer.scene.scene.add(this.light.target)
        this.spotLights.push(this.light)
      }
      if (this.helper) {
        this.includeHelper(null, false, 'DirectionalLight')
      }
      if (this.spotLights.length !== this.helperSpotLight.length) {
        this.helperSpotLight.push(new THREE.SpotLightHelper(this.light))
        this.helperSpotLight[this.helperSpotLight.length - 1].name = `${this.$gettext(
          'Spotlight'
        )} ${this.helperSpotLight.length}`
        if (this.helperSpotLight[this.helperSpotLight.length - 1].children.length) {
          this.helperSpotLight[
            this.helperSpotLight.length - 1
          ].children[0].name = this.$gettext('Helper')
        }
        window.viewer.scene.scene.add(
          this.helperSpotLight[this.helperSpotLight.length - 1]
        )
      } else {
        this.includeHelper(null, true, 'SpotLight')
      }
      this.currentSpotlight = this.helperSpotLight[this.helperSpotLight.length - 1].uuid
      this.updateLight()
      if (addition) {
        this.addGUI(position, targetPosition)
      }
      return { position, targetPosition }
    },
  },
}
