<template>
  <div
    class="container"
  >
    <div class="inner-registration-page row justify-content-center">
      <div
        class="registration-form-nova col-12 col-md-5 mt-3 mt-md-5"
      >
        <p class="text-center registration-text my-4">
          <translate>Report Bug</translate>
        </p>
        <div class="form-input-nova mx-5 my-2">
          <el-form
            ref="report"
            v-model="report"
            status-icon
            :rules="rules"
            :model="report"
          >
            <el-form-item>
              <el-input
                v-model="report.name"
                prefix-icon="icon nova-circle-10"
                :disabled="true"
              />
            </el-form-item>
            <el-form-item
              prop="name"
            >
              <el-input
                v-model="report.name"
                :placeholder="'Summary' | translate"
              />
            </el-form-item>
            <el-form-item
              prop="type"
            >
              <el-select
                v-model="report.type"
                class="custom-bug-report-select"
              >
                <el-option
                  v-for="type in serviceReportTypes"
                  :key="type.key"
                  :value="type.value"
                  class="my-2"
                  :label="type.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="report.pageLink"
                :placeholder="'Link to page with problem' | translate"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="report.description"
                :rows="2"
                :placeholder="'Description' | translate"
                type="textarea"
              />
            </el-form-item>
            <el-form-item
              class="row justify-content-center"
            >
              <el-button
                type="primary"
                class="custom-primary-btn-report"
                round
                @click="sendToJira"
              >
                <translate>Report problem</translate>
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Input, Select, Option, Button, Form, FormItem, Notification } from 'element-ui'

export default {
  name: 'Report',
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: {
    serviceDeskId: {
      type: String,
      default() {
        return '1'
      }
    },
    serviceReportTypes: {
      type: Array,
      default() {
        return [
          {
            key: 5,
            value: 5,
            label: this.$gettext('Bug')
          },
          {
            key: 7,
            value: 7,
            label: this.$gettext('Improvement')
          },
        ]
      }
    },
  },
  data() {
    return {
      report: {
        name: this.$auth.user().username,
        type: null,
        pageLink: null,
        description: null,
        email: this.$auth.user().email,
      },
      rules: {
        name: [
          { required: true, message: this.$gettext('Please input summary'), trigger: 'blur' }
        ],
        type: [
          { required: true, message: this.$gettext('Please select bug type'), trigger: 'change' }
        ],
      }
    }
  },
  computed: {
    reportData: {
      get() {
        return {
          serviceDeskId: this.serviceDeskId,
          requestTypeId: this.report.type || this.serviceReportTypes[0].key,
          requestFieldValues: {
            summary: this.report.name || 'Ошибка',
            description: `пользователь: ${this.report.name}\n страница: ${this.report.pageLink}\n email: ${this.report.email} \n описание: ${this.report.description}`,
          }
        }
      },
    }
  },
  mounted() {
    // выбираем по дефолту опцию
    this.report.type = this.serviceReportTypes[0].value
  },
  methods: {
    /**
     * Функция для отправки информации в жиру, проксируется через наш бек
     * @return {Promise<void>}
     */
    async sendToJira() {
      try {
        const request = {
          url: 'rest/servicedeskapi/request/',
          data: this.reportData
        }
        const response = await this.axios.post('jira/', request)
        debugger
        if (response.data.status === 201) {
          Notification({
            title: this.$gettext('Sent'),
            message: this.$gettext('Request has been sent'),
            type: 'success',
            position: 'top-right',
            showClose: false
          })
          // обнуляем форму
          this.report.pageLink = null
          this.report.description = null
        } else {
          Notification({
            title: this.$gettext('Not sent'),
            message: this.$gettext('Something went wrong'),
            type: 'error',
            position: 'top-right',
            showClose: false
          })
        }
      } catch (e) {
        console.error('Не удалось сохранить', e)
      }
    },
  }
}
</script>

<style>
  .registration-form-nova {
    background-color: #ffffff;
    border-radius: 32px;
  }
  .registration-text {
    text-transform: uppercase;
    font: Bold 18px/24px Open Sans;
    color: #000000;
  }
  .custom-bug-report-select {
    width: 100% !important;
  }
  .custom-primary-btn-report:hover, .custom-primary-btn-report:active, .custom-primary-btn-report:focus {
    color: #51cbce !important;
  }

  .el-select .el-input:hover .el-select .el-input:hover input {
    color: #177F7F !important;
  }
</style>
