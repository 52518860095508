<script>
import { Image } from 'element-ui'
export default {
  components: {
    [Image.name]: Image,
  },
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      img: '/assets/images/nVision_color.png'
    }
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.getImage(this.value)
          .then((res) => {
            this.img = res
          })
      }
    },
  },
  mounted() {
    if (this.value) {
      this.getImage(this.value)
        .then((res) => {
          this.img = res
        })
    }
  },
  methods: {
    /**
     * Асинхронная загрузка картинки с прокидыванием хедера авторизации
     * https://medium.com/javascript-in-plain-english/loading-images-with-authorization-8aab33663ba6
     * @param url - ссылка на картинку
     * @return {Promise<unknown>|null}
     */
    getImage(url) {
      if (!url) {
        return null
      }
      return Promise.resolve(this.axios.get(url, { ...this.$root.headers, responseType: 'blob' })
        .then((res) => {
          const reader = new FileReader()
          return new Promise((resolve) => {
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(res.data)
          })
        }).then((result) => {
          return result
        }))
    },
  }
}
</script>

<template>
  <el-image
    :src="img"
    lazy
    fit="contain"
  >
    <div slot="error">
      <span>nVision</span>
    </div>
  </el-image>
</template>
