// Easter-egg
(function(url) {
  const image = new Image()

  image.onload = function() {
    const height = 88
    const width = 500
    const style = [
      'font-size: 1px;',
      'line-height: ' + height + 'px;',
      'padding: ' + height * 0.5 + 'px ' + width * 0.5 + 'px;',
      'background-size: ' + width + 'px ' + height + 'px;',
      'background: url(' + url + '); background-size: contain;'
    ].join(' ')

    // notice the space after %c
    console.log('%c ', style)
  }

  // Actually loads the image
  image.src = url
})(`${window.location.origin}/static/img/nova-logo.png`)
