<template xmlns:v-nova-page-guide="http://www.w3.org/1999/xhtml">
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications
      transition-name="notification-list"
      transition-mode="out-in"
    />
    <router-view name="header" />
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view />
    </transition>
    <router-view name="footer" />
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import 'sweetalert2/dist/sweetalert2.css'
import 'vue-notifyjs/themes/default.css'

export default {
  components: {
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss">
  body {
    font-family: "Open Sans" !important;
  }

  button {
    font-family: "Open Sans" !important;
  }
  .content {
    background: #464646 0 0 no-repeat padding-box;
    color: #ffffff;
    height: 100% !important;
  }
  .close-guide {
    bottom: 10%;
    position: fixed;
    right: 50%;
  }
  /* стили всех кнопок, используемых в проекте */
  .el-button:hover, .el-button:focus {
    background: transparent !important;
  }
  .el-button.is-circle {
    border-radius: 50%;
    background: transparent;
    color: #ffffff;
    font-size: 25px;
    border: none;
    padding: 0;
  }
  .el-button--danger {
    background-color: #D32628 !important;
    border-color: #D32628 !important;
  }
  .el-button--danger:hover, .el-button--danger:focus {
    color: #f3282a !important;
  }
  .el-button--success {
    background: #177F7F !important;
    border-color: #177F7F !important;
  }
  .el-button--info:hover, .el-button--info:focus {
    background: transparent !important;
    color: #51cbce !important;
  }
  .el-button--info.is-disabled, .el-button--info.is-disabled:hover, .el-button--info.is-disabled:focus, .el-button--info.is-disabled:active {
    color: #565657 !important;
    background-color: transparent !important;
  }
</style>
