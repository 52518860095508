<template>
  <div class="content">
    <transition
      name="fade"
      mode="out-in"
    >
      <!-- your content here -->
      <router-view
        :key="$route.params.taskId"
      />
    </transition>
  </div>
</template>
<script>
export default {}
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }

  .content {
    padding: 0 !important;
    /*margin-top : 63px !important;*/
    margin-top : 0 !important;
    /*background-color: #05AE0E;*/
    /*height: calc(100vh - 63px) !important;*/
    min-height: 100vh !important;
  }
</style>
