<script>
import { Drawer } from 'element-ui'
export default {
  components: {
    [Drawer.name]: Drawer,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      tasks: [],
      timeLineScroller: {},
      labelsScroller: {},
      loading: false,
    }
  },
  mounted() {
    this.loadTasks()
    this.$nextTick(() => {
      this.timeLineScroller = document.querySelector('.custom-div-timeline-row').firstChild
      this.labelsScroller = document.querySelector('.custom-div-name-labels-scroller').firstChild
      this.initScroll()
    })
  },
  methods: {
    /**
     * Инициализация скролла
    */
    initScroll() {
      const scroll = function(e) {
        e.preventDefault()
        this.scrollLabels(e.deltaX + e.deltaY)
      }
      this.timeLineScroller.addEventListener('mousewheel', scroll.bind(this))
      this.labelsScroller.addEventListener('mousewheel', scroll.bind(this))
    },
    /**
     * Скролл обоих скорллеров
    */
    scrollLabels(delta) {
      this.timeLineScroller.scrollLeft = this.labelsScroller.scrollLeft + delta
      this.labelsScroller.scrollLeft = this.labelsScroller.scrollLeft + delta
    },
    /**
     * Загрузка тасков в данном проекте
     * @return {Promise<void>}
     */
    async loadTasks() {
      try {
        this.loading = true
        const url = `/projects/${this.$route.params.projectId}/tasks/`
        const response = await this.axios.get(url)
        if (response.data) {
          response.data.map(item => {
            this.tasks.push(item)
          })
          this.loading = false
        }
      } catch (e) {
        console.error('Не удалось загрузить список проектов', e)
        this.loading = false
      }
    },

    /**
     * Путь к ассетам таска для подгрузки тамбнейла
     * @param task - таск
     * @param asset - ассет
     * @return {string}
     */
    assetsPath(task, asset) {
      return this.$root.apiPath(`/api/projects/${task.project}/tasks/${task.id}/assets/${asset}`)
    },

    emitInput(flag) {
      this.$emit('input', flag)
    },
  },
}
</script>

<template>
  <el-drawer
    :visible="value"
    direction="ttb"
    :with-header="false"
    size="220"
    @open="emitInput(true)"
    @close="emitInput(false)"
  >
    <div class="timeline-drawer">
      <div
        v-loading="loading"
        class="custom-div-timeline-container"
      >
        <button @click="scrollLabels(-300)">
          <i class="fas fa-chevron-left" />
        </button>
        <div class="container-fluid custom-div-timeline-row horizontal-scrollable">
          <div
            class="text-center scroll-box"
          >
            <div
              v-for="task in tasks"
              :key="task.created_at"
              class="scroll-item"
            >
              <router-link
                v-if="task.id !== $route.params.taskId"
                :to="{name: 'task-viewer', params: {projectId: task.project, taskId: task.id}}"
              >
                {{ new Date(task.created_at).toLocaleDateString() }}
                <div
                  class="card-image"
                  :style="{backgroundImage: `url('${assetsPath(task, 'thumbnail.jpg')}')`}"
                />
                <div class="vertical-strip" />
              </router-link>
              <div
                v-else
              >
                <div class="text-center date-current">
                  {{ new Date(task.created_at).toLocaleDateString() }}
                </div>
                <div
                  class="card-image-current"
                  :style="{backgroundImage: `url('${assetsPath(task, 'thumbnail.jpg')}')`}"
                />
              </div>
            </div>
          </div>
        </div>
        <button @click="scrollLabels(300)">
          <i class="fas fa-chevron-right" />
        </button>
      </div>
      <div class="custom-div-name-labels-scroller horizontal-scrollable">
        <div
          class="scroll-box text-center"
        >
          <div
            v-for="task in tasks"
            :key="task.created_at"
            class="scroll-item"
          >
            <div class="text-center name-label">
              {{ task.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<style scoped>
  .custom-div-timeline-container {
    height: calc(100% - 34px);
    width: 100%;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    -webkit-box-orient: horizontal;
    flex-direction: row;
    overflow-x: hidden;
  }
  .custom-div-timeline-container button {
    height: 100%;
    width: 40px;
    position: relative;
    border: none;
    background-color: #E6E6E6;
    cursor: pointer;
  }
  .custom-div-timeline-container i {
    font-size: 20px;
    display: block;
    margin: auto;
  }
  .custom-div-timeline-container button:hover {
    background-color: #DEDEDE;
  }
  .custom-div-timeline-container button:focus {
    outline: none;
  }
  .custom-div-timeline-row {
    vertical-align: top;
    display: inline-block;
    margin: 0;
    padding: 0;
    height: 100%;
    width: calc(100% - 80px);
  }
  .custom-div-timeline-row .row {
    height: 100%;
  }
  .card-image {
    position: relative;
    background: #000000;
    height: 80px;
    width: 120px;
    border-radius: 3px;
    background-size: cover;
    background-position: center;
    margin: auto;
  }
  .card-image-current {
    position: absolute;
    top: 0;
    left: calc(50% - 96px);
    background: #000000;
    height: 128px;
    width: 192px;
    border-radius: 3px;
    background-size: cover;
    background-position: center;
    margin: auto;
  }
  .date-current {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .horizontal-scrollable > .scroll-box {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: 100%;
  }
  .scroll-box::-webkit-scrollbar {
     display: none;
   }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scroll-box {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .highlighted-item {
    border: solid red;
  }
  .scroll-item {
    position: relative;
    height: 100%;
    min-width: 300px;
    display: inline-block;
    float: none;
  }
  .timeline-drawer {
    background: #464646 0 0 no-repeat padding-box !important;
    height: 220px;
    padding: 28px 40px 28px 40px;
  }
  .sidebar-mini {
    background: #464646 0 0 no-repeat padding-box;
  }
  .vertical-strip {
    display: inline-block;
    position: relative;
    height: 25px;
    width: 1px;
    background-color: #E6E6E6;
    bottom: 0;
  }
  .bottom-label {
    position: absolute;
    width: 100%;
  }
  .custom-div-name-labels-scroller {
    height: 34px;
    padding: 0 41px;
  }
  .name-label {
    width: 100%;
  }
</style>
