<template xmlns:v-nova-page-guide="http://www.w3.org/1999/xhtml">
  <div>
    <nova-header />
    <div class="container">
      <div class="context-section row justify-content-between mt-3">
        <div class="search-form col-4 col-lg-3 col-xl-2 mr-auto">
          <search-input ref="search" />
        </div>
        <div class="ml-auto col-auto">
          <el-button
            type="success"
            round
            style="font-size: 10px; font-weight: 700"
            @click="toggleNewProjectModal(true)"
          >
            <translate>Add project</translate>
          </el-button>
        </div>
        <div class="section-action d-flex justify-content-between col-auto">
          <div class="action-buttons" />
          <div class="view-selector">
            <el-button-group>
              <el-button
                type="info"
                icon="icon nova-gallery-view"
                class="change-view-button"
                @click="changeView('grid')"
              />
              <el-button
                type="info"
                icon="icon nova-bullet-list-70"
                class="change-view-button"
                @click="changeView('list')"
              />
            </el-button-group>
          </div>
        </div>
      </div>
    </div>
    <div v-if="view === 'grid'" v-loading="loading">
      <projects-page-grid :projects="projects" />
    </div>
    <div v-if="view === 'list'" v-loading="loading">
      <projects-page-list :projects="projects" />
    </div>
    <el-button
      v-if="nextProjectsPage"
      icon="el-icon-arrow-down"
      class="fixed-scroll-button"
      @click="scrollBottom"
    >
      <span><translate>Scroll down to load more tasks</translate></span>
    </el-button>
    <nova-page-guide :guide-obj="guideObj" />
    <modal :show.sync="modal" modal-classes="modal-card">
      <p class="text-center registration-text">
        <translate>Create new project</translate>
      </p>
      <div class="row mb-2">
        <el-form ref="newProject" :model="newProject" status-icon class="col-12">
          <el-form-item prop="organization">
            <el-select
              v-model="selectedOrganizations"
              class="registration-input"
              :placeholder="'Start typing the name of the organization' | translate"
              filterable
              multiple
              remote
              reserve-keyword
              :loading="loading"
              :remote-method="(search) => searchOrganizationByName(search)"
              @change="(organizationId) => setOrganizations(organizationId)"
            >
              <el-option
                v-for="(organization, key) in organizationsList"
                :key="`${key}-org`"
                :value="organization.id"
                :label="organization.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="name" class="py-2">
            <el-input
              v-model="newProject.name"
              name="newProjectName"
              class="registration-input py-2"
              :placeholder="'Project name' | translate"
              type="text"
            />
          </el-form-item>
          <el-form-item prop="description" class="pb-2">
            <el-input
              v-model="newProject.description"
              name="newProjectName"
              class="registration-input py-2"
              :placeholder="'Description' | translate"
              type="text"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <el-button round type="success" class="custom-btn-success" @click="addProject">
            <translate>Add project</translate>
          </el-button>
          <el-button
            type="danger"
            round
            class="custom-btn"
            @click="toggleNewProjectModal(false)"
          >
            <translate>Cancel</translate>
          </el-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import NovaHeader from '@layouts/HeaderLayout/NovaHeader'
import ProjectsPageGrid from '@layouts/ProjectsLayout/ProjectsPageGrid'
import ProjectsPageList from '@layouts/ProjectsLayout/ProjectsPageList'
import SearchInput from '@layouts/SearchInput'
import { Input, Button, ButtonGroup, FormItem, Form, Select, Option } from 'element-ui'
import NovaPageGuide from '@nova/NovaPageGuide/NovaPageGuide'
import Modal from '@UIComponents/Modal'

export default {
  name: 'Projects',
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Select.name]: Select,
    [Option.name]: Option,
    NovaHeader,
    ProjectsPageGrid,
    ProjectsPageList,
    SearchInput,
    NovaPageGuide,
    Modal,
  },
  data() {
    return {
      modal: false,
      loading: false,
      tasks: [],
      view: 'grid',
      // Долистали ли до низа страницы
      bottom: false,
      // Ссылка на следующую страницу проектов в апи
      nextProjectsPage: null,
      nextOrganizationsPage: null,
      projects: [],
      organizationsList: [],
      selectedOrganizations: [],
      newProject: {},
    }
  },
  computed: {
    currentShownTasks() {
      if (this.tasks && this.$refs.search) {
        return this.tasks.filter((task) => {
          if (!task.name) {
            return task
          }
          if (task.name.toLowerCase().includes(this.$refs.search.text.toLowerCase())) {
            return task
          }
        })
      } else {
        return this.tasks
      }
    },
    // воткнул сюда, чтобы перевод корректно подтягивался при изменении языка
    guideObj() {
      return [
        {
          name: 'allCardsProjects',
          position: 'relative',
          desc: this.$gettext(
            'Welcome to nVision! Now you are in the list of projects. This is the main page.'
          ),
        },
        {
          name: 'cardProject',
          position: 'absolute',
          desc: this.$gettext(
            'This is a project card, you can go to the project or edit it.'
          ),
        },
        {
          name: 'sideBar',
          position: 'fixed',
          desc: this.$gettext(
            'On the left is the menu bar. Point at her to familiarize yourself.'
          ),
        },
      ]
    },
  },
  watch: {
    /**
     * Слежение за скроллом и подгрузка тасков
     */
    bottom(bottom) {
      if (bottom && this.nextProjectsPage) {
        this.loadProjects(this.nextProjectsPage)
      }
    },
  },
  async mounted() {
    // Листенер ивента скролла чтобы подгружать таски если есть еще не загруженные проекты
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
    // await this.loadTasks()
    await this.loadProjects()
    this.searchOrganizationByName()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
  },
  methods: {
    /**
     * Обновляем массив организация для создаваемого проекта
     * @param arrId - массив айдишников выбранных организаций
     */
    setOrganizations(arrId) {
      this.$set(this.newProject, 'organizations', arrId)
    },
    /**
     * Поиск по названию организации
     * @param search - поисковой запрос названия организации
     */
    async searchOrganizationByName(search = '') {
      this.loading = true
      if (search) this.organizationsList.splice(0)
      try {
        const URL = '/organizations/'
        const response = await this.axios.get(URL, {
          params: {
            name: search,
          },
        })
        if (response.status === 200) {
          this.organizationsList.push(...response.data.results)
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    /**
     * Добавляем проект
     */
    async addProject() {
      try {
        const URL = '/projects/'
        const response = await this.axios.post(URL, this.newProject)
        if (response.status === 201) {
          debugger
          this.projects.unshift(response.data)
          this.toggleNewProjectModal(false)
        }
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Загружаем проект
     */
    async loadProjects() {
      try {
        const URL = this.nextProjectsPage ? this.nextProjectsPage : '/projects'
        const response = await this.axios.get(URL)
        if (response.data.next) {
          this.nextProjectsPage = response.data.next
        } else {
          this.nextProjectsPage = null
        }
        if (response.status === 200) {
          this.projects.push(...response.data.results)
        }
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Проверка - долистали ли до низа страницы
     * @return {boolean}
     */
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    /**
     * Смена вида табличный/плитка
     * @param type
     */
    changeView(type) {
      this.view = type
    },
    /**
     * Скролл до низа страницы
     */
    scrollBottom() {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
    },
    /**
     * Включение модального окна добавления нового пользователя
     * @param value Boolean открыто ли модальное окно
     */
    toggleNewProjectModal(value) {
      this.modal = value
      if (!value) {
        // Обнуление полей
        this.newProject = {}
        this.selectedOrganizations = []
      }
    },
  },
}
</script>

<style scoped>
.el-button--info {
  background: #747474 0 0 no-repeat padding-box;
  border-color: #747474;
}
.el-button-group .el-button--info {
  font-size: 16px;
  padding: 9px;
}
.el-button-group .el-button--info {
  font-size: 16px;
  padding: 9px;
}
.el-button--info {
  background: #747474 0 0 no-repeat padding-box;
  border-color: #747474;
}
.el-button-group .el-button--info:first-child {
  margin-right: 5px;
  border-right-color: #747474;
}
.el-button-group .el-button--info:last-child {
  border-left-color: #747474;
}
.el-button--info:hover,
.el-button--info:focus {
  background: transparent !important;
  color: #51cbce !important;
}
.change-view-button:hover,
.change-view-button:focus {
  /*color: #51cbce !important;*/
  background: transparent !important;
}
.el-input__inner {
  background: #4cd964 !important;
}
.btn-run-guide {
  position: fixed;
  top: 28px;
  right: 50px;
}
</style>
<style>
.el-button--success {
  background: #177f7f;
  border-color: #177f7f;
}
.el-button--info:hover,
.el-button--info:focus {
  background: transparent !important;
  color: #51cbce !important;
}
.change-view-button:hover,
.change-view-button:focus {
  /*color: #51cbce !important;*/
  background: transparent !important;
}
.custom-el-table-row {
  color: white;
}
.custom-el-table-row:hover > td {
  background-color: #737373 !important;
}
.for-guide {
  background-color: rgb(244, 243, 239);
}
.fixed-scroll-button {
  position: fixed;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fixed-scroll-button:hover {
  background: #ffffff !important;
}
</style>
