import VueAuth from '@websanova/vue-auth'
import Http from '@websanova/vue-auth/drivers/http/axios.1.x'
import Router from '@websanova/vue-auth/drivers/router/vue-router.2.x'
import JwtDecode from 'jwt-decode'

const JWT2 = {
  request(req, token) {
    this.options.http._setHeaders.call(this, req, { Authorization: 'Bearer ' + token })
  },
  response(res) {
    const token = res.data.access
    let decoded = {}
    if (token) {
      decoded = JwtDecode(token)
    }
    if (res.status === 401 && res.config.url.endsWith(this.options.refreshData.url)) {
      this.options.logoutProcess.call(this, null, {
        redirect: this.options.authRedirect.path,
      })
      // this.logout()
    }
    if (Object.keys(decoded).length > 0) {
      /* get tokens if none present then */
      const accessToken = res.data.access
      // console.log('decoded' + JSON.stringify(decoded))

      const expiresInDate = new Date(decoded.exp * 1000)
      /* get exact time of expiration token */
      // expires_inDate.setSeconds(exp)

      localStorage.setItem('expires_in', expiresInDate)
      localStorage.setItem('access_token', accessToken)
      localStorage.setItem('refresh_token', res.data.refresh)

      // Подключаем авторизацию для Potree
      window.Potree.XHRFactory.config.customHeaders.Authorization = `Bearer ${accessToken}`

      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
      return accessToken
    }
  },
}

export default {
  install(Vue) {
    Vue.use(VueAuth, {
      auth: JWT2,
      http: Http,
      router: Router,
      rolesVar: 'role',
      fetchData: { url: 'user/info/', method: 'GET', enabled: true },
      loginData: {
        url: 'auth/obtain-token',
        method: 'POST',
        fetchUser: false,
        headers: {
          'Content-Type': 'application/json',
        },
      },
      refreshData: {
        url: 'auth/refresh-token',
        method: 'POST',
        enabled: true,
        interval: 30,
        data: {
          refresh: localStorage.getItem('refresh_token'),
          // orig_iat: localStorage.getItem('orig_iat')
        },
      },
      tokenExpired() {
        const expiresIn = localStorage.getItem('expires_in')
        const now = new Date()
        const isExpired = expiresIn && new Date(expiresIn) < now
        const atoken = localStorage.getItem('refresh_token')
        if (isExpired) {
          Vue.auth.options.refreshData.data = { refresh: atoken }
        }
        return isExpired
      },
      tokenDefaultName: 'access_token',
      parseUserData(data) {
        return data.data || {}
      },
      refreshProcess(res, data) {
        if (res.status === 200) {
          localStorage.setItem('access_token', res.data.access)
          localStorage.setItem('refresh_token', res.data.refresh)
          data.data.refresh = res.data.refresh
        } else {
          debugger
        }
        if (data.success) {
          data.success.call(this, res)
        }
        // debugger
      },
    })
  },
}
