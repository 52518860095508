import DashboardLayout from 'src/components/Dashboard/Layout/DashboardLayout.vue'
// import DashboardLayout from "src/components/Nova/Layout/ViewerLayout";
import ViewerLayout from './ViewerLayout'
import Settings from './Settings'
import Users from './UsersLayout/Users'
import User from './UsersLayout/PersonPage'
import Upload from './UploadLayout'
import NotificationsList from './HeaderLayout/NotificationsList'
import MyProfile from './UsersLayout/MyProfile'
import ProjectEditPage from './ProjectsLayout/ProjectEditPage'
import SplitscreenViewer from './SplitscreenViewer'
import Report from './Report'
import NewUser from './UsersLayout/NewUser'
import ProjectDetail from './ProjectsLayout/ProjectDetail'
import Vue from 'vue'

const Administrative = () =>
  import(
    /* webpackChunkName: "widgets" */ 'src/components/Nova/Layout/Administrative/Administrative'
  )
export default {
  path: '/',
  component: DashboardLayout,

  children: [
    {
      path: 'project/:projectId/:taskId/viewer',
      name: 'task-viewer',
      component: ViewerLayout,
      meta: {
        auth: true,
      },
    },
    {
      path: 'project/:projectId/detail',
      name: 'project-detail',
      component: ProjectDetail,
      meta: {
        auth: true,
      },
    },
    {
      path: 'project/:projectId/:taskId/edit-project',
      name: 'task-editor',
      component: ProjectEditPage,

      meta: {
        auth: true,
      },
    },
    {
      path: 'project/:projectId/splitscreen',
      name: 'splitscreen',
      component: SplitscreenViewer,
      meta: {
        auth: true,
      },
    },
    {
      path: 'settings',
      name: 'Settings',
      component: Settings,

      meta: {
        auth: true,
      },
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,

      meta: {
        auth: true,
      },
    },
    // Страница пользователя для просомтра админом
    {
      path: '/user/:userId',
      name: 'User',
      component: User,
      meta: {
        auth: true,
      },
      beforeEnter: (to, from, next) => {
        if (Vue.auth.user().id === to.params.userId) {
          next({ name: 'MyProfile' })
        } else {
          next()
        }
      },
    },
    // Страница текущего пользователя
    {
      path: '/profile',
      name: 'MyProfile',
      component: MyProfile,
      meta: {
        auth: true,
      },
    },
    // Страница всех уведомлений
    {
      path: '/notifications',
      name: 'NotificationsList',
      component: NotificationsList,
      meta: {
        auth: true,
      },
    },
    // Редирект на форму обратной связи в jira
    {
      path: '/report',
      name: 'ReportBug',
      component: Report,
      meta: {
        auth: true,
      },
    },
    {
      path: '/administrative',
      name: 'admin',
      component: Administrative,
      meta: {
        auth: true,
      },
      beforeEnter: (to, from, next) => {
        if (Vue.auth.user().is_superuser) {
          next()
        } else {
          next({ path: '/projects' })
        }
      },
    },
    {
      path: '/registration',
      name: 'Registration',
      component: NewUser,
      meta: {
        auth: false,
      },
    },
    // Страница пользователя для просомтра админом
    {
      path: '/user/:userId',
      name: 'User',
      component: User,
      meta: {
        auth: true,
      },
      beforeEnter: (to, from, next) => {
        if (Vue.auth.user().id === to.params.userId) {
          next({ name: 'MyProfile' })
        } else {
          next()
        }
      },
    },
    // Страница текущего пользователя
    {
      path: '/profile',
      name: 'MyProfile',
      component: MyProfile,
      meta: {
        auth: true,
      },
    },
    // Страница всех уведомлений
    {
      path: '/notifications',
      name: 'NotificationsList',
      component: NotificationsList,
      meta: {
        auth: true,
      },
    },
    // Редирект на форму обратной связи в jira
    {
      path: '/report',
      name: 'ReportBug',
      component: Report,
      meta: {
        auth: true,
      },
    },
    {
      path: '/administrative',
      name: 'admin',
      component: Administrative,
      meta: {
        auth: true,
      },
      beforeEnter: (to, from, next) => {
        if (Vue.auth.user().is_superuser) {
          next()
        } else {
          next({ path: '/projects' })
        }
      },
    },
    {
      path: '/files-upload',
      name: 'Upload',
      component: Upload,
      meta: {
        auth: true,
      },
      beforeEnter: (to, from, next) => {
        if (Vue.auth.user().is_superuser) {
          next()
        } else {
          next({ path: '/projects' })
        }
      },
    },
  ],
}
