/**
 * @file Определяет  класс MultiPointMeasure
 */
import {
  DEFAULT_FILL_OPACITY,
  DISTANCE_FOR_CAMERA_CENTERING,
  MODE_3D,
} from '@nova/constants'
import { Box3, Color, Object3D, Vector2, Vector3, Sphere } from 'three'
import DashMeasure from './DashMeasure'
import Utils from '@nova/Classes/Utils'
import { LatLng } from 'leaflet/dist/leaflet-src.esm'
import * as utm from 'utm'

/**
 * Класс который определяет замеры, в которых более одной точки
 */
class MultiPointMeasure extends DashMeasure {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Object} объект в котором зранятся свойства для создания объекта
   */
  constructor(vm, data = null) {
    if (data === null) {
      data = {}
    }
    super(vm, data)
    this._centerPoint = data._centerPoint
      ? new LatLng(data._centerPoint.lat, data._centerPoint.lng, data._centerPoint.alt)
      : null
    this._fill = data.fill === undefined ? true : data.fill
  }

  /**
   * @inheritDoc
   */
  centerCamera() {
    if (this.vm.currentMode === MODE_3D) {
      const points = this.$potreeMeasure.points.map((p) => p.position)

      const box = new Box3()
      box.setFromPoints(points)
      const node3d = new Object3D()
      node3d.boundingBox = box
      node3d.boundingSphere = box.getBoundingSphere(new Sphere(this.centerPoint3D))
      node3d.boundingSphere.radius = DISTANCE_FOR_CAMERA_CENTERING
      this.vm.viewer.zoomTo(node3d, 1, 300)
      setTimeout(this.vm.scaleNameLabels, 300)
    } else if (this.vm.currentMode) {
      console.warn('Центрирование карты по замеру не реализовано в режиме 2D')
    }
  }

  /**
   * @inheritDoc
   */
  setLayerColor(color) {
    // проставляем выбранные цвета, как цвета по умолчанию
    // this.$layer.options = this.$layer.options || {opacity: 0.4}
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        this.$layer.options.fillColor = color
        this.$layer.options.color = color
        // и меняем цвет текущего слоя
        this.$layer.setStyle(this.$layer.options)
      }
      if (this.$potreeMeasure) {
        const _color = new Color(color)
        this.$potreeMeasure.color = _color
        this.$potreeMeasure.spheres.map((sphere) => {
          sphere.material.color = _color
        })
      }
    } else {
      console.warn(
        'Нельзя изменить цвет замера, отсутстует объект $layer или $potreeMeasure',
        this
      )
    }
  }

  /**
   * @inheritDoc
   */
  setLayerOpacity(opacity) {
    if (this.$layer || this.$potreeMeasure) {
      if (this.$layer) {
        this.$layer.options.opacity = opacity
        // Вычисляем прозрачность заливки
        const fillOpacity = parseFloat(opacity) * DEFAULT_FILL_OPACITY
        this.$layer.options.fillOpacity = fillOpacity.toFixed(3)
        // применяем новый стиль
        this.$layer.setStyle(this.$layer.options)
        // debugger
      }
      if (this.$potreeMeasure) {
        console.error(
          'Нельзя изменить прозрачность замера, функционал не реализован в режиме 3D',
          this
        )
        // this.$potreeMeasure.color = new THREE.Color(color)
      }
    } else {
      console.warn(
        'Нельзя изменить прозрачность замера, отсутстует объект $layer или $potreeMeasure',
        this
      )
    }
  }

  /**
   * @inheritDoc
   */
  createPotreeMeasure() {
    super.createPotreeMeasure()
    this.$potreeMeasure.dashed = this.dashed
    this.$potreeMeasure.gapSize = this.potreeGapSize
    this.$potreeMeasure.dashSize = this.potreeDashSize
  }

  /**
   * Функция считающая центр фигуры из координат точки в 3D
   * @param point точка в 3D
   */
  setCenterPoint(point) {
    const newCenterPoint = utm.toLatLon(
      point.x,
      point.y,
      this.vm.utmZoneNum,
      this.vm.utmZoneLetter,
      undefined,
      false
    )
    this._centerPoint = new LatLng(
      newCenterPoint.latitude,
      newCenterPoint.longitude,
      point.z
    )
  }

  /**
   * @inheritDoc
   */
  async fixAltitudes() {
    if (this.vm.pointcloud) {
      const points = this.pointsWithoutAltitude2D
      this._centerPoint = this.centerPoint
      const point3D = utm.fromLatLon(this._centerPoint.lat, this._centerPoint.lng)
      const vector = new Vector2(point3D.easting, point3D.northing)
      vector.rawPoint = this._centerPoint
      points.push(vector)
      await Utils.fixPointsZ(this.vm.pointcloud, points, this.vm)
      this.updateCalculations()
    } else {
      console.warn(
        'Не удалось пофиксить высоты, так как отсутствует pointcloud для расчетов',
        this
      )
    }
  }

  /**
   * @inheritDoc
   */
  get color() {
    return super.color
  }

  /**
   * @inheritDoc
   */
  set color(value) {
    if (value !== this.color) {
      this._color = value
      if (this.vm.currentMeasure !== this) {
        this.setLayerColor(this._color)
      }
    }
  }

  /**
   * Возвращает длину самого длинного отрекзка в замере
   * @return {number}
   */
  get longestVertex() {
    let length = 0
    for (let pointIndex = 0; pointIndex < this.points.length; pointIndex++) {
      const currentPoint = this.points[pointIndex]
      const nextPoint =
        pointIndex === this.points.length - 1
          ? this.points[0]
          : this.points[pointIndex + 1]
      const distance = currentPoint.distanceTo(nextPoint)
      length = distance > length ? distance : length
    }
    return length
  }

  /**
   * Возвращает центральную точку в замере
   * @return {LatLng}
   */
  get centerPoint() {
    if (this._centerPoint) {
      return this._centerPoint
    } else {
      return this.$layer.getCenter()
    }
  }

  /**
   * Возвращает центральную точку в замере для 3D режима
   * @return {Vector3}
   */
  get centerPoint3D() {
    if (this._centerPoint) {
      const point3D = utm.fromLatLon(this._centerPoint.lat, this._centerPoint.lng)
      return new Vector3(point3D.easting, point3D.northing, this._centerPoint.alt)
    } else {
      return new Vector3(0, 0, 0)
    }
  }

  /**
   * @return {Boolean}
   */
  get fill() {
    return this._fill
  }

  /**
   * @inheritDoc
   */
  get exportData() {
    const data = super.exportData
    data._centerPoint = this._centerPoint
    data.fill = this.fill

    return data
  }
}

export { MultiPointMeasure }
