<script>
import { Input, Button, ButtonGroup, Dropdown, DropdownMenu, DropdownItem, Card } from 'element-ui'
import AsyncImage from './AsyncImage'

export default {
  name: 'TasksPageGrid',
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Card.name]: Card,
    AsyncImage
  },
  props: {
    currentProjectTasks: {
      type: Array,
      default: () => {}
    }
  },
  methods: {
    /**
       * Загружаем тамбнейлы тасок
       * @param task - таска
       * @param asset - название тамбнейла
       * @return {string}
       */
    assetsPath(task, asset) {
      return this.$root.apiPath(`/api/projects/${task.project}/tasks/${task.id}/assets/${asset}`)
    },
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12 px-0">
        <div
          v-if="currentProjectTasks"
          class="container"
        >
          <div
            class="projects-grid row mt-5"
          >
            <!--          tasks cards open-->
            <div
              v-for="(task, index) in currentProjectTasks"
              :key="`${index}-task`"
              class="card-task col-12 col-sm-6 col-md-4 py-2"
            >
              <el-card
                shadow="never"
                class="custom-el-card"
                :body-style="{ padding: '0px'}"
              >
                <router-link
                  :to="{name: 'task-viewer', params: {projectId: task.project, taskId: task.id}}"
                >
                  <AsyncImage
                    :value="assetsPath(task, 'thumbnail.jpg')"
                    class="d-flex card-image"
                  />
                </router-link>
                <div class="d-flex py-3 px-0 card-bottom">
                  <div class="col-auto mr-auto">
                    <p class="text-truncate card-title">
                      {{ task.name || $gettext('no name') }}
                    </p>
                    <p class="card-date mb-0">
                      {{ task.created_at | moment("DD.MM.YYYY") }}
                    </p>
                  </div>
                  <div class="col-auto align-self-center">
                    <router-link
                      :to="{name: 'task-viewer', params: {projectId: task.project, taskId: task.id}}"
                    >
                      <el-button
                        type="info"
                        icon="icon nova-btn-play"
                        circle
                      />
                    </router-link>
                    <el-dropdown
                      trigger="click"
                      :hide-on-click="false"
                    >
                      <el-button
                        size="mini"
                        type="info"
                        icon="icon nova-dots icon-rotate-90"
                        circle
                      />
                      <el-dropdown-menu slot="dropdown">
                        <router-link
                          :to="{name: 'task-editor', params: {projectId: task.project, taskId: task.id}}"
                        >
                          <el-dropdown-item>
                            <translate>Edit</translate>
                          </el-dropdown-item>
                        </router-link>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </el-card>
            </div>
            <!--tasks cards close-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .el-dropdown-menu.el-popper > a {
    text-decoration: none;
    color: #000000;
  }
  /* card project*/
  .card-image {
    background: #000000;
    height: 150px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
  }
  .card-bottom {
    background: #808080 0 0 no-repeat padding-box;
    border-radius: 0 0 5px 5px;
  }
  .card-title {
    font: 700 14px/19px Open Sans;
    letter-spacing: 0;
    color: #FFFFFF;
  }
  .card-date {
    text-align: left;
    font: 100 14px/19px Open Sans;
    letter-spacing: 0;
    color: #FFFFFF;
  }
  .card-btns {
    margin: auto 0;
  }
  .card-btns > a {
    margin-right: 0.8em;
  }
  .el-button.is-circle {
    background: transparent;
    color: #FFFFFF;
    padding: 0;
    border: none;
    font-size: 30px;
  }
  .el-button--info {
    background: #747474 0 0 no-repeat padding-box;
    border-color: #747474;
  }
  .el-button-group .el-button--info {
    font-size: 16px;
    padding: 9px;
  }
  .el-button-group .el-button--info {
    font-size: 16px;
    padding: 9px;
  }
  .el-button--info {
    background: #747474 0 0 no-repeat padding-box;
    border-color: #747474;
  }
  .el-button-group .el-button--info:first-child {
    margin-right: 5px;
    border-right-color: #747474;
  }
  .el-button-group .el-button--info:last-child {
    border-left-color: #747474;
  }
  .el-button--info:hover, .el-button--info:focus {
    background: transparent !important;
    color: #51cbce !important;
  }
  .change-view-button:hover, .change-view-button:focus {
    /*color: #51cbce !important;*/
    background: transparent !important;
  }
  .el-input__inner {
    background: #4CD964 !important;
  }
  .btn-run-guide {
    position: fixed;
    top: 28px;
    right: 50px;
  }
  .custom-el-card {
    border: none;
    background-color: transparent;
  }
</style>
