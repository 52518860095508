<template>
  <div>
    <div
      v-nova-page-guide:projectsTable
      class="projects"
    >
      <div class="container">
        <div class="projects-list mt-5">
          <!--          projects table open-->
          <div class="tables">
            <el-table
              :data="tasks"
              row-class-name="custom-el-table-row"
              style="width: 100%"
            >
              <el-table-column
                :label="'Project' | translate"
                prop="name"
              />
              <el-table-column
                :label="'Create date' | translate"
              >
                <template slot-scope="scope">
                  {{ scope.row.created_at| moment("DD.MM.YYYY, hh:mm") }}
                </template>
              </el-table-column>
              <el-table-column
                :label="'Actions' | translate"
                prop="actions"
                align="right"
              >
                <template slot-scope="scope">
                  <div class="row justify-content-end">
                    <div class="col-5 col-lg-2 p-0">
                      <router-link
                        :to="{name: 'project-detail', params: {projectId: scope.row.id}}"
                      >
                        <el-button
                          icon="icon nova-btn-play"
                          circle
                        />
                      </router-link>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--          projects table close-->
      </div>
    </div>
  </div>
</template>

<script>
import { Input, Button, ButtonGroup, Table, TableColumn, Dropdown, DropdownItem, DropdownMenu, Tooltip } from 'element-ui'

export default {
  components: {
    [Input.name]: Input,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tooltip.name]: Tooltip,
  },
  props: {
    projects: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {}
  },
  computed: {
    tasks: {
      get() {
        return this.projects
      }
    },
  },
  methods: {
    checkDataType(row, type) {
      const dataTypeProject = row.available_assets.includes(type)
      return !dataTypeProject ? 'no-layer' : ''
    },
    handleEdit(index, row) {
      console.log(index, row)
    },
    handleDelete(index, row) {
      console.log(index, row)
    },
  },
}
</script>

<style scoped>
  .el-dropdown-menu.el-popper > a {
    text-decoration: none;
    color: #000000;
  }
  .el-button:hover,
  .el-button:focus {
    color: #51cbce !important;
    background: transparent !important;
  }
  .el-table {
    color: #FFFFFF;
  }
  .el-dropdown {
    margin-left: 16px;
  }
  .tables {
    width: 100%;
  }
  .icon-xl {
    font-size: 1.4em;
  }
  .no-layer {
    opacity: 0.3;
    cursor: not-allowed;
  }
</style>
<style>
  .absent-data-type {
    text-decoration: line-through !important;
  }
  .custom-el-table-row {
    color: white;
  }
  .custom-el-table-row:hover > td {
    background-color: #737373 !important;
  }
</style>
