<script>
import 'leaflet/dist/leaflet.css'
import * as L from 'leaflet'
import 'src/vendor/leaflet-pm/src/js/L.PM'
import 'src/vendor/leaflet-pm/src/css/controls.css'
import 'src/vendor/leaflet-pm/src/css/layers.css'
import '@letsnova/leaflet-side-by-side'
import 'src/util/leafletAuthorizationPlugin'
import _ from 'lodash'
export default {
  props: {
    layer1: {
      type: Object,
      default: () => {
        return {}
      }
    },
    layer2: {
      type: Object,
      default: () => {
        return {}
      }
    },
    vertical: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      map: null,
      accessToken: 'pk.eyJ1IjoiYWZhbmVvciIsImEiOiJjangzN3V0czAwN3o1M3lydzlxMGIyNWdkIn0.__BUTmfls9DLgcusu-3jXw',
      container: 'leaflet_map',
      props: {
        opacity: 100
      },
      project: {},
      task1: {},
      task2: {},
      sideBySideController: {},
    }
  },
  watch: {
    layer1(newVal, oldVal) {
      if (this.map.hasLayer(oldVal.$layer)) {
        oldVal.$layer.remove()
      }
      this.loadSplitScreen(newVal, this.layer2)
    },
    layer2(newVal, oldVal) {
      if (this.map.hasLayer(oldVal.$layer)) {
        oldVal.$layer.remove()
      }
      this.loadSplitScreen(this.layer1, newVal)
    },
    vertical(newVal) {
      if (!_.isEmpty(this.sideBySideController)) {
        this.sideBySideController._setOrientation(newVal)
      }
    },
  },
  async mounted() {
    // Создаем карту, добавляем подложку
    this.map = L.map(this.container, {
      scrollWheelZoom: true,
      positionControl: true,
      zoomControl: false,
      editable: true
    })
    L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      maxZoom: 18,
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    }).addTo(this.map)

    // Показываем весь мир
    this.map.fitWorld()
    // простановка текущего языка
    this.map.pm.setLang(this.$language.current)
    this.map.attributionControl.setPrefix('')
  },
  methods: {
    /**
     * Загрузка сплитскрина
     * @param layer1 - левый слой
     * @param layer2 - правый слой
     */
    loadSplitScreen(layer1, layer2) {
      // Проверяем есть ли слои вообще
      const layers = []
      if (!_.isEmpty(layer1)) {
        layers.push(layer1)
      }
      if (!_.isEmpty(layer2)) {
        layers.push(layer2)
      }
      layers.map(layer => {
        if (this.map.hasLayer(layer.$layer)) {
          layer.$layer.remove()
        }
      })
      // Если один просто отображаем его
      if (layers.length === 1) {
        this.loadExtraLayer(layers[0])
          .then(() => {
            this.showLayer(layers[0])
            const bounds = L.latLngBounds(
              [layers[0].bounds.slice(0, 2).reverse(), layers[0].bounds.slice(2, 4).reverse()]
            )
            this.map.fitBounds(bounds)
          })
      } else if (layers.length === 2) {
        // Если два - грузим их и делаем сплитскрин если его нет
        this.loadExtraLayer(layers[0])
          .then(() => {
            this.loadExtraLayer(layers[1])
              .then(() => {
                layers.map(layer => {
                  this.showLayer(layer)
                })
                if (_.isEmpty(this.sideBySideController)) {
                  this.sideBySideController = L.control.sideBySide(layers[0].$layer, layers[1].$layer)
                  this.sideBySideController.addTo(this.map)
                  this.sideBySideController._setOrientation(this.vertical)
                } else {
                  this.sideBySideController.setLeftLayers(layers[0].$layer)
                  this.sideBySideController.setRightLayers(layers[1].$layer)
                }
                // Зумим карту по координатам слоев
                const bounds1 = L.latLngBounds(
                  [layers[0].bounds.slice(0, 2).reverse(), layers[0].bounds.slice(2, 4).reverse()]
                )
                const bounds2 = L.latLngBounds(
                  [layers[1].bounds.slice(0, 2).reverse(), layers[1].bounds.slice(2, 4).reverse()]
                )
                bounds1.extend(bounds2)
                this.map.fitBounds(bounds1)
              })
          })
      }
    },
    /**
     * Добавить слой на карту
     * @param layer
     */
    showLayer(layer) {
      this.map.addLayer(layer.$layer)
    },
    /**
     * Подгрузка слоя
     * @param item - слой
     * @returns {Promise<void>}
     */
    async loadExtraLayer(item) {
      const meta = {
        project: this.$route.params.projectId,
        task: item.$task.taskInfo.id,
        type: item.type,
        name: item.name
      }
      const bounds = L.latLngBounds(
        [item.bounds.slice(0, 2).reverse(), item.bounds.slice(2, 4).reverse()]
      )
      const layer = L.tileLayer.withAuth(item.tiles[0], {
        bounds,
        minZoom: item.minzoom,
        maxZoom: L.Browser.retina ? (item.maxzoom + 1) : item.maxzoom,
        maxNativeZoom: L.Browser.retina ? (item.maxzoom - 1) : item.maxzoom,
        tms: item.scheme === 'tms',
        opacity: this.props.opacity / 100,
        detectRetina: true
      },
      this.$root.headers)

      // Associate metadata with this layer
      layer[Symbol.for('meta')] = meta

      item.$layer = layer

      // For some reason, getLatLng is not defined for tileLayer?
      // We need this function if other code calls layer.openPopup()
      layer.getLatLng = function() {
        return this.options.bounds.getCenter()
      }
    },
  },
}
</script>

<template>
  <div style="height: 100%; width: 100%;">
    <div
      id="leaflet_map"
      style="height: 100%; width: 100%; position: relative"
    />
  </div>
</template>

<style scoped>

</style>
