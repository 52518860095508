import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Registration from './Registration'

export default [
  // Login Page
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false }
  },

  // Forgot password page
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: ForgotPassword,
    meta: { auth: false }
  },

  // Reset password page
  {
    path: '/reset-password/:uid-:key',
    name: 'Reset password',
    component: ResetPassword,
    meta: { auth: false }
  },

  // Registration page
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: { auth: false }
  }
]
