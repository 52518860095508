/**
 * @file Определяет  класс Annotation
 */
import uuidv4 from 'uuid/v4'
import { Measure } from '../Measures/Measure'
import { Line } from '../Measures/Line'
import { Polygon } from '../Measures/Polygon'
import { Marker } from '../Measures/Marker'
import Rectangle from '../Measures/Rectangle'
import Circle from '../Measures/Circle'
import Camera from '../Measures/Camera'

/**
 * Класс аннотации
 */
class Annotation {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Array.<Object>} массив объектов в котором содержатся объекты класса Annotation
   */
  constructor(vm, data = null) {
    if (data === null) {
      data = {
        $annotations: vm.annotations
      }
    }

    if (data.$annotations) {
      this.$annotations = data.$annotations
    } else {
      console.error('В аннотации не указана ссылка на Annotations', this, data)
    }

    this.id = data.id || null

    this._uuid = uuidv4()

    this.name = data.name || null
    this.description = data.description || null
    this._color = data.color || null
    this.sortOrder = data.sort_order || 0


    this.vm = vm
    this.data = data
    this._measures = data.data || []
    // this.visibility = true
    this._opacity = data.opacity || 1.0

    this.measures = this._measures.map(m => {
      m.$annotation = this
      switch (m.type) {
        case 'annotation-line':
          return new Line(this.vm, m)
        case 'annotation-polygon':
          return new Polygon(this.vm, m)
        case 'annotation-marker':
          return new Marker(this.vm, m)
        case 'annotation-rectangle':
          return new Rectangle(this.vm, m)
        case 'annotation-circle':
          return new Circle(this.vm, m)
        case 'annotation-camera':
          return new Camera(this.vm, m)
      }
    })
  }

  /**
   * Устанавливает значения цвета для всез замеров в аннотации
   * @param color {String} - новый цвет замеров в аннотации
   * @return {undefined}
   */
  setLayerColor(color) {
    this.measures.map(m => m.setLayerColor(color))
  }

  /**
   * Функция устанавливает значения прозрачности для всех замеров в аннотации
   * @param opacity {Number} - устанавливает прозрачность всех замеров в аннотации
   * @return {undefined}
   */
  setLayerOpacity(opacity) {
    this.measures.map(m => m.setLayerOpacity(opacity))
  }

  /**
   * Функция удаляет все данные по аннотации и ее саму
   * @return {undefined}
   */
  remove() {
    // Удаляем все замеры
    this.measures.map(m => m).map(m => m.remove())

    // Удаляем аннотацию из аннотаций
    const annotationIndex = this.$annotations.data.indexOf(this)
    if (annotationIndex !== -1) {
      this.$annotations.data.splice(annotationIndex, 1)
    }
    if (this.vm.currentAnnotation === this) { this.vm.currentAnnotation = null }
    if (this.vm.selectedInspectorItem === this) { this.vm.selectedInspectorItem = null }
  }

  /**
   * Функция добавляет замер в анноатцию или создает замер из объекта
   * @param data {Object}
   */
  add(data) {
    data.$annotation = this
    if (data instanceof Measure) {
      this.measures.push(data)
    } else {
      let measure
      switch (data.type) {
        case 'annotation-line':
          measure = new Line(this.vm, data)
          break
        case 'annotation-polygon':
          measure = new Polygon(this.vm, data)
          break
        case 'annotation-marker':
          measure = new Marker(this.vm, data)
          break
        case 'annotation-rectangle':
          measure = new Rectangle(this.vm, data)
          break
        case 'annotation-circle':
          measure = new Circle(this.vm, data)
          break
        case 'annotation-camera':
          measure = new Camera(this.vm, data)
          break
      }
      this.measures.push(measure)
    }
  }

  /**
   * Возвращает уникальный номер аннотации
   * @return {uuidv4}
   */
  get treeNodeId() {
    return this._uuid
  }

  /**
   * Возвращает лейбл для анноатции
   * @return {String}
   */
  get label() {
    return this.name ? this.name : this.vm.$gettext('Group')
  }

  /**
   * Возвращает цвет аннотации
   * @return {String}
   */
  get color() {
    return this._color || '#17f589'
  }

  /**
   * Устанавливает цвет аннотации
   * @param value {String}
   */
  set color(value) {
    if (value !== this.color) {
      this._color = value
      // eslint-disable-next-line no-return-assign
      this.measures.map(m => m.color = value)
      if (this.vm.currentAnnotation !== this) {
        this.setLayerColor(this._color)
      }
    }
  }

  /**
   * Возвращает текущую прозрачность аннотации
   * @return {number}
   */
  get opacity() {
    return this._opacity
  }

  /**
   * Устанавливает значение прозрачности
   * @param value {Number}
   */
  set opacity(value) {
    if (value !== this._opacity) {
      this._opacity = value
      // eslint-disable-next-line no-return-assign
      this.measures.map(m => m.opacity = value)
      if (this.vm.currentAnnotation !== this) {
        this.setLayerOpacity(this.opacity)
      }
    }
  }

  /**
   * Возвращает тип объекта
   * @return {String}
   */
  get type() {
    return 'annotation'
  }

  /**
   * Возвращает иконку объекта
   * @return {String}
   */
  get icon() {
    return 'far fa-comment-alt'
  }

  /**
   * Возврашает текущие параметры в меню
   * @return {Object}
   */
  get menu() {
    return {
      opacity: this.opacity,
      save: true,
      del: true,
    }
  }

  /**
   * Возвращает массив всех замеров
   * @return {Array.<Measure>}
   */
  get children() {
    return this.measures
  }

  get $ref() {
    return this
  }

  /**
   * Возвращает данные для сохранения аннотации
   * @return {Object}
   */
  get exportData() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      color: this._color,
      sort_order: this.sortOrder,
      data: this.measures.map(measure => measure.exportData),
      opacity: this._opacity
    }
  }

  /**
   * Возвращает видна ли аннотация
   * @return {boolean}
   */
  get visibility() {
    for (const measure of this.measures) {
      if (measure.visibility) {
        return true
      }
    }
    // Без этого у пустой группы замеров отображается "перечёркнутый глаз" (как будто группа скрыта)
    if (this.measures.length === 0) {
      return true
    }
    return false
  }

  /**
   * Устанавливает значение видимости для аннотации
   * @param value {boolean}
   */
  set visibility(value) {
    this.measures.map(measure => {
      measure.visibility = value
    })
  }
}

export { Annotation }
