<template xmlns:v-nova-page-guide="http://www.w3.org/1999/xhtml">
  <div v-if="visible" class="controls">
    <TimelinePanel v-if="showTimeline" v-model="showTimeline" />
    <div v-nova-page-guide:controlsBtn class="control-btns control-btns-tools">
      <!--            <el-tooltip :visible-arrow="false" :content="Select and modify"-->
      <!--                        :open-delay="0"-->
      <!--                        placement="right">-->
      <!--              <button class="p-0">-->
      <!--                <i class="fas fa-mouse-pointer"></i>-->
      <!--              </button>-->
      <!--            </el-tooltip>-->

      <div v-nova-page-guide:drawLine>
        <div style="position: relative">
          <el-tooltip
            :visible-arrow="false"
            :content="'Measure a distance or elevation' | translate"
            :open-delay="600"
            :disabled="modalBtns.line"
            placement="right"
          >
            <button
              id="draw_distance"
              class="p-0"
              :class="{ 'active-control-btn': modalBtns.line }"
              @click="drawLine"
            >
              <svg
                width="21"
                height="21"
                stroke="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <ellipse
                    id="svg_1"
                    ry="2.5"
                    rx="2.5"
                    cy="17"
                    cx="4"
                    stroke-width="1.5"
                    fill="none"
                  />
                  <ellipse
                    id="svg_2"
                    ry="2.5"
                    rx="2.5"
                    cy="4"
                    cx="17"
                    stroke-width="1.5"
                    fill="none"
                  />
                  <line
                    id="svg_4"
                    stroke-linecap="null"
                    stroke-linejoin="null"
                    stroke-opacity="null"
                    stroke-width="2"
                    fill="none"
                    y2="15"
                    x2="6"
                    y1="6"
                    x1="15"
                  />
                </g>
              </svg>
            </button>
          </el-tooltip>

          <div v-show="modalBtns.line" class="modal-btns">
            <button class="mr-1 p-0 px-3" @click="finishMeasure">
              <translate>Finish</translate>
            </button>
            <button class="mr-1 p-0 px-3" @click="removeLastVertex">
              <translate>Remove last vertex</translate>
            </button>
            <button class="mr-1 p-0 px-3" @click="disableTool">
              <translate>Cancel</translate>
            </button>
          </div>
        </div>
      </div>

      <div v-nova-page-guide:drawPolygon style="position: relative">
        <el-tooltip
          :visible-arrow="false"
          :content="'Measure an area' | translate"
          :open-delay="600"
          :disabled="modalBtns.polygon"
          placement="right"
        >
          <button
            id="draw_polygon"
            class="p-0"
            :class="{ 'active-control-btn': modalBtns.polygon }"
            @click="drawPolygon"
          >
            <i class="fas fa-draw-polygon" />
          </button>
        </el-tooltip>
        <div v-show="modalBtns.polygon" class="modal-btns">
          <button class="mr-1 p-0 px-3" @click="finishMeasure">
            <translate>Finish</translate>
          </button>
          <button class="mr-1 p-0 px-3" @click="removeLastVertex">
            <translate>Remove last vertex</translate>
          </button>
          <button class="mr-1 p-0 px-3" @click="disableTool">
            <translate>Cancel</translate>
          </button>
        </div>
      </div>

      <div v-if="false" style="position: relative">
        <el-tooltip
          :visible-arrow="false"
          :content="'Measure a volume' | translate"
          :open-delay="600"
          :disabled="modalBtns.volume"
          placement="right"
        >
          <button
            id="draw_volume"
            class="p-0"
            :class="{ 'active-control-btn': modalBtns.volume }"
            @click="drawVolume"
          >
            <i class="fas fa-ring" />
          </button>
        </el-tooltip>
        <div v-show="modalBtns.volume" class="modal-btns">
          <button class="mr-1 p-0 px-3" @click="finishMeasure">
            <translate>Finish</translate>
          </button>
          <button class="mr-1 p-0 px-3" @click="removeLastVertex">
            <translate>Remove last vertex</translate>
          </button>
          <button class="mr-1 p-0 px-3" @click="disableTool">
            <translate>Cancel</translate>
          </button>
        </div>
      </div>

      <div v-if="false" style="position: relative">
        <el-tooltip
          :visible-arrow="false"
          :content="'Edit layer' | translate"
          :open-delay="600"
          :disabled="modalBtns.edit"
          placement="right"
        >
          <button
            id="leaflet_edit"
            class="p-0"
            :class="{ 'active-control-btn': modalBtns.edit }"
            @click="drawEdit"
          >
            <i class="fas fa-bezier-curve" />
          </button>
        </el-tooltip>
        <div v-show="modalBtns.edit" class="modal-btns">
          <button class="mr-1 p-0 px-3" @click="drawEdit">
            <translate>Finish</translate>
          </button>
        </div>
      </div>

      <div v-if="false" style="position: relative">
        <el-tooltip
          :visible-arrow="false"
          :content="'Move the measurement' | translate"
          :open-delay="600"
          :disabled="modalBtns.drag"
          placement="right"
        >
          <button
            id="leaflet_drag"
            class="p-0"
            :class="{ 'active-control-btn': modalBtns.drag }"
            @click="drawDrag"
          >
            <i class="fas fa-expand-arrows-alt" />
          </button>
        </el-tooltip>
        <div v-show="modalBtns.drag" class="modal-btns">
          <button class="mr-1 p-0 px-3" @click="drawDrag">
            <translate>Finish</translate>
          </button>
        </div>
      </div>

      <div v-nova-page-guide:drawMarker style="position: relative">
        <el-tooltip
          :visible-arrow="false"
          :content="'Add a marker' | translate"
          :open-delay="600"
          :disabled="modalBtns.marker"
          placement="right"
        >
          <button
            id="draw_point"
            class="p-0"
            :class="{ 'active-control-btn': modalBtns.marker }"
            @click="drawMarker"
          >
            <i class="fas fa-map-marker-alt" />
          </button>
        </el-tooltip>
        <div v-show="modalBtns.marker" class="modal-btns">
          <button class="mr-1 p-0 px-3" @click="disableTool">
            <translate>Finish</translate>
          </button>
        </div>
      </div>
      <div v-nova-page-guide:drawRectangle style="position: relative">
        <el-tooltip
          :visible-arrow="false"
          :content="'Add a rectangle' | translate"
          :open-delay="600"
          :disabled="modalBtns.rectangle"
          placement="right"
        >
          <button
            class="p-0"
            :class="{ 'active-control-btn': modalBtns.rectangle }"
            @click="drawRectangle"
          >
            <i class="far fa-square" />
          </button>
        </el-tooltip>
        <div v-show="modalBtns.rectangle" class="modal-btns">
          <button class="mr-1 p-0 px-3" @click="disableTool">
            <translate>Cancel</translate>
          </button>
        </div>
      </div>
      <div v-nova-page-guide:drawCircle style="position: relative">
        <el-tooltip
          :visible-arrow="false"
          :content="'Add a circle' | translate"
          :open-delay="600"
          :disabled="modalBtns.circle"
          placement="right"
        >
          <button
            class="p-0"
            :class="{ 'active-control-btn': modalBtns.circle }"
            @click="drawCircle"
          >
            <i class="far fa-circle" />
          </button>
        </el-tooltip>
        <div v-show="modalBtns.circle" class="modal-btns">
          <button class="mr-1 p-0 px-3" @click="disableTool">
            <translate>Cancel</translate>
          </button>
        </div>
      </div>

      <div v-show="mode === MODE_3D" style="position: relative">
        <el-tooltip
          :visible-arrow="false"
          :content="'Add a camera' | translate"
          :open-delay="600"
          :disabled="modalBtns.camera"
          placement="right"
        >
          <button
            id="draw_camera"
            class="p-0"
            :class="{ 'active-control-btn': modalBtns.camera }"
            @click="drawCamera"
          >
            <i class="fas fa-video" />
          </button>
        </el-tooltip>
        <div v-show="modalBtns.camera" class="modal-btns">
          <button class="mr-1 p-0 px-3" @click="disableTool">
            <translate>Finish</translate>
          </button>
        </div>
      </div>
    </div>
    <div v-nova-page-guide:controlBtnView3d>
      <div v-nova-page-guide:controlBtnView class="control-btns control-btns-view ">
        <el-tooltip
          :visible-arrow="false"
          :content="'Show timeline' | translate"
          :open-delay="600"
          placement="left"
        >
          <button class="p-0" @click="showTimeline = true">
            <i class="fas fa-history" />
          </button>
        </el-tooltip>
        <el-tooltip
          :visible-arrow="false"
          :content="'Take screenshot' | translate"
          :open-delay="600"
          placement="left"
        >
          <button class="p-0" @click="takeScreenshot">
            <i class="fas fa-camera" />
          </button>
        </el-tooltip>

        <el-tooltip
          :visible-arrow="false"
          :content="'Center map' | translate"
          :open-delay="600"
          placement="left"
        >
          <button class="p-0" @click="centerView">
            <svg
              width="24px"
              height="32px"
              viewBox="0 0 24 24"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
              class="bg-warning1"
            >
              <path
                d="M5 15H3v4a2.006 2.006 0 002 2h4v-2H5v-4zM5 5h4V3H5a2.006 2.006 0 00-2 2v4h2V5zM19 3h-4v2h4v4h2V5a2.006 2.006 0 00-2-2zM19 19h-4v2h4a2.006 2.006 0 002-2v-4h-2v4zM12 15a3 3 0 100-6 3 3 0 000 6z"
              />
            </svg>
          </button>
        </el-tooltip>

        <el-tooltip
          :visible-arrow="false"
          :content="'Zoom in' | translate"
          :open-delay="600"
          placement="left"
        >
          <button class="p-0" @click="zoomIn">
            <i class="fas fa-search-plus" />
          </button>
        </el-tooltip>
        <el-tooltip
          :visible-arrow="false"
          :content="'Zoom out' | translate"
          :open-delay="600"
          placement="left"
        >
          <button class="p-0" @click="zoomOut">
            <i class="fas fa-search-minus" />
          </button>
        </el-tooltip>

        <div v-show="mode === MODE_3D">
          <el-tooltip
            :visible-arrow="false"
            :content="'Left view' | translate"
            :open-delay="600"
            placement="left"
          >
            <button class="p-0" @click="leftView">
              <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <polygon
                    points="6.6,2 6.5,13.5 1,19 1,7.5"
                    fill="#ffffff"
                    opacity="0.7"
                    stroke="#ffffff"
                    stroke-width="1.5"
                  />
                  <ellipse
                    id="svg_4"
                    fill="#C30A35"
                    stroke="#C30A35"
                    stroke-width="1.5"
                    cx="10.5"
                    cy="10.5"
                    rx="6"
                    ry="6"
                  />
                </g>
              </svg>
            </button>
          </el-tooltip>

          <el-tooltip
            :visible-arrow="false"
            :content="'Right view' | translate"
            :open-delay="600"
            placement="left"
          >
            <button class="p-0" @click="rightView">
              <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <ellipse
                    id="svg_4"
                    fill="#C30A35"
                    stroke="#C30A35"
                    stroke-width="1.5"
                    cx="10.5"
                    cy="10.5"
                    rx="6"
                    ry="6"
                  />
                  <polygon
                    points="20,13.5 20,2 14.4,7.6 14.4,19"
                    fill="#ffffff"
                    opacity="0.7"
                    stroke="#ffffff"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </button>
          </el-tooltip>

          <el-tooltip
            :visible-arrow="false"
            :content="'Front view' | translate"
            :open-delay="600"
            placement="left"
          >
            <button class="p-0" @click="frontView">
              <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <ellipse
                    id="svg_4"
                    fill="#C30A35"
                    stroke="#C30A35"
                    stroke-width="1.5"
                    cx="10.5"
                    cy="10.5"
                    rx="6"
                    ry="6"
                  />
                  <rect
                    id="svg_15"
                    fill="#ffffff"
                    stroke="#ffffff"
                    stroke-width="1.5"
                    opacity="0.7"
                    x="0.96875"
                    y="8"
                    width="12"
                    height="12"
                  />
                </g>
              </svg>
            </button>
          </el-tooltip>

          <el-tooltip
            :visible-arrow="false"
            :content="'Back view' | translate"
            :open-delay="600"
            placement="left"
          >
            <button class="p-0" @click="backView">
              <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <rect
                    id="svg_16"
                    fill="#ffffff"
                    stroke="#ffffff"
                    stroke-width="1.5"
                    opacity="0.7"
                    x="8"
                    y="1"
                    width="12"
                    height="12"
                  />
                  <ellipse
                    id="svg_4"
                    fill="#C30A35"
                    stroke="#C30A35"
                    stroke-width="1.5"
                    cx="10.5"
                    cy="10.5"
                    rx="6"
                    ry="6"
                  />
                </g>
              </svg>
            </button>
          </el-tooltip>

          <el-tooltip
            :visible-arrow="false"
            :content="'Top view' | translate"
            :open-delay="600"
            placement="left"
          >
            <button class="p-0" @click="topView">
              <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <ellipse
                    id="svg_4"
                    fill="#C30A35"
                    stroke="#C30A35"
                    stroke-width="1.5"
                    cx="10.5"
                    cy="10.5"
                    rx="6"
                    ry="6"
                  />
                  <polygon
                    points="7.5,1 19,1 13.4,6.5 2,6.5"
                    fill="#ffffff"
                    opacity="0.7"
                    stroke="#ffffff"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </button>
          </el-tooltip>

          <el-tooltip
            :visible-arrow="false"
            :content="'Bottom view' | translate"
            :open-delay="600"
            placement="left"
          >
            <button id="bottom_view" class="p-0" @click="bottomView">
              <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <polygon
                    points="19,14.5 7.6,14.5 2,20 13.4,20"
                    fill="#ffffff"
                    opacity="0.7"
                    stroke="#ffffff"
                    stroke-width="1.5"
                  />
                  <ellipse
                    id="svg_4"
                    fill="#C30A35"
                    stroke="#C30A35"
                    stroke-width="1.5"
                    cx="10.5"
                    cy="10.5"
                    rx="6"
                    ry="6"
                  />
                </g>
              </svg>
            </button>
          </el-tooltip>

          <el-tooltip
            v-if="texturesIncluded"
            :visible-arrow="false"
            :content="'Turn on helper' | translate"
            :open-delay="600"
            placement="left"
          >
            <button class="p-0" @click="$emit('includeHelper')">
              <i class="fas fa-low-vision" />
            </button>
          </el-tooltip>
          <el-tooltip
            v-if="texturesIncluded"
            :visible-arrow="false"
            :content="'Standard light' | translate"
            :open-delay="600"
            placement="left"
          >
            <button
              :disabled="modeLight === 'AmbientLight'"
              class="p-0"
              @click="$emit('changeLight', 'AmbientLight')"
            >
              <i class="fas fa-lightbulb" />
            </button>
          </el-tooltip>
          <el-tooltip
            v-if="texturesIncluded"
            :visible-arrow="false"
            :content="'Sun light' | translate"
            :open-delay="600"
            placement="left"
          >
            <button
              :disabled="modeLight === 'DirectionalLight'"
              class="p-0"
              @click="$emit('changeLight', 'DirectionalLight')"
            >
              <i class="fas fa-sun" />
            </button>
          </el-tooltip>
          <div v-show="texturesIncluded" style="position: relative">
            <el-tooltip
              v-if="texturesIncluded"
              :visible-arrow="false"
              :content="'Spotlight' | translate"
              :open-delay="600"
              placement="left"
            >
              <button
                :disabled="modeLight === 'SpotLight'"
                class="p-0"
                @click="$emit('changeLight', 'SpotLight')"
              >
                <i class="fas fa-shower" />
              </button>
            </el-tooltip>
            <div v-show="modeLight === 'SpotLight'" class="modal-btns modal-btns-right">
              <button class="p-0 px-3" @click="$emit('addSpotlight')">
                <i class="fas fa-plus" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MODE_2D, MODE_3D, SELECTED_COLOR, ZOOM_DELTA } from '@nova/constants'
import { Tooltip } from 'element-ui'
import 'leaflet'
import { SVGIcon } from 'leaflet-svgicon'
import { Marker } from '@nova/Classes/Common/Measures/Marker'
import { MOUSE } from 'three'
import Camera from '@nova/Classes/Common/Measures/Camera'
import TimelinePanel from './TimelinePanel'

export default {
  name: 'ControlsToolbar',
  components: {
    [Tooltip.name]: Tooltip,
    TimelinePanel,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    currentMeasure: {
      type: Object,
      default: () => {},
    },
    currentAnnotation: {
      type: Object,
      default: () => {},
    },
    texturesIncluded: {
      type: Boolean,
      default: false,
    },
    modeLight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      MODE_2D,
      MODE_3D,
      selectedBtn: null,
      currentTool: null,
      modalBtns: {
        line: false,
        polygon: false,
        volume: false,
        edit: false,
        drag: false,
        marker: false,
        rectangle: false,
        circle: false,
        camera: false,
      },
      showTimeline: false,
    }
  },
  computed: {
    map() {
      return this.$root.storeMap.state.map
    },
    viewer() {
      return this.$root.storeViewer.state.viewer
    },
    showTitleLabels() {
      return this.$store.state.viewer.showTitleLabels
    },
    showMeasureLabels() {
      return this.$store.state.viewer.showMeasureLabels
    },
  },
  watch: {
    viewer(newValue) {
      if (newValue) {
        this.initEventListeners()
      }
    },
    map(newValue) {
      if (newValue) {
        this.initEventListeners()
      }
    },
    value(newValue, oldValue) {
      if (oldValue && oldValue.isNew) {
        oldValue.remove()
        if (!(newValue && newValue.isNew)) {
          this.disableTool(false)
        }
      }
    },
  },
  methods: {
    initEventListeners() {
      if (this.mode === MODE_2D) {
        // Выключение режима при нажатии на правую кнопку
        this.map.on('contextmenu', this.disableTool, this)
        this.map.on('pm:create', ({ shape, layer }) => {
          this.value.isNew = false
          this.value.$layer = layer
          layer.$measure = this.value
          this.value.setLayerColor(SELECTED_COLOR)

          if (!(this.value instanceof Marker || this.value instanceof Camera)) {
            this.disableTool()
          } else {
            this.$emit('initEventListeners')
          }
        })
      } else if (this.mode === MODE_3D) {
        this.viewer.addEventListener('cancel_measure', ({ measure }) => {
          if (measure.$measure) {
            switch (measure.$measure.type) {
              case 'annotation-line':
                if (measure.points.length >= 2) {
                  measure.$measure.isNew = false
                }
                this.disableTool()
                break
              case 'annotation-polygon':
                if (measure.points.length >= 3) {
                  measure.$measure.isNew = false
                }
                this.disableTool()
                break
              case 'annotation-rectangle':
                if (measure.points.length >= 1) {
                  measure.$measure.isNew = false
                }
                measure.$measure.changeSpheresVisibility()
                this.disableTool()
                break
              case 'annotation-circle':
                if (measure.points.length >= 1) {
                  measure.$measure.isNew = false
                }
                // Скрываем сферы
                measure.$measure.changeSpheresVisibility()
                this.disableTool()
                break
              default:
                debugger
            }
          }
        })
      }
    },
    disableTool(removeValue = true) {
      if (this.currentTool) {
        this.currentTool.disable()
        this.currentTool = null
      }
      if (removeValue && this.value && this.value.isNew) {
        this.value.remove()
      }
      this.modalBtns[this.selectedBtn] = false
      this.selectedBtn = null
    },
    centerView() {
      switch (this.mode) {
        case MODE_2D:
          this.map.fitBounds(this.$store.state.viewer.mapBounds)
          break
        case MODE_3D:
          this.viewer.fitToScreen()
          break
        default:
          console.error('Неизвестный режим редактирования')
      }
    },
    leftView() {
      this.viewer.setLeftView()
    },
    rightView() {
      this.viewer.setRightView()
    },
    frontView() {
      this.viewer.setFrontView()
    },
    backView() {
      this.viewer.setBackView()
    },
    topView() {
      this.viewer.setTopView()
    },
    bottomView() {
      this.viewer.setBottomView()
    },
    drawLine() {
      if (this.changeTool('line')) {
        const self = this
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = this.map.pm.Draw.Line
            this.currentTool.enable()
            break
          case MODE_3D:
            this.currentTool = {
              enable() {
                self.$emit('enableLine')
              },
              disable() {
                self.viewer.dispatchEvent('cancel_insertions')
              },
            }
            this.currentTool.enable()
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    drawRectangle() {
      if (this.changeTool('rectangle')) {
        const self = this
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = this.map.pm.Draw.Rectangle
            this.currentTool.enable()
            break
          case MODE_3D:
            this.currentTool = {
              enable() {
                self.$emit('enableRectangle')
              },
              disable() {
                self.viewer.dispatchEvent('cancel_insertions')
              },
            }
            this.currentTool.enable()
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    drawCircle() {
      if (this.changeTool('circle')) {
        const self = this
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = this.map.pm.Draw.Circle
            this.currentTool.enable()
            break
          case MODE_3D:
            this.currentTool = {
              enable() {
                self.$emit('enableCircle')
              },
              disable() {},
            }
            this.currentTool.enable()
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    drawPolygon() {
      if (this.changeTool('polygon')) {
        const self = this
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = this.map.pm.Draw.Polygon
            this.currentTool.enable()
            break
          case MODE_3D:
            this.currentTool = {
              enable() {
                self.$emit('enablePolygon')
              },
              disable() {},
            }
            this.currentTool.enable()
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    drawVolume() {
      if (this.changeTool('volume')) {
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = this.map.pm.Draw.Volume
            this.currentTool.enable()
            break
          case MODE_3D:
            console.warn('Режим добавления объёма в режите 3D не реализован')
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    drawEdit() {
      if (this.changeTool('edit')) {
        const self = this
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = {
              enable() {
                self.map.pm.enableGlobalEditMode()
              },
              disable() {
                self.map.pm.disableGlobalEditMode()
              },
            }
            this.currentTool.enable()
            break
          case MODE_3D:
            console.warn('Режим редактирования в режите 3D не реализован')
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    drawDrag() {
      if (this.changeTool('drag')) {
        const self = this
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = {
              enable() {
                self.map.pm.enableGlobalDragMode()
              },
              disable() {
                self.map.pm.disableGlobalDragMode()
              },
            }
            this.currentTool.enable()
            break
          case MODE_3D:
            console.warn('Режим добавления объёма в режите 3D не реализован')
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    drawMarker() {
      if (this.changeTool('marker')) {
        const self = this
        // Отключаем добавление новых замеров при нажатии на правую кнопку мышки
        const rightButtonDisabler = (e) => {
          if (e.button === MOUSE.RIGHT) {
            self.disableTool()
            self.viewer.renderer.domElement.removeEventListener(
              'mousedown',
              rightButtonDisabler
            )
            e.stopPropagation()
          }
        }
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = this.map.pm.Draw.Marker
            this.currentTool.enable({
              markerStyle: { icon: new SVGIcon({ iconSize: [16, 24], circleWeight: 1 }) },
            })
            break
          case MODE_3D:
            this.currentTool = {
              enable() {
                self.$emit('enableMarker')
              },
              disable() {},
            }
            this.currentTool.enable()
            // Отключаем добавление новых замеров при нажатии на правую кнопку мышки
            this.viewer.renderer.domElement.addEventListener(
              'mousedown',
              rightButtonDisabler
            )
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    drawCamera() {
      if (this.changeTool('camera')) {
        const self = this
        // Отключаем добавление новых замеров при нажатии на правую кнопку мышки
        const rightButtonDisabler = (e) => {
          if (e.button === MOUSE.RIGHT) {
            self.disableTool()
            self.viewer.renderer.domElement.removeEventListener(
              'mousedown',
              rightButtonDisabler
            )
            e.stopPropagation()
          }
        }
        switch (this.mode) {
          case MODE_2D:
            this.currentTool = this.map.pm.Draw.Marker
            this.currentTool.enable({
              markerStyle: { icon: new SVGIcon({ iconSize: [16, 24], circleWeight: 3 }) },
            })
            break
          case MODE_3D:
            this.currentTool = {
              enable() {
                self.$emit('enableCamera')
              },
              disable() {},
            }
            this.currentTool.enable()
            // Отключаем добавление новых замеров при нажатии на правую кнопку мышки
            this.viewer.renderer.domElement.addEventListener(
              'mousedown',
              rightButtonDisabler
            )
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    changeTool(mode) {
      // Вспомогательная функция для переключения режимов, если
      if (this.selectedBtn !== mode) {
        if (this.value && this.value.isNew) {
          this.value.remove()
        }
        if (this.selectedBtn) {
          this.modalBtns[this.selectedBtn] = false
        }
        this.modalBtns[mode] = true
        this.selectedBtn = mode
        return true
      } else {
        this.modalBtns[mode] = false
        this.disableTool()
      }
    },
    removeLastVertex() {
      if (this.currentTool) {
        switch (this.mode) {
          case MODE_2D:
            // eslint-disable-next-line no-case-declarations
            const points = this.currentTool._layer.getLatLngs()
            if (points.length > 1) {
              this.currentTool._removeLastVertex()
            } else {
              this.disableTool()
            }
            break
          case MODE_3D:
            // Если больше двух точек, то третья у нас плавающая, следовательно нужно удалить точку перед плавающей
            if (this.value.$potreeMeasure.points.length > 2) {
              this.value.$potreeMeasure.removeMarker(
                this.value.$potreeMeasure.points.length - 2
              )
            } else {
              // Иначе отключаем замер
              this.disableTool()
            }
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    finishMeasure() {
      if (this.currentTool) {
        switch (this.mode) {
          case MODE_2D:
            this.currentTool._finishShape()
            // обновляем замер и добавляем его на карту после рисования
            this.currentMeasure.$layer.showMeasurements({
              showName: this.showTitleLabels,
              showMeasures: this.showMeasureLabels,
            })
            break
          case MODE_3D:
            this.viewer.dispatchEvent({
              type: 'cancel_insertions',
            })
            break
          default:
            console.error('Неизвестный режим редактирования')
        }
      }
    },
    takeScreenshot(e) {
      this.$emit('takeScreenshot')
    },
    zoomIn() {
      if (this.mode === MODE_2D) {
        this.map.zoomIn(1)
      } else {
        this.zoom(ZOOM_DELTA)
      }
    },
    zoomOut() {
      if (this.mode === MODE_2D) {
        this.map.zoomOut(1)
      } else {
        this.zoom(-ZOOM_DELTA)
      }
    },
    zoom(delta) {
      this.viewer.controls.dispatchEvent({
        type: 'mousewheel',
        delta: delta,
      })
    },
  },
}
</script>

<style scoped></style>
