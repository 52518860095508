export default function() {
  return [
    {
      icon: 'icon nova-layout-11-2',
      name: this.$gettext('Project list'),
      path: '/projects',
    },
    // {
    //   name: 'Maps',
    //   icon: 'nc-icon nc-pin-3',
    //   children: [{
    //     name: 'Google Maps',
    //     path: '/maps/google'
    //   },
    //   {
    //     name: 'Full Screen Maps',
    //     path: '/maps/full-screen'
    //   },
    //   {
    //     name: 'Vector Maps',
    //     path: '/maps/vector-map'
    //   }
    //   ]
    // },
    // {
    //   name: 'Settings',
    //   icon: 'icon nova-setup-tools',
    //   path: '/settings'
    // },
    {
      icon: 'icon nova-circle-10',
      name: this.$gettext('Users'),
      path: '/users',
    },
    {
      icon: 'icon nova-alarm',
      name: this.$gettext('Report Bug'),
      path: '/report',
    },
    {
      icon: 'icon nova-img',
      name: this.$gettext('Upload'),
      path: '/files-upload',
      superuser: true,
    },
  ]
}
