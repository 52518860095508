import { SVGIcon } from 'leaflet-svgicon'
import {
  DEFAULT_FILL_OPACITY,
  DEFAULT_LINE_WEIGHT_LEVEL,
  SELECTED_COLOR,
  ZOOM_LINE_WEIGHT_LEVEL,
} from '@nova/constants'
import * as L from 'leaflet'
import Utils from '@nova/Classes/Utils'
import Rectangle from '@nova/Classes/Common/Measures/Rectangle'
import Circle from '@nova/Classes/Common/Measures/Circle'
import { Marker } from '@nova/Classes/Common/Measures/Marker'
import { Polygon } from '@nova/Classes/Common/Measures/Polygon'
import { Line } from '@nova/Classes/Common/Measures/Line'

export default {
  data() {
    return {
      workingLayer: {},
      drawMode: false,
      // Информация о Orthomosaic
      orthophoto: null,
      // Информация о DSM
      dsm: null,
    }
  },
  computed: {
    map() {
      return this.$root.storeMap.state.map
    },
  },
  methods: {
    importMapAnnotations(annotations) {
      if (annotations) {
        annotations.data.forEach((annotation) => {
          annotation.measures.forEach((measure) => {
            // если при импорте есть данные о стиле замера, то ставим его, иначе проставляем дефолтные стили
            const style = this.getMeasureStyle(measure)
            // Оффсеты для рисования прямоугольника
            let offsetY, offsetX
            let layer
            switch (measure.type) {
              case 'annotation-line':
                layer = L.polyline(measure.points, style)
                  .addTo(this.map)
                  .showMeasurements({
                    measureName: measure.name,
                    formatDistance: Utils.labelFormatter.call(this),
                    showName: this.showTitleLabels,
                    showMeasures: this.showMeasureLabels,
                  })
                break
              case 'annotation-polygon':
              case 'annotation-volume':
                layer = L.polygon(measure.points, style)
                  .addTo(this.map)
                  .showMeasurements({
                    measureName: measure.name,
                    formatDistance: Utils.labelFormatter.call(this),
                    showName: this.showTitleLabels,
                    showMeasures: this.showMeasureLabels,
                  })
                break
              case 'annotation-rectangle':
                // Переработанное рисование прямоугольника
                // Для соответствия рисованию в 3D прямоугольник рисуется от центральной точки по длинам сторон
                offsetY = measure.lineBLength / 2
                offsetX = measure.lineALength / 2
                measure.points[0] = Utils.offsetLatLngByMeters(
                  measure.centerPoint,
                  -offsetX,
                  -offsetY
                )
                measure.points[1] = Utils.offsetLatLngByMeters(
                  measure.centerPoint,
                  -offsetX,
                  offsetY
                )
                measure.points[2] = Utils.offsetLatLngByMeters(
                  measure.centerPoint,
                  offsetX,
                  offsetY
                )
                measure.points[3] = Utils.offsetLatLngByMeters(
                  measure.centerPoint,
                  offsetX,
                  -offsetY
                )
                layer = L.rectangle(measure.points, style)
                  .addTo(this.map)
                  .showMeasurements({
                    measureName: measure.name,
                    formatDistance: Utils.labelFormatter.call(this),
                    showName: this.showTitleLabels,
                    showMeasures: this.showMeasureLabels,
                  })
                break
              case 'annotation-marker':
                style.icon = new SVGIcon({
                  color: measure.color,
                  circleFillColor: measure.color,
                  iconSize: [16, 24],
                  circleWeight: 1,
                })
                layer = L.marker(measure.points[0], style)
                  .addTo(this.map)
                  .showMeasurements({
                    measureName: measure.name,
                    showName: this.showTitleLabels,
                    showMeasures: this.showMeasureLabels,
                  })
                break
              case 'annotation-camera':
                style.icon = new SVGIcon({
                  color: measure.color,
                  circleFillColor: measure.color,
                  iconSize: [16, 24],
                  circleWeight: 4,
                })
                layer = L.marker(measure.points[0], style)
                break
              case 'annotation-circle':
                style.radius = measure.radius
                layer = L.circle(measure.points[0], style)
                  .addTo(this.map)
                  .showMeasurements({
                    measureName: measure.name,
                    formatDistance: Utils.labelFormatter.call(this),
                    showName: this.showTitleLabels,
                    showMeasures: this.showMeasureLabels,
                  })
                break
            }

            if (layer) {
              layer.$measure = measure
              measure.$layer = layer
              if (measure.type === 'annotation-rectangle') {
                measure.rotation = measure._rotation
              }
              if (measure.visibility) {
                this.map.addLayer(layer)
                this.showLayer(layer)
              }
              this.listenEditEvents(layer)
            }
          })
        })

        if (
          !this.currentMeasure &&
          this.selectedInspectorItem &&
          this.selectedInspectorItem.type !== 'annotation'
        ) {
          this.currentMeasure.setLayerColor(SELECTED_COLOR)
        } else if (this.selectedInspectorItem) {
          this.currentAnnotation.setLayerColor(SELECTED_COLOR)
        }
      } else {
        console.warn('Нет аннотаций для импорта', annotations)
      }
    },
    getMeasureStyle(measure) {
      if (!measure.color) {
        measure.color = '#4281f5'
      }
      const dashArray = measure._dashed
        ? `${measure._dashSize} ${measure._gapSize}`
        : null
      return {
        color: measure.color,
        fillColor: measure.color,
        fillOpacity: DEFAULT_FILL_OPACITY,
        weight: DEFAULT_LINE_WEIGHT_LEVEL,
        opacity: measure._opacity,
        dashArray: dashArray,
      }
    },
    showLayer(layer) {
      if (layer.$measure) {
        layer.showMeasurements({
          measureName: layer.$measure.name,
          formatDistance: Utils.labelFormatter.call(this),
          showName: this.showTitleLabels,
          showMeasures: this.showMeasureLabels,
        })
      }
    },
    listenEditEvents(layer) {
      // слушаем, что кликнули на замер
      layer.on('click', (e) => {
        // debugger
        if (e.sourceTarget && e.sourceTarget.$measure === this.currentMeasure) {
          if (e.sourceTarget.$measure.$layer._enabled) {
            e.sourceTarget.$measure.$layer.pm.disableLayerDrag()
            // для прямоугольника надо руками проставить, что замер редактируется
            if (e.sourceTarget.$measure instanceof Rectangle) {
              e.sourceTarget.$measure.$layer._enabled = false
            }
          } else {
            e.sourceTarget.$measure.$layer.pm.enableLayerDrag()
            // для прямоугольника надо руками проставить, что замер редактируется
            if (e.sourceTarget.$measure instanceof Rectangle) {
              e.sourceTarget.$measure.$layer._enabled = true
            }
          }
          // прямоугольник не даем изменять
          if (!(e.sourceTarget.$measure instanceof Rectangle)) {
            if (this.checkPointsLength(e.sourceTarget.$measure)) {
              e.sourceTarget.$measure.$layer.pm.toggleEdit({ preventMarkerRemoval: true })
            } else {
              e.sourceTarget.$measure.$layer.pm.toggleEdit({
                preventMarkerRemoval: false,
              })
            }
          }
        }
        if (e.sourceTarget && e.sourceTarget.$measure) {
          this.currentMeasure = e.sourceTarget.$measure
          this.selectedInspectorItem = e.sourceTarget.$measure || null
        }
      })

      // Слушаем что замер начали двигать и закончили
      layer.on('pm:dragstart', (e) => {
        // когда начали двигать, выключаем режим редактирования и чистим все точки редактирования
        e.target.$measure.$layer.pm.disable()
        // У круга нет свойства _markerGroup, поэтому делаем иначе
        if (e.target.$measure.$layer.pm._markerGroup) {
          e.target.$measure.$layer.pm._markerGroup.clearLayers()
        } else {
          this.map.removeLayer(e.target.$measure.$layer.pm._helperLayers)
        }
      })
      layer.on('pm:dragend', (e) => {
        const newLayer = e.target
        // когда закончили двигать, включаем режим редактирования обратно и обновляем данные замера
        // прямоугольник не даем изменять
        if (!(e.sourceTarget.$measure instanceof Rectangle)) {
          newLayer.$measure.$layer.pm.enable()
        } else {
          newLayer.$measure._centerPoint = newLayer.$measure.$layer.getCenter()
        }
        this.currentMeasure.updateCalculations()
      })

      // слушаем, что замер изменился
      const layerModified = (e) => {
        const newLayer = e.target
        // когда меняется, закидываем в замеры новый слой
        const measure = newLayer.$measure

        if (measure instanceof Circle) {
          measure._radius = newLayer._mRadius
        }

        // Обновляем точки в замере
        measure.points = []

        let _points

        if (!newLayer._latlngs && newLayer._latlng) {
          _points = [newLayer._latlng]
        } else {
          _points = newLayer._latlngs
        }

        _points.forEach((e) => {
          // При редактировании Leaflet на какой-то хер создает дополнительный массив,
          // поэтому если режим редактирования, проходим по всем массивам и добавляем точки из всех массивов
          if (e instanceof Array) {
            e.forEach((point) => {
              measure.points.push(point)
            })
          } else {
            // Иначе слой просто перетащили
            // FIXME: в этом случае проебались все высоты
            measure.points.push(e)
          }
        })

        measure.updateCalculations()
        measure.fixAltitudes()
      }

      layer.on('pm:vertexremoved', (e) => {
        if (this.checkPointsLength(e.sourceTarget.$measure)) {
          e.sourceTarget.$measure.$layer.pm.enable({ preventMarkerRemoval: true })
        }
      })
      layer.on('pm:markerdragend', (e) => {
        if (!this.checkPointsLength(e.sourceTarget.$measure)) {
          e.sourceTarget.$measure.$layer.pm.enable({ preventMarkerRemoval: false })
        }
      })
      layer.on('pm:edit', layerModified)
      layer.on('pm:snapdrag', (e) => {
        if (e.sourceTarget.$measure instanceof Marker) {
          e.sourceTarget.updateMeasurements()
        }
      })
    },
    checkPointsLength(measure) {
      switch (measure.type) {
        case 'annotation-polygon':
          return measure.points.length < 4
        case 'annotation-line':
          return measure.points.length < 3
        default:
          return false
      }
    },
    listenDrawEvents() {
      this.map.on('pm:drawstart', ({ workingLayer, shape }) => {
        this.drawMode = true
        this.workingLayer = workingLayer
        let measure
        switch (shape) {
          case 'Circle':
            measure = new Circle(this, {
              isNew: true,
              points: [],
              $annotation: this.currentAnnotation,
            })
            break
          case 'Rectangle':
            measure = new Rectangle(this, {
              isNew: true,
              points: [],
              $annotation: this.currentAnnotation,
            })
            break
          case 'Polygon':
            measure = new Polygon(this, {
              isNew: true,
              points: [],
              $annotation: this.currentAnnotation,
            })
            break
          case 'Line':
            measure = new Line(this, {
              isNew: true,
              points: [],
              $annotation: this.currentAnnotation,
            })
            break
          case 'Marker':
            measure = new Marker(this, {
              isNew: true,
              points: [],
              $annotation: this.currentAnnotation,
            })
            break
        }

        this.currentAnnotation.add(measure)

        // measure.updateCalculations()
        // measure.fixAltitudes()

        // Обновляем выбранный замер на добавленный
        this.currentMeasure = measure
        // Вынес в классы действия вызываемые параллельно с рисованием
        measure.placeLabelsOnWorkingLayer(
          this.workingLayer,
          Utils.labelFormatter.call(this),
          this.showMeasureLabels,
          this.map,
          this.drawMode
        )
      })
      // Отписка от ивентов при окончании рисования
      this.map.on('pm:drawend', ({ shape }) => {
        this.map.off('mousemove')
        this.drawMode = false
        switch (shape) {
          case 'Circle':
            this.workingLayer.off('pm:centerplaced')
            break
          case 'Polygon':
            this.workingLayer.off('pm:vertexadded')
            break
          case 'Line':
            this.workingLayer.off('pm:vertexadded')
            break
        }
      })
      this.map.on('pm:create', ({ layer }) => {
        this.listenEditEvents(layer)
        // по окончании рисовании больше не пишем длину линии относительно мыши
        this.drawMode = false
        // this.lineLength = null

        const points = []

        let _points

        if (!layer._latlngs && layer._latlng) {
          _points = [layer._latlng]
        } else {
          _points = layer._latlngs
        }

        _points.forEach((e) => {
          // При редактировании Leaflet на какой-то хер создает дополнительный массив,
          // поэтому если режим редактирования, проходим по всем массивам и добавляем точки из всех массивов
          if (e instanceof Array) {
            e.forEach((point) => {
              points.push(point)
            })
          } else {
            // Иначе слой просто перетащили
            // FIXME: в этом случае проебались все высоты
            points.push(e)
          }
        })

        const measure = layer.$measure
        measure.points = points
        // обновляем замер и добавляем его на карту после рисования
        layer.showMeasurements({
          measureName: layer.$measure.name || null,
          formatDistance: Utils.labelFormatter.call(this),
        })
        measure.updateCalculations()
        measure.fixAltitudes()

        this.toggleMeasureLabels(layer, this.showMeasureLabels)
        if (measure instanceof Circle) {
          measure._radius = layer._mRadius
        }
        this.toggleTitleLabel(layer, this.showTitleLabels)
      })
    },
    toggleMeasureLabels(layer, value) {
      if (!layer._measurementOptions) {
        layer._measurementOptions = {}
      }
      layer._measurementOptions.showMeasures = value
      layer.updateMeasurements()
      const layers = layer._measurementLayer._layers
      const layersKeys = Object.keys(layers).filter(
        (key) =>
          layers[key]._title === 'Segment length' ||
          layers[key]._title === 'Total length' ||
          layers[key]._title === 'Total area'
      )
      if (layersKeys.length) {
        if (value) {
          layersKeys.map((key) => this.map.addLayer(layers[key]))
        } else {
          layersKeys.map((key) => layers[key].remove())
        }
      }
    },
    toggleTitleLabel(layer, value) {
      layer._measurementOptions.showName = value
      layer.updateMeasurements()
      const layers = layer._measurementLayer._layers
      const layersKeys = Object.keys(layers).filter(
        (key) =>
          layers[key]._title === 'Line name' ||
          layers[key]._title === 'Polygon name' ||
          layers[key]._title === 'Marker name'
      )
      if (layersKeys.length) {
        if (value) {
          layersKeys.map((key) => this.map.addLayer(layers[key]))
        } else {
          layersKeys.map((key) => layers[key].remove())
        }
      }
    },
    listenZoomEvent(annotations) {
      // слушаем зум и увеличиваем/уменьшаем границы замеров
      this.map.on('zoom', (e) => {
        const options = { weight: ZOOM_LINE_WEIGHT_LEVEL[e.target._zoom] }
        // опиции для новых замеров
        this.map.pm.setPathOptions(options)
        // и апдейтим существующие замеры
        annotations.allMeasures.map((measure) => {
          if (
            measure.type !== 'annotation-marker' &&
            measure.type !== 'annotation-camera' &&
            measure.$layer
          ) {
            measure.$layer.setStyle(options)
          }
        })
      })
    },
    setLayerVisibility(layer, visibitily) {
      if (visibitily) {
        this.map.addLayer(layer)
        this.showLayer(layer)
      } else {
        this.map.removeLayer(layer)
      }
    },
  },
}
