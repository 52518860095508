<template>
  <div class="login-page">
    <app-navbar />
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit="submit">
                <card type="login">
                  <h3
                    slot="header"
                    class="header"
                  >
                    <translate translate-context="title">
                      Restore password
                    </translate>
                  </h3>
                  <small
                    v-for="(error, index) in errorsMessages.nonFieldErrors"
                    :key="index"
                    class="text-danger"
                  >
                    {{ error }}
                  </small>
                  <div
                    v-if="success"
                    class="alert alert-success"
                  >
                    <span>{{ message }}</span>
                  </div>
                  <div v-if="!success">
                    <h5
                      v-translate
                      class="text-center"
                    >
                      Please enter "Username"
                    </h5>
                    <fg-input
                      v-model="form.username"
                      addon-left-icon="nc-icon nc-single-02"
                      :placeholder="'Enter username' | translate"
                    />
                    <h5
                      v-translate
                      class="text-center"
                    >
                      or "Email"
                    </h5>
                    <fg-input
                      v-model="form.email"
                      addon-left-icon="nc-icon nc-email-85"
                      :placeholder="'Enter email' | translate"
                      type="email"
                    />
                  </div>
                  <div slot="footer">
                    <p-button
                      v-if="!success"
                      v-loading="loading"
                      type="warning"
                      round
                      block
                      class="mb-3"
                      :disabled="!(form.username || form.email)"
                      native-type="submit"
                    >
                      <translate>Restore password</translate>
                    </p-button>
                    <div class="forgot text-center">
                      <router-link
                        v-translate
                        to="/login"
                      >
                        Login
                      </router-link>
                    </div>
                  </div>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer />
        <div class="full-page-background" />
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Checkbox, Button } from 'src/components/UIComponents'
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter'
export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button
  },
  data() {
    return {
      message: null,
      success: false,
      loading: false,
      errorsMessages: {
        nonFieldErrors: []
      },
      form: {
        username: '',
        email: ''
      }
    }
  },
  beforeDestroy() {
    this.closeMenu()
  },
  methods: {
    cleanErrors() {
      this.errorsMessages.nonFieldErrors = []
      this.errorsMessages.username = []
      this.errorsMessages.email = []
    },
    submit(e) {
      e.preventDefault()
      this.errorsMessages = {
        nonFieldErrors: []
      }
      this.loading = true
      this.$http.post('user/forgot-password', {
        username: this.form.username,
        email: this.form.email
      }).then(response => {
        this.loading = false
        this.success = true
        this.message = response.data.message
        this.cleanErrors()
      }, error => {
        this.loading = false
        if (undefined === error.response && error.message.toString() === 'Network Error') {
          this.errorsMessages.nonFieldErrors = [this.$gettext('Network error. Check internet connection')]
        } else
        if (error.response.status === 400) {
          this.errorsMessages.nonFieldErrors = error.response.data.nonFieldErrors || []
          this.errorsMessages.username = error.response.data.username || []
          this.errorsMessages.email = error.response.data.email || []
        } else {
          this.cleanErrors()
          this.errorsMessages.nonFieldErrors = [this.$gettext('Unknown error')]
        }
      })
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    }
  }
}
</script>
<style scoped>
  .login-page {
    background: transparent url('/assets/images/shutterstock_631636766.png') 0 0 no-repeat padding-box;
  }

  h3.header {
    margin-bottom: 0;
  }
</style>
