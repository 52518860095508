<template>
  <div
    class="form-check"
    :class="[{disabled: disabled}, inlineClass]"
  >
    <label
      :for="cbId"
      class="form-check-label"
    >
      <input
        :id="cbId"
        v-model="model"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
      >
      <span class="form-check-sign" />
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: 'PCheckbox',
  model: {
    prop: 'checked'
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    checked: {
      type: [Array, Boolean],
      description: 'Whether checkbox is checked'
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled'
    },
    inline: {
      type: Boolean,
      description: 'Whether checkbox is inline'
    }
  },
  data() {
    return {
      cbId: '',
      touched: false
    }
  },
  computed: {
    model: {
      get() {
        return this.checked
      },
      set(check) {
        if (!this.touched) {
          this.touched = true
        }
        this.$emit('input', check)
      }
    },
    inlineClass() {
      if (this.inline) {
        return 'form-check-inline'
      }
      return ''
    }
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2)
  }
}
</script>
