<template>
  <!--user data-->
  <div class="user-person-data row">
    <div
      class="registration-form-nova col-12"
    >
      <p class="text-center registration-text">
        <translate>Add user</translate>
      </p>
      <div class="row justify-content-center mb-2">
        <el-radio
          v-model="isNewUser"
          label="new"
        >
          <translate>New</translate>
        </el-radio>
        <el-radio
          v-model="isNewUser"
          label="existing"
        >
          <translate>Existing</translate>
        </el-radio>
      </div>
      <div class="row mb-2">
        <el-form
          ref="value.profile"
          :model="value.profile"
          status-icon
          :rules="rules"
          class="col-12"
        >
          <el-form-item
            v-if="isNewUser === 'new'"
            prop="username"
          >
            <el-input
              v-model="value.profile.username"
              name="username"
              class="registration-input"
              :placeholder="'Username' | translate"
              prefix-icon="icon nova-circle-10"
              type="username"
              autocomplete="section-registration username"
            />
          </el-form-item>
          <el-form-item
            v-if="isNewUser === 'new'"
            prop="pass"
          >
            <el-input
              v-model="value.profile.password"
              name="password"
              class="registration-input"
              :placeholder="'Password' | translate"
              show-password
              prefix-icon="icon nova-access-key"
              type="password"
              autocomplete="section-registration new-password"
            />
          </el-form-item>
          <el-form-item
            v-if="isNewUser === 'new'"
            prop="checkPass"
          >
            <el-input
              v-model="value.profile.checkPass"
              name="confirmPassword"
              class="registration-input"
              :placeholder="'Repeat password' | translate"
              show-password
              prefix-icon="icon nova-access-key"
              type="password"
              autocomplete="section-registration new-password"
            />
          </el-form-item>
          <el-form-item
            v-if="isNewUser === 'new' || isNewUser === 'existing'"
            prop="email"
            class="mb-1"
          >
            <el-input
              v-model="value.profile.email"
              name="email"
              class="registration-input"
              :placeholder="'Email' | translate"
              prefix-icon="icon nova-globe"
              type="email"
              autocomplete="work email"
            />
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-alert
          v-if="value.error.username"
          :title="value.error.username"
          type="error"
          effect="light"
          class="mt-1"
          show-icon
        />
        <el-alert
          v-if="value.error.email"
          :title="value.error.email"
          type="error"
          effect="light"
          class="mt-1"
          show-icon
        />
        <el-alert
          v-if="value.error.password"
          :title="value.error.password"
          type="error"
          effect="light"
          class="mt-1"
          show-icon
        />
      </div>
      <div
        v-if="value.profile.email"
        class="row justify-content-center my-3"
      >
        <el-card
          :body-style="{ padding: '0px' }"
        >
          <gravatar
            :email="value.profile.email"
            alt="user avatar"
            :size="150"
          />
          <div
            class="text-center text-dark"
          >
            <translate>Your avatar</translate>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Input,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  FormItem,
  Form,
  Alert,
  Radio,
  Card
} from 'element-ui'
import Gravatar from 'vue-gravatar'

export default {
  components: {
    [Input.name]: Input,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Alert.name]: Alert,
    [Radio.name]: Radio,
    [Card.name]: Card,
    Gravatar,
  },
  props: {
    value: {
      type: Object,
      default: function() {
        return {
          profile: {
            username: '',
            password: '',
            checkPass: '',
            email: ''
          },
          company: {
            id: null,
          },
          error: {
            username: null,
            email: null,
            password: null,
          },
        }
      }
    },
  },
  data() {
    /**
     * Функции валидации пароля
     * @param rule - правила валидации
     * @param value - значния с фронта
     * @param callback
     */
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$gettext('Please input the password')))
      } else {
        if (this.value.profile.checkPass !== '') {
          this.$refs.value.profile.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$gettext('Please input the password again')))
      } else if (value !== this.value.profile.password) {
        callback(new Error(this.$gettext('Passwords don\'t match!')))
      } else {
        callback()
      }
    }
    return {
      // добавление нового или существующего юзера
      isNewUser: null,
      // правила для валидации
      rules: {
        username: [
          { required: true, message: this.$gettext('Please input user name'), trigger: 'blur' },
          { min: 3, max: 255, message: this.$gettext('Must be longer than 3 characters'), trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$gettext('Please input email address'), trigger: 'blur' },
          { type: 'email', message: this.$gettext('Please input correct email address'), trigger: ['blur', 'change'] }
        ],
      }
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style scoped>
  .el-button--info {
    background: #747474 0 0 no-repeat padding-box;
    border-color: #747474;
  }
  .el-input__inner {
    background-color: #0c2646;
  }
  .action-btns {
    margin: 0;
  }
  .action-btns .el-button.is-circle {
    background: #464646;
    color: #FFFFFF;
    border: none;
    font-size: 14px;
  }
  /*!* main content *!*/
  .font-size-10 {
    font-weight: 700;
    font-size: 10px;
  }
  .count-person {
    font-weight: 700;
  }
  .card-bottom {
    background: #808080;
    border-radius: 0 0 5px 5px;
  }
  .card__person-status i {
    font-size: 16px;
  }
</style>
<style>
  .el-button--success {
    background: #177F7F;
    border-color: #177F7F
  }
  .el-button--info:hover, .el-button--info:focus {
    background: transparent !important;
    color: #51cbce !important;
  }
  .el-button:hover, .el-button:focus {
    /*color: #51cbce !important;*/
    background: transparent !important;
  }
  .custom-el-table-row {
    color: white;
  }
  .custom-el-table-row:hover > td {
    background-color: #737373 !important;
  }
  .for-guide {
    background-color: rgb(244, 243, 239);
  }
</style>
