<template xmlns:v-nova-page-guide="http://www.w3.org/1999/xhtml">
  <div>
    <nova-header />
    <notifications />
    <div class="context-section">
      <div class="container">
        <div class="row justify-content-between mt-3">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6">
                <search-input ref="search" />
              </div>
              <div class="col-lg-6">
                <div v-if="organizations">
                  <el-select
                    v-if="organizations.length > 1"
                    v-model="currentOrganization"
                    class="select-custom"
                    @change="updateOrganization(true)"
                  >
                    <el-option
                      v-for="(organization, key) in organizations"
                      :key="key"
                      :value="key"
                      :label="organization.name"
                    />
                    <el-button
                      v-if="nextOrgPage"
                      icon="el-icon-arrow-down"
                      class="buttonBlock"
                      @click="loadOrganizations(true)"
                    />
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-9 mt-1 mt-lg-auto">
                <div class="row justify-content-lg-end">
                  <div
                    v-if="isAdmin"
                    v-nova-page-guide:addUser
                    class="col-6 d-flex justify-content-center justify-content-lg-end p-lg-0"
                  >
                    <el-button
                      type="success"
                      round
                      style="font-size: 10px; font-weight: 700"
                      @click="toggleNewUserModal(true)"
                    >
                      <translate>Add user</translate>
                    </el-button>
                  </div>
                  <div
                    v-nova-page-guide:sendInvitation
                    class="col-6 d-flex justify-content-center justify-content-lg-end p-lg-0"
                  >
                    <el-button
                      round
                      style="font-size: 10px; font-weight: 700"
                      @click="toggleInvitationModal(true)"
                    >
                      <translate>Send invitation</translate>
                    </el-button>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 d-flex justify-content-center justify-content-lg-end mt-1 mt-lg-auto"
              >
                <div v-nova-page-guide:changeViews class="view-selector">
                  <el-button-group>
                    <el-button
                      type="info"
                      icon="icon nova-gallery-view"
                      @click="changeView('grid')"
                    />
                    <el-button
                      type="info"
                      icon="icon nova-bullet-list-70"
                      @click="changeView('list')"
                    />
                  </el-button-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" v-loading="loading" style="height: 200px" />
    <div v-if="organizations">
      <div v-if="view === 'grid'">
        <user-page-grid
          :users="currentShownUsers"
          :is-admin="isAdmin"
          :company-id="organizations[currentOrganization].id"
        />
      </div>
      <div v-if="view === 'list'">
        <user-page-list
          :users="currentShownUsers"
          :is-admin="isAdmin"
          :company-id="organizations[currentOrganization].id"
        />
      </div>
      <el-button
        v-if="nextUsersPage"
        icon="el-icon-arrow-down"
        class="fixed-scroll-button"
        @click="scrollBottom"
      >
        <span><translate>Scroll down to load more tasks</translate></span>
      </el-button>
    </div>
    <!--    <nova-page-guide-->
    <!--      ref="novaPageGuideRef"-->
    <!--      :guide-obj="guideObj"-->
    <!--    />-->
    <modal :show.sync="modal.newUser" modal-classes="modal-card">
      <new-user v-model="newUser" />
      <div class="row justify-content-center mt-2">
        <el-button round type="success" class="custom-btn-success" @click="addUser">
          <translate>Add User</translate>
        </el-button>
        <el-button
          type="danger"
          round
          class="custom-btn"
          @click="toggleNewUserModal(false)"
        >
          <translate>Cancel</translate>
        </el-button>
      </div>
    </modal>
    <modal :show.sync="modal.invite" modal-classes="modal-card">
      <p class="text-center registration-text">
        <translate>Send invite</translate>
      </p>
      <div class="row justify-content-center">
        <div class="form-group col">
          <input
            id="email"
            v-model="invite.email"
            type="text"
            class="form-control bg-white"
            :placeholder="'Email' | translate"
          />
          <el-alert
            v-if="invite.error"
            :title="invite.error"
            type="error"
            class="mt-1"
            show-icon
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <el-button round type="success" class="custom-btn-success" @click="inviteUser">
          <translate>Send invite</translate>
        </el-button>
        <el-button
          type="danger"
          round
          class="custom-btn"
          @click="toggleInvitationModal(false)"
        >
          <translate>Cancel</translate>
        </el-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  Input,
  Button,
  ButtonGroup,
  Select,
  Option,
  Notification,
  Alert,
} from 'element-ui'
import NovaHeader from '@layouts/HeaderLayout/NovaHeader'
import UserPageGrid from '@layouts/UsersLayout/UsersPageGrid'
import UserPageList from '@layouts/UsersLayout/UsersPageList'
import SearchInput from '@layouts/SearchInput'
import Modal from '@UIComponents/Modal'
import NewUser from '@layouts/UsersLayout/NewUser'

export default {
  name: 'Users',
  components: {
    NewUser,
    SearchInput,
    NovaHeader,
    UserPageGrid,
    UserPageList,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Alert.name]: Alert,
    // NovaPageGuide,
    Modal,
  },
  data() {
    return {
      loading: false,
      search: {
        text: '',
      },
      users: [],
      view: 'grid',
      guideObj: {
        addUser: this.$gettext('Add new users by pressing the button'),
        sendInvitation: this.$gettext(
          'You can invite a user by clicking on this button.'
        ),
        changeViews: this.$gettext(
          'Here are the buttons to switch the display of all projects (Grid or List)'
        ),
        usersCardsGrid: this.$gettext('Here you can see all users of the system'),
        cardUserGrid: this.$gettext('Personal user card'),
        btnPlayFromUserCard: this.$gettext('By clicking here you can open the user card'),
      },
      // доступно ли добавление пользователей
      isAdmin: false,
      // Список организации доступных пользователю
      organizations: null,
      // Текущая организация
      currentOrganization: 0,
      // Модальное окно добавления нового пользователя
      modal: {
        newUser: false,
        invite: false,
      },
      // Новый пользователь
      newUser: {
        profile: {
          email: null,
          username: null,
          password: null,
        },
        company: {
          id: null,
        },
        error: {
          username: null,
          email: null,
          password: null,
        },
      },
      invite: {
        email: null,
        error: null,
        companyId: null,
      },
      nextUsersPage: null,
      bottom: false,
      nextOrgPage: null,
    }
  },
  computed: {
    currentShownUsers() {
      if (this.users && this.users.length && this.$refs.search) {
        return this.users.filter((user) => {
          if (
            user.username.toLowerCase().includes(this.$refs.search.text.toLowerCase())
          ) {
            return user
          }
        })
      } else {
        return this.users
      }
    },
  },
  watch: {
    /**
     * Слежение за скроллом и подгрузка пользователей
     */
    bottom(bottom) {
      if (bottom && this.nextUsersPage) {
        this.updateOrganization()
      }
    },
  },
  mounted() {
    // Листенер ивента скролла чтобы подгружать пользователей если есть еще не загруженные
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
    // Грузим компании доступные пользователю
    this.loadOrganizations()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
  },
  methods: {
    /**
     * Смена вида плитка/таблица
     * @param type Вид
     */
    changeView(type) {
      this.view = type
    },
    /**
     * Загрузка списка компаний
     * @returns {Promise<void>}
     */
    async loadOrganizations(extra) {
      this.loading = true
      try {
        const organizations = await this.axios.get(
          extra ? this.nextOrgPage : '/organizations/'
        )
        if (extra) {
          this.organizations.push(...organizations.data.results)
        } else {
          this.organizations = organizations.data.results
        }
        this.nextOrgPage = organizations.data.next
      } catch (e) {
        console.error('не получилось загрузить организации', e)
      }
      this.loading = false
      if (!extra) {
        this.updateOrganization(true)
      }
    },
    /**
     * ЗАгрузка текущей компании
     * @returns {Promise<void>}
     */
    async updateOrganization(withReset) {
      if (withReset) {
        this.users = []
        this.nextUsersPage = null
      }
      try {
        const response = await this.axios.get(
          this.nextUsersPage
            ? this.nextUsersPage
            : `/organizations/${this.organizations[this.currentOrganization].id}/users/`
        )
        this.nextUsersPage = response.data.next
        const users = response.data.results.map((user) => {
          user.role = 'member'
          return user
        })
        // Проверка прав пользователей в текущей компании
        const profiles = await this.axios.get(
          `/organizations/${this.organizations[this.currentOrganization].id}/profiles/`
        )
        profiles.data.results.map((profile) => {
          users.map(async (user) => {
            if (user.id === profile.user.id) {
              profile.roles.map((role) => {
                if (role.short_name === 'owner') {
                  user.role = 'owner'
                }
              })
              user.companyId = profile.id
              user.blocked = await this.checkUserBlock(
                user.companyId,
                this.organizations[this.currentOrganization].id
              )
            }
          })
        })
        this.users.push(...users)
        await this.checkUserPermissions()
      } catch (e) {
        console.error('не получилось загрузить список пользователей организации', e)
      }
    },
    /**
     * Проерка прав текущего пользования на добавление новых аккаунтов
     * @returns {Promise<void>}
     */
    async checkUserPermissions() {
      try {
        const response = await this.axios.options(
          `/organizations/${this.organizations[this.currentOrganization].id}/users/`
        )
        if (response.data.actions) {
          this.isAdmin = true
        }
      } catch (e) {
        console.error(
          'Не получилось проверить права пользователя на данную организацию',
          e
        )
      }
    },
    /**
     * Включение модального окна добавления нового пользователя
     * @param value Boolean открыто ли модальное окно
     */
    toggleNewUserModal(value) {
      this.modal.newUser = value
      if (!value) {
        // Обнуление полей ввода информации нового сотрудника
        this.newUser = {
          profile: {
            email: null,
            username: null,
            password: null,
          },
          company: {
            id: null,
          },
          error: {
            username: null,
            email: null,
            password: null,
          },
        }
      } else {
        // Запись айди компании для добавления нового пользователя
        this.newUser.company.id = this.organizations[this.currentOrganization].id
      }
    },
    /**
     * Добавление нового пользователя
     * @returns {Promise<void>}
     */
    async addUser() {
      this.newUser.error = {
        username: null,
        email: null,
        password: null,
      }
      if (this.validateEmail(this.newUser.profile.email, this.newUser.error)) {
        try {
          // Сначала пробуем добавить существующего пользователя по емэйлу
          const response = await this.axios.post(
            `/organizations/${this.newUser.company.id}/add-user/`,
            { email: this.newUser.profile.email }
          )
          if (response.status === 201) {
            this.showSuccessNotification(this.$gettext('Added successfully'))
            this.loadOrganizations()
            this.toggleNewUserModal(false)
          }
        } catch (e) {
          // Если сервер ответил что пользователя не существует, проверяем юзернейм и пробуем добавить нового пользователя
          if (e.response.status === 404) {
            if (this.validateUsername()) {
              this.addNewUser()
            }
          } else {
            console.error('Не получилось добавить к организации существующий аккаунт', e)
          }
        }
      }
    },
    /**
     * Добавление нового пользователя
     */
    async addNewUser() {
      try {
        const response = await this.axios.post(
          `/organizations/${this.newUser.company.id}/users/ `,
          this.newUser.profile
        )
        this.newUser.profile = response.data
        if (response.status === 201) {
          this.showSuccessNotification(this.$gettext('Added successfully'))
          this.loadOrganizations()
          this.toggleNewUserModal(false)
        } else {
          this.showDangerNotification(this.$gettext('Not added'))
        }
      } catch (e) {
        // Проверка на то что сервер ответил что пользователь с именем/емэйлом/паролем что то не так
        if (e.response.status === 400) {
          if (e.response.data.email) {
            this.newUser.error.email = e.response.data.email
          }
          if (e.response.data.username) {
            this.newUser.error.username = e.response.data.username
          }
          if (e.response.data.password) {
            this.newUser.error.password = e.response.data.password
          }
        } else {
          console.error('Не получилось добавить пользователя', e.response)
        }
      }
    },
    /**
     * Валидация емэйла
     * @param email - email для валидации
     * @param errorLabel - лейбл ошибки привязанный к инпуту емэйла
     * @return Boolean валидирован или нет
     */
    validateEmail(email, errorLabel) {
      let result = true
      const valEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
      if (!valEmail.test(email)) {
        errorLabel.email = this.$gettext('Invalid email')
        result = false
      }
      return result
    },
    /**
     * Валидация юзернейма
     * @return Boolean валидирован или нет
     */
    validateUsername() {
      let result = true
      const valUsername = /^[A-z][\sA-z]*$/i
      if (
        !this.newUser.profile.username ||
        !valUsername.test(this.newUser.profile.username)
      ) {
        this.newUser.error.username = this.$gettext('Invalid username')
        result = false
      }
      return result
    },
    /**
     * Проверка блокировки пользователя
     * @param userId id профайла пользователя в компании
     * @param companyId id компании
     * @return Boolean заблокирован или нет
     */
    async checkUserBlock(userId, companyId) {
      const response = await this.axios.get(
        `/organizations/${companyId}/user-project-blocks/`
      )
      let blocked = false
      response.data.results.map((block) => {
        block.blocked_users.map((user) => {
          if (user === userId) {
            blocked = true
          }
        })
      })
      return blocked
    },
    /**
     * Включение модального окна приглашения пользователя в систему
     * @param value {Boolean} - включено или выключено окно
     */
    toggleInvitationModal(value) {
      this.modal.invite = value
      if (!value) {
        this.invite = {
          email: null,
          error: null,
          companyId: null,
        }
      } else {
        this.invite.companyId = this.organizations[this.currentOrganization].id
      }
    },
    /**
     * Приглашение пользователя в систему
     */
    async inviteUser() {
      this.invite.error = null
      if (this.validateEmail(this.invite.email, this.invite.error)) {
        try {
          // Пробуем пригласить
          const response = await this.axios.post(
            `/organizations/${this.invite.companyId}/invite-user/`,
            {
              email: this.invite.email,
            }
          )
          if (response.status === 200) {
            this.showSuccessNotification(this.$gettext('Invite sent'))
            this.toggleInvitationModal(false)
          } else {
            this.showDangerNotification(
              this.$gettext('An error occurred during invitation sending')
            )
            this.toggleInvitationModal(false)
          }
        } catch (e) {
          // Если пользователь с таким емэйлом уже есть в системе, добавляем к организации
          if (e.response.status === 400) {
            this.invite.error = e.response.data
            // Пробуем добавить существующего пользователя по емэйлу
            const response = await this.axios.post(
              `/organizations/${this.invite.companyId}/add-user/`,
              { email: this.invite.email }
            )
            if (response.status === 201) {
              this.showSuccessNotification(this.$gettext('User added successfully'))
              this.loadOrganizations()
              this.toggleInvitationModal(false)
            }
          } else {
            this.invite.error = e.message
            console.error('Не получилось отправить приглашение пользователю', e)
          }
        }
      }
    },
    /**
     * Высвечивание оповещения об успехе
     * @param message Сообщение
     */
    showSuccessNotification(message) {
      Notification({
        title: this.$gettext('Saved'),
        message: message,
        type: 'success',
        position: 'top-right',
        showClose: false,
      })
    },
    /**
     * Высвечивание оповещения о неудаче
     * @param message Сообщение
     */
    showDangerNotification(message) {
      Notification({
        title: this.$gettext('Not saved'),
        message: message,
        type: 'error',
        position: 'top-right',
        showClose: false,
      })
    },
    /**
     * Скролл до низа страницы
     */
    scrollBottom() {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
    },
    /**
     * Проверка - долистали ли до низа страницы
     * @return {boolean}
     */
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
  },
}
</script>

<style scoped>
.el-button--info {
  background: #747474 0 0 no-repeat padding-box;
  border-color: #747474;
}
.el-button--success {
  background: #177f7f 0 0 no-repeat padding-box;
  border-radius: 15px;
  border-color: #177f7f;
}
.el-button-group .el-button--info {
  font-size: 16px;
  padding: 9px;
}
.el-button-group .el-button--info {
  font-size: 16px;
  padding: 9px;
}
.el-button--info {
  background: #747474 0 0 no-repeat padding-box;
  border-color: #747474;
}
.el-button-group .el-button--info:first-child {
  margin-right: 5px;
  border-right-color: #747474;
}
.el-button-group .el-button--info:last-child {
  border-left-color: #747474;
}
.el-button--info:hover,
.el-button--info:focus {
  background: transparent !important;
  color: #51cbce !important;
}
.el-button:hover,
.el-button:focus {
  /*color: #51cbce !important;*/
  background: transparent !important;
}
.btn-run-guide {
  position: fixed;
  top: 28px;
  right: 50px;
}
.modal-card {
  min-width: 50%;
}
</style>
<style>
.el-button--success {
  background: #177f7f;
  border-color: #177f7f;
}
.el-button--info:hover,
.el-button--info:focus {
  background: transparent !important;
  color: #51cbce !important;
}
.el-button:hover,
.el-button:focus {
  /*color: #51cbce !important;*/
  background: transparent !important;
}
.custom-el-table-row {
  color: white;
}
.custom-el-table-row:hover > td {
  background-color: #737373 !important;
}
.select-custom .el-input__inner {
  height: 34px !important;
}
.el-select-dropdown__item {
  font-size: 11px !important;
  font-weight: 700 !important;
}
/*.el-select__caret.el-input__icon {*/
/*  height: 32px !important;*/
/*}*/
.el-select .el-input:hover .el-input__icon,
.el-select .el-input:hover input {
  color: #177f7f !important;
}

.buttonBlock {
  width: 100%;
}
</style>
