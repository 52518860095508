// Система сквозной бизнес-аналитики https://roistat.com/ru/
export default {
  install(Vue, options) {
    if (process.env.NODE_ENV === 'production') {
      options.w.roistatProjectId = options.id; options.w.roistatHost = options.h
      const p = options.d.location.protocol === 'https:' ? 'https://' : 'http://'
      const u = /^.*roistat_visit=[^;]+(.*)?$/.test(options.d.cookie) ? '/dist/module.js' : '/api/site/1.0/' + options.id + '/init?referrer=' + encodeURIComponent(options.d.location.href)
      const js = options.d.createElement(options.s)
      js.charset = 'UTF-8'; js.async = 1; js.src = p + options.h + u
      const js2 = options.d.getElementsByTagName(options.s)[0]
      js2.parentNode.insertBefore(js, js2)
    }
  }
}

