<template>
  <div
    style="height: 100%"
    @click="
      (event) =>
        $emit('clickOnMeasure', { event, potreeContainer: $refs.potreeContainer })
    "
  >
    <div
      ref="potreeContainer"
      class="potree_container"
      style="height:100%; width: 100%; position: relative;"
    >
      <div id="potree_render_area" style="height: 100%" />
      <!--      <div id="potree_sidebar_container" style="height: 500px; height: 200px" > </div>-->
    </div>
  </div>
</template>

<script>
import 'jstree'
import { Sidebar } from '@letsnova/potree/src/viewer/sidebar'
import {
  ProfileWindow,
  ProfileWindowController,
} from '@letsnova/potree/src/viewer/profile'
import * as THREE from 'three'
import * as Potree from '@letsnova/potree/build/potree/potree'
import MTTLoader from 'src/vendor/libs/other/MTLLoader'
import 'src/vendor/libs/other/OBJLoader'
import { loadPointcloud } from 'src/util/loadPointcloud'

THREE.MTLLoader = MTTLoader

// инициализация potree
window.Potree = Potree

export default {
  name: 'PotreeViewer',
  components: {},
  props: {
    project: {
      type: Number,
      default() {
        return parseInt(this.$route.params.projectId)
      },
    },
    task: {
      type: String,
      default() {
        return this.$route.params.taskId
      },
    },
    pointcloud: {
      type: Object,
      default: () => {},
    },
    annotations: {
      type: Object,
      default: () => {},
    },
  },
  data: function() {
    return {}
  },
  computed: {
    assetsPath() {
      return this.$root.apiPath(`/api/projects/${this.project}/tasks/${this.task}/assets`)
    },
    showTitleLabels() {
      return this.$store.state.viewer.showTitleLabels
    },
    showMeasureLabels() {
      return this.$store.state.viewer.showMeasureLabels
    },
    viewer() {
      return this.$root.storeViewer.state.viewer
    },
  },
  mounted() {
    this.initViewer()
  },
  methods: {
    async initViewer() {
      this.$root.storeViewer.mutations.setViewer(this.$root.storeViewer.state, {
        project: this.project,
        task: this.task,
        document: document.getElementById('potree_render_area'),
      })
      window.viewer = this.viewer

      this.viewer.setEDLEnabled(true)
      this.viewer.setFOV(60)
      this.viewer.setPointBudget(1 * 1000 * 1000)
      this.viewer.loadSettingsFromURL()
      this.viewer.setDescription('')

      this.viewer.profileWindow = new ProfileWindow(this.viewer)
      this.viewer.profileWindowController = new ProfileWindowController(this.viewer)
      // Настройки сферы которая отображается в potreeViewer когда ведем мышкой по elevationProfile
      this.viewer.profileWindow.viewerPickSphere.material = new THREE.MeshStandardMaterial()
      this.viewer.profileWindow.viewerPickSphere.material.color = new THREE.Color(
        0xd62418
      )
      this.viewer.profileWindow.viewerPickSphere.material.emissive = { r: 1, g: 0, b: 0 }
      // Инициализируем объекты сцены
      this.sidebar = new Sidebar(this.viewer)

      if (this.pointcloud !== null) {
        this.$nextTick(() => {
          this.mountPointCloud(this.pointcloud)
        })
      } else {
        // Прокидываем авторизацию, нужно будет для того, чтобы скачивались ноды EPT
        Potree.XHRFactory.config.withCredentials = true
        Potree.XHRFactory.config.customHeaders = this.$root.headers

        // Формируем путь к воркерам Potree
        Potree.scriptPath = this.$root.apiPath(
          '/webodm/static/app/js/vendor/potree/build'
        )
        console.warn('Облако точек еще не загружено, загружаем...')
        // Путь к информации о PC
        const path = `${this.assetsPath}/entwine_pointcloud/ept.json`
        // выдернули из Potree.loadPointCloud, чтобы пробросить авторизацию
        const pointcloud = await loadPointcloud.call(this, path)
        this.$emit('pointcloud', pointcloud)
        this.mountPointCloud(pointcloud)
      }
      // Добавляем текстуры в сцену, если есть
      this.$emit('mountTexture')
      // Подписываемся на ивенты потри
      this.$emit('setPotreeListener')
    },
    async getDataFromServer() {
      const response = await this.axios.get(
        `projects/${this.project}/tasks/${this.task}/measurements/3d/`
      )
      return response.data
    },
    getElevationProfileFromPotreePoints(points) {
      const normalPoints = points.map((point) => point.position)
      return this.getElevationProfile(normalPoints)
    },
    getElevationProfile(points) {
      const profile = new Potree.Profile()
      points.map((point) =>
        profile.addMarker(new THREE.Vector3(point.x, point.y, point.z))
      )

      this.viewer.scene.addProfile(profile)

      const pco = this.viewer.scene.pointclouds[0]
      const pwc = this.viewer.profileWindowController

      const request = pco.getPointsInProfile(profile, null, {
        onProgress: (event) => {
          this.$emit('elevationProfileFinished', event)
          for (const segment of event.points.segments) {
            pwc.numPoints += segment.points.numPoints
          }

          if (pwc.numPoints > pwc.threshold) {
            request.finishLevelThenCancel()
            this.finished = true
          }
        },
        onFinish: (event) => {},
      })
      return request
    },
    mountPointCloud(pointcloud) {
      this.viewer.scene.addPointCloud(pointcloud)

      // нет понимания почему, но поинтклуад в родителе viewerLayout не равен текущем, присвоим
      this.$emit('pointcloud', pointcloud)

      const material = pointcloud.material
      material.size = 1

      material.pointSizeType = Potree.PointSizeType.FIXED
      // material.pointColorType = Potree.PointColorType.RGB
      material.shape = Potree.PointShape.CIRCLE

      this.viewer.fitToScreen()
      this.$emit('importAnnotations')
    },
  },
}
</script>

<style scoped></style>
