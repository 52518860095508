<script>
import { Button, Input, Checkbox, Form, FormItem, Alert } from 'element-ui'

export default {
  name: 'Registration',
  components: {
    [Checkbox.name]: Checkbox,
    [Input.name]: Input,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Alert.name]: Alert
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$gettext('Please input the password')))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$gettext('Please input the password again')))
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$gettext('Passwords don\'t match!')))
      } else {
        callback()
      }
    }
    return {
      response: {
        success: false,
        errors: {
          status: false,
          fields: null
        }
      },
      ruleForm: {
        username: '',
        password: '',
        checkPass: '',
        email: '',
        company: '',
        phone_number: '',
      },
      rules: {
        username: [
          { required: true, message: this.$gettext('Please input user name'), trigger: 'blur' },
          { min: 3, max: 255, message: this.$gettext('Must be longer than 3 characters'), trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$gettext('Please input email address'), trigger: 'blur' },
          { type: 'email', message: this.$gettext('Please input correct email address'), trigger: ['blur', 'change'] }
        ],
        phone_number: [
          { required: true, message: this.$gettext('Пожалуйста введите номер телефона'), trigger: 'blur' },
          { min: 16, max: 16, message: this.$gettext('Введите 10 цифр номера, без учета кода страны'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$gettext('Пожалуйста введите ваше имя'), trigger: 'blur' },
          { min: 3, max: 255, message: this.$gettext('Must be longer than 3 characters'), trigger: 'blur' }
        ],
        company: [
          { required: true, message: this.$gettext('Пожалуйста введите название вашей организации'), trigger: 'blur' },
          { min: 3, max: 255, message: this.$gettext('Must be longer than 3 characters'), trigger: 'blur' }
        ],
      }
    }
  },
  beforeDestroy() {
    this.closeMenu()
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registerUser(this.ruleForm)
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    async registerUser(payload) {
      try {
        const response = await this.axios.post('/user/register/', { ...this.ruleForm })
        if (response.status === 200) {
          // Сохраняем в окно данные успешно зарегистрированного юзера для ройстата
          Object.keys(this.ruleForm)
            .filter(key => key !== 'password' || key !== 'checkPass')
            .forEach((key) => {
              window[key] = this.ruleForm[key]
            })
          window.comment = true
          this.response.success = true
          this.response.errors.status = false
          // Нажимаем кнопку на которую подвешен ройстат
          document.getElementById('confirmRegister').click()
          this.resetForm('ruleForm')
        }
      } catch (e) {
        this.response.errors.status = true
        this.response.errors.fields = e.response.data
      }
    }
  }
}
</script>

<template>
  <div class="pb-3">
    <div class="row justify-content-center py-1 pt-md-2 pb-md-3">
      <div class="col-6 text-center">
        <img
          src="/assets/images/nova-logo-white.png"
          alt=""
        >
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="col-12 col-sm-10 col-md-5 col-lg-3 text-center scroll-bar"
      >
        <div
          v-if="response.success"
        >
          <el-alert
            :title="'You have been successfully registered in the system' | translate"
            type="success"
            effect="dark"
            show-icon
          />
          <div
            class="mt-1 mt-md-2 p-2"
          >
            <div class="row justify-content-center">
              <router-link
                to="/"
              >
                <el-button
                  type="primary"
                  icon="icon nova-btn-play"
                  style="font-size: 50px"
                  circle
                />
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-show="!response.success"
          class="row registration-form-nova mx-2"
        >
          <div class="col-12">
            <p class="header text-center registration-text">
              <translate>Registration</translate>
            </p>
          </div>
          <div class="col-12">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              status-icon
              :rules="rules"
            >
              <el-form-item
                prop="name"
              >
                <el-input
                  v-model="ruleForm.name"
                  name="name"
                  class="registration-input"
                  :placeholder="'Your name' | translate"
                  prefix-icon="icon nova-circle-08"
                  type="name"
                  autocomplete="section-registration name"
                />
              </el-form-item>
              <el-form-item
                prop="username"
              >
                <el-input
                  v-model="ruleForm.username"
                  name="username"
                  class="registration-input"
                  :placeholder="'Username' | translate"
                  prefix-icon="icon nova-circle-10"
                  type="username"
                  autocomplete="section-registration username"
                />
              </el-form-item>
              <el-form-item
                prop="pass"
              >
                <el-input
                  v-model="ruleForm.password"
                  name="password"
                  class="registration-input"
                  :placeholder="'Password' | translate"
                  show-password
                  prefix-icon="icon nova-access-key"
                  type="password"
                  autocomplete="section-registration new-password"
                />
              </el-form-item>
              <el-form-item
                prop="checkPass"
              >
                <el-input
                  v-model="ruleForm.checkPass"
                  name="confirmPassword"
                  class="registration-input"
                  :placeholder="'Repeat password' | translate"
                  show-password
                  prefix-icon="icon nova-access-key"
                  type="password"
                  autocomplete="section-registration new-password"
                />
              </el-form-item>
              <el-form-item
                prop="email"
              >
                <el-input
                  v-model="ruleForm.email"
                  name="email"
                  class="registration-input"
                  :placeholder="'Email' | translate"
                  prefix-icon="icon nova-globe"
                  type="email"
                  autocomplete="work email"
                />
              </el-form-item>

              <el-form-item
                prop="phone_number"
              >
                <el-input
                  v-model="ruleForm.phone_number"
                  v-mask="'+7(###)###-##-##'"
                  placeholder="+7(999)999-99-99"
                  name="phone_number"
                  class="registration-input"
                  prefix-icon="el-icon-phone"
                  type="tel"
                />
              </el-form-item>

              <el-form-item
                prop="company"
              >
                <el-input
                  v-model="ruleForm.company"
                  name="company"
                  class="registration-input"
                  :placeholder="'Organization' | translate"
                  prefix-icon="el-icon-office-building"
                />
              </el-form-item>
              <el-form-item>
                <div class="row justify-content-around">
                  <div class="col-auto pt-1">
                    <el-button
                      type="primary"
                      class="registration-btn"
                      @click.prevent="(e) => submitForm('ruleForm', e)"
                    >
                      <translate>Register</translate>
                    </el-button>
                  </div>
                  <div class="col-auto pt-1">
                    <el-button
                      class="reset-btn"
                      @click="resetForm('ruleForm')"
                    >
                      <translate>Clear</translate>
                    </el-button>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div
              v-if="response.errors.status"
            >
              <el-alert
                v-for="item of Object.values(response.errors.fields)"
                :key="item.id"
                :title="item[0]"
                type="error"
                effect="dark"
                class="mt-1"
                show-icon
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      id="confirmRegister"
      style="display: none;"
    />
    <div class="bg--black" />
  </div>
</template>

<style lang="scss" scoped>
  .registration-form-nova {
    background-color: #ffffff;
    border-radius: 32px;
  }
  .registration-text {
    padding-top: 52px;
    text-transform: uppercase;
    font: Bold 18px/24px Open Sans;
  }
  .registration-btn {
    background-color: #51cbce;
    border-radius: 15px;
    &:hover, &:focus {
      color: #51cbce;
    }
  }
  .reset-btn {
    border-radius: 15px;
  }
  .bg--black {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2500px;
    z-index: -1;
  }
  .scroll-bar {
    overflow: hidden !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    overflow: -moz-scrollbars-none !important;
  }
  .scroll-bar::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
</style>
