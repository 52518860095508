<template>
  <div class="login-page">
    <!--    <app-navbar></app-navbar>-->
    <div class="wrapper wrapper-full-page">
      <div class="container">
        <div class="inner-login-page">
          <div class="logo-nova pt-md-5 my-3 my-md-5">
            <img
              src="/assets/images/nova-logo-white.png"
              alt=""
            >
          </div>
          <div class="login-form-nova mt-3 mt-md-5">
            <p class="header text-center login-text">
              <translate>Log in</translate>
            </p>
            <div class="form-input-nova">
              <el-input
                v-model="form.username"
                class="login-input"
                :placeholder="'Username' | translate"
                prefix-icon="icon nova-circle-10"
              />
              <el-input
                v-model="form.password"
                class="login-input"
                :placeholder="'Password' | translate"
                show-password
                prefix-icon="icon nova-access-key"
              />
              <p
                v-for="(error, index) in errorsMessages.nonFieldErrors"
                :key="index"
                class="login-alert py-0"
              >
                {{ error }}
              </p>
              <el-checkbox
                v-model="checked"
                class="pt-2 pb-4"
              >
                <translate>Remember password</translate>
              </el-checkbox>
              <el-button
                v-loading="loading"
                class="login-btn"
                type="danger"
                :disabled="!(form.username && form.password)"
                native-type="submit"
                @click="submit"
              >
                <translate>Login</translate>
              </el-button>
              <el-button
                class="d-flex mx-auto"
                type="text"
                native-type="submit"
                @click="toRegistrtion"
              >
                <span style="color: black"><translate>Registration</translate></span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--      <app-footer></app-footer>-->
      <div class="full-page-background" />
    </div>
  </div>
</template>
<script>
import { Button, Input, Checkbox } from 'element-ui'

export default {
  components: {
    [Checkbox.name]: Checkbox,
    [Input.name]: Input,
    [Button.name]: Button
  },
  data() {
    return {
      loading: false,
      errorsMessages: {
        nonFieldErrors: []
      },
      form: {
        username: '',
        password: '',

      },
      checked: false,
    }
  },
  beforeDestroy() {
    this.closeMenu()
  },
  methods: {
    submit(e) {
      e.preventDefault()
      this.errorsMessages = {
        nonFieldErrors: []
      }
      this.loading = true
      this.$auth.login({
        data: {
          username: this.form.username,
          password: this.form.password
        },
        success: function() {
          this.loading = false
        },
        error: function(response) {
          this.loading = false
          if (undefined === response.response && response.message.toString() === 'Network Error') {
            this.errorsMessages.nonFieldErrors = [this.$gettext('Network error. Check internet connection')]
          } else {
            this.errorsMessages.nonFieldErrors = [this.$gettext('No active account found with the given credentials')]
            // this.errorsMessages.nonFieldErrors = response.response.data.detail || []
            this.errorsMessages.username = response.response.config.data.username || []
            this.errorsMessages.password = response.response.config.data.password || []
          }
        },
        rememberMe: true,
        redirect: '/',
        fetchUser: true
      })
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    toRegistrtion() {
      this.$router.push({ name: 'Registration' })
    }
  }
}
</script>

<style scoped>
  .full-page-background {
    background: #000000 url('/assets/images/shutterstock_631636766.png') 0 0 no-repeat padding-box;
    background-size: cover;
    position: absolute;
    top: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
  }
  .inner-login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-form-nova {
    max-width: 375px;
    height: 424px;
    background-color: #ffffff;
    border-radius: 32px;
  }
  .login-text {
    padding-top: 52px;
    text-transform: uppercase;
    font: Bold 18px/24px Open Sans;
  }
  .form-input-nova {
    display: flex;
    flex-direction: column;
    margin: 20px 60px 83px;
  }
  .login-input {
    margin-top: 20px;
  }
  .login-btn {
    background-color: #D32628;
    border-radius: 15px;
  }
  .login-alert {
    color: #D32628;
    text-align: center;
  }
</style>
