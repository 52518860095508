const loadFromLocalStorageOrDefault = function(key, defaultValue) {
  if (!localStorage.getItem(key)) {
    localStorage.setItem(key, defaultValue)
    return defaultValue
  } else {
    return localStorage.getItem(key)
  }
}


export const ROISTAT_COUNTER_ID = loadFromLocalStorageOrDefault('ROISTAT_COUNTER_ID', '4643a0675f52f70c66e63471ccfe74db')
