import * as L from 'leaflet'

export const state = {
  props: {
    opacity: 100,
  },
  mapBounds: null,
  showTitleLabels: true,
  showMeasureLabels: true,
}

export const getters = {}

export const mutations = {
  showTitleLabels(state, value) {
    state.showTitleLabels = value
  },
  showMeasureLabels(state, value) {
    state.showMeasureLabels = value
  },
  // методы для карты
  setMapBounds(state, value) {
    state.mapBounds = value
  },
  extendMapBounds(state, value) {
    state.mapBounds.extend(value)
  },
}

export const actions = {
  /**
   * Подгрузка слоёв DSM и orthophoto
   */
  async loadExtraLayers(
    { state, commit },
    { project, task, headers, dsm, orthophoto, map }
  ) {
    commit('setMapBounds', null)
    const queue = []
    if (dsm !== null) queue.push(dsm)
    if (orthophoto !== null) queue.push(orthophoto)
    const layers = []
    queue.forEach((info) => {
      const meta = {
        project: project,
        task: task,
        type: info.type,
        name: info.name,
      }
      const bounds = L.latLngBounds([
        info.bounds.slice(0, 2).reverse(),
        info.bounds.slice(2, 4).reverse(),
      ])
      const layer = L.tileLayer.withAuth(
        info.tiles[0],
        {
          bounds,
          minZoom: info.minzoom,
          maxZoom: L.Browser.retina ? info.maxzoom + 1 : info.maxzoom,
          maxNativeZoom: L.Browser.retina ? info.maxzoom - 1 : info.maxzoom,
          tms: info.scheme === 'tms',
          opacity: state.props.opacity / 100,
          detectRetina: true,
        },
        headers
      )

      // Associate metadata with this layer
      layer[Symbol.for('meta')] = meta

      // For some reason, getLatLng is not defined for tileLayer?
      // We need this function if other code calls layer.openPopup()
      // TODO переменных ниже нигде нет, не понятно зачем это
      layer.getLatLng = () => {}
      // layer.getLatLng = () => {
      //   return this.lastClickedLatLng
      //     ? this.lastClickedLatLng
      //     : this.options.bounds.getCenter()
      // }

      info.$layer = layer

      // DSM по-умолчанию не добавляем
      if (info.$menu && info.$menu.visibility) {
        layers.push(info.$layer)
        map.addLayer(layer)
      }

      if (!state.mapBounds) {
        commit('setMapBounds', L.latLngBounds())
      }
      commit('extendMapBounds', bounds)
    })
    return layers
  },
}
