<template>
  <div
    v-if="$auth.watch.authenticated"
    class="user"
  >
    <div class="photo">
      <gravatar
        :email="$auth.user().email"
        alt="user avatar"
      />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        href="#"
        @click="toggleMenu"
      >
        <span>
          <span v-if="$auth.user().fullName">{{ $auth.user().fullName }}</span>
          <span v-else>{{ $auth.user().username }}</span>
          <b class="caret" />
        </span>
      </a>
      <div class="clearfix" />
      <div>
        <collapse-transition>
          <ul
            v-show="!isClosed"
            class="nav nav-menu"
          >
            <router-link
              :to="{ name: 'MyProfile' }"
              class="nav-item"
              tag="li"
            >
              <a
                class="nav-link"
              >
                <span class="sidebar-mini-icon fa fa-user-circle" />
                <span
                  class="sidebar-normal"
                ><translate>User page</translate></span>
              </a>
            </router-link>
            <li>
              <a
                href="javascript:void(0)"
                @click="$auth.logout({redirect: '/login'})"
              >
                <span class="sidebar-mini-icon fa fa-lock" />
                <span
                  class="sidebar-normal"
                ><translate>Logout</translate></span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
  <div
    v-else
    class="user"
  >
    <router-link
      to="/login"
      class="nav-item"
      tag="li"
    >
      <a class="nav-link">
        <i class="nc-icon nc-tap-01" /> <translate>Login</translate>
      </a>
    </router-link>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions'
import Gravatar from 'vue-gravatar'

export default {
  components: {
    CollapseTransition,
    Gravatar
  },
  data() {
    return {
      isClosed: true,
      tasks: null,
    }
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed
    },
  },
}
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
