/**
 * @file Определяет  класс Polygon
 */
import { MultiPointMeasure } from './MultiPointMeasure'
import { Measure } from '@letsnova/potree/src/utils/Measure'
import { last } from 'lodash'
import Rectangle3D from '@nova/Classes/3D/Rectangle3D'
import Utils from '@nova/Classes/Utils'
import { DEFAULT_RECTANGLE_LINE_LENGTH, MODE_3D, SELECTED_COLOR } from '@nova/constants'
import * as THREE from 'three'
import * as L from 'leaflet'

class Rectangle extends MultiPointMeasure {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Object} объект в котором зранятся свойства для создания объекта
   */
  constructor(vm, data = null) {
    if (data === null) {
      data = {}
    }
    super(vm, data)
    this._isSquare = false
    this.type = 'annotation-rectangle'
    this._rotation = data.rotation || 0
    // Длина от второй точки до третьей
    this.lineALength = parseInt(data.lineALength) || null
    // Длина от первой точки до второй
    this.lineBLength = parseInt(data.lineBLength) || null
  }

  /**
   * @inheritDoc
   */
  createPotreeMeasure() {
    this.$potreeMeasure = new Rectangle3D(
      this.centerPoint3D,
      this.lineALength,
      this.lineBLength
    )
    this.setUpPotreeMeasure()
    this.$potreeMeasure.createMesh()
    this.$potreeMeasure.mesh.visible = this.fill
    if (this.checkPotreeMeasureIsCorrect(Measure)) {
      this.vm.viewer.scene.addMeasurement(this.$potreeMeasure)
    } else {
      console.error('Недопустимый тип замера', this.$potreeMeasure)
    }
    // при создании в 3D проставляем разворот, чтобы меш тоже повернулся
    this.rotation = this._rotation
  }

  /**
   * @inheritDoc
   */
  updateCalculations() {
    if (this.points.length > 0) {
      if (this.$layer) {
        this.length2D = this.getPathLength(this.$layer)
        this.area2D = this.getArea(this.$layer)
      }
      if (this.$potreeMeasure) {
        this.area3D = this.$potreeMeasure.getArea()
        this.length3D = this.$potreeMeasure.getTotalDistance()
        this._volumeNeedUpdate = true
      } else {
        this.area3D = null
        this.length3D = null
      }
    } else {
      console.warn('У замера нет точек для подсчётов', this)
    }
  }

  /**
   * @inheritDoc
   */
  changeSpheresVisibility() {
    if (this.$potreeMeasure) {
      // Проверяем что замер уже нарисован, если нет, то сфера, которую рисуем как центральную точку - отображаем
      if (!this.isNew) {
        this.$potreeMeasure.spheres.map((sphere) => {
          sphere.visible = !!sphere.isCenter
        })
      }
    }
    console.warn('убираем сферы у квадрата всегда')
  }

  /**
   * @inheritDoc
   */
  setLayerColor(color) {
    super.setLayerColor(color)
    if (this.$potreeMeasure) {
      const _color = new THREE.Color(color)
      this.$potreeMeasure.mesh.material.color = _color
    }
  }

  getRotatedPoints(center, lineA, lineB, rotation) {
    const res = []
    // const centerPoint = this.vm.map.latLngToLayerPoint(center)
    // const angle = yaw * (Math.PI / 180)
    // for (let i = 0; i < points.length; i++) {
    //   const p = this.vm.map.latLngToLayerPoint(points[i])
    //   // translate to center
    //   const p2 = new Point(p.x - centerPoint.x, p.y - centerPoint.y)
    //   // rotate using matrix rotation
    //   const p3 = new Point(Math.cos(angle) * p2.x - Math.sin(angle) * p2.y, Math.sin(angle) * p2.x + Math.cos(angle) * p2.y)
    //   // translate back to center
    //   let p4 = new Point(p3.x + centerPoint.x, p3.y + centerPoint.y)
    //   // done with that point
    //   p4 = this.vm.map.layerPointToLatLng(p4)
    //   res.push(p4)
    // }
    const angle = rotation * (Math.PI / 180)
    const x = Math.atan(lineB / lineA)
    const s = Math.sqrt(lineA * lineA + lineB * lineB) / 2
    const offsetX1 = s * Math.cos(angle - x)
    const offsetY1 = s * Math.sin(angle - x)
    const offsetX2 = s * Math.sin((90 * Math.PI) / 180 - angle - x)
    const offsetY2 = s * Math.cos((90 * Math.PI) / 180 - angle - x)
    res.push(Utils.offsetLatLngByMeters(center, -offsetX1, offsetY1))
    res.push(Utils.offsetLatLngByMeters(center, -offsetX2, offsetY2))
    res.push(Utils.offsetLatLngByMeters(center, offsetX1, -offsetY1))
    res.push(Utils.offsetLatLngByMeters(center, offsetX2, -offsetY2))
    return res
  }

  /**
   * @inheritDoc
   */
  placeLabelsOnWorkingLayer(layer, formatter, visible, map, drawMode) {
    map.on('mousemove', (mouse) => {
      if (drawMode && visible) {
        if (layer._parts) {
          if (!layer._measurementOptions) {
            layer._measurementOptions = L.extend(
              {
                measureName: null,
                defaultMeasureName: null,
                showOnHover: false,
                minPixelDistance: 30,
                showDistances: true,
                showArea: true,
                lang: {
                  totalLength: 'Total length',
                  totalArea: 'Total area',
                  segmentLength: 'Segment length',
                  lineName: 'Line name',
                  polygonName: 'Polygon name',
                  markername: 'Marker name',
                },
              },
              {}
            )
            layer._measurementLayer = L.layerGroup().addTo(map, true)
            layer._measurementOptions.showMeasures = visible
          }
        }
      }
    })
  }

  calculateSquare() {
    if (this.points && (this.points.length === 4 || this.points.length === 5)) {
      // проверяем являются ли точки LatLng, так в 3D это слетает
      // если точек нет или их не 4, то точно не квадрат
      const length = parseInt(last(this.points).distanceTo(this.points[0]))
      // последнюю точку не считаем, потому что вычислили ее выше
      for (let pointIndex = 0; pointIndex < 3; pointIndex++) {
        const distance = parseInt(
          this.points[pointIndex].distanceTo(this.points[pointIndex + 1])
        )
        if (distance !== length) {
          return false
        }
      }
      return true
    } else {
      return false
    }
  }

  updatePosition(points) {
    if (!this.isNew && this.$potreeMeasure) {
      // обновим положение точек углов
      if (this.$potreeMeasure.differencePoint) {
        const currentPoints = this.$potreeMeasure.points
        for (const ind in currentPoints) {
          if (ind < 4) {
            currentPoints[ind].position.x += this.$potreeMeasure.differencePoint.x || 0
            currentPoints[ind].position.y += this.$potreeMeasure.differencePoint.y || 0
            currentPoints[ind].position.z += this.$potreeMeasure.differencePoint.z || 0
            this.$potreeMeasure.setPosition(ind, currentPoints[ind].position, true)
          }
        }
      }

      // обновим положение центральной точки
      this.$potreeMeasure.centerPoint = points[4].position
      this.setCenterPoint(points[4].position)
      this.$potreeMeasure.createMesh()
    }
  }

  /**
   * @inheritDoc
   */
  get defaultLabel() {
    return this.vm.$gettext('Rectangle')
  }

  /**
   * Возвращает является ли прямоугольник квадратом
   * @return {boolean}
   */
  get isSquare() {
    return this._isSquare
  }

  /**
   * Делает прямоугольник квадратом
   * @param value {boolean}
   */
  set isSquare(value) {
    this._isSquare = value
    if (value) {
      if (this.$layer) {
        const editEnabled = this.$layer.pm._enabled
        // и отрисовываем слой по новому
        this.$layer.setBounds(this.centerPoint.toBounds(this.longestVertex))
        if (editEnabled) {
          this.$layer.pm.disable()
          this.$layer.pm._markerGroup.clearLayers()
        }
        // и подставляем в наш объект точки с текущего слоя
        this.points = []

        this.$layer._latlngs.forEach((e) => {
          // При редактировании Leaflet на какой-то хер создает дополнительный массив,
          // поэтому если режим редактирования, проходим по всем массивам и добавляем точки из всех массивов
          if (e instanceof Array) {
            e.forEach((point) => {
              this.points.push(point)
            })
          } else {
            // Иначе слой просто перетащили
            // FIXME: в этом случае проебались все высоты
            this.points.push(e)
          }
        })
        if (editEnabled) {
          this.$layer.pm.enable()
          this.$layer.pm.enableLayerDrag()
        }
      }
      if (this.vm.currentMode === MODE_3D) {
        if (+this.lineALength > +this.lineBLength) {
          this.lineBLength = +this.lineALength
        } else {
          this.lineALength = +this.lineBLength
        }
      }
      this.rotation = this._rotation
      // и пересчитываем точки в 3D
      this.fixAltitudes()
    }
  }

  /**
   * @inheritDoc
   */
  get isElevationProfile() {
    return false
  }

  /**
   * Возвращает длину линии A (Левый верхний угол - левый нижный)
   * @return {Number}
   */
  get lineALength() {
    if (this.points.length === 4 || this.points.length === 5) {
      return this.points[1].distanceTo(this.points[2]).toFixed(1)
    } else {
      return DEFAULT_RECTANGLE_LINE_LENGTH
    }
  }

  /**
   * Двигает точки прямоугольника
   * @param value {Number} - оффсет для сдвига точки в метрах
   */
  set lineALength(value) {
    if (value !== null) {
      const offsetY = this.lineBLength / 2
      const offsetX = value / 2
      this.points[0] = Utils.offsetLatLngByMeters(this.centerPoint, -offsetX, -offsetY)
      this.points[1] = Utils.offsetLatLngByMeters(this.centerPoint, -offsetX, offsetY)
      this.points[2] = Utils.offsetLatLngByMeters(this.centerPoint, offsetX, offsetY)
      this.points[3] = Utils.offsetLatLngByMeters(this.centerPoint, offsetX, -offsetY)

      this._isSquare = this.calculateSquare()

      if (this.$potreeMeasure && this.vm.currentMode === MODE_3D) {
        this.$potreeMeasure.$scene.removeMeasurement(this.$potreeMeasure)
        // создаем новый
        this.createPotreeMeasure()
        this.changeSpheresVisibility()
      } else if (this.$layer) {
        this.$layer.setLatLngs(this.points)
      }
      // без этого не показывается лейбл названия
      this.updateCalculations()
      if (this.$potreeMeasure && this.vm.currentMode === MODE_3D) {
        this.vm.recomputeNameLabelPosition(this.$potreeMeasure)
        this.vm.scaleNameLabel(this.$potreeMeasure.nameLabel)
      }
      this.setLayerColor(SELECTED_COLOR)
      if (this.$layer) {
        const rotatedPoints = this.getRotatedPoints(
          this.centerPoint,
          value,
          this.lineBLength,
          this._rotation
        )
        this.$layer.setLatLngs(rotatedPoints)
      }
    }
  }

  /**
   * Возвращает длину линии B (Левый верхний угол - правый верхний)
   * @return {Number}
   */
  get lineBLength() {
    if (this.points.length === 4 || this.points.length === 5) {
      return this.points[1].distanceTo(this.points[0]).toFixed(1)
    } else {
      return DEFAULT_RECTANGLE_LINE_LENGTH
    }
  }

  /**
   * Двигает точки прямоугольника
   * @param value {Number} - оффсет для сдвига точки в метрах
   */
  set lineBLength(value) {
    if (value !== null) {
      const offsetX = this.lineALength / 2
      const offsetY = value / 2
      this.points[0] = Utils.offsetLatLngByMeters(this.centerPoint, -offsetX, -offsetY)
      this.points[1] = Utils.offsetLatLngByMeters(this.centerPoint, -offsetX, offsetY)
      this.points[2] = Utils.offsetLatLngByMeters(this.centerPoint, offsetX, offsetY)
      this.points[3] = Utils.offsetLatLngByMeters(this.centerPoint, offsetX, -offsetY)

      this._isSquare = this.calculateSquare()

      if (this.$potreeMeasure && this.vm.currentMode === MODE_3D) {
        this.$potreeMeasure.$scene.removeMeasurement(this.$potreeMeasure)
        // создаем новый
        this.createPotreeMeasure()
        this.changeSpheresVisibility()
      } else if (this.$layer) {
        this.$layer.setLatLngs(this.points)
      }
      // без этого не показывается лейбл названия
      this.updateCalculations()
      if (this.$potreeMeasure && this.vm.currentMode === MODE_3D) {
        this.vm.recomputeNameLabelPosition(this.$potreeMeasure)
        this.vm.scaleNameLabel(this.$potreeMeasure.nameLabel)
      }
      this.setLayerColor(SELECTED_COLOR)
      if (this.$layer) {
        const rotatedPoints = this.getRotatedPoints(
          this.centerPoint,
          this.lineALength,
          value,
          this._rotation
        )
        this.$layer.setLatLngs(rotatedPoints)
      }
    }
  }

  get rotation() {
    return this._rotation
  }

  set rotation(value) {
    // нужен, потому что крутим в 3Д на угол, а не устанавливаем определнный
    const rotationDiff = value - this._rotation === 0 ? value : value - this._rotation
    this._rotation = value
    if (this.$potreeMeasure && this.vm.currentMode === MODE_3D) {
      if (this.$potreeMeasure._rotation !== value) {
        this.$potreeMeasure._rotation = value

        const angle = (rotationDiff * Math.PI) / 180
        this.$potreeMeasure.mesh.geometry.rotateZ(-angle)
        this.$potreeMeasure.mesh.geometry.verticesNeedUpdate = true
        // this.$potreeMeasure.mesh.rotateZ(angle)

        // FIXME Путает 2 и 3 точки
        // for (
        //   let pointIndex = 0;
        //   pointIndex < this.$potreeMeasure.points.length;
        //   pointIndex++
        // ) {
        // const center = new THREE.Vector3()
        // center.copy(this.$potreeMeasure.centerPoint)
        // center.add(this.$potreeMeasure.mesh.geometry.vertices[pointIndex])
        // this.$potreeMeasure.points[pointIndex].position = center
        // }
        // Поэтому здесь костыль FIXME
        // const temp = this.$potreeMeasure.points[2].position
        // this.$potreeMeasure.points[2].position = this.$potreeMeasure.points[3].position
        // this.$potreeMeasure.points[3].position = temp
        //
        this.vm.recomputeNameLabelPosition(this.$potreeMeasure)
      }

      return
    }
    if (this.$layer) {
      const rotatedPoints = this.getRotatedPoints(
        this.centerPoint,
        this.lineALength,
        this.lineBLength,
        this._rotation
      )
      this.$layer.setLatLngs(rotatedPoints)
      this.points = rotatedPoints
    }

    // this.$layer.pm.disable()
    // this.$layer.pm._markerGroup.clearLayers()
    //
    // this.$layer.pm.enable()
    // this.$layer.pm.enableLayerDrag()
  }

  /**
   * Геттер возвращает значение заливки для замера
   * @return {Boolean}
   */
  get fill() {
    return this._fill
  }

  /**
   * Сеттер устанавливает значение заливки для замера
   * @param value {Boolean} - значения заливки
   */
  set fill(value) {
    this._fill = value
    this.$potreeMeasure.mesh.visible = value
  }

  get exportData() {
    const data = super.exportData
    data.rotation = this.rotation
    data.lineALength = this.lineALength
    data.lineBLength = this.lineBLength
    return data
  }
}

export default Rectangle
