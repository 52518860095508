<script>
import { Card, Input, Button, ButtonGroup, Table, TableColumn, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import AsyncImage from './AsyncImage'

export default {
  name: 'ProjectsPageGrid',
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Card.name]: Card,
    AsyncImage
  },
  props: {
    projects: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
    }
  },
  computed: {
    tasks: {
      get() {
        return this.projects
      }
    }
  },
  methods: {
    assetsPath(project, asset) {
      return this.$root.apiPath(`/api/projects/${project.id}/tasks/${project.tasks[0]}/assets/${asset}`)
    },
  }
}
</script>

<template>
  <div>
    <div class="projects">
      <div
        v-if="projects"
        v-nova-page-guide:allCardsProjects
        class="container"
      >
        <div class="row">
          <div
            v-for="(project, index) in projects"
            :key="`${index}-project`"
            class="card-project col-12 col-sm-6 col-md-4 py-2"
            :class="{ 'first-for-guide': index === 0 }"
          >
            <el-card
              shadow="never"
              class="custom-el-card"
              :body-style="{ padding: '0px' }"
            >
              <router-link
                :to="{name: 'project-detail', params: {projectId: project.id}}"
              >
                <AsyncImage
                  :value="assetsPath(project, 'thumbnail.jpg')"
                  class="d-flex card-image"
                />
              </router-link>
              <div class="d-flex py-3 px-0 card-bottom">
                <div class="col-10">
                  <p class="text-truncate card-title">
                    {{ project.name }}
                  </p>
                  <p class="card-date mb-0">
                    {{ project.created_at | moment("DD.MM.YYYY") }}
                  </p>
                </div>
                <div class="col-2 align-self-center px-0">
                  <router-link
                    :to="{name: 'project-detail', params: {projectId: project.id}}"
                  >
                    <el-button
                      type="info"
                      icon="icon nova-btn-play"
                      circle
                    />
                  </router-link>
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .el-dropdown-menu.el-popper > a {
    text-decoration: none;
    color: #000000;
  }
  /* card project*/
  .card-image {
    background: #000000;
    height: 150px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
  }
  .card-bottom {
    background: #808080 0 0 no-repeat padding-box;
    border-radius: 0 0 5px 5px;
  }
  .card-title {
    font: 700 14px/19px Open Sans;
    color: #FFFFFF;
    &:hover {
      white-space: normal;
      text-overflow: unset;
    }
  }
  .card-date {
    text-align: left;
    font: 100 14px/19px Open Sans;
    letter-spacing: 0;
    color: #FFFFFF;
  }
  .card-btns {
    margin: auto 0;
  }
  .card-btns > a {
    margin-right: 0.8em;
  }
  .el-button.is-circle {
    background: transparent;
    color: #FFFFFF;
    padding: 0;
    border: none;
    font-size: 30px;
  }
  .custom-el-card {
    border: none;
    background-color: transparent;
  }
</style>
