<template>
  <div>
    <div class="container">
      <div class="row justify-content-center mt-3">
        <div class="col-12 d-flex justify-content-center">
          <img
            src="/assets/images/nova-logo-white.png"
            alt=""
          >
        </div>
        <div class="col-12 text-center">
          <span class="text-secondary display-3">404</span>
        </div>
        <div class="col-12 text-center">
          <h1 class="text-secondary">
            <translate>Page not found</translate>
          </h1>
        </div>
        <div class="col-12 text-center">
          <router-link
            v-translate
            to="/"
            type="button"
            class="btn btn-dark rounded-pill"
          >
            To main
          </router-link>
        </div>
      </div>
    </div>
    <div class="full-page-background img-fluid" />
  </div>
</template>
<script>
import { Checkbox, Button } from 'src/components/UIComponents'
export default {
  components: {
    [Checkbox.name]: Checkbox,
    [Button.name]: Button
  },
  beforeDestroy() {
    this.closeMenu()
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    }
  }
}
</script>
<style>
  .full-page-background {
    background: #000000 url('/assets/images/shutterstock_631636766.png') 0 0 no-repeat padding-box;
    background-size: cover;
    position: absolute;
    top: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
  }
</style>
