<template>
  <div>
    <notifications />
    <div class="card-image">
      <gravatar
        :email="value.email"
        alt="user avatar"
        :size="184"
        class="image my-2 mx-auto"
      ></gravatar>
    </div>
    <div class="card-bottom d-flex justify-content-between py-3">
      <div class="card-desc d-flex flex-column justify-content-around col-8">
        <div class="card-title">
          {{ value.username }} <br />
          {{ value.role }}
        </div>
        <div class="card-date"></div>
      </div>
      <div class="card-btns col-4 mt-auto">
        <div class="row">
          <router-link
            v-if="!myProfile"
            :to="{ name: 'User', params: { userId: value.id } }"
            class="col-6 p-0"
          >
            <el-button
              v-nova-page-guide:btnPlayFromUserCard
              icon="icon nova-btn-play"
              circle
            ></el-button>
          </router-link>
          <router-link v-if="myProfile" :to="{ name: 'MyProfile' }" class="col-6 p-0">
            <el-button
              v-nova-page-guide:btnPlayFromUserCard
              icon="icon nova-btn-play"
              circle
            ></el-button>
          </router-link>
          <el-dropdown
            v-if="isAdmin"
            trigger="click"
            :hide-on-click="false"
            class="my-auto col-6 p-0"
            @command="handleCardCommands"
          >
            <el-button
              size="mini"
              type="info"
              icon="icon nova-dots icon-rotate-90"
              circle
            ></el-button>
            <el-dropdown-menu v-if="!myProfile" slot="dropdown">
              <el-dropdown-item v-if="value.blocked" command="unblock">
                <translate key="unblock">
                  To unblock
                </translate>
              </el-dropdown-item>

              <el-dropdown-item v-else command="block">
                <translate key="block">
                  To block
                </translate>
              </el-dropdown-item>

              <el-dropdown-item command="password">
                <translate>Change password</translate>
              </el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu v-else slot="dropdown">
              <el-dropdown-item>
                <router-link
                  v-translate
                  to="/profile"
                  class="text-muted text-decoration-none"
                >
                  My profile
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Avatar,
  Notification,
} from 'element-ui'
import Gravatar from 'vue-gravatar'

export default {
  name: 'UserCard',
  components: {
    [Button.name]: Button,
    [Avatar.name]: Avatar,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    Gravatar,
  },
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      },
    },
    isAdmin: {
      type: Boolean,
      default: function() {
        return false
      },
    },
    companyId: {
      type: Number,
      default: function() {
        return null
      },
    },
  },
  data() {
    return {
      // Если true - значит профиль текущего пользователя, ссылка будет вести на просмотр своего профиля
      myProfile: false,
    }
  },
  async mounted() {
    // Проверка на текущего пользователя
    const currentUser = await this.$auth.user().id
    if (currentUser === this.value.id) {
      this.myProfile = true
    }
  },
  created() {},
  methods: {
    /**
     * Обработка действий дропдауна карточки
     * @param action действие
     * @return {Promise<void>}
     */
    async handleCardCommands(action) {
      let result
      switch (action) {
        case 'block':
          result = await this.$store.dispatch('users/block', {
            userId: this.value.companyId,
            companyId: this.companyId,
            username: this.value.username,
          })
          if (result) {
            this.showNotification(true, this.$gettext('User blocked'))
          } else {
            this.showNotification(false, this.$gettext('User not blocked'))
          }
          this.$set(this.value, 'blocked', result)
          this.$emit('update', this.value)
          this.$forceUpdate()
          break
        case 'unblock':
          result = await this.$store.dispatch('users/block', {
            userId: this.value.companyId,
            companyId: this.companyId,
          })
          if (result) {
            this.showNotification(true, this.$gettext('User unblocked'))
          } else {
            this.showNotification(false, this.$gettext('User not unblocked'))
          }
          this.$set(this.value, 'blocked', result)
          this.$emit('update', this.value)
          this.$forceUpdate()
          break
        case 'password':
          result = await this.$store.dispatch('users/changePassword', {
            companyId: this.value.companyId,
            email: this.value.email,
          })
          if (result) {
            this.showNotification(true, this.$gettext('Password recovery requested'))
          } else {
            this.showNotification(
              false,
              this.$gettext('Password recovery request ends with errors')
            )
          }
          break
      }
    },
    /**
     * Высвечивание оповещения
     * @param message Сообщение
     */
    showNotification(success, message) {
      Notification({
        title: success ? this.$gettext('Saved') : this.$gettext('Not saved'),
        message: message,
        type: success ? 'success' : 'error',
        position: 'top-right',
        showClose: false,
      })
    },
  },
}
</script>

<style scoped>
.card-image {
  background: #ffffff 0 0 no-repeat padding-box;
  border-radius: 5px 5px 0 0;
  height: 184px;
}
.image {
  display: block;
}
.card-bottom {
  background: #808080 0 0 no-repeat padding-box;
  border-radius: 0 0 5px 5px;
}
.card-title {
  font: 700 14px/19px Open Sans;
  letter-spacing: 0;
  color: #ffffff;
  margin: auto 0;
}
.el-button--info:hover,
.el-button--info:focus {
  background: transparent !important;
  color: #51cbce !important;
}
.el-button:hover,
.el-button:focus {
  /*color: #51cbce !important;*/
  background: transparent !important;
}
/* for guide */
.for-guide {
  background-color: rgb(244, 243, 239);
}
</style>
