import L from 'leaflet'
{
  async function fetchImage(url, callback, headers) {
    const _headers = {}
    if (headers) {
      headers.forEach(h => {
        _headers[h.header] = h.value
      })
    }
    const controller = new AbortController()
    const signal = controller.signal
    const f = await fetch(url, {
      method: 'GET',
      headers: _headers,
      mode: 'cors',
      signal: signal
    })
    const blob = await f.blob()
    callback(blob)
  }
  L.TileLayer.WithAuth = L.TileLayer.extend({
    initialize: function(url, options, headers) {
      L.TileLayer.prototype.initialize.call(this, url, options)
      this.headers = headers
    },
    createTile(coords, done) {
      const url = this.getTileUrl(coords)
      const img = document.createElement('img')
      fetchImage(
        url,
        resp => {
          const reader = new FileReader()
          reader.onload = () => {
            img.src = reader.result
          }
          reader.readAsDataURL(resp)
          done(null, img)
        },
        this.headers
      )
      return img
    }
  })
  L.tileLayer.withAuth = function(url, options, headers) {
    return new L.TileLayer.WithAuth(url, options, headers)
  }
}
