<template>
  <div
    class="card"
    :class="[
      {'card-plain': plain},
      {'card-raised': raised},
      {[`card-${type}`]: type}
    ]"
    :data-background-color="color"
  >
    <h6
      v-if="$slots.category || category"
      class="card-category"
    >
      {{ category }}
    </h6>
    <div
      v-if="$slots.image"
      class="card-image"
    >
      <slot name="image" />
    </div>

    <div
      v-if="$slots.avatar"
      class="card-avatar"
    >
      <slot name="avatar" />
    </div>

    <div
      v-if="$slots.info"
      class="info"
    >
      <slot name="info" />
    </div>

    <div
      v-if="$slots.header || title"
      class="card-header"
      :class="headerClasses"
    >
      <slot name="header">
        <h1
          v-if="title"
          class="card-title"
        >
          {{ title }}
        </h1>
        <h3
          v-if="subTitle"
          class="card-category"
        >
          {{ subTitle }}
        </h3>
        <p
          v-if="description"
          class="card-description"
        >
          {{ description }}
        </p>
      </slot>
    </div>
    <div
      v-if="$slots.default"
      class="card-body"
      :class="cardBodyClasses"
    >
      <slot />
    </div>

    <slot name="raw-content" />

    <hr v-if="$slots.footer && footerLine">
    <div
      v-if="$slots.footer"
      class="card-footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    type: {
      type: String,
      description: 'Card type (user|stats|tasks|testimonials|signup|profile|pricing|map|plain|lock|info-area|contribution)'
    },
    // eslint-disable-next-line vue/require-default-prop
    title: {
      type: String,
      description: 'Card title'
    },
    // eslint-disable-next-line vue/require-default-prop
    subTitle: {
      type: String,
      description: 'Card subtitle'
    },
    // eslint-disable-next-line vue/require-default-prop
    category: {
      type: String,
      description: 'Card category title (displayed above normal title)'
    },
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: String,
      description: 'Card color (orange|yellow|red|green|blue|"")'
    },
    // eslint-disable-next-line vue/require-default-prop
    description: {
      type: String,
      description: 'Card description'
    },
    footerLine: {
      type: Boolean,
      description: 'Whether footer line should be displayed'
    },
    plain: {
      type: Boolean,
      description: 'Whether card is plain'
    },
    raised: {
      type: Boolean,
      description: 'Whether card is raised'
    },
    // eslint-disable-next-line vue/require-default-prop
    cardBodyClasses: {
      type: [String, Object, Array],
      description: 'Card body css classes'
    },
    // eslint-disable-next-line vue/require-default-prop
    headerClasses: {
      type: [String, Object, Array],
      description: 'Card header css classes'
    }
  }
}
</script>
<style>
</style>
