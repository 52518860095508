import './easter-egg'
import Vue from 'vue'

// Стили Bootstrap v4.3.1
import 'bootstrap/dist/css/bootstrap.min.css'

import './pollyfills'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'
import VeeValidate from 'vee-validate'
import VeeValidateRU from 'vee-validate/dist/locale/ru'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'
import App from './App.vue'
import GetTextPlugin from 'vue-gettext'
import translations from './translations'
import store from './store'
import storeMap from './store/root/map'
import storeViewer from './store/root/viewer'
import VueHtml2Canvas from 'vue-html2canvas'
import URL from 'url-parse'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueYandexMetrika from 'vue-yandex-metrika'
import RoistatPlugin from './plugins/roistat'

import VPageGuide from 'vue-page-guide'

import axiosPlugin from './axiosPlugin.js'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar'
import VueTheMask from 'vue-the-mask'

// router setup
import routes from './routes/routes'

// library imports

import './assets/sass/paper-dashboard.scss'
import './assets/sass/element_variables.scss'
import './assets/sass/demo.scss'

import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'

// only import the icons you use to reduce bundle size
import 'vue-awesome/icons/flag'

// or import all icons if you don't care about bundle size
import 'vue-awesome/icons'

/* Register component with one of 2 methods */
import Icon from 'vue-awesome/components/Icon'

import authPlugin from './authPlugin'

import * as THREE from 'three'
import { ROISTAT_COUNTER_ID } from '@/constants.js'

if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  })
}

global.jQuery = require('jquery')
window.JQuery = global.jQuery

window.THREE = THREE

// plugin setup
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, { sidebarLinks: sidebarLinks })
Vue.use(VueHtml2Canvas)
Vue.use(VueTheMask)
Vue.use(RoistatPlugin, {
  w: window,
  d: document,
  s: 'script',
  h: 'cloud.roistat.com',
  id: ROISTAT_COUNTER_ID,
})

// globally (in your main .js file)
Vue.component('v-icon', Icon)

// Подключение валидатора на русском языке
VeeValidate.Validator.localize('ru', VeeValidateRU)
Vue.use(VeeValidate, { locale: 'ru' })
locale.use(lang)
Vue.use(GetTextPlugin, {
  availableLanguages: {
    en: 'English',
    ru: 'Русский',
  },
  defaultLanguage: localStorage.language || 'ru',
  translations: translations,
  silent: process.env.NODE_ENV === 'production',
})

Vue.filter('translate', (value) => {
  return !value ? '' : Vue.prototype.$gettext(value.toString())
})

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

initProgress(router)

Vue.use(axiosPlugin)

Vue.router = router
App.router = Vue.router

// if (NPMPackage.novaConfig?.useAuth) {
//   const authPlugin = require('./authPlugin')
// }
Vue.use(authPlugin)

// Vue.use(require('vue-moment'));

const moment = require('moment')
require('moment/locale/ru')

Vue.use(require('vue-moment'), {
  moment,
})

Vue.use(VPageGuide)
// require('vue-tour/dist/vue-tour.css')
// Vue.use(VueTour)
// https://www.npmjs.com/package/vue-yandex-metrika
try {
  const metrikaID = process.env.VUE_APP_YANDEX_METRIKA || null
  Vue.use(VueYandexMetrika, {
    id: metrikaID,
    router: router,
    env: process.env.NODE_ENV,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  })
} catch (e) {
  console.error(e)
}

const baseApiURL = new URL(Vue.axios.defaults.baseURL)

// Объект для хранения ссылок на элементы Нова Гайд
Vue.prototype.$novaPageGuideElements = {}

// Определение директирвы Нова Гайд
Vue.directive('nova-page-guide', {
  /**
   * Хук пользовательской директивы
   * @param el - елемент, на который привязана директива
   * @param binding - объект, соделжащий свойства
   */
  inserted: (el, binding) => {
    const elements = Vue.prototype.$novaPageGuideElements
    const arg = binding.arg
    elements[arg] = el
    // if (Object.prototype.hasOwnProperty.call(elements, arg)) {
    //   const dublicate = arg + '_dublicate'
    //   elements[dublicate] = el
    // } else {
    //   elements[arg] = el
    // }
  },
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  data: { storeMap, storeViewer },
  computed: {
    baseApi() {
      return baseApiURL.href
    },
    headers() {
      return [
        {
          header: 'authorization',
          value: `Bearer ${localStorage.getItem('access_token')}`,
        },
      ]
    },
  },
  watch: {
    '$language.current'(newValue) {
      this.changeLanguage(newValue)
    },
  },
  mounted() {
    this.changeLanguage(this.$language.current)
  },
  methods: {
    changeLanguage(language) {
      // Применяем настройки для MomentJS
      this.$moment.locale(language)
      // FIXME: Необходимо добавить локализацию остальных модулей: VeeValidate и Element-UI
    },
    apiPath(path) {
      if (path.startsWith('/')) {
        return `${baseApiURL.protocol}//${baseApiURL.host}${path}`
      } else {
        return `${this.baseApi}${path}`
      }
    },
  },
  render: (h) => h(App),
  store,
})
