import DashboardLayout from 'src/components/Dashboard/Layout/DashboardLayout.vue'
// import DashboardLayout from "src/components/Nova/Layout/ViewerLayout";
import Projects from '../Layout/ProjectsLayout/Projects'
import Details from './Details'

export default [{
  path: '/project',
  component: DashboardLayout,
  redirect: { name: 'project-list' },

  children: [{
    path: '/projects',
    name: 'project-list',
    component: Projects,
    // redirect: "/viewer/2d",
    meta: {
      auth: true
    },
    children: [
      // {
      //   path: "2d",
      //   name: "viewer-2d",
      //   component: LeafletViewer,
      //   meta: {
      //     auth: true
      //   }
      // },
      // {
      //   path: "3d",
      //   name: "viewer-3d",
      //   component: PotreeViwer,
      //   meta: {
      //     auth: true
      //   }
      // }
    ]
  },
  {
    path: ':projectId',
    name: 'project-details',
    component: Details,
    meta: {
      auth: true
    }
  },
  ]
}]
