<template>
  <div class="container">
    <div
      v-if="turnOnGuide"
      class="btn-run-guide rounded-left"
    >
      <el-button
        style="font-size: 50px"
        class="start-button"
        icon="el-icon-question"
        type="info"
        circle
        @click="runNovaPageGuide"
      />
    </div>
    <div
      v-if="goGuide"
      class="shadow"
      :class="{ 'show-guide': goGuide }"
    />
    <!--    control panel-->
    <div
      v-if="goGuide"
      class="control container d-flex justify-content-center to-front"
    >
      <div
        class="row control-panel col-8 col-xl-6 to-front mt-2"
      >
        <div class="row justify-content-center col-12 col-xl-3">
          <div>
            <button
              :disabled="selectionElement === 0"
              class="btn-arrow px-1"
              @keyup.left="previousGuideElement"
              @click="previousGuideElement"
            >
              <i class="icon nova-left-arrow" />
            </button>
            <div class="count btn">
              {{ selectionElement + 1 + '/' + guideList.length }}
            </div>
            <button
              :disabled="!(selectionElement < guideList.length - 1)"
              class="btn-arrow px-1"
              @keyup.right="nextGuideElement"
              @click="nextGuideElement"
            >
              <i class="icon nova-right-arrow" />
            </button>
          </div>
        </div>
        <div class="text-description col-12 col-xl-8 mt-1">
          {{ desc ? desc : 'NOT DESCRIPTION' }}
        </div>
        <div class="skip col-1 text-right pt-1">
          <i
            class="icon nova-c-remove"
            @click="skipGuide"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Input, Button, ButtonGroup, Card } from 'element-ui'

export default {
  name: 'NovaPageGuide',
  components: {
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Input.name]: Input,
    [Card.name]: Card,
  },
  props: {
    // включение/выключение Нова Гайд
    turnOnGuide: {
      type: Boolean,
      default: true
    },
    // объект со всеми инструкциями Нова Гайд
    guideObj: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // свойсво со всеми елементами
      g: this.$novaPageGuideElements,
      // запуск Нова Гайд
      goGuide: false,
      // Массив ключей Нова Гайд
      guideList: [],
      // выбранный элемент
      selectionElement: null,
      // описание выбранного элемента
      desc: '',
    }
  },
  mounted() {
    this.goGuide = false
  },
  methods: {
    /**
     * Запуск Нова Гайд
     */
    runNovaPageGuide() {
      this.guideList = []
      this.goGuide = true
      this.getGuideElements()
      this.selectionElement = 0
      this.addClassToElement(this.selectionElement)
    },
    /**
     * Выбираем следующий элемент
     */
    nextGuideElement() {
      this.selectionElement++
      this.removeClassFromElement(this.selectionElement - 1)
      this.addClassToElement(this.selectionElement)
    },
    /**
     * Выбираем предыдущий элемент
     */
    previousGuideElement() {
      this.removeClassFromElement(this.selectionElement)
      this.selectionElement--
      this.addClassToElement(this.selectionElement)
    },
    /**
     * Скривыем Нова Гайд
     */
    skipGuide() {
      this.goGuide = false
      Object.keys(this.g).forEach(el => {
        this.g[el].classList.remove('target-guide-element-absolute')
        this.g[el].classList.remove('target-guide-element-fixed')
        this.g[el].classList.remove('target-guide-element-relative')
      })
      this.selectionElement = null
    },
    /**
     * Добавляем елементу класс для отображения на переднем плане
     * @param id - идентификатор выбранного элемента
     */
    addClassToElement(id) {
      const elObj = this.guideObj.find(el => el.name === this.guideList[id])
      const el = this.g[this.guideList[id]]
      if (elObj.position !== 'fixed') {
        if (elObj.position !== 'absolute') {
          el.classList.add('target-guide-element-relative')
        } else {
          el.classList.add('target-guide-element-absolute')
        }
      } else {
        el.classList.add('target-guide-element-fixed')
      }
      this.desc = this.guideObj.find(el => el.name === this.guideList[id]).desc
    },
    /**
     * Удаляем класс у элемента
     * @param id - идентификатор выбранного элемента
     */
    removeClassFromElement(id) {
      this.g[this.guideList[id]].classList.remove('target-guide-element-absolute')
      this.g[this.guideList[id]].classList.remove('target-guide-element-fixed')
      this.g[this.guideList[id]].classList.remove('target-guide-element-relative')
    },
    /**
     * Получаем актуальный массыв элементов, сопоставляя его с глобальным
     * фильтруем его по необходимости отображения в выбранном Режиме
     */
    getGuideElements() {
      this.guideObj.forEach(el => {
        if (Object.prototype.hasOwnProperty.call(this.g, el.name)) {
          if (el.mode === this.$parent.currentMode || el.mode === 1) {
            this.guideList.push(el.name)
          }
        }
      })
    }
  },
}
</script>

<style>
  .show-guide {
    background-color: #000000;
    z-index: 100000;
    opacity: .7;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
  }
  .to-front {
    position: relative;
    z-index: 100001;
  }
  .target-guide-element-absolute {
    position: absolute !important;
    z-index: 100001 !important;
   }
  .target-guide-element-fixed {
    position: fixed !important;
    z-index: 100001 !important;
  }
  .target-guide-element-relative {
    position: relative !important;
    z-index: 100001 !important;
  }
  .control {
    position: fixed;
    top: 0;
    background: transparent;
    z-index: 100002;
    animation-name: example;
    animation-duration: 1s;
  }
  .control-panel {
    background-color: #000000;
    border: solid 1px #7a7a7a;
    border-radius: 5px;
  }
  .description {
    margin: 0;
  }
  .btn-run-guide {
    position: fixed;
    bottom: 0;
    background: #000000;
    left: 0;
    z-index: 100002;
  }
  .count {
    font-size: 1.2em !important;
    padding: .5em !important;
  }
  .btn-arrow {
    background-color: transparent;
    color: white;
    border: none;
  }
  .btn-arrow:hover {
    color: #74C9E3;
  }
  .skip {
    position: absolute;
    right: 1em;
  }
  .icon:hover {
    cursor: pointer;
  }
  @keyframes example {
    0%   {top:-70px;}
    100% {top:0;}
  }
</style>
