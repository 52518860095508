<template>
  <!--user data-->
  <div class="user-person-data row mt-4">
    <notifications />
    <div
      class="person-card col-lg-3"
    >
      <div
        class="container px-0"
      >
        <div
          v-if="value.profile.email"
          class="user-card rounded"
        >
          <div
            class="card-grey d-flex justify-content-around rounded-top"
          >
            <p class="m-0 py-2">
              <strong>
                {{ value.profile.username }}
              </strong>
            </p>
          </div>
          <div class="card-image row justify-content-center py-2">
            <gravatar
              :email="value.profile.email"
              alt="user avatar"
              :size="200"
            />
          </div>
          <div
            v-if="value.tasks"
            class="card-grey d-flex justify-content-around py-3 px-4 rounded-bottom"
          >
            <div class="row justify-content-center">
              <div class="col-6">
                <div class="row">
                  <div class="col text-center count-person">
                    {{ value.tasks }}
                  </div>
                  <div class="col text-center">
                    <translate>projects</translate>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="col text-center">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$parent.blocked ? blocking.blocked.title : blocking.unlocked.title"
                    placement="top"
                    :visible-arrow="false"
                  >
                    <i
                      class="icon"
                      :class="$parent.blocked ? blocking.blocked.style : blocking.unlocked.style"
                    />
                  </el-tooltip>
                </div>
                <div class="col text-center">
                  <translate>status</translate>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="person-form col-lg-9 rounded"
    >
      <div
        class="container p-4"
        style="height: 100%"
      >
        <form>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label
                for="surname"
                class="font-size-10"
              >
                <translate>Surname</translate></label>
              <input
                id="surname"
                v-model="value.profile.last_name"
                type="text"
                class="form-control bg-white"
                :placeholder="'Surname' | translate"
                :readonly="!value.editable.fullName"
              >
            </div>
            <div class="form-group col-md-4">
              <label
                for="personName"
                class="font-size-10"
              >
                <translate>Person name</translate>
              </label>
              <input
                id="personName"
                v-model="value.profile.first_name"
                type="text"
                class="form-control bg-white"
                :placeholder="'Person name' | translate"
                :readonly="!value.editable.fullName"
              >
            </div>
            <div class="form-group col-md-4">
              <label
                for="patronomic"
                class="font-size-10"
              >
                <translate>Patronomic</translate>
              </label>
              <input
                id="patronomic"
                v-model="value.profile.patronomic"
                type="text"
                class="form-control bg-white"
                :placeholder="'Patronomic' | translate"
                :readonly="!value.editable.fullName"
              >
            </div>
          </div>
          <div
            class="form-row"
          >
            <div class="form-group col-md-4">
              <label
                for="companyName"
                class="font-size-10"
              >
                <translate>Company name</translate>
              </label>
              <input
                id="companyName"
                v-model="value.company.name"
                type="text"
                class="form-control bg-white"
                :placeholder="'Company name' | translate"
                :readonly="true"
              >
            </div>
            <div class="form-group col-md-4">
              <label
                for="companyCity"
                class="font-size-10"
              >
                <translate>Company city</translate>
              </label>
              <input
                id="companyCity"
                v-model="value.company.city"
                type="text"
                class="form-control bg-white"
                :placeholder="'Company city' | translate"
                :readonly="true"
              >
            </div>
            <div class="form-group col-md-4">
              <label
                for="companyCountry"
                class="font-size-10"
              >
                <translate>Company country</translate>
              </label>
              <input
                id="companyCountry"
                v-model="value.company.country"
                type="text"
                class="form-control bg-white"
                :placeholder="'Company country' | translate"
                :readonly="true"
              >
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label
                for="email"
                class="font-size-10"
              >
                <translate>Email</translate>
              </label>
              <input
                id="email"
                v-model="value.profile.email"
                type="text"
                class="form-control bg-white"
                :placeholder="'Email' | translate"
                :readonly="!value.editable.email"
              >
            </div>
          </div>
          <div class="form-row">
            <div
              v-if="value.editable.fullName || value.editable.email"
              class="form-group col-md-4"
            >
              <el-button
                type="success"
                round
                @click="saveProfile"
              >
                <translate>Save profile</translate>
              </el-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Input,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Notification,
  Tooltip
} from 'element-ui'
import Gravatar from 'vue-gravatar'

export default {
  components: {
    [Input.name]: Input,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tooltip.name]: Tooltip,
    Gravatar,
  },
  props: {
    value: {
      type: Object,
      default: function() {
        // Профиль пользователя для отображения в карточке
        return {
          // опции редактирования фио и емэйла
          editable: {
            fullName: false,
            email: false
          },
          // Профиль пользователя
          profile: {
            email: null,
            last_name: null,
            first_name: null,
            pathronomic: null,
          },
          // Текущая выбранная компания
          company: {
            name: null,
            country: null,
            city: null,
            // Айди пользователя в компании
            userId: null,
          },
          // количество доступных проектов в компании
          tasks: null,
        }
      }
    },
  },
  data() {
    return {
      // иконка и тултип статуса блокировки в карточке пользователя
      blocking: {
        blocked: {
          title: this.$gettext('Blocked'),
          style: 'nova-c-remove text-danger'
        },
        unlocked: {
          title: this.$gettext('Active'),
          style: 'nova-c-check text-success'
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    /**
     * Сохранение профиля пользователя
     * @returns {Promise<void>}
     */
    async saveProfile() {
      try {
        const response = await this.axios.patch(
          `/organizations/${this.$parent.organizations[this.$parent.currentOrganization].id}/users/${this.value.profile.id}/`,
          this.value.profile
        )
        this.value.profile = response.data
        if (response.status === 200) {
          this.showSuccessNotification(this.$gettext('Saved successfully'))
        } else {
          this.showDangerNotification(this.$gettext('Not saved'))
        }
      } catch (e) {
        console.error('Не получилось сохранить информацию оп пользователе', e)
      }
    },
    /**
     * Высвечивание оповещения об успехе
     * @param message Сообщение
     */
    showSuccessNotification(message) {
      Notification({
        title: this.$gettext('Saved'),
        message: message,
        type: 'success',
        position: 'top-right',
        showClose: false
      })
    },
    /**
     * Высвечивание оповещения о неудаче
     * @param message Сообщение
     */
    showDangerNotification(message) {
      Notification({
        title: this.$gettext('Not saved'),
        message: message,
        type: 'error',
        position: 'top-right',
        showClose: false
      })
    },
  }
}
</script>
<style scoped>
  .el-button--info {
    background: #747474 0 0 no-repeat padding-box;
    border-color: #747474;
  }
  .el-input__inner {
    background-color: #0c2646;
  }
  .action-btns {
    margin: 0;
  }
  .action-btns .el-button.is-circle {
    background: #464646;
    color: #FFFFFF;
    border: none;
    font-size: 14px;
  }
  /*!* main content *!*/
  .font-size-10 {
    font-weight: 700;
    font-size: 10px;
  }
  .count-person {
    font-weight: 700;
  }
  .card-grey {
    background: #808080;
  }
  .card__person-status i {
    font-size: 16px;
  }
  .user-card {
    background: #ffffff;
  }
  .person-form {
    color: #787878;
    background-color: #ffffff;
  }
</style>
<style>
  .el-button--success {
    background: #177F7F;
    border-color: #177F7F
  }
  .el-button--info:hover, .el-button--info:focus {
    background: transparent !important;
    color: #51cbce !important;
  }
  .el-button:hover, .el-button:focus {
    /*color: #51cbce !important;*/
    background: transparent !important;
  }
  .custom-el-table-row {
    color: white;
  }
  .custom-el-table-row:hover > td {
    background-color: #737373 !important;
  }
  .el-button.is-circle {
    border-radius: 50%;
    background: transparent;
    color: #ffffff;
    font-size: 25px;
    border: none;
    padding: 0;
  }
  .for-guide {
    background-color: rgb(244, 243, 239);
  }
</style>
