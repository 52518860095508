<template>
  <div>
    <nova-header />
    <div
      v-if="loadingProject"
      v-loading="loadingProject"
      style="height: 200px"
    />
    <div
      v-if="taskData"
      class="container"
    >
      <div class="row justify-content-end mt-3">
        <div class="ml-auto col-auto">
          <el-button
            round
            style="font-size: 10px; font-weight: 700"
            @click="toggleFilesModal(true)"
          >
            <translate>Add files</translate>
          </el-button>
        </div>
        <div class="section-action d-flex justify-content-lg-end col-auto my-1 my-sm-1 my-lg-0">
          <div
            v-if="currentUserAdmin"
            class="action-btns"
          >
            <div
              class="mr-1"
            >
              <el-button
                type="success"
                round
                style="font-size: 10px; font-weight: 700"
                @click="toggleAddUserModal(true)"
              >
                <translate>Add user</translate>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="project-data row mt-4">
        <div
          class="card-project col-12 col-md-4"
        >
          <router-link
            :to="{name: 'task-viewer', params: {projectId: taskData.project, taskId: taskData.id}}"
          >
            <el-image
              :src="assetsPath(taskId, 'thumbnail.jpg')"
              fit="contain"
            >
              <div
                slot="error"
                class="image-slot-error"
              >
                <i class="el-icon-picture-outline" />
                no image
              </div>
            </el-image>
          </router-link>
          <div class="data-type row">
            <div class="col-12">
              <translate>Data type</translate>:
              <el-tooltip
                :class="checkDataType(taskData, 'orthophoto.mbtiles')"
                effect="dark"
                :content="'Orthophoto' | translate"
                placement="top"
                :popper-class="checkDataType(taskData, 'orthophoto.mbtiles') ? 'absent-data-type' : ''"
                :visible-arrow="false"
              >
                <i
                  class="icon nova-map icon-xl p-1"
                />
              </el-tooltip>
              <el-tooltip
                :class="checkDataType(taskData, 'dsm.tif')"
                effect="dark"
                :content="'DSM' | translate"
                placement="top"
                :popper-class="checkDataType(taskData, 'dsm.tif') ? 'absent-data-type' : ''"
                :visible-arrow="false"
              >
                <i
                  class="icon nova-blend icon-xl p-1"
                />
              </el-tooltip>
              <el-tooltip
                :class="checkDataType(taskData, 'georeferenced_model.laz')"
                effect="dark"
                :content="'Point cloud' | translate"
                placement="top"
                :popper-class="checkDataType(taskData, 'georeferenced_model.laz') ? 'absent-data-type' : ''"
                :visible-arrow="false"
              >
                <i
                  class="icon nova-apps icon-xl p-1"
                />
              </el-tooltip>
              <el-tooltip
                :class="checkDataType(taskData, 'textured_model.zip')"
                effect="dark"
                :content="'Mesh' | translate"
                placement="top"
                :popper-class="checkDataType(taskData, 'textured_model.zip') ? 'absent-data-type' : ''"
                :visible-arrow="false"
              >
                <i
                  class="icon nova-globe icon-xl p-1"
                />
              </el-tooltip>
            </div>
            <div class="col-12">
              <translate>Created at</translate>: {{ taskData.created_at | moment("DD.MM.YYYY") }}
            </div>
            <div class="col-12">
              <translate>Status</translate>: {{ projectInfo.is_visible ? privaces.public : privaces.private }}
            </div>
          </div>
        </div>
        <div
          class="edit-project-form col-lg-8"
          style="color: #787878"
        >
          <div
            class="container p-4"
            style="background: #ffffff; border-radius: 5px; height: 100%"
          >
            <form>
              <fieldset
                :disabled="!currentUserAdmin"
              >
                <div class="form-row">
                  <div class="form-group col-12">
                    <label
                      for="taskName"
                      class="font-size-10"
                    >
                      <translate>Project name</translate></label>
                    <input
                      id="taskName"
                      v-model="taskData.name"
                      type="text"
                      class="form-control bg-white"
                      :placeholder="'Task name' | translate"
                    >
                  </div>
                  <div class="form-group col-12">
                    <label
                      for="description"
                      class="font-size-10"
                    >
                      <translate>Description</translate>
                    </label>
                    <textarea
                      id="description"
                      class="form-control bg-white"
                      :placeholder="'Description' | translate"
                    />
                  </div>
                  <div
                    v-if="currentUserAdmin"
                    class="form-group col-12 d-flex justify-content-end m-0"
                  >
                    <el-button
                      round
                      style="font-size: 10px; font-weight: 700"
                      type="success"
                      class="custom-btn-success"
                      @click="saveNewProjectName(taskData.name)"
                    >
                      <translate>Save</translate>
                    </el-button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <div
        class="table mt-3"
      >
        <div
          v-if="loadingTable"
          v-loading="loadingTable"
          style="height: 200px"
        />
        <el-table
          v-if="showTable && currentUserAdmin"
          :data="tableData"
          row-class-name="custom-el-table-row"
          style="width: 100%"
        >
          <el-table-column
            :label="'ID' | translate"
            prop="id"
            width="60"
          />
          <el-table-column
            :label="'User' | translate"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.user.username }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="'Project roles' | translate"
          >
            <template slot-scope="scope">
              <div
                v-if="!scope.row.editRoles"
              >
                <span
                  v-for="role of scope.row.roles"
                  :key="role.id"
                  class="badge badge-light ml-1"
                >
                  {{ role.localized_name }}
                </span>
              </div>
              <div
                v-else
                class="d-flex"
              >
                <el-select
                  v-model="currentRole"
                  placeholder="select"
                  class="select-custom"
                  automatic-dropdown
                >
                  <el-option
                    v-for="item of projectInfo.roles"
                    :key="item.id"
                    :label="item.localized_name"
                    :value="item.id"
                  />
                </el-select>
                <div
                  class="row align-items-center"
                >
                  <div class="col">
                    <el-button
                      icon="icon nova-c-remove icon-sm"
                      class="py-0 pl-2 pr-1"
                      circle
                      @click="closeEditRoles(scope.row)"
                    />
                    <el-button
                      icon="icon nova-c-check icon-sm"
                      class="m-0 py-0 px-1"
                      circle
                      @click="changeProjectRoles(scope.row, currentRole)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!--          <el-table-column-->
          <!--            :label="'Organization roles' | translate"-->
          <!--          >-->
          <!--            <template slot-scope="scope">-->
          <!--              <div-->
          <!--                v-if="!editRoles.organization"-->
          <!--              >-->
          <!--                <span-->
          <!--                  v-for="role of scope.row.orgRoles"-->
          <!--                  :key="role.id"-->
          <!--                  class="badge badge-light ml-1"-->
          <!--                >-->
          <!--                  {{ role.short_name }}-->
          <!--                </span>-->
          <!--              </div>-->
          <!--              <div-->
          <!--                v-else-->
          <!--                class="d-flex"-->
          <!--              >-->
          <!--                <el-select-->
          <!--                  v-model="currentRole.organization"-->
          <!--                  placeholder="select"-->
          <!--                  multiple-->
          <!--                >-->
          <!--                  <el-option-->
          <!--                    v-for="item of projectsRoles[0]"-->
          <!--                    :key="item.id"-->
          <!--                    :label="item.short_name"-->
          <!--                    :value="item.id"-->
          <!--                  />-->
          <!--                </el-select>-->
          <!--                <div-->
          <!--                  class="row align-items-center"-->
          <!--                >-->
          <!--                  <div class="col">-->
          <!--                    <el-button-->
          <!--                      icon="icon nova-c-remove icon-sm"-->
          <!--                      class="py-0"-->
          <!--                      circle-->
          <!--                      @click="editRoles.organization = !editRoles.organization"-->
          <!--                    />-->
          <!--                    <el-button-->
          <!--                      icon="icon nova-c-check icon-sm"-->
          <!--                      class="m-0 py-0"-->
          <!--                      circle-->
          <!--                      @click="changeOrganizationRoles('organization', organizations[0].id, scope.row, currentRole.organization)"-->
          <!--                    />-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column
            :label="'Actions' | translate"
            prop="actions"
            align="right"
            width="140"
          >
            <template slot-scope="scope">
              <router-link
                :to="{ name: 'User', params: { userId: scope.row.user.id }}"
              >
                <el-button
                  icon="icon nova-btn-play"
                  circle
                />
              </router-link>
              <el-dropdown
                trigger="click"
                :hide-on-click="false"
              >
                <el-button
                  size="mini"
                  type="info"
                  icon="icon nova-dots icon-rotate-90"
                  :disabled="!currentUserAdmin || scope.row.readonly"
                  circle
                />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      type="text"
                      style="color: #606266"
                      @click="changeRoles(scope.row, scope.row.roles)"
                    >
                      <translate>Change project roles</translate>
                    </el-button>
                  </el-dropdown-item>
                  <!--                  <el-dropdown-item>-->
                  <!--                    <el-button-->
                  <!--                      type="text"-->
                  <!--                      style="color: #606266"-->
                  <!--                      @click="changeRoles('organization', scope.row.orgRoles)"-->
                  <!--                    >-->
                  <!--                      <translate>Change organization roles</translate>-->
                  <!--                    </el-button>-->
                  <!--                  </el-dropdown-item>-->
                  <el-dropdown-item
                    v-if="scope.row.roles"
                  >
                    <el-button
                      type="text"
                      style="color: #606266"
                      @click="removeFromProject(scope.row)"
                    >
                      <translate>Remove from project</translate>
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <modal
      v-if="projectInfo"
      :show.sync="modal.addUser"
      modal-classes="modal-card"
    >
      <p class="text-center registration-text">
        <translate>Choose user and role for add to project</translate>
      </p>
      <div class="form-input-nova">
        <el-form
          status-icon
        >
          <el-form-item
            prop="username"
          >
            <el-select
              v-model="currentNewUser"
              :placeholder="'Username or email' | translate"
              class="registration-input"
              automatic-dropdown
              remote
              reserve-keyword
              filterable
              :remote-method="loadUsersToAdd"
              :loading="loading"
            >
              <el-option
                v-for="item of newUsers"
                :key="item.id"
                :label="item.username"
                :value="item.username"
              >
                <span style="float: left">{{ item.username }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.email }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="role"
          >
            <el-select
              v-model="currentRole"
              :placeholder="'Role' | translate"
              class="registration-input"
              automatic-dropdown
              clearable
            >
              <el-option
                v-for="item of projectInfo.roles"
                :key="item.id"
                :label="item.localized_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            class="row justify-content-center"
          >
            <el-button
              round
              type="success"
              class="custom-btn-success"
              @click="addUser"
            >
              <translate>Add user</translate>
            </el-button>
            <el-button
              round
              class="custom-btn"
              @click="toggleAddUserModal(false)"
            >
              <translate>Cancel</translate>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </modal>
    <modal
      :show.sync="modalFiles"
      modal-classes="modal-card"
    >
      <p class="text-center registration-text">
        <translate>Add task files</translate>
      </p>
      <div class="row mb-2">
        <el-form
          status-icon
          class="col-12"
        >
          <el-form-item
            prop="file"
            class="pb-2"
          >
            <vue2-dropzone
              id="dropzone"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
              @vdropzone-complete="(res) => dropzoneComplete(res)"
              @vdropzone-sending="(file, xhr, formData) => dropzoneSending(file, xhr, formData)"
            >
              <div class="dropzone-custom-content py-0">
                <p class="dropzone-custom-title">
                  <translate>Drag and drop to upload content!</translate>
                </p>
                <p class="subtitle">
                  <translate>...or click to select a file from your computer</translate>
                </p>
              </div>
            </vue2-dropzone>
          </el-form-item>
          <el-form-item
            v-if="messages.length !== 0"
          >
            <div class="row">
              <div
                v-for="(message, index) in messages"
                :key="`${index}-message`"
                class="col-12 py-0 text-center"
              >
                <span
                  v-translate="{ message: message }"
                  class="pb-2"
                >
                  File %{ message }
                </span>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            v-if="errors.length !== 0"
          >
            <div class="row">
              <div
                v-for="(error, index) in errors"
                :key="`${index}-error`"
                class="col-12 py-0 text-center"
              >
                <span
                  v-translate="{ error: error }"
                  class="pb-2"
                >
                  File %{ error }
                </span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  Input,
  Button,
  ButtonGroup,
  Image,
  Tooltip,
  Notification,
  Table,
  TableColumn,
  Select,
  Option,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Tag,
  Form,
  FormItem,
  Alert
} from 'element-ui'

import NovaHeader from '../HeaderLayout/NovaHeader'
import Modal from 'src/components/UIComponents/Modal'
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'ProjectEditPage',
  components: {
    Modal,
    NovaHeader,
    [Input.name]: Input,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Image.name]: Image,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Alert.name]: Alert,
    vue2Dropzone
  },
  props: {
    projectId: {
      type: Number,
      default() {
        return parseInt(this.$route.params.projectId)
      }
    },
    taskId: {
      type: String,
      default() {
        return this.$route.params.taskId
      }
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: '/',
        thumbnailMethod: 'contain',
        headers: {
          Authorization: this.$root.headers[0].value,
        },
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        addRemoveLinks: true,
        params: {},
      },
      modalFiles: false,
      namePage: this.$route.name,
      // Таск
      taskData: null,
      // Организация
      organization: null,
      // Проект
      projectInfo: null,
      // id пользователя который просматривает страницу
      currentUserId: this.$auth.user().id,
      // Является ли данный пользователь админом, овнером или админом проекта
      currentUserAdmin: false,
      // Содержимое таблицы
      tableData: null,
      // Текущие роли пользователя, роли которого редактируют
      currentRole: null,
      // Костыль для перезагрузки таблицы
      showTable: true,
      privaces: {
        private: this.$gettext('Private'),
        public: this.$gettext('Public')
      },
      loadingProject: false,
      loadingTable: false,
      currentNewUser: null,
      newUsers: null,
      loading: false,
      modal: {
        addUser: false,
      },
      projectProfiles: null,
      messages: [],
      errors: [],
    }
  },
  computed: {},
  watch: {
    modalFiles(newVal) {
      if (newVal === false) {
        this.$refs.myVueDropzone.removeAllFiles()
        this.messages.splice(0)
        this.errors.splice(0)
      }
    }
  },
  beforeMount() {
    this.$set(this.dropzoneOptions, 'url', `/api/projects/${this.projectId}/tasks/${this.taskId}/upload/`)
  },
  async mounted() {
    await this.loadTaskData()
    await this.loadOrganization()
  },
  methods: {
    dropzoneComplete(res) {
      if (res.status === 'success') {
        this.messages.push(`${res.name}${this.$gettext(' added')}`)
      } else {
        this.errors.push(`${res.name}${this.$gettext(' failed to load')}`)
      }
    },
    dropzoneSending(file, xhr, formData) {
      formData.set('FILES', [file])
    },
    /**
     * Проверка прав текущего пользователя на редактирование прав других пользователей в проекте
     */
    checkCurrentUserRights() {
      if (this.$auth.user().is_superuser) {
        this.currentUserAdmin = true
        return
      }
      this.projectProfiles.map(profile => {
        if (profile.user.id === this.currentUserId) {
          if (profile.roles) {
            profile.roles.map(role => {
              if (role.short_name === 'owner' || role.short_name === 'project_admin') {
                this.currentUserAdmin = true
              }
            })
          }
        }
      })
    },
    /**
     * Загрузка организаций
     */
    async loadOrganization() {
      try {
        const response = await this.axios.get(`/organizations/${this.projectInfo.organizations[0]}/`)
        this.organization = response.data
        await this.loadUsers()
      } catch (e) {
        console.error('Не получилось загрузить компанию', e)
      }
    },
    /**
     * Загрузка профилей пользователей в организации и их прав в проекте
     */
    async loadUsers() {
      this.loadingTable = true
      try {
        // Загрузка профилей в проекте
        const response = await this.axios.get(`/organizations/${this.projectInfo.organizations[0]}/projects/${this.projectId}/profiles/`)
        response.data.results.forEach((profile, index) => {
          profile.editRoles = false
          profile.readonly = false
          if (profile.roles.length) {
            const owner = profile.roles.findIndex(role => role.short_name === 'owner')
            if (owner >= 0) {
              profile.readonly = true
            }
          }
          if (profile.user.id === this.currentUserId) {
            profile.readonly = true
          }
        })
        this.projectProfiles = response.data.results
        // Проверяем права текущего пользователя
        this.checkCurrentUserRights()
        // Грузим пользователей в таблицу
        this.tableData = this.projectProfiles
      } catch (e) {
        console.error('Не  получилось загрузить пользователей', e)
      }
      this.loadingTable = false
    },
    /**
     * Загрузка ролей прикрепленных к проекту
     */
    async loadProjectRoles() {
      try {
        const response = await this.axios.get(`/organizations/${this.projectInfo.organizations[0]}/projects/${this.projectId}/roles/`)
        this.projectInfo.roles = response.data.results
      } catch (e) {
        console.error('Не получилось загрузить список ролей проекта', e)
      }
    },
    /**
     * Включение режима редактирования ролей
     * @param edit - scope.row из таблицы чтобы включить режим редактирования
     * @param roles - текущие роли пользователя в проекте
     */
    changeRoles(edit, roles) {
      edit.editRoles = true
      this.currentRole = roles[0].id
      this.terribleRerenderTable()
    },
    /**
     * Выключение режима редактирования ролей
     * @param scope - scope.row из таблицы чтобы выключить режим редактирования
     */
    closeEditRoles(scope) {
      scope.editRoles = false
      this.currentRole = []
      this.terribleRerenderTable()
    },
    /**
     * Смена ролей пользователя
     * @param user - пользователь
     * @param roles - роль
     */
    async changeProjectRoles(user, role) {
      try {
        // Объект с сообщениями для вывода в оповещения
        const messages = {
          success: this.$gettext('Roles added successfully'),
          abort: this.$gettext('An error occurred during adding roles'),
        }
        await this.changeRolesOfExistingProfile(user, [role], messages)
      } catch (e) {
        console.error('Не получилось добавить/поменять роли в проекте', e)
      }
    },
    /**
     * Смена ролей пользователя который уже есть в проекте
     * @param user - пользователь
     * @param roles - массив ролей
     * @param messages - объект с сообщениями для вывода в оповещения
     * @return - возвращает статус ответа сервера
     */
    async changeRolesOfExistingProfile(user, role, messages) {
      const response = await this.axios.patch(`/organizations/${this.projectInfo.organizations[0]}/projects/${this.projectId}/profiles/${user.id}/`, {
        roles: role
      })
      if (response.status === 200) {
        this.showSuccessNotification(messages.success)
        await this.loadUsers()
        this.terribleRerenderTable()
      } else {
        this.showDangerNotification(messages.abort)
        await this.loadUsers()
        this.terribleRerenderTable()
      }
      return response.status
    },
    /**
     * Удаление пользователя из проекта
     * @param user - пользователь
     */
    async removeFromProject(user) {
      try {
        // Объект с сообщениями для вывода в оповещения
        const messages = {
          success: this.$gettext('User successfully removed from project'),
          abort: this.$gettext('An error occurred during removing user from project')
        }
        await this.changeRolesOfExistingProfile(user, [], messages)
      } catch (e) {
        console.error('Не получилось удалить из проекта', e)
      }
    },
    /**
     * Загрузка информации о проекте
     */
    async loadProjectInfo() {
      try {
        const response = await this.axios.get(`/projects/${this.projectId}/`)
        this.projectInfo = response.data
        await this.loadProjectRoles()
      } catch (e) {
        console.error(`Не удалось загрузить информацию проекта ${this.projectId}`, e)
      }
    },
    /**
     * Загрузка информации о таске
     */
    async loadTaskData() {
      try {
        const response = await this.axios.get(`projects/${this.projectId}/tasks/${this.taskId}/`)
        this.taskData = response.data
      } catch (e) {
        console.error(`Не удалось загрузить информацию о задаче ${this.taskId} проекта ${this.projectId}`, e)
      }
      await this.loadProjectInfo()
    },
    /**
     * Проверка на наличие ассетов определенного типа
     * @param data - таск
     * @param type - тип данных
     */
    checkDataType(data, type) {
      const dataTypeProject = data.available_assets.includes(type)
      return !dataTypeProject ? 'no-layer' : ''
    },
    /**
     * Сохранить новое имя таска
     * @param - новое имя
     */
    async saveNewProjectName(newName) {
      const data = {
        name: newName
      }
      try {
        await this.axios.patch(`projects/${this.projectId}/tasks/${this.taskId}/`, data)
          .then(response => {
            if (response.status === 200) {
              this.showSuccessNotification(this.$gettext('Changes saved successfully'))
            } else {
              this.showDangerNotification(this.$gettext('Changes not saved'))
            }
          })
      } catch (e) {
        console.error('что-то пошло не так', e)
      }
    },
    /**
     * Загрузка списка пользователей доступных для добавления к проекту
     * @param search {String} - строка поиска по которой фильтруются пользователи на бэке
     */
    async loadUsersToAdd(search) {
      try {
        if (search !== '') {
          this.loading = true
          const response = await this.axios.get(`/organizations/${this.projectInfo.organizations[0]}/users/?search=${search}`)
          const users = response.data.results
          this.newUsers = users.filter(user => {
            let hasProfile = false
            this.projectProfiles.forEach(profile => {
              if (user.id === profile.user.id) {
                hasProfile = true
              }
            })
            if (!hasProfile) {
              return user
            }
          })
        }
      } catch (e) {
        console.error('Не получилось загрузить пользователей для добавления к проекту', e)
      }
      this.loading = false
    },
    /**
     * Включение модального окна добавления нового пользователя в проект
     * @param value Boolean открыто ли модальное окно
     */
    toggleAddUserModal(value) {
      this.modal.addUser = value
    },
    /**
     * Добавление пользователя к проекту
     */
    async addUser() {
      try {
        const messages = {
          success: this.$gettext('User added successfully'),
          abort: this.$gettext('An error occurred during adding user to project'),
        }
        const response = await this.axios.patch(`/organizations/${this.projectInfo.organizations[0]}/projects/${this.projectId}/roles/${this.currentRole}/add-user-to-project/`, {
          users: [{
            username: this.currentNewUser
          }]
        })
        if (response.status === 201) {
          this.showSuccessNotification(messages.success)
          this.toggleAddUserModal(false)
          await this.loadUsers()
          this.terribleRerenderTable()
        } else {
          this.showDangerNotification(messages.abort)
        }
      } catch (e) {
        console.error('', e)
      }
    },
    /**
     * Путь к ассетам таска
     * @param task - таск
     * @param asset - тип ассета
     */
    assetsPath(task, asset) {
      return this.$root.apiPath(`/api/projects/${this.projectId}/tasks/${task}/assets/${asset}`)
    },
    /**
     * Высвечивание оповещения об успехе
     * @param message Сообщение
     */
    showSuccessNotification(message) {
      Notification({
        title: this.$gettext('Saved'),
        message: message,
        type: 'success',
        position: 'top-right',
        showClose: false
      })
    },
    /**
     * Высвечивание оповещения о неудаче
     * @param message Сообщение
     */
    showDangerNotification(message) {
      Notification({
        title: this.$gettext('Not saved'),
        message: message,
        type: 'error',
        position: 'top-right',
        showClose: false
      })
    },
    /**
     * Ререндер таблицы посредством перекликивания v-if на ней
     */
    // TODO: без этого не обновляется таблица, но вообще надо избавиться от этого убожества
    terribleRerenderTable() {
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
    },
    toggleFilesModal(value) {
      this.modalFiles = value
    },
  }
}
</script>

<style scoped>
</style>
<style>
  .el-input.el-input--suffix:hover {
    color: #c4c6cf !important;
  }
  .custom-btn {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .custom-btn-success {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .custom-btn-success.el-button--success:hover, .custom-btn-success.el-button--success:active, .custom-btn-success.el-button--success:focus {
    color: #464646 !important;
  }
  .el-button--info:hover, .el-button--info:focus {
    background: transparent !important;
    color: #51cbce !important;
  }
  .el-button:hover, .el-button:focus {
    /*color: #51cbce !important;*/
    background: transparent !important;
  }
  .el-tag.el-tag--info {
    color: #ffffff !important;
  }
  .el-select .el-tag__close.el-icon-close {
    background-color: #ffffff !important;
    color: #464646 !important;
  }
  .registration-form-nova {
    background-color: #ffffff;
    border-radius: 32px;
  }
  .registration-text {
    /*padding-top: 52px;*/
    /*text-transform: uppercase;*/
    font: Bold 18px/24px Open Sans;
  }
  .registration-btn {
    background-color: #51cbce;
    border-radius: 15px;
  }
  .reset-btn {
    border-radius: 15px;
  }
  .registration-input {
    width: 100%;
  }
  .no-layer {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .absent-data-type {
    text-decoration: line-through !important;
  }
</style>
