<template>
  <div>
    <nova-header />
    <div class="container">
      <div class="context-section row justify-content-end mt-3">
        <div class="col-12 col-lg-4 d-flex justify-content-between px-0">
          <el-select
            v-if="organizations && organizations.length > 1"
            v-model="currentOrganization"
            @change="loadTasks"
          >
            <el-option
              v-for="(organization, key) in organizations"
              :key="key"
              :value="key"
              :label="organization.name"
            />
          </el-select>
          <el-button
            round
            style="font-size: 10px; font-weight: 700"
            @click="showSwal('password')"
          >
            <translate>Change password</translate>
          </el-button>
        </div>
      </div>
    </div>
    <div class="container">
      <div v-if="loadingUser" v-loading="loadingUser" style="height: 200px" />
      <user-profile-card v-if="user" ref="profile" v-model="user" />
      <!--      person projects table-->
      <div v-if="loadingTable" v-loading="loadingTable" style="height: 200px" />
      <div v-if="tasks" class="tables mt-5">
        <el-table
          v-if="showTable"
          ref="table"
          :data="tasks"
          style="width: 100%; font-weight: 700"
          row-class-name="custom-el-table-row"
        >
          <el-table-column :label="'Project' | translate" prop="name" />
          <el-table-column :label="'Access rights' | translate" prop="localizedRole" />
          <!-- экшены для моего профиля в текущем виде бесполезны, пользователь не видит проекты прав к которым у него нет и не видит свои права в проектах -->
          <!--          <el-table-column-->
          <!--            :label="'Actions' | translate"-->
          <!--            prop="actions"-->
          <!--            align="right"-->
          <!--          >-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-dropdown-->
          <!--                trigger="click"-->
          <!--                :hide-on-click="false"-->
          <!--                @command="(c) => handleTaskActions(c, scope.row)"-->
          <!--              >-->
          <!--                <el-button-->
          <!--                  size="mini"-->
          <!--                  type="info"-->
          <!--                  icon="icon nova-dots icon-rotate-90"-->
          <!--                  circle-->
          <!--                  @click="handleEdit(scope.$index, scope.row)"-->
          <!--                />-->
          <!--                <el-dropdown-menu-->
          <!--                  v-if="scope.row.id"-->
          <!--                  slot="dropdown"-->
          <!--                >-->
          <!--                  <el-dropdown-item-->
          <!--                    v-if="scope.row.role !== $gettext('None')"-->
          <!--                    command="exit"-->
          <!--                  >-->
          <!--                    <translate-->
          <!--                      key="exit"-->
          <!--                    >-->
          <!--                      Exit from project-->
          <!--                    </translate>-->
          <!--                  </el-dropdown-item>-->

          <!--                  <el-dropdown-item-->
          <!--                    v-else-->
          <!--                    command="rights"-->
          <!--                  >-->
          <!--                    <translate-->
          <!--                      key="rights"-->
          <!--                    >-->
          <!--                      Request edit rights-->
          <!--                    </translate>-->
          <!--                  </el-dropdown-item>-->
          <!--                </el-dropdown-menu>-->
          <!--              </el-dropdown>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
      </div>
    </div>
    <notifications />
  </div>
  <!--      end person projects table-->
</template>

<script>
import {
  Input,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Select,
  Option,
  Notification,
  Checkbox,
} from 'element-ui'
import NovaHeader from '@layouts/HeaderLayout/NovaHeader'
import UserProfileCard from '@layouts/UsersLayout/UserProfileCard'
import swal from 'sweetalert2'

export default {
  components: {
    NovaHeader,
    [Input.name]: Input,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    UserProfileCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      search: '',
      // Профиль пользователя
      user: {
        // опции редактирования фио и емэйла
        editable: {
          fullName: false,
          email: false,
        },
        // Профиль пользователя
        profile: {
          email: null,
          last_name: null,
          first_name: null,
          pathronomic: null,
        },
        // Текущая выбранная компания
        company: {
          name: null,
          country: null,
          city: null,
          // Айди пользователя в компании
          userId: null,
        },
        // количество доступных проектов в компании
        tasks: null,
      },
      // Проекты пользователя в текущей организации
      tasks: [],
      // Организации пользователя
      organizations: null,
      // Текущая организация
      currentOrganization: 0,
      showTable: true,
      loadingUser: false,
      loadingTable: false,
    }
  },
  async mounted() {
    // Получаем айди пользователя и грузим профиль
    const currentUserAuth = await this.$auth.user()
    this.user.profile.id = currentUserAuth.id
    this.loadUser(+this.user.profile.id)
  },
  methods: {
    handleEdit(index, row) {
      console.info(index, row)
    },
    handleDelete(index, row) {
      console.info(index, row)
    },
    /**
     * Функция которая загружает пользователя
     * @param userId айди пользователя
     * @returns {Promise<void>}
     */
    async loadUser(userId) {
      this.loadingUser = true
      try {
        const response = await this.axios.get(`/user/${userId}`)
        this.user.profile = response.data
        this.loadCompanies(this.user.profile.id)
      } catch (e) {
        console.error('Не удалось загрузить пользователя', e)
      }
      this.loadingUser = false
    },
    /**
     * Функция которая загружает компании в которых состоят и тот кто смотрит профайл и тот чей профайл
     * @param userId айди пользователя чей профайл
     * @returns {Promise<void>}
     */
    async loadCompanies(userId) {
      // TODO надо бы переделать запрос на бэке с фильтром по ид
      const response = await this.axios.get('/organizations/')
      this.organizations = response.data.results.filter((company) => {
        for (const user of company.users) {
          if (+user.id === +userId) {
            return true
          }
        }
      })
      this.user.company = this.organizations[this.currentOrganization]
      this.loadTasks()
    },
    /**
     * Функция которая загружает проекты доступные в компании пользователю
     * @returns {Promise<void>}
     */
    async loadTasks() {
      this.loadingTable = true
      try {
        this.tasks = null
        const projects = []
        // Грузим проекты в текущей организации доступные пользователю который смотрит профайл
        let response = await this.axios.get(
          `/organizations/${this.organizations[this.currentOrganization].id}/projects/`
        )
        let nextProjectsPage = response.data.next
        projects.push(...response.data.results)
        while (nextProjectsPage) {
          const response = await this.axios.get(nextProjectsPage)
          nextProjectsPage = response.data.next
          projects.push(...response.data.results)
        }
        // грузим задачи проектов
        let tasks = await Promise.all(
          projects.map(async (project) => {
            const response = await this.axios.get(`/projects/${project.id}/tasks/`)
            return response.data
          })
        )
        tasks = tasks.flat()
        this.user.tasks = tasks.length
        // грузим профайлы проектов
        let profiles = await Promise.all(
          projects.map(async (project) => {
            const response = await this.axios.get(
              `/organizations/${
                this.organizations[this.currentOrganization].id
              }/projects/${project.id}/profiles/`
            )
            return { results: response.data.results, project: project.id }
          })
        )
        profiles = profiles.flat()
        // Грузим проекты в организации доступные пользователю чей профайл чтобы взять права на эти проекты
        for (const task of tasks) {
          const taskProfiles = profiles.find(
            (profile) => profile.project === task.project
          )
          for (const profile of taskProfiles.results) {
            if (profile.id === this.user.company.userId) {
              task.role = profile.roles[0].short_name
              task.localizedRole = profile.roles[0].localized_name
            }
          }
          if (!task.role) {
            task.localizedRole = this.$gettext('None')
          }
        }
        // записываем айди юзера чей профайл в организации
        response = await this.axios.get(
          `/organizations/${this.organizations[this.currentOrganization].id}/profiles/`
        )
        response.data.results.map((profile) => {
          if (profile.user.id === this.user.profile.id) {
            this.user.company.userId = profile.id
            profile.roles.map((role) => {
              role.projects.map((project) => {
                tasks.map((task) => {
                  if (task.project === project) {
                    task.role = this.$gettext(role.short_name)
                  }
                  if (!task.role) {
                    task.role = this.$gettext('None')
                  }
                })
              })
            })
          }
        })
        this.tasks = tasks
        // не понял что не работает, чекбоксы заполняются корректно
        // setTimeout(this.terribleRerenderTable, 500)
      } catch (e) {
        console.error('Не удалось загрузить список проектов', e)
      }
      this.loadingTable = false
    },
    /**
     * Функция смены пароля
     */
    async changePassword() {
      try {
        const response = await this.axios.post(
          `/organizations/${
            this.organizations[this.currentOrganization].id
          }/users/forgot-password/`,
          {
            email: this.user.profile.email,
          }
        )
        if (response.status === 200) {
          this.showSuccessNotification(this.$gettext('Password recovery requested'))
          this.toggleChangePasswordModal(false)
        } else {
          this.showDangerNotification(
            this.$gettext('Password recovery request ends with errors')
          )
        }
      } catch (e) {
        console.error('Не удалось запросить смену пароля', e)
      }
    },
    /**
     * Обработка событий дропдауна в таблице проектов
     * @param action действие
     * @param task таск
     */
    async handleTaskActions(action, task) {
      switch (action) {
        case 'exit':
          break
        case 'rights':
          break
      }
    },
    /**
     * Высвечивание оповещения об успехе
     * @param message Сообщение
     */
    showSuccessNotification(message) {
      Notification({
        title: this.$gettext('Saved'),
        message: message,
        type: 'success',
        position: 'top-right',
        showClose: false,
      })
    },
    /**
     * Высвечивание оповещения о неудаче
     * @param message Сообщение
     */
    showDangerNotification(message) {
      Notification({
        title: this.$gettext('Not saved'),
        message: message,
        type: 'error',
        position: 'top-right',
        showClose: false,
      })
    },
    /**
     * Предупреждение при запуске смены пароля
     * @param type - тип оповещения
     */
    showSwal(type) {
      if (type === 'password') {
        const self = this
        swal({
          title: this.$gettext('Are you sure?'),
          text: this.$gettext('Leave a password request?'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'action-btn save-btn py-3 w-50',
          cancelButtonClass: 'action-btn cancel-btn py-3 w-50',
          confirmButtonText: this.$gettext('Yes, change password!'),
          cancelButtonText: this.$gettext('Cancel'),
          buttonsStyling: false,
        }).then(() => {
          self.changePassword()
        })
      }
    },
    /**
     * Ререндер таблицы посредством перекликивания v-if на ней
     */
    // TODO: без этого не обновляется таблица, но вообще надо избавиться от этого убожества
    terribleRerenderTable() {
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
    },
  },
}
</script>

<style scoped>
.el-button--info {
  background: #747474 0 0 no-repeat padding-box;
  border-color: #747474;
}
.el-input__inner {
  background-color: #0c2646;
}
.action-btns {
  margin: 0;
}
.action-btns .el-button.is-circle {
  background: #464646;
  color: #ffffff;
  border: none;
  font-size: 14px;
}
/*!* main content *!*/
.font-size-10 {
  font-weight: 700;
  font-size: 10px;
}
.count-person {
  font-weight: 700;
}
.card-bottom {
  background: #808080;
  border-radius: 0 0 5px 5px;
}
.card__person-status i {
  font-size: 16px;
}
</style>
<style>
.el-button:hover,
.el-button:focus {
  /*color: #51cbce !important;*/
  background: transparent !important;
}
.custom-el-table-row {
  color: white;
}
.custom-el-table-row:hover > td {
  background-color: #737373 !important;
}
.for-guide {
  background-color: rgb(244, 243, 239);
}
</style>
