<template>
  <div>
    <nova-header />
    <div class="container">
      <div class="context-section row justify-content-between mt-3">
        <div class="search-form col-4 col-lg-4 col-xl-2 mr-auto">
          <search-input ref="search" />
        </div>

        <div class="ml-auto col-auto">
          <el-button
            type="success"
            round
            style="font-size: 10px; font-weight: 700"
            @click="toggleNewTaskModal(true)"
          >
            <translate>Add task</translate>
          </el-button>
        </div>
        <div class="section-action d-flex justify-content-between col-auto">
          <div class="action-buttons" />
          <div class="view-selector">
            <el-button-group>
              <el-button
                type="info"
                icon="icon nova-gallery-view"
                class="change-view-button"
                @click="changeView('grid')"
              />
              <el-button
                type="info"
                icon="icon nova-bullet-list-70"
                class="change-view-button"
                @click="changeView('list')"
              />
            </el-button-group>
          </div>
        </div>
      </div>
      <div v-if="currentProjectTasks.length === 0" class="row justify-content-center">
        <span><translate>No tasks found. Create your first task</translate></span>
      </div>
      <div v-if="view === 'grid'" v-loading="loading">
        <tasks-page-grid :current-project-tasks="currentProjectTasks" />
      </div>
      <div v-if="view === 'list'" v-loading="loading">
        <tasks-page-list :current-project-tasks="currentProjectTasks" />
      </div>
      <el-button
        v-if="nextTasksPage"
        icon="el-icon-arrow-down"
        class="fixed-scroll-button"
        @click="scrollBottom"
      >
        <span><translate>Scroll down to load more tasks</translate></span>
      </el-button>
    </div>
    <modal :show.sync="modal" modal-classes="modal-card">
      <p class="text-center registration-text">
        <translate>Create new task</translate>
      </p>
      <div class="row mb-2">
        <el-form ref="newTask" :model="newTask" status-icon class="col-12">
          <el-form-item v-if="!newTask.id" prop="name" class="pt-2 pb-0">
            <el-input
              v-model="newTask.name"
              name="newTaskName"
              class="registration-input py-2"
              :placeholder="'New task name' | translate"
              type="text"
            />
          </el-form-item>
          <el-form-item v-if="newTask.name" prop="name" class="py-0">
            <div class="row">
              <div class="col-12 py-0 text-center">
                <el-button
                  round
                  type="success"
                  class="custom-btn-success"
                  @click="addTask"
                >
                  <translate>Create task</translate>
                </el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="message">
            <div class="row">
              <div class="col-12 py-0 text-center">
                <span v-translate="{ message: message }" class="pb-2">
                  Task %{ message } created
                </span>
              </div>
              <div class="col-12 py-0 text-center">
                <span v-translate class="pb-2">
                  Go to edit to add files to the task
                </span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!--      <div-->
      <!--        class="row mt-2"-->
      <!--      >-->
      <!--        <div class="col-12 text-center">-->
      <!--          <el-button-->
      <!--            round-->
      <!--            type="success"-->
      <!--            class="custom-btn-success"-->
      <!--          >-->
      <!--            <translate>Ok</translate>-->
      <!--          </el-button>-->
      <!--          <el-button-->
      <!--            type="danger"-->
      <!--            round-->
      <!--            class="custom-btn"-->
      <!--            @click="toggleNewTaskModal(false)"-->
      <!--          >-->
      <!--            <translate>Cancel</translate>-->
      <!--          </el-button>-->
      <!--        </div>-->
      <!--      </div>-->
    </modal>
  </div>
</template>

<script>
import NovaHeader from '../HeaderLayout/NovaHeader'
import SearchInput from '../SearchInput'
import {
  Input,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  FormItem,
  Form,
} from 'element-ui'
import TasksPageGrid from './TasksPageGrid'
import TasksPageList from './TasksPageList'
import Modal from 'src/components/UIComponents/Modal'

export default {
  name: 'ProjectDetail',
  components: {
    NovaHeader,
    SearchInput,
    [Input.name]: Input,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    TasksPageGrid,
    TasksPageList,
    Modal,
  },
  data() {
    return {
      message: '',
      currentProject: {},
      nextTasksPage: null,
      currentProjectTasks: [],
      view: 'grid',
      loading: false,
      modal: false,
      newTask: {},
      tasks: [],
      uploadFiles: [],
    }
  },
  mounted() {
    this.loadProjectTasks()
  },
  methods: {
    /**
     * Добавляем задачу
     */
    async addTask() {
      try {
        const URL = `/projects/${this.$route.params.projectId}/tasks/`
        const response = await this.axios.post(URL, { name: this.newTask.name })
        if (response.status === 201) {
          response.data.name = this.newTask.name
          this.message = this.newTask.name
          this.newTask.name = ''
          this.currentProjectTasks.unshift(response.data)
        }
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Загружаем таски текущего проекта
     * @return {Promise<void>}
     */
    async loadProjectTasks() {
      try {
        const URL = `/projects/${this.$route.params.projectId}/tasks/`
        const response = await this.axios.get(URL)
        if (response.data.next) {
          this.nextTasksPage = response.data.next
        } else {
          this.nextTasksPage = null
        }
        if (response.status === 200) {
          this.currentProjectTasks.push(...response.data)
        }
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Загружаем тамбнейлы тасок
     * @param task - таска
     * @param asset - название тамбнейла
     * @return {string}
     */
    assetsPath(task, asset) {
      return this.$root.apiPath(
        `/api/projects/${task.project}/tasks/${task.id}/assets/${asset}`
      )
    },

    /**
     * Скролл до низа страницы
     */
    scrollBottom() {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
    },
    /**
     * Смена вида табличный/плитка
     * @param type
     */
    changeView(type) {
      this.view = type
    },
    /**
     * Включение модального окна добавления нового пользователя
     * @param value Boolean открыто ли модальное окно
     */
    toggleNewTaskModal(value) {
      this.modal = value
      if (!value) {
        this.newTask = {}
      }
    },
  },
}
</script>

<style scoped>
.el-button--info {
  background: #747474 0 0 no-repeat padding-box;
  border-color: #747474;
}
.el-button-group .el-button--info {
  font-size: 16px;
  padding: 9px;
}
.el-button-group .el-button--info {
  font-size: 16px;
  padding: 9px;
}
.el-button--info {
  background: #747474 0 0 no-repeat padding-box;
  border-color: #747474;
}
.el-button-group .el-button--info:first-child {
  margin-right: 5px;
  border-right-color: #747474;
}
.el-button-group .el-button--info:last-child {
  border-left-color: #747474;
}
.el-button--info:hover,
.el-button--info:focus {
  background: transparent !important;
  color: #51cbce !important;
}
.change-view-button:hover,
.change-view-button:focus {
  /*color: #51cbce !important;*/
  background: transparent !important;
}
.el-input__inner {
  background: #4cd964 !important;
}
.btn-run-guide {
  position: fixed;
  top: 28px;
  right: 50px;
}
</style>

<style src="vue2-dropzone/dist/vue2Dropzone.min.css"></style>
