/**
 * @file Определяет  класс Annotations
 */
import { Annotation } from './Annotation'
/**
 * Класс объединяющий все аннотации
 */
class Annotations {
  /**
   * @param vm {VueComponent} объект в котором создается аннотация, в нашем случае ViewerLayout
   * @param data {Array.<Object>} массив объектов в котором содержатся объекты класса Annotation
   */
  constructor(vm, data) {
    this.vm = vm
    this.data = []
    data = data || []
    this.createAnnotations(data)
  }

  /**
   * Функция для сохранения всех аннотаций на беке
   * @return {undefined}
   */
  async save() {
    try {
      const result = await this.vm.axios.patch(
        `projects/${this.vm.project}/tasks/${this.vm.task}/`,
        { annotations: this.exportData }
      )
      this.removeAnnotations()
      this.createAnnotations(result.data.annotations)
      this.vm.importAnnotations(this)
      return result.status
    } catch (e) {
      return e.response.status
    }
  }

  /**
   * Функция для добавления аннотаций в список аннотаций
   * @param data {Object} объект для добавления в список аннатоций
   * @param inTop {boolean} - параметр определяющий добавлять ли аннотацию в списке вверх
   * @return {undefined}
   */
  add(data, inTop = false) {
    if (data instanceof Annotation) {
      if (inTop) {
        this.data.unshift(data)
      } else {
        this.data.push(data)
      }
    } else {
      this.data.push(new Annotation(this.vm, data))
    }
  }

  /**
   * Функция для добавления всего массива объектов в список аннотаций
   * @param data {Array.<Object>} - массив объектов аннотаций
   * @return {undefined}
   */
  createAnnotations(data) {
    data.map((annotationData) => {
      this.add({ ...annotationData, $annotations: this })
    })
  }

  /**
   * Функция для удаления всех аннотаций
   * @return {undefined}
   */
  removeAnnotations() {
    while (this.data.length > 0) {
      this.data[0].remove()
    }
  }

  updateAllMeasures() {
    this.allMeasures.map((measure) => measure.updateCalculations())
  }

  /**
   * @return {Number}
   */
  get length() {
    return this.data.length
  }

  /**
   * @return {boolean}
   */
  get changed() {
    // TODO: Здесь будет проверка есть ли изменения в аннотациях
    return true
  }

  /**
   * @return {Array.<Object>}
   */
  get exportData() {
    return this.data.map((annotation) => annotation.exportData)
  }

  /**
   * @return {Array.<Measure>}
   */
  get allMeasures() {
    const measures = []
    this.data.map((annotation) => {
      annotation.measures.map((measure) => {
        measures.push(measure)
      })
    })
    return measures
  }
}

export { Annotations }
