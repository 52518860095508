/**
 * @file Определяет  класс Circle3D
 */
import { Measure } from '@letsnova/potree/src/utils/Measure'
import * as THREE from 'three'
/**
 * Класс Circle3D для отображения круга в 3D
 */
export default class Circle3D extends Measure {
  /**
   * @param centerPoint {Vector3} - центральная точка вокруг которой строится круг
   * @param radius {Number} - радиус круга
   */
  constructor(centerPoint, radius, dashed, dashSize, gapSize) {
    super()

    this.centerPoint = centerPoint
    this.radius = radius
    this.dashed = dashed || false
    this.dashSize = dashSize || 5
    this.gapSize = gapSize || 5

    this.addMarker(centerPoint)
  }

  createMesh() {
    if (this.mesh) {
      this.remove(this.mesh)
    }
    if (this.circleLine) {
      this.remove(this.circleLine)
    }
    this.circleGeometry = new THREE.CircleGeometry(this.radius, 128)
    this.circleLineGeometry = this.createEdgeGeometry(this.radius, 128)
    // this.material = this.createMeshMaterial()
    this.circleLine = new THREE.Line(this.circleLineGeometry, this.createEdgeMaterial())
    this.circleLine.position.set(
      this.centerPoint.x,
      this.centerPoint.y,
      this.centerPoint.z
    )
    this.circleLine.visible = true
    this.circleLine.computeLineDistances()

    this.mesh = new THREE.Mesh(this.circleGeometry, this.createMeshMaterial(0.4))
    this.mesh.position.set(this.centerPoint.x, this.centerPoint.y, this.centerPoint.z)
    this.mesh.visible = this.$measure.fill

    this.add(this.mesh)
    this.add(this.circleLine)
  }

  /**
   * Создание простого круга с стандартным радиусом в 3D
   * @param centerPoint - центр круга
   */
  createSimple3D(centerPoint) {
    this.centerPoint = centerPoint
    this.radius = 10

    // Задаем центр для замера
    this.$measure.setCenterPoint(centerPoint)
    this.createMesh()
  }

  /**
   * Возвращает материал для меша замера
   * @param opacity - прозрачность материала
   * @return {MeshBasicMaterial}
   */
  createMeshMaterial(opacity = 1) {
    return new THREE.MeshBasicMaterial({
      color: this.color,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: opacity,
    })
  }

  createEdgeMaterial() {
    let material = {}
    if (this.dashed) {
      material = new THREE.LineDashedMaterial({
        color: this.color,
        linewidth: 2,
        dashSize: this.dashSize,
        gapSize: this.gapSize,
      })
    } else {
      material = new THREE.LineBasicMaterial({
        color: this.color,
        linewidth: 2,
      })
    }
    material.resolution = new THREE.Vector2(1000, 100)
    return material
  }

  createEdgeGeometry(radius, divisions = 128) {
    const vertices = []

    for (let i = 0; i <= divisions; i++) {
      const v = (i / divisions) * (Math.PI * 2)

      const x = radius * Math.sin(v)
      const y = radius * Math.cos(v)

      vertices.push(x, y, 0)
    }

    const circleLineGeometry = new THREE.BufferGeometry()
    circleLineGeometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3)
    )
    return circleLineGeometry
  }

  /**
   * @inheritDoc
   */
  getArea() {
    return Math.PI * this.radius * this.radius
  }

  /**
   *@inheritDoc
   */
  getTotalDistance() {
    return 2 * Math.PI * this.radius
  }
}
